import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./PrivacyPolicy.css";
import logo3 from "./../assets/images/logo3.png";
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = { render: false };
    window.scrollTo(0, 0);
  }
  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }

  contactUs() {
    this.props.history.push({
      pathname: "/home",
      state: {
        scrollTo: "contact",
      },
    });
  }

  render() {
    return (
      <>
        <div>
          <nav
            id="ptsHomeHeader"
            style={{ padding: 0 }}
            className="navbar navbar-default bootsnav navbar-fixed no-background white"
          >
            <div style={{ backgroundColor: "#054e63" }}>
              <div className="navbar-header">
                <button
                  type="button"
                  className="mobile-nav-toggle d-lg-none text-end"
                  style={{ marginRight: "-5em", marginTop: "2em" }}
                  onClick={(e) => this.openMenu()}
                >
                  <i className="fa fa-bars" style={{ fontSize: "1.5em" }}></i>
                </button>{" "}
                <span style={{ display: "inline-flex", margin: "1.5em 8.5em" }}>
                  <img
                    src={logo3}
                    className="logo"
                    alt=""
                    style={{ width: "44px" }}
                    width="auto"
                    height="auto"
                  />
                  &nbsp;&nbsp;
                  <h3
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      margin: "auto",
                    }}
                  >
                    Taxonomy Hub
                  </h3>
                </span>
                {/* </a> */}
              </div>
              <div className="collapse navbar-collapse" id="navbar-menu">
                <ul
                  className="nav navbar-nav navbar-right"
                  style={{ flexDirection: "inherit" }}
                >
                  <li>
                    <a
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={() => this.navigateToLink("home")}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => this.navigateToLink("/login")}
                      style={{ cursor: "pointer" }}
                    >
                      Login
                    </a>
                  </li>
                  {/* <li>
                      <a
                        onClick={() =>
                          this.navigateToLink("/personalDetailsReg")
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Register
                      </a>
                    </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
        {/* <section id="termsCondition" className="contact" style={{ padding: '75px 50px 20px 50px' }}> */}
        <div
          className="termsBanner"
          id="top"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <div
            className="container termsConditionsUI"
            style={{ border: "1px solid lightgray", padding: "2em" }}
          >
            <Row>
              <Col md={12}>
                <h3 style={{ color: "#000", textAlign: "center" }}>
                  <b>
                    <u>Privacy Policy</u>
                  </b>
                </h3>
              </Col>
            </Row>
            <Row
              className="privacyPolicy"
              style={{
                marginTop: "1em",
                fontWeight: "400",
                lineHeight: "28px",
              }}
            >
              <Col md={12} style={{ textAlign: "left" }}>
                <p>
                  <strong>Proto Technology Solutions Private Limited </strong>
                  ("we", "us", or "our") operates the{" "}
                  <strong>TaxonomyHub</strong> mobile application (the "App").
                  This Privacy Policy informs you about our policies regarding
                  the collection, use, and disclosure of personal information
                  when you use our App.
                </p>
                <p>
                  By downloading, installing, and using the App, you agree to
                  the collection and use of information in accordance with this
                  Privacy Policy. If you do not agree with the terms, please do
                  not use the App.
                </p>
                <p>
                  We worried about customer and user privacy for almost 9 years,
                  long before it became fashionable, politically correct, or
                  legally binding to take such a position. We ask for only the
                  least amount of information necessary, gathering only what we
                  believe is essential for doing business, or for the specific
                  transaction at hand. We let customers know the information we
                  have on them and allow them to opt out of specific
                  engagements. But, by far, our biggest commitment is that we do
                  not make a single dollar from advertising revenue—never have,
                  never will—even from the free editions of our products. This
                  means we avoid the fundamental conflict of interest between
                  gathering customer information and fuelling advertising
                  revenue, and the unavoidable compromises in customer privacy
                  that it brings.
                </p>
                <p>
                  The goal of this policy is to make explicit the information we
                  gather, how we will use it, and how we will not. This policy
                  is unfortunately longer than we would like, but we must
                  unambiguously address all the relevant cases. We will try and
                  keep the language simple and direct as much as possible.
                </p>
                <ol>
                  <li>
                    <p className="fw-bold">Information We Collect</p>
                    <p>
                      We only collect the information that we need. Some of that
                      is information that you actively give us when you sign up
                      for an account, register for an event, ask for customer
                      support, or buy something from us. We store your name and
                      contact information, but we don't store credit card
                      numbers in our system.
                    </p>
                    <p>
                      We may collect different types of information to improve
                      your experience with the App. This may include:
                    </p>
                    <ol>
                      <li className="mb-2">
                        <p className="fw-bold">
                          Personal Information Provided during account signup{" "}
                        </p>
                        <p>
                          We may collect the following information when you
                          provide it directly through the App:
                        </p>
                        <ul className="ms-4">
                          <li>Name</li>
                          <li>Email address</li>
                          <li>Phone number</li>
                          <li>Profile details (e.g., username, logo)</li>
                          <li>
                            Any other information you voluntarily provide when
                            using specific features
                          </li>
                        </ul>
                      </li>
                      <li className="mb-2">
                        <p className="fw-bold">
                          Automatically Collected Information
                        </p>
                        <p>
                          When you use the App, we may automatically collect:
                        </p>
                        <ul className="ms-4">
                          <li>
                            <strong>Device Information:</strong> The type of
                            mobile device you use, your device's unique ID, IP
                            address, operating system version, and app usage
                            statistics.
                          </li>
                          <li>
                            <strong>Log Information:</strong> Information about
                            how you interact with the App, including time spent,
                            features accessed, and crash reports
                          </li>
                          <li>
                            <strong>Location Data:</strong> If you enable
                            location services, we may collect and process your
                            location information for location-based features.
                          </li>
                        </ul>
                      </li>
                      <li className="mb-2">
                        <p className="fw-bold">Testimonials</p>
                        <p>
                          When you authorize us to post testimonials about our
                          products and services on websites, we may include your
                          name and other personal information in the
                          testimonial. You will be given an opportunity to
                          review and approve the testimonial before we post it.
                        </p>
                      </li>
                      <li className="mb-2">
                        <p className="fw-bold">Third-Party Services</p>
                        <p>
                          We may use third-party services like analytics
                          providers (e.g., Google Analytics) that collect,
                          monitor, and analyse this data to help us improve the
                          performance and functionality of the App.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="fw-bold"> How We Use Your Information</p>

                    <p>We use the collected information to:</p>
                    <ul className="ms-4">
                      <li>Provide and maintain the App.</li>
                      <li>Personalize your experience within the App.</li>
                      <li>Improve app performance and features.</li>
                      <li>
                        Respond to customer service requests and provide
                        support.
                      </li>
                      <li>Notify you of changes or updates to the App.</li>
                      <li>
                        Monitor usage patterns and trends to better understand
                        how users interact with the App.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p className="fw-bold">
                      Sharing and Disclosure of Information
                    </p>

                    <p>
                      We do not sell or rent your personal information. We may
                      share your data in the following cases:
                    </p>
                    <ul className="ms-4">
                      <li>
                        <strong>With Service Providers:</strong> We may share
                        your information with third-party service providers who
                        help us manage or operate the App (e.g., cloud hosting,
                        data analytics).
                      </li>
                      <li>
                        <strong>Legal Compliance:</strong> If required by law,
                        we may disclose your information to comply with legal
                        obligations, protect our rights, or cooperate with
                        investigations.
                      </li>
                      <li>
                        <strong>Business Transfers:</strong> If we are involved
                        in a merger, acquisition, or sale of assets, your
                        information may be transferred as part of that
                        transaction.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p className="fw-bold">Third-Party Services and Links</p>
                    <p>
                      The App may contain links to third-party services or
                      websites. We do not control these services and are not
                      responsible for their privacy practices. We encourage you
                      to review the privacy policies of any third-party services
                      you interact with.
                    </p>
                  </li>
                  <li>
                    <p className="fw-bold">Security of Your Information</p>
                    <p>
                      We take reasonable measures to protect your personal data
                      from unauthorized access, alteration, disclosure, or
                      destruction. However, please note that no method of
                      transmission over the internet or method of electronic
                      storage is 100% secure, and we cannot guarantee absolute
                      security.
                    </p>
                  </li>
                  <li>
                    <p className="fw-bold">Data Retention</p>
                    <p>
                      We will retain your personal data only for as long as is
                      necessary for the purposes set out in this Privacy Policy
                      or as required by applicable laws. Once the data is no
                      longer needed, we will delete it or anonymize it.
                    </p>
                  </li>
                  <li>
                    <p className="fw-bold">Your Privacy Rights</p>
                    <p>
                      Depending on your location, you may have rights regarding
                      your personal data, including:
                    </p>
                    <ul className="ms-4">
                      <li>
                        <strong>Right to Access:</strong> You can request to see
                        the personal data we have about you.
                      </li>
                      <li>
                        <strong>Right to Correct:</strong> You can request
                        corrections to any inaccuracies in your data.
                      </li>
                      <li>
                        <strong>Right to Delete:</strong> You can request that
                        we delete your personal data under certain conditions.
                      </li>
                      <li>
                        <strong>Right to Object:</strong> You can object to how
                        we process your personal data.
                      </li>
                      <p>
                        To exercise any of these rights, please &nbsp;
                        <b>
                          <a
                            onClick={this.contactUs.bind(this)}
                            style={{ cursor: "pointer", color: "#337AB7" }}
                          >
                            <b>CONTACT US.</b>
                          </a>
                        </b>
                      </p>
                    </ul>
                  </li>
                  <li>
                    <p className="fw-bold">Children’s Privacy</p>
                    <p>
                      Our App is not directed to individuals under the age of
                      13, and we do not knowingly collect personal data from
                      children. If we become aware that we have collected
                      personal data from a child under 13 without parental
                      consent, we will take steps to delete such information.
                    </p>
                  </li>
                  <li>
                    <p className="fw-bold">Changes to This Privacy Policy</p>
                    <p>
                      We may update this Privacy Policy from time to time. We
                      will notify you of any changes by posting the new Privacy
                      Policy on this page. Changes are effective immediately
                      upon posting.
                    </p>
                  </li>
                  <li>
                    <p className="fw-bold">Contact Us</p>

                    <p>
                      If you have any questions about this Privacy Policy or our
                      data practices, please contact us at:
                    </p>
                    <ul className="ms-4">
                      <li>
                        <strong>Email:</strong>{" "}
                        <a
                          href="mailto:taxonomyhub@prototsolutions.com"
                          target="_blank"
                          style={{
                            cursor: "pointer",
                            color: "#337AB7",
                            fontWeight: "bold",
                          }}
                        >
                          taxonomyhub@prototsolutions.com
                        </a>
                      </li>
                      <li>
                        <strong>Address:</strong> 105/106 Silver chambers,
                        Vaiduwadi, Hadapsar, Pune 411028
                      </li>
                    </ul>
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
        </div>
        {/* </section> */}
        <footer style={{ background: "#000" }}>
          <div className="container termsConditionsUI">
            <div className="row">
              <div className="col-lg-6" style={{ margin: "auto" }}>
                <div
                  className="copyright"
                  style={{ fontSize: "12px", color: "#fff" }}
                >
                  © Copyright
                  <strong>
                    <span> AssetTrackifier</span>
                  </strong>
                  . All Rights Reserved
                </div>
              </div>
              <div className="col-lg-3"></div>
              <div className="col-lg-3">
                <ul
                  style={{
                    float: "right",
                    listStyle: "none",
                    lineHeight: "3em",
                    margin: "0 100px 0px 0",
                  }}
                >
                  <li style={{ textAlign: "left" }}>
                    <i
                      className="fa fa-angle-right"
                      style={{ color: "#fff" }}
                    />

                    <a
                      onClick={() => this.navigateToLink("/termsConditions")}
                      style={{ color: "#fff", cursor: "pointer" }}
                    >
                      {" "}
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li style={{ textAlign: "left" }}>
                    <i
                      className="fa fa-angle-right"
                      style={{ color: "#fff" }}
                    />

                    <a
                      onClick={() => this.navigateToLink("/privacypolicy")}
                      style={{ color: "#fff", cursor: "pointer" }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default PrivacyPolicy;

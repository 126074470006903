import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import ToggleButton from "@mui/material/ToggleButton";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import CancelButton from "../buttons/CancelButton";
import CreateButton from "../buttons/CreateButton";
import DeleteIconButton from "../buttons/DeleteIconButton";
import EditIconButton from "../buttons/EditIconButton";
import SaveButton from "../buttons/SaveButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./BankAccount.scss";
import BankAccountList from "./BankAccountList";
export class BankAccount extends Component {
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;

  constructor(props) {
    super(props);

    let bankAccount = props.data;
    if (bankAccount) {
      this.state = {
        id: null === bankAccount.id ? "" : bankAccount.id,
        accountholder:
          null === bankAccount.accountholder ? "" : bankAccount.accountholder,
        bankname: null === bankAccount.bankname ? "" : bankAccount.bankname,
        accountnum:
          null === bankAccount.accountnum ? "" : bankAccount.accountnum,
        ifsc: null === bankAccount.ifsc ? "" : bankAccount.ifsc,
        swiftcode: null === bankAccount.swiftcode ? "" : bankAccount.swiftcode,
        accountname:
          null === bankAccount.accountname ? "" : bankAccount.accountname,
        address: null === bankAccount.address ? "" : bankAccount.address,
        bankAccounts: props.bankAccounts,
        isdefault:
          null === bankAccount.isdefault ? false : bankAccount.isdefault,
        onChangeSettings: this.props.onChangeSettings,
        action: this.props.action,
      };
    } else {
      this.state = {
        id: "",
        accountholder: "",
        bankname: "",
        accountnum: "",
        ifsc: "",
        swiftcode: "",
        accountname: "",
        address: "",
        isdefault: false,
        bankAccounts: props.bankAccounts,
        onChangeSettings: this.props.onChangeSettings,
        action: this.props.action,
      };
    }
  }

  handleAccountHolderChange(e) {
    this.setState({ accountholder: e.target.value });
  }
  handleBankNameChange(e) {
    this.setState({ bankname: e.target.value });
  }
  handleAccountNumberChange(e) {
    this.setState({ accountnum: e.target.value });
  }
  handleAccountNameChange(e) {
    this.setState({ accountname: e.target.value });
  }
  handleIFSCChange(e) {
    this.setState({ ifsc: e.target.value });
  }
  handleBankAddressChange(e) {
    this.setState({ address: e.target.value });
  }
  handleSwiftCodeChange(e) {
    this.setState({ swiftcode: e.target.value });
  }

  handleIsDefaultChange(selectedState) {
    this.setState({ isdefault: !this.state.isdefault });
  }
  saveBankAccount() {}
  createBankAccountInBackend(e) {
    /*
       Validate Required Fields
    */
    e.preventDefault();
    // const form = e.target;

    //GenericValidator.validateForm(form);

    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      bankAccount: {
        id: this.state.id,
        bankname: this.state.bankname,
        accountholder: this.state.accountholder,
        address: this.state.address,
        ifsc: this.state.ifsc,
        swiftcode: this.state.swiftcode,
        accountname: this.state.accountname,
        accountnum: this.state.accountnum,
        isdefault: this.state.isdefault,
      },
      header: header,
    };
    FetchServerData.callPostService("/bankaccountms/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let bankAccountData = output.data;
          let newData = {
            id: bankAccountData.id,
            bankname: bankAccountData.bankname,
            accountholder: bankAccountData.accountholder,
            address: bankAccountData.address,
            ifsc: bankAccountData.ifsc,
            swiftcode: bankAccountData.swiftcode,
            accountname: bankAccountData.accountname,
            accountnum: bankAccountData.accountnum,
            isdefault: bankAccountData.isdefault,
            action: "show_list",
            render: true,
            isAdd: true,
          };
          PtsAlert.success(output.message);
          this.props.onChangeSettings(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }

  updateBankAccountInBackend(e) {
    /*
       Validate Required Fields
    */
    e.preventDefault();
    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      bankAccount: {
        id: this.state.id,
        bankname: this.state.bankname,
        accountholder: this.state.accountholder,
        address: this.state.address,
        ifsc: this.state.ifsc,
        swiftcode: this.state.swiftcode,
        accountname: this.state.accountname,
        accountnum: this.state.accountnum,
        isdefault: this.state.isdefault,
      },
      header: header,
    };
    FetchServerData.callPostService("/bankaccountms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let bankAccountData = output.data;
          let newData = {
            id: bankAccountData.id,
            bankname: bankAccountData.bankname,
            accountholder: bankAccountData.accountholder,
            address: bankAccountData.address,
            ifsc: bankAccountData.ifsc,
            swiftcode: bankAccountData.swiftcode,
            accountname: bankAccountData.accountname,
            accountnum: bankAccountData.accountnum,
            isdefault: bankAccountData.isdefault,
            action: "show_list",
            render: true,
            isUpdate: true,
          };
          PtsAlert.success(output.message);
          this.props.onChangeSettings(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }

  deleteBankAccount() {
    /* Go Back to List Page */
    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      bankAccount: {
        id: this.state.id,
        bankname: this.state.bankname,
        accountholder: this.state.accountholder,
        address: this.state.address,
        ifsc: this.state.ifsc,
        swiftcode: this.state.swiftcode,
        accountname: this.state.accountname,
        accountnum: this.state.accountnum,
        isdefault: this.state.isdefault,
      },
      header: header,
    };
    FetchServerData.callPostService("/bankaccountms/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let bankAccountData = output.data;
          let newData = {
            id: bankAccountData.id,
            bankname: bankAccountData.bankname,
            accountholder: bankAccountData.accountholder,
            address: bankAccountData.address,
            ifsc: bankAccountData.ifsc,
            swiftcode: bankAccountData.swiftcode,
            accountname: bankAccountData.accountname,
            isdefault: bankAccountData.isdefault,
            action: "show_list",
            render: true,
            isDelete: true,
          };
          PtsAlert.success(output.message);
          this.props.onChangeSettings(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }

  modifyBankAccount() {
    /* Go Back to Edit Page */
    this.setState({ action: "edit" });
  }
  cancel() {
    /* Go Back to List Page */
    this.setState({ action: "show_list" });
  }

  viewAccount() {
    /* Go Back to View Page */
    this.setState({ action: "view" });
  }

  render() {
    if (this.state.action === "show_list") {
      return (
        <BankAccountList
          data={this.state.bankAccounts}
          onChange={this.state.onChangeSettings}
          action="view"
        />
      );
    } else if (this.state.action === "create") {
      return this.createBankAccount();
    } else if (this.state.action === "edit") {
      return this.editBankAccount();
    } else {
      return this.viewBankAccount();
    }
  }

  viewBankAccount() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "5px",
            }}
          >
            <Col>
              <span>
                <strong>Bank Account Details</strong>
              </span>
            </Col>
            <Col>
              <div style={{ float: "right" }}>
                <DeleteIconButton
                  domainObject="Bank Account"
                  onClick={this.deleteBankAccount.bind(this)}
                  caption="Delete Bank Account"
                />
                <EditIconButton
                  onClick={this.modifyBankAccount.bind(this)}
                  caption="Edit Bank Account"
                ></EditIconButton>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Row md={12} style={{ margin: 0, padding: 0 }}>
            <Col md={6}>
              <Row style={{ padding: "5px" }}>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Account Identifier
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>
                    {this.state.accountname}
                  </label>
                </Col>
              </Row>

              <Row style={{ padding: "5px" }}>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Account Number
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>
                    {this.state.accountnum}
                  </label>
                </Col>
              </Row>

              <Row style={{ padding: "5px" }}>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Branch Address
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>{this.state.address}</label>
                </Col>
              </Row>

              <Row style={{ padding: "5px" }}>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    SWIFT Code
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>
                    {this.state.swiftcode}
                  </label>
                </Col>
              </Row>
              <Row style={{ padding: "5px" }}>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Is Default Account ?
                  </label>
                </Col>
                <Col md={8}>
                  {this.state.isdefault === true && (
                    <ToggleButton className="ToggleButtonActive">
                      Yes
                    </ToggleButton>
                  )}
                  {this.state.isdefault === false && (
                    <ToggleButton className="ToggleButtonInActive">
                      No
                    </ToggleButton>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row style={{ padding: "5px" }}>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Account Holder
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>
                    {this.state.accountholder}
                  </label>
                </Col>
              </Row>
              <Row style={{ padding: "5px" }}>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Bank Name
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>{this.state.bankname}</label>
                </Col>
              </Row>
              <Row style={{ padding: "5px" }}>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    IFSC
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>{this.state.ifsc}</label>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
        <CardFooter style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<CloseIcon />}
              onClick={(e) => this.cancel()}
            >
              Close
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }

  createBankAccount() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <ValidatorForm
          ref="form"
          onSubmit={this.createBankAccountInBackend.bind(this)}
        >
          <CardHeader
            style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "5px",
              }}
            >
              <Col>
                <span>
                  <strong>Create Bank Account</strong>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Account Identifier<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  id="accountname"
                  placeholder="Account Identifier"
                  value={this.state.accountname}
                  onChange={this.handleAccountNameChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="accountname"
                  autoComplete="accountname"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Account Holder<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  id="accountholder"
                  placeholder="Account Holder"
                  value={this.state.accountholder}
                  onChange={this.handleAccountHolderChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="accountholder"
                  autoComplete="accountholder"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
            </Row>

            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Account Number<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  id="accountnum"
                  placeholder="Account Number"
                  value={this.state.accountnum}
                  onChange={this.handleAccountNumberChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="accountnum"
                  autoComplete="accountnum"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Bank Name<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  id="bankname"
                  placeholder="Bank Name"
                  value={this.state.bankname}
                  onChange={this.handleBankNameChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="bankname"
                  autoComplete="bankname"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
            </Row>
            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Branch Address
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  id="address"
                  placeholder="Branch Address"
                  value={this.state.address}
                  onChange={this.handleBankAddressChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="address"
                  autoComplete="address"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  IFSC<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  // type="number"
                  id="ifsc"
                  placeholder="IFSC"
                  value={this.state.ifsc}
                  onChange={this.handleIFSCChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="ifsc"
                  autoComplete="ifsc"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
            </Row>

            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Swift Code
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="swiftcode"
                  placeholder="Swift Code"
                  value={this.state.swiftcode}
                  onChange={this.handleSwiftCodeChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="swiftcode"
                  autoComplete="swiftcode"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6} style={{ padding: "5px" }}>
                <label for="isdefault">Is Default Account ?</label>

                {this.state.isdefault === true && (
                  <ToggleButton
                    className="ToggleButtonActive"
                    onChange={this.handleIsDefaultChange.bind(this)}
                  >
                    Yes
                  </ToggleButton>
                )}
                {this.state.isdefault === false && (
                  <ToggleButton
                    className="ToggleButtonInActive"
                    onChange={this.handleIsDefaultChange.bind(this)}
                  >
                    No
                  </ToggleButton>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <CreateButton type="submit" />
              <CancelButton onClick={(e) => this.cancel()} />
            </div>
          </CardFooter>
        </ValidatorForm>
      </Card>
    );
  }
  editBankAccount() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <ValidatorForm
          ref="form"
          onSubmit={this.updateBankAccountInBackend.bind(this)}
        >
          <CardHeader
            style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "5px",
              }}
            >
              <Col>
                <span>
                  <strong>Edit </strong>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Account Identifier<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  id="accountname"
                  placeholder="Account Identifier"
                  value={this.state.accountname}
                  onChange={this.handleAccountNameChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="accountname"
                  autoComplete="accountname"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Account Holder<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  id="accountholder"
                  placeholder="Account Holder"
                  value={this.state.accountholder}
                  onChange={this.handleAccountHolderChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="accountholder"
                  autoComplete="accountholder"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
            </Row>

            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Account Number<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  id="accountnum"
                  placeholder="Account Number"
                  value={this.state.accountnum}
                  onChange={this.handleAccountNumberChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="accountnum"
                  autoComplete="accountnum"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Bank Name<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  id="bankname"
                  placeholder="Bank Name"
                  value={this.state.bankname}
                  onChange={this.handleBankNameChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="bankname"
                  autoComplete="bankname"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
            </Row>
            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Branch Address
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  id="address"
                  placeholder="Branch Address"
                  value={this.state.address}
                  onChange={this.handleBankAddressChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="address"
                  autoComplete="address"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  IFSC<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  // type="number"
                  id="ifsc"
                  placeholder="IFSC"
                  value={this.state.ifsc}
                  onChange={this.handleIFSCChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="ifsc"
                  autoComplete="ifsc"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
            </Row>

            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Swift Code
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="swiftcode"
                  placeholder="Swift Code"
                  value={this.state.swiftcode}
                  onChange={this.handleSwiftCodeChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="swiftcode"
                  autoComplete="swiftcode"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6}>
                <label for="isdefault">Is Default Account ?</label>

                {this.state.isdefault === true && (
                  <ToggleButton
                    className="ToggleButtonActive"
                    onChange={this.handleIsDefaultChange.bind(this)}
                  >
                    Yes
                  </ToggleButton>
                )}
                {this.state.isdefault === false && (
                  <ToggleButton
                    className="ToggleButtonInActive"
                    onChange={this.handleIsDefaultChange.bind(this)}
                  >
                    No
                  </ToggleButton>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SaveButton type="submit" />
              <CancelButton onClick={(e) => this.viewAccount()} />
            </div>
          </CardFooter>
        </ValidatorForm>
      </Card>
    );
  }
}

export default BankAccount;

import TextField from "@material-ui/core/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
import PaymentDetailsDialogue from "../../payments/PaymentDetailsDialogue";
import Button from "@material-ui/core/Button";
import RepeatIcon from "@material-ui/icons/Repeat";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PtsAlert from "../../ptsAlert/PtsAlert";
import Tooltip from "@material-ui/core/Tooltip";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import paymentIcon from "../../../../assets/images/paymentIcon.jpeg";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});

export class DebitNoteList extends Component {
  header = store.getState().header.header;
  salesDebitNoteState = store.getState().applicationState.salesDebitNoteState;
  customerList = store.getState().master.masterData.customers;
  businessUnitList = store.getState().master.masterData.businessUnits;
  salesDebitNoteStateDetails = {};
  breadCrumb = [
    {
      pageid: "/sales",
      uiname: "Sales",
      cssclass: "menu-icon tf-icons fa fa-line-chart",
    },
    { pageid: "/dn", uiname: "Debit Note List" },
  ];
  title = "Debit Note List";
  constructor(props) {
    super(props);
    let allCustomers = {
      name: "All",
    };
    const customerList = [allCustomers, ...this.customerList];
    this.tableIcons = UiUtils.getMaterialTableIcons();
    if (this.salesDebitNoteState) {
      this.salesDebitNoteStateDetails = Object.getOwnPropertyNames(
        this.salesDebitNoteState
      );
    }
    if (this.salesDebitNoteStateDetails.length > 0) {
      this.state = {
        selectedTabIndex: 0,
        invoiceId: this.salesDebitNoteState.invoiceId,
        pageSize: this.salesDebitNoteState.pageSize,
        initialPage: this.salesDebitNoteState.initialPage,
        startDate: this.salesDebitNoteState.startDate,
        endDate: this.salesDebitNoteState.endDate,
        selectedBusinessUnit:
          this.salesDebitNoteState.selectedBusinessUnit !== undefined
            ? this.salesDebitNoteState.selectedBusinessUnit
            : this.businessUnitList[0],
        selectedCustomer:
          this.salesDebitNoteState.selectedCustomer !== undefined
            ? this.salesDebitNoteState.selectedCustomer
            : customerList[0],
        isAsset: this.salesDebitNoteState.isAsset,
        tabName: this.salesDebitNoteState.tabName,
        selectedDebitNoteCategory: null,
        customerList: customerList,
        invoiceList: [],
        businessUnits: [],
        privileges: [],
        invoiceStatusList: [],
        invoiceCategoryList: [],
        open: false,
        isFirstTimeLoading: false,
        isInvoiceStatus: false,
        isPaging: false,
        render: false,
      };
    } else {
      this.state = {
        selectedTabIndex: 0,
        initialPage: 0,
        pageSize: 10,
        selectedBusinessUnit: this.businessUnitList[0],
        selectedCustomer: customerList[0],
        selectedDebitNoteCategory: null,
        customerList: customerList,
        invoiceList: [],
        businessUnits: [],
        privileges: [],
        invoiceStatusList: [],
        invoiceCategoryList: [],
        startDate: null,
        endDate: null,
        open: false,
        isFirstTimeLoading: true,
        isInvoiceStatus: false,
        isPaging: false,
        render: false,
      };
    }
    this.getDebitNoteList(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedBusinessUnit?.code,
      this.state.selectedCustomer?.id,
      this.state.isAsset,
      this.state.isFirstTimeLoading
    );
  }

  getDebitNoteList(startDate, endDate, bucode, custid, isAsset, reset) {
    if (this.state.errorMassage || this.state.error) {
      return false;
    }
    const postObject = {
      header: this.header,
      startDate: startDate,
      endDate: endDate,
      bucode: bucode,
      custid: custid,
      isAsset: isAsset,
    };

    FetchServerData.callPostService("/dnms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let invoiceDetails = output.data;
          let invoiceList = output.data.invoiceList;
          let privileges = output.data.privileges;
          let invoiceStatusList = output.data.invoiceStatusList;
          // store.dispatch(appDataAction.setUsersBusinessUnits(businessUnits));
          // store.dispatch(appDataAction.setDebitNoteCategories(invoiceCategoryList));
          for (let i = 0; i < invoiceList.length; i++) {
            invoiceList[i].invoicedate = Utils.ptsDateFormat(
              invoiceList[i].invoicedate
            );
            invoiceList[i].totaltaxable = `${
              invoiceList[i].currencysymbol
            } ${invoiceList[i].totaltaxable.toFixed(2)}`;
            invoiceList[i].totalamount = `${
              invoiceList[i].currencysymbol
            } ${invoiceList[i].totalamount.toFixed(2)}`;
          }
          let isPaging = false;
          if (invoiceList.length > this.state.pageSize) {
            isPaging = true;
          }
          // let tabName = {}
          // for (let j = 0; j < invoiceStatusList.length; j++) {
          //   const element = invoiceStatusList[j];
          //   if (element.status === "All") {
          //     tabName.status = element.status;
          //   }
          // }
          this.setState({
            invoiceList: invoiceList,
            // businessUnits: businessUnits,
            // invoiceCategoryList: invoiceCategoryList,
            isPaging: isPaging,
            isFirstTimeLoading: reset ? true : false,
            selectedBusinessUnit: reset
              ? this.businessUnitList[0]
              : this.state.selectedBusinessUnit,
            selectedCustomer: reset
              ? this.state.customerList[0]
              : this.state.selectedCustomer,
            privileges: privileges,
            invoiceStatusList: invoiceStatusList,
            isAsset: isAsset,
            defaultStartDate: invoiceDetails.startDateString,
            defaultEndDate: invoiceDetails.endDateString,
            startDate: invoiceDetails.startDate,
            endDate: invoiceDetails.endDate,
            // tabName: tabName,
            open: false,
            render: true,
          });
        } else {
          this.setState({ isInvoiceStatus: true });
        }
      }
    );
  }

  paymentDetails(invoiceId) {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current
    ) {
      this.referenceInvoiceDialogueRef.current.showPaymentDetails(invoiceId);
    }
  }

  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setSalesDebitNoteTab(index));
    this.setState({ selectedTabIndex: index, tabName: tabName });
  }
  addDebitNote = (e) => {
    this.props.history.push({
      pathname: "/debitNoteCreate",
      state: {
        action: "create",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  toDebitNoteDetails = (e, data) => {
    let salesDebitNoteState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      selectedCustomer: this.state.selectedCustomer,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
      isAsset: this.state.isAsset,
      tabName: this.state.tabName,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ salesDebitNoteState })
    );
    this.props.history.push({
      pathname: "/debitNote",
      state: {
        invoiceId: data.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  requestVerification(e) {
    let selectedInvoiceId = [];
    for (let i = 0; i < this.state.invoiceList.length; i++) {
      if (this.state.invoiceList[i].tableData?.checked === true) {
        selectedInvoiceId.push(this.state.invoiceList[i].id);
      }
    }
    if (!selectedInvoiceId.length > 0) {
      return PtsAlert.error("Please Select Invoice");
    }
    let postObject = {
      invoices: selectedInvoiceId,
      header: this.header,
    };
    FetchServerData.callPostService(
      "/workflow/sentMultipleToAudit",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.getDebitNoteList();
      } else {
      }
    });
  }

  verifyInvoice(e) {
    let selectedInvoiceId = [];
    for (let i = 0; i < this.state.invoiceList.length; i++) {
      if (this.state.invoiceList[i].tableData?.checked === true) {
        selectedInvoiceId.push(this.state.invoiceList[i].id);
      }
    }
    if (!selectedInvoiceId.length > 0) {
      return PtsAlert.error("Please Select Invoice");
    }
    let postObject = {
      invoices: selectedInvoiceId,
      header: this.header,
    };
    FetchServerData.callPostService("/workflow/approveAudits", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.getDebitNoteList();
        } else {
        }
      }
    );
  }

  getInvoiceButtons() {
    let status = this.state.tabName?.status;
    if (Utils.equalsIgnoreCase("CREATED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.requestVerification(e)}
          >
            Request Verification
          </Button>
        </div>
      );
    } else if (Utils.equalsIgnoreCase("VERIFICATIONREQUESTED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<VerifiedUserIcon />}
            onClick={(e) => this.verifyInvoice(e)}
          >
            Verify
          </Button>
        </div>
      );
    }
  }
  rowItemSelected(rowData, e) {
    let selectedItems = this.state.invoiceList;
    for (let i = 0; i < selectedItems.length; i++) {
      if (rowData.id === selectedItems[i].id) {
        if (e.target.checked) {
          selectedItems[i].isSelected = true;
        } else {
          selectedItems[i].isSelected = false;
        }
      }
    }
    this.setState({ invoiceList: selectedItems });
  }

  showActionButton(rowData) {
    return (
      <>
        {rowData.hasPayment === true ? (
          <Tooltip title="Payment Details">
            <img
              className="ms-2"
              alt=""
              src={paymentIcon}
              onClick={this.paymentDetails.bind(this, rowData.id)}
              style={{ height: "28px", maxWidth: "none", cursor: "pointer" }}
            />
          </Tooltip>
        ) : (
          <div style={{ marginLeft: "1.8em", fontSize: "1.3rem" }}></div>
        )}
        {rowData.hasAuditNotes === true ? (
          <Tooltip title="Audit Notes">
            <DescriptionIcon
              className="ms-2"
              style={{
                fontSize: "22px",
                alignItems: "center",
                color: "#ffbb33",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        ) : (
          <div style={{ marginLeft: "1.4em", fontSize: "1.3rem" }}></div>
        )}
      </>
    );
  }

  getTablesWithStatus(status) {
    let filteredData = this.state.invoiceList.filter(function (e) {
      return e.status === status;
    });
    if (status === "All") {
      filteredData = this.state.invoiceList;
    }

    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Debit Note",
        isFreeAction: true,
        onClick: (event) => this.addDebitNote(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <Row md={12} style={{ padding: "0px 5px" }}>
        <div style={{ width: "100%", padding: "0em 0.7em" }}>
          <MaterialTable
            icons={this.tableIcons}
            localization={{
              body: {
                emptyDataSourceMessage: Utils.communizeTableText(),
              },
            }}
            title=""
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "5%",
                },
              },
              {
                title: "Date",
                field: "invoicedate",
                type: "date",
                align: "left",
                cellStyle: {
                  width: "11%",
                },
              },
              {
                title: "Invoice Number",
                field: "invnumber",
                align: "left",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Customer",
                field: "customername",
                align: "left",
                cellStyle: {
                  width: "18%",
                  wordBreak: "break-word",
                },
              },
              {
                title: "Taxable Amount",
                field: "totaltaxable",
                align: "right",
                cellStyle: {
                  width: "14%",
                },
              },
              {
                title: "Invoice Amount",
                field: "totalamount",
                align: "right",
                cellStyle: {
                  width: "14%",
                },
              },
              {
                title: "Status",
                field: "statusdescription",
                align: "left",
                cellStyle: {
                  width: "14%",
                  wordBreak: "break-word",
                },
              },
              {
                title: "Action",
                sorting: false,
                disableClick: true,
                render: (rowData) => this.showActionButton(rowData),
                cellStyle: {
                  width: "12%",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "smaller",
                },
              },
            ]}
            data={filteredData}
            options={{
              sorting: true,
              selection:
                Utils.equalsIgnoreCase("CREATED", status) ||
                Utils.equalsIgnoreCase("VERIFICATIONREQUESTED", status)
                  ? true
                  : false,
              showTextRowsSelected: false,
              actionsColumnIndex: -1,
              pageSize: this.state.pageSize,
              paging: this.state.isPaging,
              initialPage: this.state.initialPage,
              pageSizeOptions: Utils.materialTablePageSize(),
              headerStyle: {
                backgroundColor: "#1b7189",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.id === this.state.invoiceId ? "#FEF5E7" : "",
                height: "3em",
                textTransform: "capitalize",
              }),
            }}
            style={{
              boder: "1px solid black",
            }}
            onSelectionChange={(rowData) => {
              this.rowItemSelected(rowData);
            }}
            onChangePage={(page, pageSize) => {
              this.handleMaterialTablePageChange(page, pageSize);
            }}
            actions={actionList}
            onRowClick={(e, rowData) => this.toDebitNoteDetails(e, rowData)}
          />
        </div>
      </Row>
    );
  }

  handleStartDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.endDate);
    if (this.state.endDate === null && date === null) {
      this.setState({
        startDate: date,
        errorMassage: false,
        error: false,
      });
    } else if (date === null) {
      this.setState({
        startDate: date,
        errorMassage: true,
        error: false,
      });
    } else if (fromDate > toDate) {
      this.setState({
        startDate: date,
        error: true,
        errorMassage: false,
      });
    } else {
      this.setState({
        startDate: date,
        errorMassage: false,
        error: false,
      });
    }
  }

  handleEndDateChange(date) {
    let fromDate = Date.parse(this.state.startDate);
    let toDate = Date.parse(date);

    if (this.state.startDate === null && date === null) {
      this.setState({
        endDate: date,
        errorMassage: false,
        error: false,
      });
    } else if (this.state.startDate === null) {
      this.setState({
        endDate: date,
        errorMassage: true,
        error: false,
      });
    } else if (fromDate > toDate) {
      this.setState({
        endDate: date,
        error: true,
        errorMassage: false,
      });
    } else {
      this.setState({
        endDate: date,
        errorMassage: false,
        error: false,
      });
    }
  }

  updateBusinessUnit(e, bu) {
    this.setState({ selectedBusinessUnit: bu });
  }

  updateCustomer(e, customer) {
    this.setState({ selectedCustomer: customer });
  }

  handleAssetChange(e) {
    this.setState({ isAsset: e.target.checked });
  }

  tabSelected() {}
  updateDebitNoteCategory(e, name) {
    this.setState({ selectedDebitNoteCategory: name });
  }
  filterRecords(e) {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let bucode = this.state.selectedBusinessUnit
      ? this.state.selectedBusinessUnit.code
      : "";
    let custid = this.state.selectedCustomer
      ? this.state.selectedCustomer.id
      : "";
    let isAsset = this.state.isAsset;
    this.getDebitNoteList(startDate, endDate, bucode, custid, isAsset);
  }
  cancelView() {
    this.props.history.push({ pathname: "/master/" });
  }
  resetFilter() {
    store.dispatch(ApplicationStateAction.setApplicationState({}));
    let startDate = null;
    let endDate = null;
    let bucode = "";
    let custid = "";
    let isAsset = false;
    this.getDebitNoteList(startDate, endDate, bucode, custid, isAsset, true);
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  render() {
    this.referenceInvoiceDialogueRef = React.createRef();
    if (this.state.render === false) {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            backCallback={this.cancelView.bind(this)}
          />
          {this.state.isInvoiceStatus && (
            <div className="row mt-4">
              <div className="col md-12">
                <div className="card p-4">
                  <div className="row text-center p-2">
                    <h5 style={{ color: "red" }}>
                      Contact Admin user to enable this Workflow setting.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }

    const tabList = this.state.invoiceStatusList?.map((expStatus, keyIndex) => {
      return (
        <Tab
          key={keyIndex}
          onClick={(e) => this.selectedTab(expStatus, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{expStatus.uiname}</span>
        </Tab>
      );
    });

    const tabPanelList = this.state.invoiceStatusList?.map((tab, keyIndex) => {
      return (
        <>
          <PaymentDetailsDialogue
            ref={this.referenceInvoiceDialogueRef}
            style={{ float: "right" }}
          />
          <TabPanel key={keyIndex}>
            {this.getTablesWithStatus(tab.status)}
          </TabPanel>
        </>
      );
    });

    let selectedIndex = store.getState().appData.salesDNSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <div className="row">
          <div className="col-8 align-items-start text-start">
            <span className="ms-2">
              {this.state.selectedCustomer !== null ||
              this.state.selectedBusinessUnit !== null ||
              this.state.isAsset !== false ? (
                <span>
                  {this.state.selectedCustomer &&
                  this.state.selectedCustomer !== null ? (
                    <span>
                      <b>Customer : </b>
                      {this.state.selectedCustomer?.name + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.selectedBusinessUnit &&
                  this.state.selectedBusinessUnit !== null ? (
                    <span>
                      <b> Business Unit : </b>
                      {this.state.selectedBusinessUnit?.name + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.isAsset && this.state.isAsset !== false ? (
                    <span>
                      <b> Is Asset Sales: </b>
                      {`${this.state.isAsset ? "Yes" : "No"}, `}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.defaultStartDate !== "" &&
                    this.state.defaultStartDate !== null && (
                      <span>
                        <b>Start Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultStartDate) +
                          ", "}
                      </span>
                    )}
                  &nbsp;
                  {this.state.defaultEndDate !== "" &&
                    this.state.defaultEndDate !== null && (
                      <span>
                        <b>End Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultEndDate) + ", "}
                      </span>
                    )}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="col-4 align-items-end text-end">
            <span
              className="text-end me-1"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.handleAccountDropdownOpen}
            >
              <FilterAltIcon
                style={{ fontSize: "28px", color: "black" }}
                aria-controls={this.state.open ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.open ? "true" : undefined}
              />
              Filter
            </span>
            {"  "}
            {this.state.isFirstTimeLoading ? (
              ""
            ) : (
              <span
                className="me-1"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.resetFilter.bind(this)}
              >
                &nbsp;&nbsp;<i class="fa fa-refresh" aria-hidden="true"></i>{" "}
                Reset
              </span>
            )}
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Filter</h5>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Customer
                </label>
                <Autocomplete
                  fullWidth
                  variant="outlined"
                  id="customerList"
                  ListboxProps={{ className: "myCustomList" }}
                  options={this.state.customerList}
                  value={this.state.selectedCustomer}
                  defaultValue={this.state.selectedCustomer}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Select Customer"
                  onChange={(event, value) => {
                    this.updateCustomer(event, value);
                  }}
                  style={{ background: "#fff", borderRadius: "5px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="regCountry"
                      placeholder="Customer"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Business Unit
                </label>
                <Autocomplete
                  fullWidth
                  variant="outlined"
                  id="businessUnit"
                  ListboxProps={{ className: "myCustomList" }}
                  options={this.businessUnitList}
                  value={this.state.selectedBusinessUnit}
                  defaultValue={this.state.selectedBusinessUnit}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Select Business Unit"
                  style={{ background: "#fff", borderRadius: "5px" }}
                  onChange={(event, value) => {
                    this.updateBusinessUnit(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="regCountry"
                      placeholder="Business Unit"
                      variant="outlined"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <div className="row">
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label "
                      // htmlFor="collapsible-companyName"
                    >
                      Start Date
                    </label>
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          value={this.state.startDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleStartDateChange.bind(this)}
                          helperText={
                            this.state.errorMassage
                              ? "Start Date is Required"
                              : ""
                          }
                          error={this.state.errorMassage}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      End Date
                    </label>{" "}
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          value={this.state.endDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleEndDateChange.bind(this)}
                          helperText={
                            this.state.endDateErrorMassage
                              ? "End Date is Required"
                              : ""
                          }
                          error={this.state.endDateErrorMassage}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  {this.state.error ? (
                    <lable className="errorMassageShow">
                      End Date should be after Start Date
                    </lable>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <FormControlLabel
                  style={{
                    color: "#000",
                    width: "100%",
                    margin: "0",
                    padding: "0",
                  }}
                  control={
                    <Checkbox
                      variant="outlined"
                      id="isAsset"
                      checked={this.state.isAsset}
                      value={this.state.isAsset}
                      onChange={this.handleAssetChange.bind(this)}
                      style={{ background: "none" }}
                    />
                  }
                  label={<span style={{}}>Is Asset Sale?</span>}
                />
              </div>
              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  // onClick={this.onFilterClick.bind(this)}
                  onClick={(e) => this.filterRecords(e)}
                >
                  <i className="fa-solid fa-filter"></i>
                  &nbsp; Filter
                </button>
                &nbsp; &nbsp;
                <button
                  className="resetButton"
                  onClick={this.resetFilter.bind(this)}
                >
                  <i className="fa fa-refresh"></i>
                  &nbsp; Reset
                </button>
              </div>
            </div>
          </div>
        </Menu>
        <Row md={12} className="mt-2">
          <Tabs
            onSelect={this.tabSelected.bind(this)}
            selectedIndex={selectedIndex}
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            <TabList
              style={{
                minWidth: "5em",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              {tabList}
            </TabList>
            {tabPanelList}
          </Tabs>
        </Row>
        <Row md={12} className="mt-4">
          {this.getInvoiceButtons()}
        </Row>
      </>
    );
  }
}
export default DebitNoteList;

import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Badge from "@material-ui/core/Badge";
import DateFnsUtils from "@date-io/date-fns";
import { Buffer } from "buffer";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Switch, createMuiTheme } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
// import PrintIcon from "@material-ui/icons/Print";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
import PaymentDetailsDialogue from "../../payments/PaymentDetailsDialogue";
import "../LedgerManagement.scss";
import Tooltip from "@material-ui/core/Tooltip";
import paymentIcon from "../../../../assets/images/paymentIcon.jpeg";
import {
  // createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});
// const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "black",
        backgroundColor: "#F3E3A3",
        border: "1px solid black",
        cursor: "pointer",
      },
    },
  },
});
export class InwardLedger extends Component {
  header = store.getState().header.header;
  customerMasterState = store.getState().applicationState.customerMasterState;
  customerList = store.getState().master.masterData.customers;
  businessUnitList = store.getState().master.masterData.businessUnits;
  financialsYearArray = store.getState().appData.fyList;
  printComponentRef = React.createRef();
  customerMasterStateDetails = {};
  breadCrumb = [
    {
      pageid: "/ledgerMgmt",
      uiname: "Ledger Management",
      cssclass: "menu-icon tf-icons fas fa-book",
    },
    { pageid: "/ledgerInward", uiname: "Inward Ledger" },
  ];
  title = "Inward Ledger";
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    let allCustomers = {
      name: "All",
    };
    const customerList = [allCustomers, ...this.customerList];

    this.state = {
      showAddcustomer: false,
      customerList: customerList,
      inwardLedgerList: [],
      privileges: [],
      customerId: "",
      fromDate: null,
      toDate: null,
      financialsYear: null,
      selectedBusinessUnit: this.businessUnitList[0],
      selectedExcelBusinessUnit: this.businessUnitList[0],
      selectedCustomer: customerList[0],
      selectedExcelCustomer: customerList[0],
      pageSize: 10,
      initialStep: 0,
      isPaging: false,
      isFirstTimeLoading: true,
      defaultFromDate: null,
      defaultToDate: null,
      excelFromDate: null,
      excelToDate: null,
      excelFinancialsYear: null,
      downloadOpen: false,
      error: false,
      errorMassage: false,
      excelError: false,
      excelErrorMassage: false,
      swichCheck: false,
      excelSwichCheck: false,
      render: false,
    };
    this.getInwardLedgerData("", "", null, "", "", false);
  }

  getInwardLedgerData(
    custid,
    bucodeId,
    financialsYear,
    fromDate,
    toDate,
    reset
  ) {
    const postObject = {
      header: this.header,
      customer: { id: custid },
      bu: { id: bucodeId },
      financialYear: financialsYear,
      fromDate: fromDate,
      toDate: toDate,
    };

    FetchServerData.callPostService("/ledgerms/getInward", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let inwardata = output.data;
          let privileges = output.data.privileges;
          let inwardLedgerList = inwardata.ledgerItems;
          let isPaging = false;
          if (inwardLedgerList.length > 10) {
            isPaging = true;
          }
          for (let i = 0; i < inwardLedgerList.length; i++) {
            inwardLedgerList[i].invoicedate = Utils.ptsDateFormat(
              inwardLedgerList[i].invoicedate
            );

            if (inwardLedgerList[i].invoicetype === "RE") {
              inwardLedgerList[i].advanceAmountUtilized =
                inwardLedgerList[i].advanceAmountUsed;
              inwardLedgerList[i].advanceGSTUtilized =
                inwardLedgerList[i].advanceGstUsed;
            }
          }

          this.setState({
            render: true,
            open: false,
            errorMassage: false,
            isPaging: isPaging,
            inwardLedgerList: inwardLedgerList,
            privileges: privileges,
            isFirstTimeLoading: reset ? false : true,
            financialsYear: inwardata.financialYear,
            excelFinancialsYear: inwardata.financialYear,
            excelFromDate:
              inwardata.financialYear === null ? inwardata.fromDate : null,
            excelToDate:
              inwardata.financialYear === null ? inwardata.toDate : null,
            fromDate: inwardata.fromDate,
            toDate: inwardata.toDate,
            defaultFromDate: inwardata.fromDateStr,
            defaultToDate: inwardata.toDateStr,
            defaultFinancialYear: inwardata.financialYear,
            swichCheck: financialsYear === null ? true : false,
            excelSwichCheck: financialsYear === null ? true : false,
          });
        }
      }
    );
  }

  InwardLedger = (e, data) => {
    let customerId = data.id;
    let customerMasterState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ customerMasterState })
    );
    this.props.history.push({
      pathname: "/customerview",
      state: {
        customerId: customerId,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };
  cancelView() {
    this.props.history.push({ pathname: "/master/" });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  // handleToDateChange(date) {
  //   this.setState({ toDate: date });
  // }
  // handleFromDateChange(date) {
  //   this.setState({ fromDate: date });
  // }

  handleFromDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.toDate);
    if (this.state.toDate === null && date === null) {
      this.setState({
        fromDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (date === null) {
      this.setState({
        fromDate: date,
        error: false,
        errorMassage: true,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        fromDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        fromDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  handleToDateChange(date) {
    let fromDate = Date.parse(this.state.fromDate);
    let toDate = Date.parse(date);

    if (this.state.fromDate === null && date === null) {
      this.setState({
        toDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (this.state.fromDate === null) {
      this.setState({ toDate: date, errorMassage: true, financialsYear: null });
    } else if (fromDate > toDate) {
      this.setState({
        toDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        toDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  handleSwichCheckChange(e) {
    this.setState({ swichCheck: e.target.checked });
  }
  handleExcelSwichCheckChange(e) {
    this.setState({ excelSwichCheck: e.target.checked });
  }

  updateCustomer(e, customer) {
    this.setState({ selectedCustomer: customer });
  }
  updateExcelCustomer(e, customer) {
    this.setState({ selectedExcelCustomer: customer });
  }

  updateBusinessUnit(e, bu) {
    this.setState({ selectedBusinessUnit: bu });
  }

  updateExcelBusinessUnit(e, bu) {
    this.setState({ selectedExcelBusinessUnit: bu });
  }

  filterRecords(e) {
    if (this.state.errorMassage || this.state.error) {
      return false;
    }

    let fromDate = this.state.fromDate ? this.state.fromDate : null;
    let toDate = this.state.toDate ? this.state.toDate : null;
    let financialsYear = this.state.financialsYear
      ? this.state.financialsYear
      : null;
    let custid = this.state.selectedCustomer
      ? this.state.selectedCustomer.id
      : "";
    let bucodeId = this.state.selectedBusinessUnit
      ? this.state.selectedBusinessUnit.id
      : "";
    this.getInwardLedgerData(
      custid,
      bucodeId,
      financialsYear,
      fromDate,
      toDate,
      true
    );
  }
  resetFilter() {
    let bucodeId = "";
    let custid = "";
    let financialsYear = null;
    let fromDate = null;
    let toDate = null;
    this.setState({
      selectedCustomer: this.state.customerList[0],
      selectedBusinessUnit: this.businessUnitList[0],
      financialsYear: this.state.financialsYear,
      fromDate: null,
      toDate: null,
      swichCheck: false,
      excelSwichCheck: false,
      error: false,
      errorMassage: false,
    });
    this.getInwardLedgerData(
      custid,
      bucodeId,
      financialsYear,
      fromDate,
      toDate,
      false
    );
  }

  paymentDetails(invoiceId) {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current
    ) {
      this.referenceInvoiceDialogueRef.current.showPaymentDetails(invoiceId);
    }
  }

  handleExcelDownloadDropdownOpen = (e) => {
    this.setState({ downloadOpen: true, anchorEl1: e.currentTarget });
  };
  handleExcelDownloadDropdownClose = () => {
    this.setState({ downloadOpen: false, anchorEl1: null });
  };

  // handleExcelFromDateChange(exdate) {
  //   this.setState({ excelFromDate: exdate });
  // }

  // handleExcelToDateChange(exdate) {
  //   this.setState({ excelToDate: exdate });
  // }

  handleExcelFromDateChange(exdate) {
    let startDate = Date.parse(exdate);
    let endDate = Date.parse(this.state.excelToDate);

    if (this.state.excelToDate === null && exdate === null) {
      this.setState({
        excelFromDate: exdate,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else if (exdate === null) {
      this.setState({
        excelFromDate: exdate,
        excelErrorMassage: true,
        excelFinancialsYear: null,
      });
    } else if (startDate > endDate) {
      this.setState({
        excelFromDate: exdate,
        excelError: true,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else {
      this.setState({
        excelFromDate: exdate,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    }
  }

  handleExcelToDateChange(exdate) {
    let startDate = Date.parse(this.state.excelFromDate);
    let endDate = Date.parse(exdate);

    if (this.state.excelFromDate === null && exdate === null) {
      this.setState({
        excelToDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (this.state.excelFromDate === null) {
      this.setState({
        excelToDate: exdate,
        excelErrorMassage: true,
        excelError: true,
        excelFinancialsYear: null,
      });
    } else if (startDate > endDate) {
      this.setState({
        excelStartDate: exdate,
        excelError: true,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else {
      this.setState({
        excelToDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    }
  }

  updateFinancialYear(e, year) {
    this.setState({ financialsYear: year, fromDate: null, toDate: null });
  }

  updateExcelFinancialYear(e, year) {
    this.setState({
      excelFinancialsYear: year,
      excelFromDate: null,
      excelToDate: null,
    });
  }

  downloadExcelFromBackend() {
    if (this.state.excelErrorMassage || this.state.excelError) {
      return false;
    }

    const postObject = {
      header: this.header,
      fromDate: this.state.excelFromDate,
      toDate: this.state.excelToDate,
      // invoiceType: invoiceTypeArray,
      customer: {
        id: this.state.selectedExcelCustomer?.id,
      },
      bu: {
        id: this.state.selectedExcelBusinessUnit?.id,
      },
      financialYear: this.state.excelFinancialsYear,
    };

    FetchServerData.callPostService(
      "/ledgerms/createInwardExcel",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let excelData = output.data;
        const byteArray = new Buffer(
          excelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );

        const outputFilename = excelData.filename;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        this.setState({
          // excelStartDate: null,
          // excelEndDate: null,
          // excelFinancialsYear: null,
          // selectedBusinessUnit: null,
          // selectedCustomer: null,
          selectedExcelInvoice: null,
          excelError: false,
          excelErrorMassage: false,
          downloadOpen: false,
        });
      } else {
      }
    });
  }

  printButtonClicked() {
    this.props.history.push({
      pathname: "/ledgerInwardPrint",
      state: {
        inwardLedgerList: this.state.inwardLedgerList,
        fromDate:
          this.state.fromDate !== null
            ? this.state.fromDate
            : this.state.defaultFromDate,
        toDate:
          this.state.toDate !== null
            ? this.state.toDate
            : this.state.defaultToDate,
        selectedBusinessUnit:
          this.state.selectedBusinessUnit !== null
            ? this.state.selectedBusinessUnit
            : this.businessUnitList[0],
        isHistory: this.state.isHistory,
      },
    });
  }
  shawTooltipData(rowData) {
    if (
      (rowData.advanceAmountUsed !== 0.0 || rowData.advanceGstUsed !== 0.0) &&
      rowData.invoicetype !== "RE"
    ) {
      return (
        <p>
          Advance Used :{rowData.advanceAmountUsed}
          <br />
          Advance GST Used :{rowData.advanceGstUsed}
        </p>
      );
    } else {
      return (
        <p>
          Advance Utilized :{rowData.advanceAmountUtilized}
          <br />
          Advance GST Utilized :{rowData.advanceGSTUtilized}
        </p>
      );
    }
  }

  render() {
    this.referenceInvoiceDialogueRef = React.createRef();
    if (this.state.render === false) {
      return <div />;
    }

    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Customer",
        isFreeAction: true,
        textAlign: "left",
        onClick: (event) => this.addInwardLedger(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <>
        <PaymentDetailsDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
        />
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        <div className="row">
          <div className="col me-2 align-items-end">
            <div className="row-12 d-flex">
              {this.state.isFirstTimeLoading ? (
                <div className="col-12">
                  <div className="row-12 d-flex">
                    <div className="col-7 ms-2 align-items-start">
                      {this.state.selectedCustomer &&
                      this.state.selectedCustomer !== null ? (
                        <span>
                          <b>Customer : </b>
                          {this.state.selectedCustomer?.name + ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.selectedBusinessUnit &&
                      this.state.selectedBusinessUnit !== null ? (
                        <span>
                          <b>Business Unit : </b>
                          {this.state.selectedBusinessUnit?.name + ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.defaultFinancialYear !== "" &&
                        this.state.defaultFinancialYear !== null && (
                          <span>
                            <b>Financial Year : </b>
                            {this.state.defaultFinancialYear + ", "}
                          </span>
                        )}{" "}
                      &nbsp;
                      {this.state.defaultFromDate &&
                      this.state.defaultFromDate !== null ? (
                        <span>
                          <b>Start Date : </b>
                          {Utils.ptsDateFormat(this.state.defaultFromDate) +
                            ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.defaultToDate &&
                      this.state.defaultToDate !== null ? (
                        <span>
                          <b>End Date : </b>
                          {Utils.ptsDateFormat(this.state.defaultToDate)}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-5 me-1 align-items-end text-end">
                      <span
                        className="text-end"
                        style={{
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={this.handleAccountDropdownOpen}
                      >
                        <FilterAltIcon
                          style={{ fontSize: "28px", color: "black" }}
                          aria-controls={
                            this.state.open ? "dropdown-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={this.state.open ? "true" : undefined}
                        />
                        Filter
                      </span>
                      {"  "} &nbsp;&nbsp;
                      {this.state.isFirstTimeLoading ? (
                        ""
                      ) : (
                        <span
                          style={{
                            fontSize: "17px",
                            fontWeight: "500",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={this.resetFilter.bind(this)}
                        >
                          <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                        </span>
                      )}
                      {/* <ReactToPrint
                        trigger={() => ( */}
                      {/* <Button
                        variant="contained"
                        size="small"
                        style={{
                          background: "#1b7189",
                          color: "#fff",
                          padding: "5px",
                          width: "120px",
                          textTransform: "capitalize",
                        }}
                        startIcon={<PrintIcon />}
                        onClick={this.printButtonClicked.bind(this)}
                      >
                        Print Preview
                      </Button> */}
                      {/* )} */}
                      {/* content={() => this.printComponentRef} */}
                      {/* /> */}
                      {/* &nbsp; &nbsp; */}
                      <Button
                        variant="contained"
                        size="small"
                        id="excelBtn"
                        style={{
                          background: "#5ac489",
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                        startIcon={<i class="fa fa-download"></i>}
                        onClick={this.handleExcelDownloadDropdownOpen}
                      >
                        Download Excel
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-12">
                    <div className="row-12 d-flex">
                      <div className="col-7 ms-2 align-items-start">
                        {this.state.selectedCustomer &&
                        this.state.selectedCustomer !== null ? (
                          <span>
                            <b>Customer : </b>
                            {this.state.selectedCustomer?.name + ", "}
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.selectedBusinessUnit &&
                        this.state.selectedBusinessUnit !== null ? (
                          <span>
                            <b>Business Unit : </b>
                            {this.state.selectedBusinessUnit?.name + ", "}
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.defaultFinancialYear !== "" &&
                          this.state.defaultFinancialYear !== null && (
                            <span>
                              <b>Financial Year : </b>
                              {this.state.defaultFinancialYear + ", "}
                            </span>
                          )}{" "}
                        &nbsp;
                        {this.state.defaultFromDate &&
                        this.state.defaultFromDate !== null ? (
                          <span>
                            <b>Start Date : </b>
                            {Utils.ptsDateFormat(this.state.defaultFromDate) +
                              ", "}
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.defaultToDate &&
                        this.state.defaultToDate !== null ? (
                          <span>
                            <b>End Date : </b>
                            {Utils.ptsDateFormat(this.state.defaultToDate)}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-5 align-items-end text-end">
                        <span
                          className="text-end"
                          style={{
                            fontSize: "17px",
                            fontWeight: "500",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={this.handleAccountDropdownOpen}
                        >
                          <FilterAltIcon
                            style={{ fontSize: "28px", color: "black" }}
                            aria-controls={
                              this.state.open ? "dropdown-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={this.state.open ? "true" : undefined}
                          />
                          Filter
                        </span>
                        {"  "} &nbsp;&nbsp;
                        {this.state.isFirstTimeLoading ? (
                          ""
                        ) : (
                          <span
                            className="me-1"
                            style={{
                              fontSize: "17px",
                              fontWeight: "500",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={this.resetFilter.bind(this)}
                          >
                            <i class="fa fa-refresh" aria-hidden="true"></i>{" "}
                            Reset
                          </span>
                        )}
                        &nbsp;&nbsp;
                        {/* <Button
                          variant="contained"
                          size="small"
                          style={{
                            background: "#1b7189",
                            color: "#fff",
                            padding: "5px",
                            width: "120px",
                            textTransform: "capitalize",
                          }}
                          startIcon={<PrintIcon />}
                          onClick={this.printButtonClicked.bind(this)}
                        >
                          Print Preview
                        </Button>
                        &nbsp; &nbsp; */}
                        <Button
                          variant="contained"
                          size="small"
                          id="excelBtn"
                          style={{
                            background: "#5ac489",
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                          startIcon={<i class="fa fa-download"></i>}
                          onClick={this.handleExcelDownloadDropdownOpen}
                        >
                          Download Excel
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Filter</h5>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Customer
                </label>
                <Autocomplete
                  fullWidth
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  variant="outlined"
                  id="customerList"
                  options={this.state.customerList}
                  value={this.state.selectedCustomer}
                  defaultValue={this.state.selectedCustomer}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Select Customer"
                  onChange={(event, value) => {
                    this.updateCustomer(event, value);
                  }}
                  style={{ background: "#fff", borderRadius: "5px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="regCountry"
                      placeholder="Customer"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Business Unit
                </label>
                <Autocomplete
                  fullWidth
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  variant="outlined"
                  id="businessUnit"
                  options={this.businessUnitList}
                  value={this.state.selectedBusinessUnit}
                  defaultValue={this.state.selectedBusinessUnit}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Select Business Unit"
                  style={{ background: "#fff", borderRadius: "5px" }}
                  onChange={(event, value) => {
                    this.updateBusinessUnit(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="regCountry"
                      placeholder="Business Unit"
                      variant="outlined"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="row mt-2 d-flex">
                <div className="col-md-12" id="assetFormLabel">
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0px",
                      padding: "0",
                    }}
                    control={
                      <Switch
                        variant="outlined"
                        // id="isAsset"
                        checked={this.state.swichCheck}
                        value={this.state.swichCheck}
                        onChange={this.handleSwichCheckChange.bind(this)}
                        style={{ background: "none", margin: "8px" }}
                      />
                    }
                    label={<span>Custom search </span>}
                  />
                </div>
              </div>
              {!this.state.swichCheck && (
                <div className="col-md-12 mt-1" id="assetFormLabel">
                  <label className="form-label ">Financial Year</label>
                  <br />
                  <Autocomplete
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    variant="outlined"
                    id="supplierList"
                    options={this.financialsYearArray}
                    value={this.state.financialsYear}
                    defaultValue={this.state.financialsYear}
                    getOptionLabel={(option) => option}
                    style={{ background: "#fff", borderRadius: "5px" }}
                    onChange={(event, value) => {
                      this.updateFinancialYear(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Financial Year"
                        className="regCountry"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
              )}
              <div className="col-md-12 mt-1" id="assetFormLabel">
                {this.state.swichCheck && (
                  <div className="row mt-2">
                    <div className="col-md-6" id="assetFormLabel">
                      <label
                        className="form-label "
                        // htmlFor="collapsible-companyName"
                      >
                        Start Date
                      </label>
                      <br />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            clearable
                            required
                            value={this.state.fromDate}
                            // variant="inline"
                            margin="normal"
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            variant="outlined"
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="dd-MM-yyyy"
                            onChange={this.handleFromDateChange.bind(this)}
                            helperText={
                              this.state.errorMassage
                                ? "Start Date is Required"
                                : ""
                            }
                            error={this.state.errorMassage}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-6" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                        style={{ color: "#000" }}
                      >
                        End Date
                      </label>{" "}
                      <br />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            clearable
                            required
                            value={this.state.toDate}
                            // variant="inline"
                            margin="normal"
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            variant="outlined"
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="dd-MM-yyyy"
                            onChange={this.handleToDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    {this.state.error ? (
                      <lable className="errorMassageShow">
                        End Date should be after Start Date
                      </lable>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  onClick={(e) => this.filterRecords(e)}
                >
                  <i className="fa-solid fa-filter"></i>
                  &nbsp; Filter
                </button>
                &nbsp; &nbsp;
                <button
                  className="resetButton"
                  onClick={this.resetFilter.bind(this)}
                >
                  <i className="fa fa-refresh"></i>
                  &nbsp; Reset
                </button>
              </div>
            </div>
          </div>
        </Menu>
        <Menu
          anchorEl={this.state.anchorEl1}
          id="dropdown-menu1"
          className="filterDropdownMenu"
          open={this.state.downloadOpen}
          onClose={this.handleExcelDownloadDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Download Excel</h5>
              <div className="row">
                <div className="col-md-12" id="assetFormLabel">
                  <label
                    className="form-label "
                    htmlFor="collapsible-supplierType"
                  >
                    Customer
                  </label>
                  <Autocomplete
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    variant="outlined"
                    id="customerList"
                    options={this.state.customerList}
                    value={this.state.selectedExcelCustomer}
                    defaultValue={this.state.selectedExcelCustomer}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Select Customer"
                    onChange={(event, value) => {
                      this.updateExcelCustomer(event, value);
                    }}
                    style={{ background: "#fff", borderRadius: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Customer"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-md-12" id="assetFormLabel">
                  <label
                    className="form-label "
                    htmlFor="collapsible-supplierType"
                  >
                    Business Unit
                  </label>
                  <Autocomplete
                    margin="normal"
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    className="expenseAutoComplete"
                    id="businessUnit"
                    style={{ background: "#fff", borderRadius: "5px" }}
                    options={this.businessUnitList}
                    value={this.state.selectedExcelBusinessUnit}
                    defaultValue={this.state.selectedExcelBusinessUnit}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateExcelBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Business Unit"
                        {...params}
                        style={{}}
                        variant="outlined"
                        className="regCountry"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row mt-2 d-flex">
                <div className="col-md-12" id="assetFormLabel">
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0px",
                      padding: "0",
                    }}
                    control={
                      <Switch
                        variant="outlined"
                        // id="isAsset"
                        checked={this.state.excelSwichCheck}
                        value={this.state.excelSwichCheck}
                        onChange={this.handleExcelSwichCheckChange.bind(this)}
                        style={{ background: "none", margin: "8px" }}
                      />
                    }
                    label={<span>Custom search </span>}
                  />
                </div>
              </div>
              <div className="row mt-2">
                {!this.state.excelSwichCheck && (
                  <div className="col-md-12" id="assetFormLabel">
                    <label
                      className="form-label "
                      // htmlFor="collapsible-companyName"
                    >
                      Financial Year
                    </label>
                    <br />
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="supplierList"
                      options={this.financialsYearArray}
                      value={this.state.excelFinancialsYear}
                      defaultValue={this.state.excelFinancialsYear}
                      getOptionLabel={(option) => option}
                      style={{ background: "#fff", borderRadius: "5px" }}
                      onChange={(event, value) => {
                        this.updateExcelFinancialYear(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Financial Year"
                          className="regCountry"
                          InputLabelProps={{
                            fontSize: "small !important",
                            style: { fontSize: "small !important" },
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                {this.state.excelSwichCheck && (
                  <>
                    <div className="col-md-6" id="assetFormLabel">
                      <label
                        className="form-label "
                        // htmlFor="collapsible-companyName"
                      >
                        Start Date
                      </label>
                      <br />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            clearable
                            value={this.state.excelFromDate}
                            // variant="inline"
                            margin="normal"
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            variant="outlined"
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="dd-MM-yyyy"
                            onChange={this.handleExcelFromDateChange.bind(this)}
                            helperText={
                              this.state.excelErrorMassage
                                ? "Start Date is Required"
                                : ""
                            }
                            error={this.state.excelErrorMassage}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-6" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                        style={{ color: "#000" }}
                      >
                        End Date
                      </label>{" "}
                      <br />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            clearable
                            value={this.state.excelToDate}
                            // variant="inline"
                            margin="normal"
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            variant="outlined"
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="dd-MM-yyyy"
                            onChange={this.handleExcelToDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    {this.state.excelError ? (
                      <lable className="errorMassageShow">
                        End Date should be after Start Date
                      </lable>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  onClick={this.downloadExcelFromBackend.bind(this)}
                >
                  <i className="fa fa-download"></i>
                  &nbsp; Download
                </button>
                &nbsp; &nbsp;
              </div>
            </div>
          </div>
        </Menu>
        <div
          className="ledgerTable mt-4"
          style={{ background: "transparent", width: "100%" }}
        >
          <MaterialTable
            icons={this.tableIcons}
            tableRef={(el) => (this.printComponentRef = el)}
            title=""
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "3%",
                },
              },
              {
                title: "Date",
                field: "invoicedate",
                align: "left",
                cellStyle: {
                  width: "6%",
                },
              },
              {
                title: "Invoice Number",
                field: "invnumber",
                align: "left",
                sorting: false,
                render: (rowData) => {
                  return (
                    <>
                      {/* this.shawTooltipData(rowData) */}
                      {rowData.advanceAmountUsed !== 0.0 ||
                      rowData.advanceGstUsed !== 0.0 ? (
                        <MuiThemeProvider theme={theme}>
                          <Tooltip title={this.shawTooltipData(rowData)}>
                            <div style={{ cursor: "pointer" }}>
                              <b>{rowData.invnumber}</b>
                            </div>
                          </Tooltip>
                        </MuiThemeProvider>
                      ) : (
                        <div>{rowData.invnumber}</div>
                      )}
                    </>
                  );
                },
                // cellStyle: {
                //   width: "10%",
                // },
              },
              {
                title: "Type",
                field: "invoicetypeDescription",
                align: "left",
                sorting: false,
                cellStyle: {
                  width: "8%",
                },
              },
              {
                title: "Status",
                field: "status",
                align: "left",
                cellStyle: {
                  width: "6%",
                  wordBreak: "break-word",
                },
                sorting: false,
              },
              {
                title: "Customer Name",
                field: "customerName",
                align: "left",
                cellStyle: {
                  width: "12%",
                },
                sorting: false,
              },
              {
                title: "GST",
                render: (rowData) =>
                  rowData.currencySymbol +
                  " " +
                  parseFloat(rowData.totalgst).toFixed(2),
                align: "right",
                // cellStyle: {
                //   width: "8%",
                // },
                sorting: false,
              },
              {
                title: "Taxable",
                field: "totaltaxable",
                render: (rowData) =>
                  rowData.currencySymbol +
                  " " +
                  parseFloat(rowData.totaltaxable).toFixed(2),
                align: "right",
                // cellStyle: {
                //   width: "8%",
                // },
                sorting: false,
              },
              {
                title: "Total",
                render: (rowData) =>
                  rowData.currencySymbol +
                  " " +
                  parseFloat(rowData.totalamount).toFixed(2),
                align: "right",
                // cellStyle: {
                //   width: "8%",
                // },
                sorting: false,
              },
              {
                title: "EX Rate",
                render: (rowData) =>
                  rowData.baseCurrencySymbol +
                  " " +
                  parseFloat(rowData.exchangerate).toFixed(2),
                align: "right",
                cellStyle: {
                  width: "6%",
                },
                sorting: false,
              },
              {
                title: "Credit",
                render: (rowData) =>
                  rowData.baseCurrencySymbol +
                  " " +
                  parseFloat(rowData.creditAmount).toFixed(2),
                align: "right",
                cellStyle: {
                  width: "8%",
                },
                sorting: false,
              },
              {
                title: "Debit",
                // field: "debitAmount",
                render: (rowData) =>
                  rowData.baseCurrencySymbol +
                  " " +
                  parseFloat(rowData.debitAmount).toFixed(2),
                align: "right",
                cellStyle: {
                  width: "8%",
                },
                sorting: false,
              },
              {
                title: "Balance Amount",
                render: (rowData) =>
                  rowData.baseCurrencySymbol +
                  " " +
                  parseFloat(rowData.balanceAmount).toFixed(2),
                align: "right",
                cellStyle: {
                  width: "10%",
                },
                sorting: false,
              },
            ]}
            data={this.state.inwardLedgerList}
            options={{
              maxBodyHeight: 470,
              overflowY: "none",
              paging: false,
              // pageSize: this.state.pageSize,
              // pageSizeOptions: [5, 10, 20, 50, 100, 200],
              // initialPage: this.state.initialPage,
              sorting: true,
              toolbar: true,
              actionsColumnIndex: -1,
              headerStyle: {
                margin: 0,
                backgroundColor: "#1b7189",
                // border: "1px solid #e2dddd",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.currencySymbol !== "₹" ? "#FEF5E7" : "",
                height: "3em",
                textTransform: "capitalize",
              }),
            }}
            // onChangePage={(page, pageSize) => {
            //     this.handleMaterialTablePageChange(page, pageSize);
            // }}
            actions={[
              (rowData) => ({
                icon: () =>
                  rowData.hasPayment === true ? (
                    <>
                      <Badge color="primary" overlap="rectangular">
                        <img
                          className="ms-2"
                          alt=""
                          src={paymentIcon}
                          onClick={this.paymentDetails.bind(this, rowData.id)}
                          style={{
                            height: "28px",
                            maxWidth: "none",
                            cursor: "pointer",
                          }}
                        />
                      </Badge>
                      &nbsp;
                    </>
                  ) : (
                    <div
                      style={{ marginLeft: "1.4em", fontSize: "1.5rem" }}
                    ></div>
                  ),
                tooltip: "Payment Details",
              }),
            ]}
          />
        </div>
      </>
    );
  }
}
export default InwardLedger;

import Button from "@material-ui/core/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Autocomplete } from "@mui/material";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import Pagination from "../pagination/Pagination";
import PtsAlert from "../ptsAlert/PtsAlert";

export default class SearchHsnSacDialogue extends Component {
  serviceGoodList = ["Service", "Goods"];
  header = store.getState().header.header;

  tableIcons = UiUtils.getMaterialTableIcons();

  constructor(props) {
    super(props);
    this.state = {
      onCodeChange: props.onCodeChange,
      serviceGoods: "Service",
      pageNo: 0,
      showPerPage: 10,
      totalRecords: 0,
      code: "",
      codeList: [],
      open: false,
      isPaging: false,
    };
  }
  componentWillMount() {
    this.state = {
      serviceGoods: "Service",
      code: "",
      pageNo: 0,
      showPerPage: 10,
      totalRecords: 0,
      codeList: [],
      isPaging: false,
      open: false,
    };
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleServiceGoodsSelectChange(e, selected) {
    this.setState({ serviceGoods: selected });
  }

  onPaginationChange(returnProp) {
    let counter = returnProp.counter;
    let pageNo = this.state.pageNo + counter;
    let pageSize = returnProp.showPerPage;
    let buttonIndex = returnProp.buttonIndex;
    let currentPageIndex =
      typeof counter !== "undefined" ? pageNo : buttonIndex;
    let resetPageNo = returnProp.resetPageNo;
    if (resetPageNo === true) {
      currentPageIndex = 0;
    }
    this.setState({
      pageNo: currentPageIndex,
    });
    this.searchSacHsnCode(currentPageIndex, pageSize);
  }

  onSearchClick() {
    this.searchSacHsnCode(this.state.pageNo, this.state.pageSize);
  }

  searchSacHsnCode(pageNo, pageSize) {
    if (
      Utils.isNull(this.state.code) ||
      this.state.code.toString().trim().length < 1
    ) {
      document.getElementById("code").focus();
      PtsAlert.error("Please Enter 1 digit Code");
      return;
    }
    const postObject = {
      header: this.header,
      // hsncode: this.state.code,
      page: {
        searchText: this.state.code,
        pageNo: pageNo,
        pageSize: pageSize,
      },
    };
    if (Utils.equalsIgnoreCase(this.state.serviceGoods, "GOODS")) {
      FetchServerData.callPostService("/hsns/searchGoods", postObject).then(
        (output) => {
          if (output.status === "SUCCESS") {
            let sacCodeList = output.data.goods;
            let page = output.data.page;
            let isPaging = false;
            if (sacCodeList.length > 10) {
              isPaging = true;
            }
            this.setState({
              codeList: sacCodeList,
              isPaging: isPaging,
              code: page.searchText,
              pageNo: page.pageNo,
              totalPages: page.totalPages,
              totalRecords: page.total,
              showPerPage: page.pageSize,
            });
          }
        }
      );
    } else {
      FetchServerData.callPostService("/hsns/searchService", postObject).then(
        (output) => {
          if (output.status === "SUCCESS") {
            let sacCodeList = output.data.services;
            let page = output.data.page;
            let isPaging = false;
            if (sacCodeList.length > 10) {
              isPaging = true;
            }
            this.setState({
              codeList: sacCodeList,
              isPaging: isPaging,
              code: page.searchText,
              pageNo: page.pageNo,
              totalPages: page.totalPages,
              totalRecords: page.total,
              showPerPage: page.pageSize,
            });
          }
        }
      );
    }
  }

  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  hsnCodeSelected(selectedCode) {
    console.log(selectedCode);
    if (Utils.isNotNull(this.props.onCodeChange)) {
      this.props.onCodeChange(selectedCode);
      this.setState({ open: false });
    }
  }
  render() {
    console.log(this.state.totalRecords);
    return (
      <>
        <SearchIcon
          style={{
            cursor: "pointer",
            background: "#1b7189",
            color: "#fff",
            // background: "#1b7189",
            // color: "#fff",
            // marginRight: "1rem",
            height: "40.8px",
            padding: "4px",
            width: "40px",
            // marginTop: "-5px",
            marginRight: "-0.7em",
          }}
          onClick={this.handleOpen.bind(this)}
        />

        <Dialog
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          fullWidth={true}
          maxWidth="xl"
        >
          <DialogTitle
            style={{
              background: "#282929",
              color: "#fff",
              padding: "6px 10px 6px 20px",
            }}
          >
            <label style={{ color: "#fff", fontWeight: 600 }}>
              Search SAC/HSN
            </label>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={this.handleClose.bind(this)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon style={{ color: "#fff", fontWeight: "600" }} />
          </IconButton>
          <DialogContent style={{ margin: "10px", padding: "0" }}>
            <Row md={12} style={{ margin: "0", padding: "10px" }}>
              <Col className="mt-2" md={4}>
                <Autocomplete
                  disableClearable
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  id="businessUnit"
                  size="small"
                  options={
                    undefined === this.serviceGoodList ||
                    null === this.serviceGoodList
                      ? []
                      : this.serviceGoodList
                  }
                  value={this.state.serviceGoods}
                  defaultValue={this.state.serviceGoods}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    this.handleServiceGoodsSelectChange(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      fullWidth={true}
                      variant="outlined"
                      className="regCountry"
                      {...params}
                      placeholder="Select"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Col>
              <Col md={4} style={{ padding: "5px", textAlign: "left" }}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth={true}
                  variant="outlined"
                  id="code"
                  onChange={this.handleCodeChange.bind(this)}
                  value={this.state.code ? this.state.code : ""}
                  placeholder="Enter Code"
                />
              </Col>
              <Col md={4} style={{ margin: "auto" }}>
                <Button
                  // type="submit"
                  variant="contained"
                  // className="codeBtn"
                  size="small"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    // marginRight: "1rem",
                    height: "38px",
                    padding: "5px",
                    width: "120px",
                    // marginRight: "-0.7em",
                  }}
                  startIcon={<SearchIcon />}
                  onClick={this.onSearchClick.bind(this)}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <Row md={12}>
              <div className="card">
                <div className="row justify-content-between p-3">
                  <div className="d-flex col-12 col-lg-12 col-xl-12">
                    <div className="col-12 text-start totalResults">
                      <span>Total Results: {this.state.totalRecords}</span>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table text-start table-hover">
                    <thead className="tableThead">
                      <tr>
                        <th scope="col fw-bold">#</th>
                        <th scope="col fw-bold">Codes</th>
                        <th scope="col fw-bold">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.codeList?.map((rowData, index) => (
                        <tr
                          className="hoverTable"
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={this.hsnCodeSelected.bind(this, rowData)}
                        >
                          <td style={{ width: "6px" }}>
                            {this.state.pageNo * this.state.showPerPage +
                              index +
                              1}
                          </td>
                          <td>{rowData.code}</td>
                          <td>{rowData.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {this.state.totalRecords === 0 && (
                  <div className="row text-center p-2">
                    <h5>Data not Available</h5>
                  </div>
                )}
                {this.state.totalRecords > 10 && (
                  <div className="row justify-content-between">
                    <div className="d-flex col-12 col-lg-3 col-xl-3">
                      <div
                        className="pageSize col-12 text-start ms-4"
                        style={{ marginTop: "30px" }}
                      >
                        Page {this.state.pageNo + 1} of {this.state.totalPages}
                      </div>
                    </div>
                    <div className="d-flex col-12 col-lg-9 col-xl-9">
                      <div className="col-12 text-end">
                        <Pagination
                          onPaginationChange={this.onPaginationChange.bind(
                            this
                          )}
                          showPerPage={this.state.showPerPage}
                          total={this.state.totalRecords}
                          pageNo={this.state.pageNo}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

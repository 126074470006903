import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import { CancelIconButton } from "../buttons/CancelButton";
import DeleteIconButton from "../buttons/DeleteIconButton";
import EditIconButton from "../buttons/EditIconButton";
import SaveButton from "../buttons/SaveButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Role.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RoleList from "./RoleList";
import Utils from "../../../provider/Utils";
import CreateButton from "../buttons/CreateButton";
export class Role extends Component {
  header = store.getState().header.header;
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  roleMenuRefList = [];
  constructor(props) {
    super(props);
    let role = props.data;
    if (role === null || props.action === "create") {
      this.state = {
        name: "",
        id: null,
        role: {},
        selectedIndex: 0,
        roleMenuList: [],
        tabList: [
          { uiname: "Profile Domain Permissions", isactive: true },
          { uiname: "Tab Setting", isactive: true },
        ],
        activeMenus: new Map(),
        invRoleStatusMapping: [],
        onChange: this.props.onChange,
        action: this.props.action,
        updateProfile: false,
        addProfile: true,
        render: true,
      };
    } else {
      this.state = {
        name: "",
        id: null === role.id ? "" : role.id,
        role: [],
        roleMenuList: [],
        selectedIndex: 0,
        tabList: [
          { uiname: "Profile Domain Permissions", isactive: true },
          { uiname: "Workflow Settings", isactive: true },
        ],
        activeMenus: new Map(),
        menus: [],
        invRoleStatusMapping: [],
        expenseStatusMappingList: [],
        salesStatusMappingList: [],
        purchaseStatusMappingList: [],
        onChange: this.props.onChange,
        action: this.props.action,
        updateProfile: false,
        addProfile: true,
        render: false,
      };
      this.getProfileDetails();
    }
  }

  getProfileDetails() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
      profile: { id: this.props.data.id },
    };
    FetchServerData.callPostService("/profilems/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let profileDetails = output.data.profile;
          let menuList = profileDetails.menus;
          let expenseStatusMappingList = profileDetails.expenseStatusMapping;
          let salesStatusMappingList = profileDetails.salesStatusMapping;
          let purchaseStatusMappingList = profileDetails.purchaseStatusMapping;
          this.setState({
            render: true,
            action: "view",
            name: profileDetails.name,
            id: profileDetails.id,
            menus: menuList.sort(
              (a, b) => a.displaysequence - b.displaysequence
            ),
            expenseStatusMappingList: expenseStatusMappingList,
            salesStatusMappingList: salesStatusMappingList,
            purchaseStatusMappingList: purchaseStatusMappingList,
            getUpdatePermission: true,
            getDeletePermission: true,
          });
        } else {
        }
      }
    );
  }
  // handleNameChange(e) {
  //   let role = {
  //     name: e.target.value,
  //     id: null,
  //   };
  //   this.setState({ role: role });
  // }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleSalesTabSetting(e) {
    let statusList = [];
    let selectedItem = null;
    this.state.invRoleStatusMapping.sales.statusList.forEach((item, index) => {
      if ("" + item.id === e.target.value) {
        item.isactive = e.target.checked;
        selectedItem = item;
      }
      statusList[index] = item;
    });
    if (selectedItem && selectedItem.status === "All") {
      if (e.target.checked) {
        statusList.forEach((item, index) => {
          if (item.id !== selectedItem.id) {
            statusList[index].isactive = true;
          }
        });
      } else {
        statusList.forEach((item, index) => {
          if (item.id !== selectedItem.id) {
            statusList[index].isactive = false;
          }
        });
      }
    }

    this.state.invRoleStatusMapping.sales.statusList = statusList;
    this.setState({ invRoleStatusMapping: this.state.invRoleStatusMapping });
  }
  handlePurchaseTabSetting(e) {
    let statusList = [];
    let selectedItem = null;
    this.state.invRoleStatusMapping.purchase.statusList.forEach(
      (item, index) => {
        if ("" + item.id === e.target.value) {
          item.isactive = e.target.checked;
          selectedItem = item;
        }
        statusList[index] = item;
      }
    );
    if (selectedItem && selectedItem.status === "All") {
      if (e.target.checked) {
        statusList.forEach((item, index) => {
          if (item.id !== selectedItem.id) {
            statusList[index].isactive = true;
          }
        });
      } else {
        statusList.forEach((item, index) => {
          if (item.id !== selectedItem.id) {
            statusList[index].isactive = false;
          }
        });
      }
    }

    this.state.invRoleStatusMapping.purchase.statusList = statusList;
    this.setState({ invRoleStatusMapping: this.state.invRoleStatusMapping });
  }
  handleExpenseTabSetting(e) {
    let statusList = [];
    let selectedItem = null;
    this.state.invRoleStatusMapping.expense.statusList.forEach(
      (item, index) => {
        if ("" + item.id === e.target.value) {
          item.isactive = e.target.checked;
          selectedItem = item;
        }
        statusList[index] = item;
      }
    );
    if (selectedItem && selectedItem.status === "All") {
      if (e.target.checked) {
        statusList.forEach((item, index) => {
          if (item.id !== selectedItem.id) {
            statusList[index].isactive = true;
          }
        });
      } else {
        statusList.forEach((item, index) => {
          if (item.id !== selectedItem.id) {
            statusList[index].isactive = false;
          }
        });
      }
    }

    this.state.invRoleStatusMapping.expense.statusList = statusList;
    this.setState({ invRoleStatusMapping: this.state.invRoleStatusMapping });
  }

  // updateTabSettingInBackend() {
  //   let role = {
  //     name: this.state.role?.name,
  //     id: this.state.id,
  //   };
  //   const header = store.getState().header.header;
  //   this.setState({ render: false });
  //   const postObject = {
  //     role: role,
  //     //roleMenuList:newRoleMenuList,
  //     // invRoleStatusMapping:this.state.invRoleStatusMapping,
  //     header: header,
  //     invRoleStatusMapping: this.state.invRoleStatusMapping,
  //   };
  //   FetchServerData.callPostService(
  //     "/profilems/setInvProfileStatusMapping",
  //     postObject
  //   ).then((output) => {
  //     if (output.status === "SUCCESS") {
  //       PtsAlert.success("Role Tab Settings Updated Successfully");
  //       this.setState({ action: "show_list", render: true });
  //     } else {
  //     }
  //   });
  // }

  createProfileToBackend() {
    const postObject = {
      header: this.header,
      profile: {
        name: this.state.name,
        id: this.state.id,
        menus: this.state.menus,
      },
    };
    FetchServerData.callPostService("/profilems/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let profileDetails = output.data.profile;
          let menuList = profileDetails.menus;
          this.setState({
            render: true,
            updateProfile: true,
            addProfile: false,
            name: profileDetails.name,
            id: profileDetails.id,
            menus: menuList.sort(
              (a, b) => a.displaysequence - b.displaysequence
            ),
          });
        } else {
        }
      }
    );
  }

  updateProfileToBackend() {
    const postObject = {
      header: this.header,
      profile: {
        name: this.state.name,
        id: this.state.id,
        menus: this.state.menus,
      },
    };
    FetchServerData.callPostService("/profilems/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let profileDetails = output.data.profile;
          let menuList = profileDetails.menus;
          let expenseStatusMappingList = profileDetails.expenseStatusMapping;
          let salesStatusMappingList = profileDetails.salesStatusMapping;
          let purchaseStatusMappingList = profileDetails.purchaseStatusMapping;
          this.setState({
            render: true,
            action: "view",
            name: profileDetails.name,
            id: profileDetails.id,
            menus: menuList.sort(
              (a, b) => a.displaysequence - b.displaysequence
            ),
            expenseStatusMappingList: expenseStatusMappingList,
            salesStatusMappingList: salesStatusMappingList,
            purchaseStatusMappingList: purchaseStatusMappingList,
            getUpdatePermission: true,
            getDeletePermission: true,
          });
        } else {
        }
      }
    );
  }

  editProfileManagement() {
    this.setState({
      action: "edit",
      profileId: this.state.id,
      activeMenus: new Map(),
    });
  }

  deleteProfileToBackend = () => {
    const postObject = {
      header: this.header,
      profile: {
        id: this.state.id,
      },
    };
    FetchServerData.callPostService("/profilems/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  };

  updateAllRoleDetailsInBackend() {
    let newRoleMenuList = [];
    for (let i = 0; i < this.roleMenuRefList.length; i++) {
      let currentRoleMenu = this.roleMenuRefList[i].current.state;
      let roleMenu = {
        id: currentRoleMenu.id,
        name: currentRoleMenu.name,
        parentid: currentRoleMenu.parentid,
        isActive: currentRoleMenu.isActive,
        domainObject: currentRoleMenu.domainObject,
        // subMenus:currentRoleMenu.subMenus,
      };
      newRoleMenuList.push(roleMenu);
      for (let j = 0; j < currentRoleMenu.subMenus.length; j++) {
        let newSubMenu = currentRoleMenu.subMenus[j];
        // let subRoleMenu = {
        //   id: newSubMenu.id,
        //   name: newSubMenu.name,
        //   parentid: newSubMenu.parentid,
        //   isActive: newSubMenu.isActive,
        //   domainObject: newSubMenu.domainObject,
        //   // subMenus:currentRoleMenu.subMenus,
        // };
        newRoleMenuList.push(newSubMenu);
      }
    }

    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      role: this.state.role,
      roleMenuList: newRoleMenuList,
      // invRoleStatusMapping:this.state.invRoleStatusMapping,
      header: header,
    };
    FetchServerData.callPostService(
      "/rolems/setmenuroledomainobjprivillage",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success("Role Menu Updated Successfully");
        this.setState({ action: "show_list", render: true });
      } else {
      }
    });
  }
  showrolesubMenu(menuId) {
    let menus = this.state.activeMenus;
    if (menus.has(menuId)) {
      menus.delete(menuId);
    } else {
      menus.set(menuId, new Set());
    }
    this.setState({ activeMenus: menus });
  }
  showDomainObject(domainObjectId, menuId) {
    let domainObjects = this.state.activeMenus.get(menuId);
    if (domainObjects.has(domainObjectId)) {
      //remove
      domainObjects.delete(domainObjectId);
    } else {
      //Add
      domainObjects.add(domainObjectId);
    }
    this.setState({
      activeMenus: this.state.activeMenus.set(menuId, domainObjects),
    });
  }

  onDomainObjectPrivilegeClicked(id) {
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
          if (domainObjectPrivilege.id === id) {
            if (domainObjectPrivilege.isactive === false) {
              this.handleDomainObjectPrivilegeChecked(
                domainObjectPrivilege,
                domainObject,
                menu
              );
            } else {
              this.handleDomainObjectPrivilegeUnChecked(
                domainObjectPrivilege,
                domainObject,
                menu
              );
            }
            return;
          }
        });
      });
      menu.childMenus.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
            if (domainObjectPrivilege.id === id) {
              if (domainObjectPrivilege.isactive === false) {
                this.handleDomainObjectPrivilegeChecked(
                  domainObjectPrivilege,
                  domainObject,
                  menu
                );
              } else {
                this.handleDomainObjectPrivilegeUnChecked(
                  domainObjectPrivilege,
                  domainObject,
                  menu
                );
              }

              return;
            }
          });
        });
      });
    });
    this.setState({ menus: this.state.menus });
  }

  handleDomainObjectPrivilegeChecked(
    inputDomainObjectPrivilege,
    inputdomainObject,
    menu
  ) {
    let inputPrivilge = inputDomainObjectPrivilege.privilege;
    if (inputPrivilge.name === "*") {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
      inputDomainObjectPrivilege.isactive = true;
    } else if (inputPrivilge.name === "READ") {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege.privilege;
        if (privilege.name === "READ") {
          domainObjectPrivilege.isactive = true;
        }
        if (privilege.name === "*") {
          domainObjectPrivilege.isactive = false;
        }
      });
    } else {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege.privilege;
        if (privilege.name === "READ") {
          domainObjectPrivilege.isactive = true;
        } else if (privilege.name === inputPrivilge.name) {
          domainObjectPrivilege.isactive = true;
        }
      });

      let isCreateCheckd = false;
      let isUpdateCheckd = false;
      let isDeleteheckd = false;
      let isApproveCheckd = false;
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege.privilege;
        if (
          privilege.name === "CREATE" &&
          domainObjectPrivilege.isactive === true
        ) {
          isCreateCheckd = true;
        } else if (
          privilege.name === "UPDATE" &&
          domainObjectPrivilege.isactive === true
        ) {
          isUpdateCheckd = true;
        } else if (
          privilege.name === "DELETE" &&
          domainObjectPrivilege.isactive === true
        ) {
          isDeleteheckd = true;
        } else if (
          privilege.name === "APPROVE" &&
          domainObjectPrivilege.isactive === true
        ) {
          isApproveCheckd = true;
        }
      });
      if (
        isCreateCheckd &&
        isUpdateCheckd &&
        isDeleteheckd &&
        isApproveCheckd
      ) {
        inputdomainObject.domainObjectPrivileges.map(
          (domainObjectPrivilege) => {
            let privilege = domainObjectPrivilege.privilege;
            if (privilege.name === "*") {
              domainObjectPrivilege.isactive = true;
            } else {
              domainObjectPrivilege.isactive = false;
            }
          }
        );
      } else {
        inputdomainObject.domainObjectPrivileges.map(
          (domainObjectPrivilege) => {
            let privilege = domainObjectPrivilege.privilege;
            if (privilege.name === "*") {
              domainObjectPrivilege.isactive = false;
            }
          }
        );
      }
    }
    inputdomainObject.isactive = true;
    this.handleDomainObjectChecked(inputdomainObject, menu);
  }

  handleDomainObjectPrivilegeUnChecked(
    inputDomainObjectPrivilege,
    inputdomainObject,
    menu
  ) {
    let inputPrivilge = inputDomainObjectPrivilege.privilege;
    if (inputPrivilge.name === "*") {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
    } else if (inputPrivilge.name === "READ") {
      let flag = false;
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege.privilege;
        if (
          privilege.name !== "READ" &&
          domainObjectPrivilege.isactive === true
        ) {
          flag = true;
        }
      });

      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege.privilege;
        if (privilege.name === "READ") {
          inputDomainObjectPrivilege.isactive = flag;
        }
      });
    } else {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege.privilege;
        if (privilege.name === inputPrivilge.name) {
          domainObjectPrivilege.isactive = false;
        }
      });
    }

    let flag = false;
    inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
      if (domainObjectPrivilege.isactive === true) {
        flag = true;
      }
    });
    if (flag === false) {
      this.handleDomainObjectUnChecked(inputdomainObject, menu);
    }
  }

  renderDomainObjectPrivileges(
    domainObjectPrivilege,
    menuId,
    domainObjectId,
    action
  ) {
    return (
      <>
        {action === "view" ? (
          <>
            {domainObjectPrivilege.privilege !== null ? (
              <div
                style={{
                  width: "18em",
                  marginLeft: "3em",
                  paddingBottom: "0px",
                }}
              >
                {domainObjectPrivilege.isactive ? (
                  <>
                    <span
                      className="bold"
                      style={{ color: "green", fontWeight: "600" }}
                    >
                      {Utils.toCamelCaseUL(
                        domainObjectPrivilege.privilege?.name
                      )}
                      &nbsp;&nbsp;
                    </span>
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </>
                ) : (
                  <span style={{ color: "black" }}>
                    {Utils.toCamelCaseUL(domainObjectPrivilege.privilege?.name)}
                    &nbsp;
                  </span>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <li
            className="list-group-item list-group-item-action prev"
            style={{ width: "21em", marginLeft: "2em" }}
          >
            <input
              // className="checkbox"
              type="checkbox"
              checked={domainObjectPrivilege.isactive}
              onChange={this.onDomainObjectPrivilegeClicked.bind(
                this,
                domainObjectPrivilege.id
              )}
            />
            &nbsp; {Utils.toCamelCaseUL(domainObjectPrivilege.privilege?.name)}
          </li>
        )}
      </>
    );
  }

  onDomainObjectClicked(id) {
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        if (domainObject.id === id) {
          if (domainObject.isactive === false) {
            this.handleDomainObjectChecked(domainObject, menu, true);
          } else {
            this.handleDomainObjectUnChecked(domainObject, menu, true);
          }
          return;
        }
      });
      menu.childMenus.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          if (domainObject.id === id) {
            if (domainObject.isactive === false) {
              this.handleDomainObjectChecked(domainObject, submenu, true);
            } else {
              this.handleDomainObjectUnChecked(domainObject, submenu, true);
            }
          }
          return;
        });
      });
    });
    this.setState({ menus: this.state.menus });
  }

  handleDomainObjectChecked(inputdomainObject, inputMenu, checkPrivilges) {
    if (checkPrivilges && checkPrivilges === true) {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        // if (domainObjectPrivilege.privilege.name === '*') {
        domainObjectPrivilege.isactive = true;
        // } else {
        // 	domainObjectPrivilege.isactive = false;
        // }
      });
    }

    inputdomainObject.isactive = true;
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        if (inputdomainObject.name === domainObject.name) {
          menu.isactive = true;
          domainObject.isactive = true;
        }
      });

      menu.childMenus.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          if (inputdomainObject.name === domainObject.name) {
            submenu.isactive = true;
            menu.isactive = true;
          }
        });
      });
    });
  }

  handleDomainObjectUnChecked(inputdomainObject, menu, checkPrivilges) {
    if (checkPrivilges && checkPrivilges === true) {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
    }
    inputdomainObject.isactive = false;
    this.state.menus.map((menu) => {
      let flag = false;
      let found = false;
      menu.domainObjects.map((domainObject) => {
        if (inputdomainObject.name === domainObject.name) {
          domainObject.isactive = false;
          found = true;
        }
        if (domainObject.isactive === true) {
          flag = true;
        }
      });
      if (found) {
        menu.isactive = flag;
      }

      menu.childMenus.map((submenu) => {
        let subFlag = false;
        let subFound = false;
        submenu.domainObjects.map((subdomainObject) => {
          if (inputdomainObject.name === subdomainObject.name) {
            subdomainObject.isactive = false;
            submenu.isactive = false;
            subFound = true;
          }
          if (subdomainObject.isactive === true) {
            subFlag = true;
          }
        });

        if (subFound === true) {
          menu.childMenus.map((submenu) => {
            if (submenu.isactive === true) {
              subFlag = true;
            }
          });
          submenu.isactive = subFlag;
          menu.isactive = subFlag;
        }
      });
    });
  }

  renderDomainObject(domainObject, menu, action) {
    let activeMenus = this.state.activeMenus;
    let menuId = menu.id;
    return (
      <>
        {action === "view" ? (
          <>
            <div style={{ width: "18em", paddingBottom: "0px" }}>
              {domainObject.isactive ? (
                <>
                  <span
                    className="bold"
                    style={{
                      fontSize: "16px",
                      color: "#e67300",
                      fontWeight: "650",
                    }}
                  >
                    {" "}
                    {domainObject.name}&nbsp;&nbsp;
                  </span>
                  <i class="fa fa-check" aria-hidden="true"></i>
                </>
              ) : (
                <span
                  className="bold"
                  style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "650",
                  }}
                >
                  {" "}
                  {domainObject.name}&nbsp;
                </span>
              )}
            </div>
            <div className="row">
              <>
                <div className="row d-flex">
                  <ul
                    className="sub-menu list-group"
                    stype={{ display: "inline" }}
                  >
                    {domainObject.domainObjectPrivileges.map(
                      (domainObjectPrivilege) =>
                        this.renderDomainObjectPrivileges(
                          domainObjectPrivilege,
                          menuId,
                          domainObject.id,
                          action
                        )
                    )}
                  </ul>
                </div>
              </>
            </div>
          </>
        ) : (
          <li className="list-group-item list-group-item-action prev">
            <input
              // className="checkbox"
              type="checkbox"
              checked={domainObject.isactive}
              onChange={this.onDomainObjectClicked.bind(
                this,
                domainObject.id,
                menu
              )}
            />
            &nbsp; &nbsp; {domainObject.name}&nbsp; &nbsp;
            <a
              className="btn-flat pull-right ps-2"
              style={{ display: "contents" }}
            >
              <i
                className={
                  activeMenus.has(menuId) &&
                  activeMenus.get(menuId).has(domainObject.id)
                    ? "fa fa-lg fa-minus"
                    : "fa fa-lg fa-plus"
                }
                onClick={this.showDomainObject.bind(
                  this,
                  domainObject.id,
                  menuId
                )}
              ></i>
              {activeMenus.has(menuId) &&
              activeMenus.get(menuId).has(domainObject.id) ? (
                <ul
                  className="sub-menu list-group"
                  stype={{ marginLeft: "2em" }}
                >
                  {domainObject.domainObjectPrivileges.map(
                    (domainObjectPrivilege) =>
                      this.renderDomainObjectPrivileges(
                        domainObjectPrivilege,
                        menuId,
                        domainObject.id
                      )
                  )}
                </ul>
              ) : (
                ""
              )}
            </a>
          </li>
        )}
      </>
    );
  }

  onMenuClicked(id) {
    this.state.menus.map((menu) => {
      if (menu.id === id) {
        if (menu.isactive === false) {
          this.handleMenuChecked(menu);
        } else {
          this.handleMenuUnChecked(menu);
        }
      }
      if (menu.childMenus && menu.childMenus.length > 0) {
        menu.childMenus.map((submenu) => {
          if (submenu.id === id) {
            if (submenu.isactive === false) {
              this.handleMenuChecked(submenu, menu);
            } else {
              this.handleMenuUnChecked(submenu, menu);
            }
          }
        });
      }
    });

    this.setState({ menus: this.state.menus });
  }

  handleMenuChecked(inputmenu) {
    this.state.menus.map((menu) => {
      if (menu.menuid === inputmenu.menuid) {
        menu.isactive = true;
        menu.childMenus.map((submenu) => {
          submenu.isactive = true;
          submenu.domainObjects.map((domainObject) => {
            domainObject.isactive = true;
            domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
              domainObjectPrivilege.isactive = true;
            });
          });
        });
        menu.domainObjects.map((domainObject) => {
          domainObject.isactive = true;
          domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
            // if (domainObjectPrivilege.privilege.name === '*') {
            domainObjectPrivilege.isactive = true;
            // } else {
            // 	domainObjectPrivilege.isactive = false;
            // }
          });
        });
      } else {
        menu.childMenus.map((submenu) => {
          if (submenu.menuid === inputmenu.menuid) {
            submenu.isactive = true;
            submenu.domainObjects.map((domainObject) => {
              domainObject.isactive = true;
              domainObject.domainObjectPrivileges.map(
                (domainObjectPrivilege) => {
                  // if (domainObjectPrivilege.privilege.name === '*') {
                  domainObjectPrivilege.isactive = true;
                  // } else {
                  // 	domainObjectPrivilege.isactive = false;
                  // }
                }
              );
            });
            menu.isactive = true;
          }
        });
      }
    });
  }

  handleMenuUnChecked(inputmenu, parentmenu) {
    this.state.menus.map((menu) => {
      if (menu.menuid === inputmenu.menuid) {
        menu.isactive = false;
        menu.childMenus.map((submenu) => {
          submenu.isactive = false;
          submenu.domainObjects.map((domainObject) => {
            domainObject.isactive = false;
            domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
              domainObjectPrivilege.isactive = false;
            });
          });
        });
        menu.domainObjects.map((domainObject) => {
          domainObject.isactive = false;
          domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
            domainObjectPrivilege.isactive = false;
          });
        });
      } else {
        menu.childMenus.map((submenu) => {
          if (submenu.menuid === inputmenu.menuid) {
            submenu.isactive = false;
            submenu.domainObjects.map((domainObject) => {
              domainObject.isactive = false;
              domainObject.domainObjectPrivileges.map(
                (domainObjectPrivilege) => {
                  domainObjectPrivilege.isactive = false;
                }
              );
            });

            let flag = false;
            menu.childMenus.map((submenu) => {
              if (submenu.isactive === true) {
                flag = true;
              }
            });
            menu.isactive = flag;
          }
        });
      }
    });
  }

  renderMenus(menu, action) {
    if (menu.childMenus && menu.childMenus.length > 0) {
      return (
        <>
          {action === "view" ? (
            <div className="row d-flex ms-1 me-1">
              <li className="list-group-item list-group-item-action prev">
                <div style={{ width: "21em", paddingBottom: "0px" }}>
                  {menu.isactive ? (
                    <>
                      <span
                        className="bold"
                        style={{
                          fontSize: "16px",
                          color: "#0099f1",
                          fontWeight: "650",
                        }}
                      >
                        {" "}
                        {menu.uiname}&nbsp;&nbsp;
                      </span>
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style={{ color: "#0099f1" }}
                      ></i>
                    </>
                  ) : (
                    <span
                      className="bold"
                      style={{
                        fontSize: "16px",
                        color: "black",
                        fontWeight: "650",
                      }}
                    >
                      {" "}
                      {menu.uiname}&nbsp;
                    </span>
                  )}
                </div>
                <div className="row d-flex">
                  <ul
                    className="list-group m-2"
                    style={{
                      display: "inline-flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      listStyleType: "none",
                    }}
                  >
                    {menu.childMenus.map((submenu) =>
                      this.renderMenus(submenu, "view")
                    )}
                  </ul>
                </div>
              </li>
            </div>
          ) : (
            <li
              className="list-group-item list-group-item-action prev"
              style={{ marginLeft: "1em" }}
            >
              <input
                // className="checkbox"
                type="checkbox"
                checked={menu.isactive}
                onChange={this.onMenuClicked.bind(this, menu.id)}
              />
              &nbsp; &nbsp;{menu.uiname}&nbsp; &nbsp;
              <a style={{ display: "contents" }}>
                <i
                  className={
                    this.state.activeMenus.has(menu.id)
                      ? "fa fa-lg fa-minus"
                      : "fa fa-lg fa-plus"
                  }
                  onClick={this.showrolesubMenu.bind(this, menu.id)}
                ></i>
                {this.state.activeMenus.has(menu.id) ? (
                  <ul className="list-group" style={{ display: "content" }}>
                    {menu.childMenus.map((submenu) =>
                      this.renderMenus(submenu)
                    )}
                  </ul>
                ) : (
                  ""
                )}
              </a>
            </li>
          )}
        </>
      );
    } else {
      return (
        <>
          {action === "view" ? (
            <div className="row d-flex ms-1 me-1">
              <li className="list-group-item list-group-item-action prev border-0">
                <div style={{ width: "21em", paddingBottom: "0px" }}>
                  {menu.isactive ? (
                    <>
                      <span
                        className="bold"
                        style={{
                          fontSize: "16px",
                          color: "#0099f1",
                          fontWeight: "650",
                        }}
                      >
                        {" "}
                        {menu.uiname}&nbsp;&nbsp;
                      </span>
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style={{ color: "#0099f1" }}
                      ></i>
                    </>
                  ) : (
                    <span
                      className="bold"
                      style={{
                        fontSize: "16px",
                        color: "black",
                        fontWeight: "650",
                      }}
                    >
                      {" "}
                      {menu.uiname}&nbsp;
                    </span>
                  )}
                </div>
                <ul
                  className="sub-menu list-group"
                  style={{
                    display: "flex",
                    marginLeft: "2em",
                    width: "fit-content",
                  }}
                >
                  {menu.domainObjects.map((domainObject) =>
                    this.renderDomainObject(domainObject, menu, action)
                  )}
                </ul>
              </li>
            </div>
          ) : (
            <li
              className="list-group-item list-group-item-action prev"
              style={{ marginLeft: "1em" }}
            >
              <div>
                <input
                  // className="checkbox"
                  type="checkbox"
                  checked={menu.isactive}
                  onChange={this.onMenuClicked.bind(this, menu.id)}
                />
                &nbsp; &nbsp;{menu.uiname}&nbsp; &nbsp;
                {menu.domainObjects.length > 0 ? (
                  <a style={{ display: "contents" }}>
                    <i
                      className={
                        this.state.activeMenus.has(menu.id)
                          ? "fa fa-lg fa-minus"
                          : "fa fa-lg fa-plus"
                      }
                      onClick={this.showrolesubMenu.bind(this, menu.id)}
                    ></i>
                    {this.state.activeMenus.has(menu.id) ? (
                      <ul
                        className="sub-menu list-group"
                        style={{
                          display: "flex",
                          marginLeft: "2em",
                          width: "fit-content",
                        }}
                      >
                        {menu.domainObjects.map((domainObject) =>
                          this.renderDomainObject(domainObject, menu)
                        )}
                      </ul>
                    ) : (
                      ""
                    )}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </li>
          )}
        </>
      );
    }
  }
  editCancel() {
    this.setState({
      action: "view",
    });
  }
  cancelProfile() {
    this.setState({ action: "show_list" });
  }
  cancel() {
    /* Go Back to List Page */
    this.setState({ action: "show_list" });
  }

  updateRole() {
    this.setState({ action: "edit" });
  }

  backToList() {
    this.setState({ action: "show_list" });
  }
  selectedTab(tabName, index) {
    // store.dispatch(appDataAction.setSettingsTab(index));
    this.setState({ selectedIndex: index });
  }

  updateTabStatusToBackend() {
    let salesStatusMappingData = this.state.salesStatusMappingList;
    let salesStatusMappingDataList = [];
    for (let i = 0; i < salesStatusMappingData.length; i++) {
      salesStatusMappingDataList.push({
        isactive: salesStatusMappingData[i].isactive,
        id: salesStatusMappingData[i].id,
      });
    }

    let purchaseStatusMappingData = this.state.purchaseStatusMappingList;
    let purchaseStatusMappingDataList = [];
    for (let i = 0; i < purchaseStatusMappingData.length; i++) {
      purchaseStatusMappingDataList.push({
        isactive: purchaseStatusMappingData[i].isactive,
        id: purchaseStatusMappingData[i].id,
      });
    }

    let expenseStatusMappingData = this.state.expenseStatusMappingList;
    let expenseStatusMappingDataList = [];
    for (let i = 0; i < expenseStatusMappingData.length; i++) {
      expenseStatusMappingDataList.push({
        isactive: expenseStatusMappingData[i].isactive,
        id: expenseStatusMappingData[i].id,
      });
    }
    const postObject = {
      header: this.header,
      profile: {
        id: this.state.id,
      },
      invProfileStatusMapping: {
        sales: {
          statusList: salesStatusMappingDataList,
        },
        purchase: {
          statusList: purchaseStatusMappingDataList,
        },
        expense: {
          statusList: expenseStatusMappingDataList,
        },
      },
    };
    FetchServerData.callPostService(
      "/profilems/setInvProfileStatusMapping",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        // let statusMappingDetails = output.data;
        this.setState({
          render: true,
          action: "view",
        });
      } else {
      }
    });
  }
  handleStatusChecked(inputStatus, action) {
    if (action === "salesStatus") {
      this.state.salesStatusMappingList.map((salesStatus) => {
        if (salesStatus.id === inputStatus.id) {
          salesStatus.isactive = true;
        }
      });
    }
    if (action === "purchaseStatus") {
      this.state.purchaseStatusMappingList.map((purchaseStatus) => {
        if (purchaseStatus.id === inputStatus.id) {
          purchaseStatus.isactive = true;
        }
      });
    }
    if (action === "expenseStatus") {
      this.state.expenseStatusMappingList.map((expenseStatus) => {
        if (expenseStatus.id === inputStatus.id) {
          expenseStatus.isactive = true;
        }
      });
    }
  }
  handleStatusUnChecked(inputStatus, action) {
    if (action === "salesStatus") {
      this.state.salesStatusMappingList.map((salesStatus) => {
        if (salesStatus.id === inputStatus.id) {
          salesStatus.isactive = false;
        }
      });
    }
    if (action === "purchaseStatus") {
      this.state.purchaseStatusMappingList.map((purchaseStatus) => {
        if (purchaseStatus.id === inputStatus.id) {
          purchaseStatus.isactive = false;
        }
      });
    }
    if (action === "expenseStatus") {
      this.state.expenseStatusMappingList.map((expenseStatus) => {
        if (expenseStatus.id === inputStatus.id) {
          expenseStatus.isactive = false;
        }
      });
    }
  }

  onStatusClicked(id, action) {
    if (action === "salesStatus") {
      this.state.salesStatusMappingList.map((salesStatus) => {
        if (salesStatus.id === id) {
          if (salesStatus.isactive === false) {
            this.handleStatusChecked(salesStatus, action);
          } else {
            this.handleStatusUnChecked(salesStatus, action);
          }
        }
      });
      this.setState({
        salesStatusMappingList: this.state.salesStatusMappingList,
      });
    }
    if (action === "purchaseStatus") {
      this.state.purchaseStatusMappingList.map((purchaseStatus) => {
        if (purchaseStatus.id === id) {
          if (purchaseStatus.isactive === false) {
            this.handleStatusChecked(purchaseStatus, action);
          } else {
            this.handleStatusUnChecked(purchaseStatus, action);
          }
        }
      });
      this.setState({
        purchaseStatusMappingList: this.state.purchaseStatusMappingList,
      });
    }
    if (action === "expenseStatus") {
      this.state.expenseStatusMappingList.map((expenseStatus) => {
        if (expenseStatus.id === id) {
          if (expenseStatus.isactive === false) {
            this.handleStatusChecked(expenseStatus, action);
          } else {
            this.handleStatusUnChecked(expenseStatus, action);
          }
        }
      });
      this.setState({
        expenseStatusMappingList: this.state.expenseStatusMappingList,
      });
    }
  }

  onAllStatusClicked(e, action) {
    if (action === "salesStatus") {
      this.state.salesStatusMappingList.map((salesStatus) => {
        if (e.target.checked === false) {
          salesStatus.isactive = false;
        } else {
          salesStatus.isactive = true;
        }
      });
      this.setState({
        salesStatusMappingList: this.state.salesStatusMappingList,
      });
    }
    if (action === "purchaseStatus") {
      this.state.purchaseStatusMappingList.map((purchaseStatus) => {
        if (e.target.checked === false) {
          purchaseStatus.isactive = false;
        } else {
          purchaseStatus.isactive = true;
        }
      });
      this.setState({
        purchaseStatusMappingList: this.state.purchaseStatusMappingList,
      });
    }
    if (action === "expenseStatus") {
      this.state.expenseStatusMappingList.map((expenseStatus) => {
        if (e.target.checked === false) {
          expenseStatus.isactive = false;
        } else {
          expenseStatus.isactive = true;
        }
      });
      this.setState({
        expenseStatusMappingList: this.state.expenseStatusMappingList,
      });
    }
  }

  renderTabSetting(statusData, action) {
    return (
      <li
        className="list-group-item list-group-item-action prev"
        style={{ marginLeft: "0.3em" }}
      >
        <div>
          <input
            // className="checkbox"
            type="checkbox"
            checked={statusData.isactive}
            onChange={this.onStatusClicked.bind(this, statusData.id, action)}
          />
          &nbsp; &nbsp;{statusData.uiname}&nbsp; &nbsp;
        </div>
      </li>
    );
  }

  allSelectedProfileMenu(e) {
    this.state.menus.map((menu) => {
      if (e.target.checked === false) {
        menu.isactive = false;
        let childMenus = menu.childMenus;
        let domainObjects = menu.domainObjects;
        if (childMenus.length > 0) {
          domainObjects.map((domainObject) => {
            domainObject.isactive = false;
            domainObject.domainObjectPrivileges.map((Privilege) => {
              Privilege.isactive = false;
            });
          });
          childMenus.map((childMenu) => {
            childMenu.isactive = false;
            let domainObjects = childMenu.domainObjects;
            domainObjects.map((domainObject) => {
              domainObject.isactive = false;
              domainObject.domainObjectPrivileges.map((Privilege) => {
                Privilege.isactive = false;
              });
            });
          });
        } else {
          domainObjects.map((domainObject) => {
            domainObject.isactive = false;
            domainObject.domainObjectPrivileges.map((Privilege) => {
              Privilege.isactive = false;
            });
          });
        }
      } else {
        menu.isactive = true;
        let childMenus = menu.childMenus;
        let domainObjects = menu.domainObjects;
        if (childMenus.length > 0) {
          domainObjects.map((domainObject) => {
            domainObject.isactive = true;
            domainObject.domainObjectPrivileges.map((Privilege) => {
              Privilege.isactive = true;
            });
          });
          childMenus.map((childMenu) => {
            childMenu.isactive = true;
            let domainObjects = childMenu.domainObjects;
            domainObjects.map((domainObject) => {
              domainObject.isactive = true;
              domainObject.domainObjectPrivileges.map((Privilege) => {
                Privilege.isactive = true;
              });
            });
          });
        } else {
          domainObjects.map((domainObject) => {
            domainObject.isactive = true;
            domainObject.domainObjectPrivileges.map((Privilege) => {
              Privilege.isactive = true;
            });
          });
        }
      }
    });
    this.setState({
      menus: this.state.menus,
    });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    if (this.state.action === "show_list") {
      return <RoleList action="view" />;
    }

    if (this.state.action === "create") {
      return this.createProfile();
    }

    if (this.state.action === "edit") {
      return this.editProfile();
    }
    if (this.state.action === "view") {
      return this.viewProfile();
    }
  }
  createProfile() {
    return (
      <>
        {/* <MenuWithBreadScrum
					breadCrumb={this.breadCrumb}
					navigate={this.props.navigate}
					domainObject="Profile"
					name="Create Profile"
				/> */}
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body" style={{ height: "100%" }}>
                {this.state.addProfile ? (
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-4 mt-4">
                      <div className="row">
                        <label
                          className="col-sm-3 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Profile<span className="asterisk"> *</span>
                        </label>
                        <div className="col-sm-9" id="OrganizationCol">
                          <TextField
                            type="text"
                            className="textField"
                            fullWidth
                            id="basic-default-name"
                            variant="outlined"
                            placeholder="Profile"
                            value={this.state.name}
                            onChange={this.handleNameChange.bind(this)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 mt-4 text-start align-self-center">
                      <CreateButton
                        btnName="Create"
                        type="submit"
                        onClick={this.createProfileToBackend.bind(this)}
                      />
                      &nbsp;&nbsp;
                      <CancelIconButton
                        btnName="Cancel"
                        onClick={(e) => this.cancelProfile()}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-4 mt-4">
                      <div className="row">
                        <label
                          className="col-sm-3 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Profile<span className="asterisk"> *</span>
                        </label>
                        <div className="col-sm-9" id="OrganizationCol">
                          <TextField
                            type="text"
                            className="textField"
                            fullWidth
                            id="basic-default-name"
                            variant="outlined"
                            placeholder="Profile"
                            value={this.state.name}
                            onChange={this.handleNameChange.bind(this)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.updateProfile && (
                  <>
                    <div className="mt-4">
                      <ul className="list-group text-start">
                        <li
                          className="list-group-item list-group-item-action prev ms-3 d-flex"
                          style={{
                            backgroundColor: "#c5e4ff",
                            padding: "8px 12px",
                          }}
                        >
                          <input
                            className="checkbox"
                            type="checkbox"
                            onChange={(e) => this.allSelectedProfileMenu(e)}
                          />
                          <span
                            className="bold"
                            style={{
                              marginLeft: "10px",
                              fontSize: "16px",
                              color: "black",
                              fontWeight: "650",
                            }}
                          >
                            Select All
                          </span>
                        </li>
                        {this.state.menus &&
                          this.state.menus.map((menu) =>
                            this.renderMenus(menu)
                          )}
                      </ul>
                    </div>
                    <div className="row d-flex align-items-center justify-content-between">
                      <div className="col-sm-12 mt-3 text-center">
                        <SaveButton
                          btnName="Create"
                          type="submit"
                          onClick={this.updateProfileToBackend.bind(this)}
                        />
                        &nbsp;&nbsp;
                        <CancelIconButton
                          btnName="Cancel"
                          onClick={(e) => this.cancelProfile()}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  viewProfile() {
    if (this.state.render === false) {
      return <></>;
    } else {
      const tabList = this.state.tabList.map((tab, keyIndex) => {
        return (
          tab.isactive && (
            <Tab
              key={keyIndex}
              onClick={(e) => this.selectedTab(tab, keyIndex)}
            >
              <span style={{ fontWeight: "600" }}>{tab.uiname}</span>
            </Tab>
          )
        );
      });
      return (
        <>
          {/* <MenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						navigate={this.props.navigate}
						deleteCallback={this.deleteProfileToBackend.bind(this)}
						editCallback={this.editProfileManagement.bind(this)}
						getDeletePermission={this.state.getDeletePermission}
						getUpdatePermission={this.state.getUpdatePermission}
						domainObject="Profile"
						name="View Profile"
					/> */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body" style={{ height: "100%" }}>
                  {/* <div className="col-auto me-auto text-start">
                    <div id="headerLabel">
                      <span className='ms-3'>{this.state.name}</span>
                    </div>
                  </div> */}
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginLeft: "5px",
                    }}
                  >
                    <Col>
                      <span id="headerLabel">
                        <strong>{this.state.name}</strong>
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12} className="settingTabRow">
                      <Tabs
                        md={12}
                        style={{
                          width: "100%",
                          color: "#1b7189",
                          outline: "none",
                          margin: "0",
                        }}
                        selectedIndex={this.state.selectedIndex}
                        className="settingTab"
                      >
                        <TabList
                          style={{
                            color: "#1b7189",
                            outline: "none",
                            margin: "0",
                          }}
                        >
                          {tabList}
                        </TabList>
                        <TabPanel>
                          <Row
                            style={{
                              position: "relative",
                              margin: "0.1em 1em 1em 0em",
                            }}
                          >
                            <Col
                              md={12}
                              style={{ position: "relative", padding: "1px" }}
                            >
                              <Card style={{ backgroundColor: "#d5d8dc" }}>
                                <div className="row">
                                  <div className="col-12">
                                    <div
                                      style={{
                                        float: "left",
                                        margin: "0.8em 0em 0em 2em",
                                      }}
                                    >
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        style={{
                                          background: "#717373",
                                          color: "#fff",
                                          margin: "0.5em",
                                          padding: "5px 15px 5px 15px",
                                        }}
                                        startIcon={<ArrowBackIcon />}
                                        onClick={(e) => this.cancelProfile(e)}
                                      >
                                        Back
                                      </Button>
                                    </div>
                                    <div
                                      style={{
                                        float: "right",
                                        margin: "1.5em 2em 0em 0em",
                                      }}
                                    >
                                      <DeleteIconButton
                                        domainObject="Expense Category"
                                        onClick={this.deleteProfileToBackend.bind(
                                          this
                                        )}
                                        caption="Delete Business Unit"
                                      />
                                      <EditIconButton
                                        onClick={this.editProfileManagement.bind(
                                          this
                                        )}
                                        caption="Edit Business Unit"
                                      ></EditIconButton>
                                    </div>
                                  </div>
                                </div>
                                <ul
                                  className="list-group text-start"
                                  style={{ margin: "0em 2em 1.5em 2em" }}
                                >
                                  {this.state.menus &&
                                    this.state.menus.map((menu) =>
                                      this.renderMenus(menu, "view")
                                    )}
                                </ul>
                              </Card>
                            </Col>
                          </Row>
                        </TabPanel>
                        <TabPanel>
                          <Row
                            style={{
                              position: "relative",
                              margin: "0.1em 1em 1em 0em",
                            }}
                          >
                            <Col
                              md={12}
                              style={{ position: "relative", padding: "1px" }}
                            >
                              <Card style={{ backgroundColor: "#d5d8dc" }}>
                                <div className="row ms-4 mt-4">
                                  <span
                                    style={{ fontSize: "16px", color: "red" }}
                                  >
                                    * Selected Tabs will be shown on List page
                                    of Sales Voucher, Purchase Voucher and
                                    Expense
                                  </span>
                                </div>
                                <div className="row">
                                  <div className="col-4">
                                    <ul
                                      className="list-group text-start"
                                      style={{ margin: "2em 2em 1.5em 2em" }}
                                    >
                                      {/* <div className="row d-flex ms-1 me-1"> */}
                                      <li
                                        className="list-group-item list-group-item-action prev ms-1"
                                        style={{
                                          backgroundColor: "#c5e4ff",
                                          padding: "12px",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            this.onAllStatusClicked(
                                              e,
                                              "salesStatus"
                                            )
                                          }
                                        />
                                        <span
                                          className="bold"
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: "650",
                                          }}
                                        >
                                          Sales Voucher
                                        </span>
                                      </li>
                                      {/* </div> */}
                                      {this.state.salesStatusMappingList &&
                                        this.state.salesStatusMappingList.map(
                                          (salesStatus) =>
                                            this.renderTabSetting(
                                              salesStatus,
                                              "salesStatus"
                                            )
                                        )}
                                    </ul>
                                  </div>
                                  <div className="col-4">
                                    <ul
                                      className="list-group text-start"
                                      style={{ margin: "2em 2em 1.5em 2em" }}
                                    >
                                      <li
                                        className="list-group-item list-group-item-action prev ms-1"
                                        style={{
                                          backgroundColor: "#c5e4ff",
                                          padding: "12px",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            this.onAllStatusClicked(
                                              e,
                                              "purchaseStatus"
                                            )
                                          }
                                        />
                                        <span
                                          className="bold"
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: "650",
                                          }}
                                        >
                                          Purchase Voucher
                                        </span>
                                      </li>
                                      {this.state.purchaseStatusMappingList &&
                                        this.state.purchaseStatusMappingList.map(
                                          (purchaseStatus) =>
                                            this.renderTabSetting(
                                              purchaseStatus,
                                              "purchaseStatus"
                                            )
                                        )}
                                    </ul>
                                  </div>
                                  <div className="col-4">
                                    <ul
                                      className="list-group text-start"
                                      style={{ margin: "2em 2em 1.5em 2em" }}
                                    >
                                      <li
                                        className="list-group-item list-group-item-action prev ms-1"
                                        style={{
                                          backgroundColor: "#c5e4ff",
                                          padding: "12px",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            this.onAllStatusClicked(
                                              e,
                                              "expenseStatus"
                                            )
                                          }
                                        />
                                        <span
                                          className="bold"
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: "650",
                                          }}
                                        >
                                          Expense
                                        </span>
                                      </li>
                                      {this.state.expenseStatusMappingList &&
                                        this.state.expenseStatusMappingList.map(
                                          (expenseStatus) =>
                                            this.renderTabSetting(
                                              expenseStatus,
                                              "expenseStatus"
                                            )
                                        )}
                                    </ul>
                                  </div>
                                  <div className="row d-flex align-items-center justify-content-between pb-4">
                                    <div className="col-sm-12 text-center">
                                      <SaveButton
                                        btnName="Create"
                                        type="submit"
                                        onClick={this.updateTabStatusToBackend.bind(
                                          this
                                        )}
                                      />
                                      &nbsp;&nbsp;
                                      {/* <CancelIconButton btnName="Cancel" onClick={(e) => this.editCancel()} /> */}
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </TabPanel>
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{ background: "#717373", color: "#fff", margin: "0.5em" }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelProfile(e)}
            >
              Back
            </Button>
          </div>
        </>
      );
    }
  }

  editProfile() {
    return (
      <>
        {/* <MenuWithBreadScrum
					breadCrumb={this.breadCrumb}
					navigate={this.props.navigate}
					domainObject="Profile"
					name="Edit Profile"
				/> */}
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body" style={{ height: "100%" }}>
                <div className="row">
                  <div className="col-md-4 mt-4"></div>
                  <div className="col-md-4 mt-4">
                    <div className="row">
                      <label
                        className="col-sm-3 col-form-label fw-bold"
                        htmlFor="basic-default-name"
                        id="assetFormLabel"
                      >
                        Profile<span className="asterisk"> *</span>
                      </label>
                      <div className="col-sm-9" id="OrganizationCol">
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Profile"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <ul className="list-group text-start">
                    <li
                      className="list-group-item list-group-item-action prev ms-3 d-flex"
                      style={{
                        backgroundColor: "#c5e4ff",
                        padding: "8px 12px",
                      }}
                    >
                      <input
                        className="checkbox"
                        type="checkbox"
                        onChange={(e) => this.allSelectedProfileMenu(e)}
                      />
                      <span
                        className="bold"
                        style={{
                          marginLeft: "10px",
                          fontSize: "16px",
                          color: "black",
                          fontWeight: "650",
                        }}
                      >
                        Select All
                      </span>
                    </li>
                    {this.state.menus &&
                      this.state.menus.map((menu) => this.renderMenus(menu))}
                  </ul>
                </div>
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-sm-12 mt-3 text-center">
                    <SaveButton
                      btnName="Create"
                      type="submit"
                      onClick={this.updateProfileToBackend.bind(this)}
                    />
                    &nbsp;&nbsp;
                    <CancelIconButton
                      btnName="Cancel"
                      onClick={(e) => this.editCancel()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Role;

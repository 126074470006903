// import Button from "@material-ui/core/Button";
// import SaveIcon from "@material-ui/icons/Save";
import ToggleButton from "@mui/material/ToggleButton";
import React, { Component } from "react";
import { CardBody, CardHeader, CardFooter, Col, Row } from "reactstrap";
import { Autocomplete } from "@mui/material";
import TextField from "@material-ui/core/TextField";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import PtsAlert from "../ptsAlert/PtsAlert";
import SaveButton from "../buttons/SaveButton";
import CancelButton from "../buttons/CancelButton";
import EditIconButton from "../buttons/EditIconButton";
import "./GeneralSettings.scss";
import userMenusAction from "../../../redux/userMenus/userMenusAction";
export class GeneralSettings extends Component {
  defaultCountry = store.getState().organization.organization.defaultCountry;
  defaultCurrency = store.getState().organization.organization.defaultCurrency;
  general = store.getState().setting.general;
  constructor(props) {
    super(props);
    let settings = props.data;
    if (settings !== null) {
      this.state = {
        action: this.props.action,
        id: null === settings.id ? "" : settings.id,
        isexportapplicable:
          null === settings.isexportapplicable
            ? ""
            : settings.isexportapplicable,
        issezapplicable:
          null === settings.issezapplicable ? "" : settings.issezapplicable,
        issalesthrecom:
          null === settings.issalesthrecom ? "" : settings.issalesthrecom,
        isoriginalduplicatebills:
          null === settings.isoriginalduplicatebill
            ? ""
            : settings.isoriginalduplicatebills,
        financialStartMonth:
          null === settings.financialStartMonth
            ? this.general.financialStartMonth
            : settings.financialStartMonth,
        defaultcountry:
          null === settings.defaultcountry
            ? this.defaultCountry
            : settings.defaultcountry,
        defaultcurrency: this.defaultCurrency,
        callSettingsCallback: this.props.onChange,
        monthList: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      };
    } else {
      this.state = {
        id: "",
        action: this.props.action,
        isexportapplicable: false,
        issezapplicable: false,
        issalesthrecom: false,
        isoriginalduplicatebills: false,
        financialStartMonth: null,
        defaultcountry: this.defaultCountry,
        defaultcurrency: this.defaultCurrency,
        callSettingsCallback: this.props.onChange,
        monthList: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      };
    }
  }

  handleExportApplicableChange(selectedState) {
    this.setState({ isexportapplicable: !this.state.isexportapplicable });
  }

  handleSezApplicableChange(selectedState) {
    this.setState({ issezapplicable: !this.state.issezapplicable });
  }

  handleEcomSaleChange(selectedState) {
    this.setState({ issalesthrecom: !this.state.issalesthrecom });
  }

  handleDulicateBillsChange(selectedState) {
    this.setState({
      isoriginalduplicatebills: !this.state.isoriginalduplicatebills,
    });
  }
  handleFinancialStartMonth(e, month) {
    this.setState({
      financialStartMonth: month,
    });
  }
  editButtonClicked(e) {
    this.setState({ action: "edit" });
  }
  cancel() {
    let settings = this.props.data;
    this.setState({
      action: "view",
      render: true,
      id: null === settings.id ? "" : settings.id,
      isexportapplicable:
        null === settings.isexportapplicable ? "" : settings.isexportapplicable,
      issezapplicable:
        null === settings.issezapplicable ? "" : settings.issezapplicable,
      issalesthrecom:
        null === settings.issalesthrecom ? "" : settings.issalesthrecom,
      isoriginalduplicatebills:
        null === settings.isoriginalduplicatebill
          ? ""
          : settings.isoriginalduplicatebills,
      financialStartMonth:
        null === settings.financialStartMonth
          ? ""
          : settings.financialStartMonth,
    });
  }
  saveGeneralSettings() {
    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      settings: {
        financialStartMonth: this.state.financialStartMonth,
        isexportapplicable: this.state.isexportapplicable,
        issezapplicable: this.state.issezapplicable,
        issalesthrecom: this.state.issalesthrecom,
        ispregstinvoices: this.state.ispregstinvoices,
        isoriginalduplicatebills: this.state.isoriginalduplicatebills,
      },

      header: header,
    };
    FetchServerData.callPostService("/settings/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let settings = output.data;
          PtsAlert.success("Settings Updated Successfully");
          if (undefined !== this.state.callSettingsCallback) {
            this.state.callSettingsCallback(settings);
          }
          let appuserVo = settings.profile.menus;
          store.dispatch(userMenusAction.setUserMenus(appuserVo));
          // store.dispatch(userMenusAction.setUserTopMenus(appuserVo));
          // let appData = store.getState().appData;
          // appData.settings = settings
          this.setState({
            action: "view",
            render: true,
          });
        } else {
          PtsAlert.error("Settings Update Failed :" + output.message);
        }
      }
    );
  }

  render() {
    if (this.state.action === "view") {
      return this.viewGeneral();
    } else if (this.state.action === "edit") {
      return this.editGeneral();
    }
  }

  viewGeneral() {
    return (
      <>
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col md={6} style={{ margin: "auto" }}>
              <span>
                <strong>General Details</strong>
              </span>
            </Col>
            <Col md={6} style={{ marginTop: "0.5em" }}>
              <EditIconButton
                onClick={this.editButtonClicked.bind(this)}
                caption="Edit Organization"
              ></EditIconButton>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row md={12} className="mt-4 pb-3">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Whether Export applicable for Your Business ?
              </h6>
            </Col>
            <Col md={6}>
              {this.state.isexportapplicable === true && (
                // <ToggleButton
                //   value="Yes"
                //   className="ToggleButtonActive"
                // // onChange={this.handleExportApplicableChange.bind(this)}
                // >
                <span className="bold">Yes</span>
                // </ToggleButton>
              )}
              {this.state.isexportapplicable === false && (
                <span className="bold">No</span>
              )}
            </Col>
          </Row>
          <Row md={12} className="pb-3">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Whether SEZ / Deemed Export applicable for Your Business ?
              </h6>
            </Col>
            <Col md={6}>
              {this.state.issezapplicable === true && (
                <span className="bold">Yes</span>
              )}
              {this.state.issezapplicable === false && (
                <span className="bold">No</span>
              )}
            </Col>
          </Row>
          <Row md={12} className="pb-3">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Whether Sales through ECOM ?
              </h6>
            </Col>
            <Col md={6}>
              {this.state.issalesthrecom === true && (
                <span className="bold">Yes</span>
              )}
              {this.state.issalesthrecom === false && (
                <span className="bold">No</span>
              )}
            </Col>
          </Row>
          <Row md={12} className="pb-3">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Do you want to print Original/Duplicate on Invoice Copies ?
              </h6>
            </Col>
            <Col md={6}>
              {this.state.isoriginalduplicatebills === true && (
                <span className="bold">Yes</span>
              )}
              {this.state.isoriginalduplicatebills === false && (
                <span className="bold">No</span>
              )}
            </Col>
          </Row>
          <Row md={12} className="pb-3">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Default Country
              </h6>
            </Col>
            <Col md={6}>
              <span>
                <b>
                  {null === this.defaultCountry ? "" : this.defaultCountry.name}
                </b>
              </span>
            </Col>
          </Row>
          <Row md={12} className="pb-3">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Default Currency
              </h6>
            </Col>
            <Col md={6}>
              <span>
                <b>
                  {null === this.defaultCurrency
                    ? ""
                    : this.defaultCurrency.name}
                </b>
              </span>
            </Col>
          </Row>
          <Row md={12} className="pb-3">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Financial Year Start Month
              </h6>
            </Col>
            <Col md={6}>
              <span>
                <b>{this.state.financialStartMonth}</b>
              </span>
            </Col>
          </Row>
        </CardBody>
        {/* <CardFooter style={{ width: "100%" }}>
          <Row md={12}>
            <div style={{ textAlign: "center", width: "100%" }}>
              <Button
                variant="contained"
                style={{ background: "#1b7189", color: "#fff" }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.saveGeneralSettings()}
              >
                Save
              </Button>
            </div>
          </Row>
        </CardFooter> */}
      </>
    );
  }

  editGeneral() {
    return (
      <>
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
              padding: "10px",
            }}
          >
            <Col md={12} style={{ margin: "auto" }}>
              <span>
                <strong>General Details</strong>
              </span>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row md={12} className="mt-4">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Whether Export applicable for Your Business ?
              </h6>
            </Col>
            <Col md={6}>
              {this.state.isexportapplicable === true && (
                <ToggleButton
                  value="Yes"
                  className="ToggleButtonActive"
                  onChange={this.handleExportApplicableChange.bind(this)}
                >
                  Yes
                </ToggleButton>
              )}
              {this.state.isexportapplicable === false && (
                <ToggleButton
                  value="No"
                  className="ToggleButtonInActive"
                  onChange={this.handleExportApplicableChange.bind(this)}
                >
                  No
                </ToggleButton>
              )}
            </Col>
          </Row>
          <Row md={12}>
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Whether SEZ / Deemed Export applicable for Your Business ?
              </h6>
            </Col>
            <Col md={6}>
              {this.state.issezapplicable === true && (
                <ToggleButton
                  value="Yes"
                  className="ToggleButtonActive"
                  onChange={this.handleSezApplicableChange.bind(this)}
                >
                  Yes
                </ToggleButton>
              )}
              {this.state.issezapplicable === false && (
                <ToggleButton
                  value="No"
                  className="ToggleButtonInActive"
                  onChange={this.handleSezApplicableChange.bind(this)}
                >
                  No
                </ToggleButton>
              )}
            </Col>
          </Row>
          <Row md={12}>
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Whether Sales through ECOM ?
              </h6>
            </Col>
            <Col md={6}>
              {this.state.issalesthrecom === true && (
                <ToggleButton
                  value="Yes"
                  className="ToggleButtonActive"
                  onChange={this.handleEcomSaleChange.bind(this)}
                >
                  Yes
                </ToggleButton>
              )}
              {this.state.issalesthrecom === false && (
                <ToggleButton
                  value="No"
                  className="ToggleButtonInActive"
                  onChange={this.handleEcomSaleChange.bind(this)}
                >
                  No
                </ToggleButton>
              )}
            </Col>
          </Row>
          <Row md={12}>
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Do you want to print Original/Duplicate on Invoice Copies ?
              </h6>
            </Col>
            <Col md={6}>
              {this.state.isoriginalduplicatebills === true && (
                <ToggleButton
                  value="Yes"
                  className="ToggleButtonActive"
                  onChange={this.handleDulicateBillsChange.bind(this)}
                >
                  Yes
                </ToggleButton>
              )}
              {this.state.isoriginalduplicatebills === false && (
                <ToggleButton
                  value="No"
                  className="ToggleButtonInActive"
                  onChange={this.handleDulicateBillsChange.bind(this)}
                >
                  No
                </ToggleButton>
              )}
            </Col>
          </Row>
          <Row md={12} className="mb-2">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Default Country
              </h6>
            </Col>
            <Col md={2}>
              <TextField
                disabled
                fullWidth={true}
                variant="outlined"
                id="name"
                placeholder="Name"
                value={
                  null === this.defaultCountry ? "" : this.defaultCountry.name
                }
                name="name"
                autoComplete="name"
                InputlabelProps={{ shrink: true }}
              />
            </Col>
          </Row>
          <Row md={12} className="mb-2">
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Default Currency
              </h6>
            </Col>
            <Col md={2}>
              <TextField
                disabled
                fullWidth={true}
                variant="outlined"
                id="name"
                placeholder="Name"
                value={
                  null === this.defaultCurrency ? "" : this.defaultCurrency.name
                }
                name="name"
                autoComplete="name"
                InputlabelProps={{ shrink: true }}
              />
            </Col>
          </Row>
          <Row md={12}>
            <Col md={6}>
              <h6 style={{ color: "#000", fontFamily: "600" }}>
                Financial Year Start Month
              </h6>
            </Col>
            <Col md={2}>
              <Autocomplete
                id="financialStartMonth"
                size="small"
                ListboxProps={{ className: "myCustomList" }}
                options={
                  undefined === this.state.monthList ||
                  null === this.state.monthList
                    ? []
                    : this.state.monthList
                }
                value={this.state.financialStartMonth}
                getOptionLabel={(option) => option}
                onChange={(event, value) => {
                  this.handleFinancialStartMonth(event, value);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth={true}
                    variant="outlined"
                    className="regCountry"
                    {...params}
                    placeholder="Financial Year Start Month"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter style={{ width: "100%" }}>
          <Row md={12}>
            <div style={{ textAlign: "center", width: "100%" }}>
              {/* <Button
                variant="contained"
                style={{ background: "#1b7189", color: "#fff" }}
                startIcon={<SaveIcon />}

              >
                Save
              </Button> */}
              <SaveButton
                type="submit"
                onClick={(e) => this.saveGeneralSettings()}
              />
              <CancelButton onClick={(e) => this.cancel()} />
            </div>
          </Row>
        </CardFooter>
      </>
    );
  }
}

export default GeneralSettings;

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Checkbox from "@material-ui/core/Checkbox";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DescriptionIcon from "@mui/icons-material/Description";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Badge from "@material-ui/core/Badge";
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import appDataAction from "../../../redux/appData/appDataAction";
import store from "../../../redux/store";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import "./Expense.scss";
import ApplicationStateAction from "../../../redux/applicationState/ApplicationStateAction";
import AddIconButton from "../buttons/AddIconButton";
import masterDataAction from "../../../redux/masterData/masterDataAction";
import PaymentDetailsDialogue from "../payments/PaymentDetailsDialogue";
import ViewReceipt from "../attachments/ViewReceipt";
import RepeatIcon from "@material-ui/icons/Repeat";
import PtsAlert from "../ptsAlert/PtsAlert";
import Tooltip from "@material-ui/core/Tooltip";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import paymentIcon from "../../../assets/images/paymentIcon.jpeg";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});

export class ExpenseList extends Component {
  header = store.getState().header.header;
  expenseState = store.getState().applicationState.expenseState;
  businessUnits = store.getState().master.masterData.businessUnits;
  expenseStateDetails = {};
  breadCrumb = [];
  title = "Expense List";
  constructor(props) {
    super(props);
    let menu = null;
    if (this.props.location && this.props.location.pathname) {
      let pathname = this.props.location.pathname;
      let char = pathname.slice(-1);
      if (char !== "/") {
        pathname = pathname + "/";
      }
      menu = Utils.getTopMenuWithPageID(pathname);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
    }
    if (menu) {
      this.breadCrumb = Utils.getMenuHierarchy(menu.pageid);

      store.dispatch(appDataAction.setActiveMenu(menu.id));
    }
    this.tableIcons = UiUtils.getMaterialTableIcons();
    if (this.expenseState) {
      this.expenseStateDetails = Object.getOwnPropertyNames(this.expenseState);
    }
    if (this.expenseStateDetails.length > 0) {
      this.state = {
        selectedTabIndex: 0,
        invoiceId: this.expenseState.invoiceId,
        pageSize: this.expenseState.pageSize,
        initialPage: this.expenseState.initialPage,
        startDate: this.expenseState.startDate,
        endDate: this.expenseState.endDate,
        selectedBusinessUnit:
          this.expenseState.selectedBusinessUnit !== undefined
            ? this.expenseState.selectedBusinessUnit
            : this.businessUnits[0],
        isAsset: this.expenseState.isAsset,
        tabName: this.expenseState.tabName,
        expenseList: [],
        privileges: [],
        invoiceStatusList: [],
        expenseCategoryList: [],
        businessUnits: this.businessUnits,
        open: false,
        isPaging: false,
        isFirstTimeLoading: false,
        isInvoiceStatus: false,
        render: false,
      };
    } else {
      this.state = {
        selectedTabIndex: 0,
        selectedBusinessUnit: null,
        expenseList: [],
        privileges: [],
        invoiceStatusList: [],
        expenseCategoryList: [],
        businessUnits: this.businessUnits,
        initialPage: 0,
        pageSize: 10,
        startDate: null,
        endDate: null,
        open: false,
        isPaging: false,
        isFirstTimeLoading: true,
        isInvoiceStatus: false,
        render: false,
      };
    }
    this.getExpenseList(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedBusinessUnit?.code,
      this.state.isAsset,
      false
    );
  }

  componentDidMount() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/masterms/getMasterData", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let masterData = output.data;
          store.dispatch(masterDataAction.setMasterData(masterData));
          this.setState({
            // render: true,
            businessUnits: masterData.businessUnits,
            selectedBusinessUnit: masterData.businessUnits[0],
          });
        }
      }
    );
  }
  getExpenseList(startDate, endDate, bucode, isAsset, reset) {
    if (this.state.errorMassage || this.state.error) {
      return false;
    }
    const postObject = {
      header: this.header,
      bucode: bucode,
      startDate: startDate,
      endDate: endDate,
      isAsset: isAsset,
    };
    FetchServerData.callPostService(
      "/expensems/getListForUser",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let expenseDetails = output.data;
        let expenseList = output.data.expenses;
        // let businessUnits = output.data.businessUnits;
        let expenseCategoryList = output.data.expenseCategoryList;
        let privileges = output.data.privileges;
        let invoiceStatusList = output.data.invoiceStatusList;
        // store.dispatch(appDataAction.setUsersBusinessUnits(businessUnits));
        store.dispatch(appDataAction.setExpenseCategories(expenseCategoryList));
        let isPaging = false;
        if (expenseList.length > this.state.pageSize) {
          isPaging = true;
        }
        let expstatus = {};
        for (let i = 0; i < expenseList.length; i++) {
          expenseList[i].expdate = Utils.ptsDateFormat(expenseList[i].expdate);
          expenseList[i].newAmount = `${
            expenseList[i].currencysymbol +
            " " +
            expenseList[i].amount.toFixed(2)
          }`;
          expenseList[i].fileExtension = expenseList[
            i
          ].receiptFilename?.substring(
            expenseList[i].receiptFilename.lastIndexOf(".") + 1
          );
          expstatus.status = expenseList[i].status;
        }
        this.setState({
          isFirstTimeLoading: reset ? true : false,
          selectedBusinessUnit: reset
            ? this.businessUnits[0]
            : this.state.selectedBusinessUnit,
          expenseList: expenseList,
          // businessUnits: businessUnits,
          expenseCategoryList: expenseCategoryList,
          privileges: privileges,
          invoiceStatusList: invoiceStatusList,
          expstatus: expstatus,
          isPaging: isPaging,
          isAsset: isAsset,
          defaultStartDate: expenseDetails.startDateString,
          defaultEndDate: expenseDetails.endDateString,
          startDate: expenseDetails.startDate,
          endDate: expenseDetails.endDate,
          open: false,
          render: true,
        });
      } else {
        this.setState({ isInvoiceStatus: true });
      }
    });
  }

  selectedTab(tabName, index) {
    this.setState({ selectedTabIndex: index, tabName: tabName });
  }
  addExpense = (e) => {
    this.props.history.push({
      pathname: "/expenseCreate",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  toExpenseDetails = (e, data) => {
    let expenseState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
      isAsset: this.state.isAsset,
      tabName: this.state.tabName,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ expenseState })
    );
    this.props.history.push({
      pathname: "/expenseView",
      state: {
        expenseId: data.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  paymentDetails(invoiceId) {
    if (
      this.paymentDetailsDialogueRef &&
      this.paymentDetailsDialogueRef.current
    ) {
      this.paymentDetailsDialogueRef.current.showExpensePaymentDetails(
        invoiceId
      );
    }
  }

  ReceiptDetails(data) {
    if (
      data.fileExtension === "xlsx" ||
      data.fileExtension === "zip" ||
      data.fileExtension === "pptx" ||
      data.fileExtension === "docx"
    ) {
      // let receiptUrl = data.receiptUrl;
      if (this.receiptDialogueRef && this.receiptDialogueRef.current) {
        this.receiptDialogueRef.current.downloadDocument(data);
      }
    } else {
      let receiptUrl = data.receiptUrl;
      let aid = data.id;
      if (this.receiptDialogueRef && this.receiptDialogueRef.current) {
        this.receiptDialogueRef.current.viewDocument(receiptUrl, aid);
      }
    }
  }

  rowItemSelected(rowData, e) {
    let selectedItems = this.state.expenseList;
    for (let i = 0; i < selectedItems.length; i++) {
      if (rowData.id === selectedItems[i].id) {
        if (e.target.checked) {
          selectedItems[i].isSelected = true;
        } else {
          selectedItems[i].isSelected = false;
        }
      }
    }
    this.setState({ expenseList: selectedItems });
  }

  showActionButton(rowData) {
    let fileExtension = rowData.fileExtension;
    return (
      <>
        {rowData.hasReceipt === true ? (
          <>
            <Badge color="primary" overlap="rectangular">
              {(fileExtension === "pdf" ||
                fileExtension === "png" ||
                fileExtension === "doc" ||
                fileExtension === "jpg" ||
                fileExtension === "jpeg") && (
                <Tooltip title="Receipt">
                  <ReceiptLongIcon
                    className="message"
                    style={{
                      fontSize: "25px",
                      alignItems: "center",
                      color: "#1b7189",
                      cursor: "pointer",
                    }}
                    onClick={this.ReceiptDetails.bind(this, rowData)}
                  />
                </Tooltip>
              )}
              {fileExtension === "pptx" && (
                <Tooltip title="Receipt">
                  <i
                    className="fa fa-file-powerpoint-o ms-1"
                    aria-hidden="true"
                    style={{
                      fontSize: "22px",
                      alignItems: "center",
                      color: "#dc4c4c",
                      cursor: "pointer",
                    }}
                    onClick={this.ReceiptDetails.bind(this, rowData)}
                  ></i>
                </Tooltip>
              )}
              {fileExtension === "docx" && (
                <Tooltip title="Receipt">
                  <i
                    className="fa fa-file-word-o ms-1"
                    aria-hidden="true"
                    style={{
                      fontSize: "22px",
                      alignItems: "center",
                      color: "#589cb4",
                      cursor: "pointer",
                    }}
                    onClick={this.ReceiptDetails.bind(this, rowData)}
                  ></i>
                </Tooltip>
              )}
              {fileExtension === "xlsx" && (
                <Tooltip title="Receipt">
                  <i
                    className="fa fa-file-excel-o ms-1"
                    aria-hidden="true"
                    style={{
                      fontSize: "22px",
                      alignItems: "center",
                      color: "green",
                      cursor: "pointer",
                    }}
                    onClick={this.ReceiptDetails.bind(this, rowData)}
                  ></i>
                </Tooltip>
              )}
              {fileExtension === "zip" && (
                <Tooltip title="Receipt">
                  <i
                    className="fa fa-file-archive-o ms-1"
                    aria-hidden="true"
                    style={{
                      fontSize: "22px",
                      alignItems: "center",
                      backgroundColor: "#FFE9A2",
                      cursor: "pointer",
                    }}
                    onClick={this.ReceiptDetails.bind(this, rowData)}
                  ></i>
                </Tooltip>
              )}
            </Badge>
            &nbsp; &nbsp;
          </>
        ) : (
          <div style={{ marginLeft: "0.8em", fontSize: "1.3rem" }}>
            {" "}
            &nbsp;&nbsp;
          </div>
        )}
        {rowData.hasPayment === true ? (
          <Tooltip title="Payment Details">
            <img
              className="ms-2"
              alt=""
              src={paymentIcon}
              onClick={this.paymentDetails.bind(this, rowData.id)}
              style={{ height: "28px", maxWidth: "none", cursor: "pointer" }}
            />
          </Tooltip>
        ) : (
          <div style={{ marginLeft: "1.8em", fontSize: "1.3rem" }}></div>
        )}
        {rowData.hasAuditNotes === true ? (
          <Tooltip title="Audit Notes">
            <DescriptionIcon
              className="ms-2"
              style={{
                fontSize: "22px",
                alignItems: "center",
                color: "#ffbb33",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        ) : (
          <div style={{ marginLeft: "1.4em", fontSize: "1.3rem" }}></div>
        )}
      </>
    );
  }

  getTablesWithStatus(status) {
    let filteredData = this.state.expenseList.filter(function (e) {
      return e.status === status;
    });
    if (status === "All") {
      filteredData = this.state.expenseList;
    }
    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Expense",
        isFreeAction: true,
        textAlign: "left",
        onClick: (event) => this.addExpense(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <Row md={12}>
        <div style={{ width: "100%", padding: "0em 1em" }}>
          <MaterialTable
            icons={this.tableIcons}
            localization={{
              body: {
                emptyDataSourceMessage: Utils.communizeTableText(),
              },
            }}
            title=""
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "5%",
                },
              },
              {
                title: "Date",
                field: "expdate",
                type: "date",
                align: "left",
                cellStyle: {
                  width: "11%",
                },
              },
              {
                title: "Expense Number",
                field: "expnumber",
                align: "left",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Bill Number",
                field: "billnumber",
                align: "left",
                cellStyle: {
                  width: "13%",
                },
              },
              {
                title: "Purpose",
                field: "purpose",
                align: "left",
                cellStyle: {
                  width: "15%",
                  wordBreak: "break-word",
                },
              },
              {
                title: "Supplier",
                field: "supplier",
                align: "left",
                cellStyle: {
                  width: "15%",
                  wordBreak: "break-word",
                },
              },
              {
                title: "Amount",
                field: "newAmount",
                align: "right",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Status",
                field: "statusdescription",
                align: "Left",
                cellStyle: {
                  width: "15%",
                  wordBreak: "break-word",
                },
              },
              {
                title: "Action",
                sorting: false,
                disableClick: true,
                render: (rowData) => this.showActionButton(rowData),
                cellStyle: {
                  width: "20%",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "smaller",
                },
              },
            ]}
            data={filteredData}
            options={{
              sorting: true,
              isFreeAction: true,
              showFirstLastPageButtons: true,
              selection:
                Utils.equalsIgnoreCase("CREATED", status) ||
                Utils.equalsIgnoreCase("VERIFICATIONREQUESTED", status)
                  ? true
                  : false,
              showTextRowsSelected: false,
              actionsColumnIndex: -1,
              pageSize: this.state.pageSize,
              paging: filteredData.length > this.state.pageSize,
              initialPage: this.state.initialPage,
              pageSizeOptions: Utils.materialTablePageSize(),
              headerStyle: {
                backgroundColor: "#1b7189",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.id === this.state.invoiceId ? "#FEF5E7" : "",
                height: "3em",
                textTransform: "capitalize",
              }),
            }}
            style={{
              boder: "1px solid black",
            }}
            onSelectionChange={(rowData) => {
              this.rowItemSelected(rowData);
            }}
            onChangePage={(page, pageSize) => {
              this.handleMaterialTablePageChange(page, pageSize);
            }}
            actions={actionList}
            onRowClick={(e, rowData) => this.toExpenseDetails(e, rowData)}
          />
        </div>
      </Row>
    );
  }

  handleStartDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.endDate);
    if (this.state.endDate === null && date === null) {
      this.setState({
        startDate: date,
        errorMassage: false,
        error: false,
      });
    } else if (date === null) {
      this.setState({
        startDate: date,
        errorMassage: true,
        error: false,
      });
    } else if (fromDate > toDate) {
      this.setState({
        startDate: date,
        error: true,
        errorMassage: false,
      });
    } else {
      this.setState({
        startDate: date,
        errorMassage: false,
        error: false,
      });
    }
  }

  handleEndDateChange(date) {
    let fromDate = Date.parse(this.state.startDate);
    let toDate = Date.parse(date);

    if (this.state.startDate === null && date === null) {
      this.setState({
        endDate: date,
        errorMassage: false,
        error: false,
      });
    } else if (this.state.startDate === null) {
      this.setState({
        endDate: date,
        errorMassage: true,
        error: false,
      });
    } else if (fromDate > toDate) {
      this.setState({
        endDate: date,
        error: true,
        errorMassage: false,
      });
    } else {
      this.setState({
        endDate: date,
        errorMassage: false,
        error: false,
      });
    }
  }

  updateBusinessUnit(e, name) {
    this.setState({ selectedBusinessUnit: name });
  }

  handleAssetChange(e) {
    this.setState({ isAsset: e.target.checked });
  }

  filterRecords(e) {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let bucode = this.state.selectedBusinessUnit
      ? this.state.selectedBusinessUnit.code
      : null;
    let isAsset = this.state.isAsset;
    this.getExpenseList(startDate, endDate, bucode, isAsset);
  }
  tabSelected() {}
  resetFilter() {
    store.dispatch(ApplicationStateAction.setApplicationState({}));
    let startDate = null;
    let endDate = null;
    let bucode = null;
    let isAsset = false;
    this.getExpenseList(startDate, endDate, bucode, isAsset, true);
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  requestVerification(e) {
    let selectedExpenseId = [];
    for (let i = 0; i < this.state.expenseList.length; i++) {
      if (this.state.expenseList[i].tableData?.checked === true) {
        selectedExpenseId.push(this.state.expenseList[i].id);
      }
    }
    if (!selectedExpenseId.length > 0) {
      return PtsAlert.error("Please Select Expense");
    }
    let postObject = {
      expenses: selectedExpenseId,
      header: this.header,
    };
    FetchServerData.callPostService(
      "/eworkflow/bulkVerficationRequest",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.getExpenseList();
      } else {
      }
    });
  }

  verifyExpense(e) {
    let selectedExpenseId = [];
    for (let i = 0; i < this.state.expenseList?.length; i++) {
      if (this.state.expenseList[i].tableData?.checked === true) {
        selectedExpenseId.push(this.state.expenseList[i].id);
      }
    }
    if (!selectedExpenseId.length > 0) {
      return PtsAlert.error("Please Select Expense");
    }
    let postObject = {
      expenses: selectedExpenseId,
      header: this.header,
    };
    FetchServerData.callPostService("/eworkflow/bulkVerify", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.getExpenseList();
        } else {
        }
      }
    );
  }

  getExpenseButtons() {
    let status = this.state.tabName?.status;
    if (Utils.equalsIgnoreCase("CREATED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.requestVerification(e)}
          >
            Request Verification
          </Button>
        </div>
      );
    } else if (Utils.equalsIgnoreCase("VERIFICATIONREQUESTED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<VerifiedUserIcon />}
            onClick={(e) => this.verifyExpense(e)}
          >
            Verify
          </Button>
        </div>
      );
    }
  }

  render() {
    this.paymentDetailsDialogueRef = React.createRef();
    this.receiptDialogueRef = React.createRef();
    if (this.state.render === false) {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
          />
          {this.state.isInvoiceStatus && (
            <div className="row mt-4">
              <div className="col md-12">
                <div className="card p-4">
                  <div className="row text-center p-2">
                    <h5 style={{ color: "red" }}>
                      Contact Admin user to enable this Workflow setting.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }

    const tabList = this.state.invoiceStatusList?.map((expStatus, keyIndex) => {
      return (
        <>
          <Tab
            key={keyIndex}
            onClick={(e) => this.selectedTab(expStatus, keyIndex)}
          >
            <span style={{ fontWeight: "600" }}>{expStatus.uiname}</span>
          </Tab>
        </>
      );
    });

    const tabPanelList = this.state.invoiceStatusList?.map((tab, keyIndex) => {
      return (
        <>
          <PaymentDetailsDialogue
            ref={this.paymentDetailsDialogueRef}
            style={{ float: "right" }}
          />

          <ViewReceipt
            ref={this.receiptDialogueRef}
            style={{ float: "right" }}
            action="view"
          />

          <TabPanel key={keyIndex}>
            {this.getTablesWithStatus(tab.status)}
          </TabPanel>
        </>
      );
    });

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name="Expense List"
        />
        <div className="row">
          <div className="col-8 align-items-start text-start">
            <span className="ms-1">
              {this.state.selectedBusinessUnit !== null ||
              this.state.isAsset !== false ? (
                <span>
                  {this.state.selectedBusinessUnit &&
                  this.state.selectedBusinessUnit !== null ? (
                    <span>
                      <b> Business Unit : </b>
                      {this.state.selectedBusinessUnit?.name + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.isAsset && this.state.isAsset !== false ? (
                    <span>
                      <b>Is Capital Asset: </b>
                      {`${this.state.isAsset ? "Yes" : "No"}, `}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.defaultStartDate !== "" &&
                    this.state.defaultStartDate !== null && (
                      <span>
                        <b>Start Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultStartDate) +
                          ", "}
                      </span>
                    )}
                  &nbsp;
                  {this.state.defaultEndDate !== "" &&
                    this.state.defaultEndDate !== null && (
                      <span>
                        <b>End Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultEndDate) + ", "}
                      </span>
                    )}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="col-4 align-items-end text-end">
            <span
              className="text-end me-1"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.handleAccountDropdownOpen}
            >
              <FilterAltIcon
                style={{ fontSize: "28px", color: "black" }}
                aria-controls={this.state.open ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.open ? "true" : undefined}
              />
              Filter
            </span>
            {"  "}
            {this.state.isFirstTimeLoading ? (
              ""
            ) : (
              <span
                className="me-2"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.resetFilter.bind(this)}
              >
                &nbsp;&nbsp;<i class="fa fa-refresh" aria-hidden="true"></i>{" "}
                Reset
              </span>
            )}
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Filter</h5>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Business Unit
                </label>
                <Autocomplete
                  margin="normal"
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  className="expenseAutoComplete"
                  id="businessUnit"
                  style={{ background: "#fff", borderRadius: "5px" }}
                  options={this.state.businessUnits}
                  value={this.state.selectedBusinessUnit}
                  defaultValue={this.state.selectedBusinessUnit}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    this.updateBusinessUnit(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Business Unit"
                      {...params}
                      style={{}}
                      variant="outlined"
                      className="regCountry"
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <div className="row">
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label "
                      // htmlFor="collapsible-companyName"
                    >
                      Start Date
                    </label>
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          value={this.state.startDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleStartDateChange.bind(this)}
                          helperText={
                            this.state.errorMassage
                              ? "Start Date is Required"
                              : ""
                          }
                          error={this.state.errorMassage}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      End Date
                    </label>{" "}
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          value={this.state.endDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleEndDateChange.bind(this)}
                          helperText={
                            this.state.endDateErrorMassage
                              ? "End Date is Required"
                              : ""
                          }
                          error={this.state.endDateErrorMassage}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  {this.state.error ? (
                    <lable className="errorMassageShow">
                      End Date should be after Start Date
                    </lable>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <FormControlLabel
                  style={{
                    color: "#000",
                    width: "100%",
                    margin: "0",
                    padding: "0",
                  }}
                  control={
                    <Checkbox
                      variant="outlined"
                      id="isAsset"
                      checked={this.state.isAsset}
                      value={this.state.isAsset}
                      onChange={this.handleAssetChange.bind(this)}
                      style={{ background: "none" }}
                    />
                  }
                  label={<span style={{}}>Is Capital Asset?</span>}
                />
              </div>
              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  // onClick={this.onFilterClick.bind(this)}
                  onClick={(e) => this.filterRecords(e)}
                >
                  <i className="fa-solid fa-filter"></i>
                  &nbsp; Filter
                </button>
                &nbsp; &nbsp;
                <button
                  className="resetButton"
                  onClick={this.resetFilter.bind(this)}
                >
                  <i className="fa fa-refresh"></i>
                  &nbsp; Reset
                </button>
              </div>
            </div>
          </div>
        </Menu>
        <Row md={12} className="mt-2">
          <Tabs
            onSelect={this.tabSelected.bind(this)}
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            <TabList
              style={{
                minWidth: "5em",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              {tabList}
            </TabList>
            {tabPanelList}
          </Tabs>
        </Row>
        <Row md={12} className="mt-4">
          {this.getExpenseButtons()}
        </Row>
      </>
    );
  }
}
export default ExpenseList;

import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import CancelButton from "../buttons/CancelButton";
import DeleteIconButton from "../buttons/DeleteIconButton";
import DownloadIconButton from "../buttons/DownloadIconButton";
import ViewIconButton from "../buttons/ViewIconButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./FileAttachment.scss";
import ViewReceipt from "./ViewReceipt";
// import { CustomErrorComponent } from "custom-error";
export class FileAttachment extends Component {
  header = store.getState().header.header;
  fileUploadRef = React.createRef();
  constructor(props) {
    super(props);
    let fileAttachment = props.data;
    let doctype = null;
    if (props.documentTypeList && props.documentTypeList.length > 0) {
      doctype = props.documentTypeList[0];
    }
    if (fileAttachment.documenttype) {
      doctype = fileAttachment.documenttype;
    }
    if (fileAttachment) {
      this.state = {
        id: null === fileAttachment.id ? "" : fileAttachment.id,
        aid: props.aid,
        index: props.index + 1,
        title: props.title,
        url: fileAttachment.url,
        filename:
          null === fileAttachment.filename ? "" : fileAttachment.filename,
        documentId: null === fileAttachment.id ? "" : fileAttachment.id,
        documentTypeList: props.documentTypeList ? props.documentTypeList : [],
        documenttype: doctype,
        onChange: this.props.onChange,
        onCancelAdd: this.props.onCancelAdd,
        onDocumentAdd: props.onDocumentAdd,
        onDocumentDelete: props.onDocumentDelete,
        selectedFile: null,
        action: this.props.action,
        open: false,
        srcType: props.srcType ? props.srcType : "",
      };
    } else {
      this.state = {
        id: "",
        index: 0,
        filename: "",
        url: "",
        documentId: "",
        expenseReceiptUrl: "",
        documenttype: null,
        documentTypeList: props.documentTypeList ? props.documentTypeList : [],
        onChange: this.props.onChange,
        action: this.props.action,
        open: false,
        srcType: props.srcType ? props.srcType : "",
      };
    }
  }
  downloadDocument() {
    const postObject = {
      url: this.state.url,
      header: this.header,
    };

    FetchServerData.callPostFileDownload("/filems/get", postObject).then(
      (output) => {
        if ("" !== output.data) {
          var url = "data:application/*;base64," + output.data.entity;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            !this.state.filename ? "download" : this.state.filename
          );
          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();
        }
      }
    );
  }

  ReceiptDetails() {
    let receiptUrl = this.state.url;
    let aid = this.state.aid;
    if (this.receiptDialogueRef && this.receiptDialogueRef.current) {
      this.receiptDialogueRef.current.viewDocument(receiptUrl, aid);
    }
  }


  deleteDocument() {
    const postObject = {
      documentid: this.state.id,
      aid: this.state.aid,
      header: this.header,
    };

    if (this.state.onDocumentDelete) {
      let index = this.state.index - 1;
      this.state.onDocumentDelete(postObject, index);
    }
  }

  onFileUpload = (e) => {
    if (
      !this.state.selectedFile ||
      !this.state.selectedFile.name ||
      this.state.selectedFile.name === ""
    ) {
      PtsAlert.error("Please select file to upload");
      return;
    }

    if (!this.state.documenttype || !this.state.documenttype.type) {
      PtsAlert.error("Please Select Document Type");
      return;
    }
    let header = store.getState().header.header;
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    formData.append("sid", header.sessionid);
    formData.append("tid", header.tid);
    formData.append("uid", header.email);
    formData.append("aid", this.state.aid);
    formData.append("documenttype", this.state.documenttype.type);
    formData.append("appclient", header.appclient);
    if (this.state.onDocumentAdd) {
      let index = this.state.index - 1;
      this.state.onDocumentAdd(formData, index);
    }
  };

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };
  updateDocumentType(e, selected) {
    this.setState({ documenttype: selected });
  }

  cancelAddDocument(e) {
    this.setState({ action: "view" });
    if (this.state.onCancelAdd) {
      let index = this.state.index - 1;
      this.state.onCancelAdd(this.state, index);
    }
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    this.receiptDialogueRef = React.createRef();
    if (this.state.action === "create") {
      return this.createFileAttachment();
    } else {
      return this.viewFileAttachment();
    }
  }

  viewFileAttachment() {
    let urlArr = this.state.filename.split(".");
    let fileType = urlArr[1];
    return (
      <>
        <TableRow>
          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
            <span style={{ fontSize: "small" }}>{this.state.index}</span>
          </TableCell>
          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
            <span style={{ fontSize: "small" }}>{this.state.filename}</span>
          </TableCell>
          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
            <span style={{ fontSize: "small" }}>
              {this.state.documenttype ? this.state.documenttype.type : ""}
            </span>
          </TableCell>
          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
            <div style={{ display: "flex", marginTop: "5px" }}>
              {(fileType !== "zip" && fileType !== "xlsx" && fileType !== "pptx" && fileType !== "docx") &&
                <ViewIconButton onClick={this.ReceiptDetails.bind(this)} />
              }
              <DownloadIconButton onClick={this.downloadDocument.bind(this)} />
              <DeleteIconButton
                onClick={this.deleteDocument.bind(this)}
                domainObject={
                  this.props.domainObject ? this.props.domainObject : ""
                }
              />
              <div id="downloadFormPoster"></div>
            </div>
          </TableCell>
        </TableRow>

        <ViewReceipt
          ref={this.receiptDialogueRef}
          style={{ float: "right" }}
          action="view"
        />
      </>
    );
  }
  createFileAttachment() {
    return (
      <TableRow>
        <TableCell style={{ width: "100%" }}>
          <Row
            md={12}
            style={{
              margin: "0",
              padding: "5px",
              alignItems: "flex-end",
              fontSize: "small",
            }}
          >
            <Col md={5} style={{ margin: "0", padding: "0" }}>
              <span style={{ fontSize: "small" }}>Document Type</span>
            </Col>
            <Col md={7} style={{ margin: "0", padding: "0" }}>
              <Autocomplete
                disableClearable
                id="businessUnit"
                size="small"
                ListboxProps={{
                  className: "myCustomList"
                }}
                options={
                  undefined === this.state.documentTypeList ||
                    null === this.state.documentTypeList
                    ? []
                    : this.state.documentTypeList
                }
                value={this.state.documenttype ? this.state.documenttype : null}
                getOptionLabel={(option) => option.type}
                onChange={(event, value) => {
                  this.updateDocumentType(event, value);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth={true}
                    variant="outlined"
                    className="regCountry"
                    {...params}
                    placeholder="Select"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Col>
          </Row>
          <Row
            md={12}
            style={{
              margin: "0",
              padding: "5px",
              alignItems: "flex-end",
              fontSize: "small",
            }}
          >
            <Col md={12} style={{ margin: "0", padding: "0" }}>
              <TextField
                className="documentFile"
                style={{
                  fontSize: "small",
                  width: "-webkit-fill-available",
                  padding: "10px",
                  // border: "1px solid #000"
                }}
                required
                type="file"
                variant="outlined"
                id="file"
                ref={this.fileUploadRef}
                onChange={this.onFileChange.bind(this)}
              />
            </Col>
          </Row>
          <Row
            md={12}
            style={{
              margin: "0",
              padding: "5px",
              alignItems: "flex-end",
              fontSize: "small",
            }}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              {this.state.selectedFile ? (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<CloudUploadIcon />}
                    onClick={(e) => this.onFileUpload()}
                  >
                    Upload
                  </Button>
                </>
              ) : (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    marginRight: "1rem",
                    opacity: "0.4",
                  }}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload
                </Button>
              )}
              {/* <UploadButton onClick={(e) => this.onFileUpload()} /> */}
              <CancelButton onClick={(e) => this.cancelAddDocument()} />
            </div>
          </Row>
        </TableCell>
      </TableRow>
    );
  }
}

export default FileAttachment;

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { CardBody, Col, Progress, Row } from "reactstrap";
import Logo3 from "../../../assets/images/logo3.png";
import FetchServerData from "../../../provider/FetchServerData";
import Utils from "../../../provider/Utils";
import appDataAction from "../../../redux/appData/appDataAction";
import headerAction from "../../../redux/header/headerAction";
import staticDataAction from "../../../redux/staticData/staticDataAction";
import store from "../../../redux/store";
import userMenuAction from "../../../redux/userMenus/userMenusAction";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Login.scss";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { NavLink } from "react-router-dom";
import { Autocomplete } from "@mui/material";
//const loader = Loaders();
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SettingDataAction from "../../../redux/settings/SettingDataAction";
import OrganizationDataAction from "../../../redux/organizationData/OrganizationDataAction";
const styles = (theme) => ({
  bigIndicator: {
    height: 5,
  },
});
// const adminLoginRef = React.createRef();

export class Login extends Component {
  constructor(props) {
    super(props);
    // FetchServerData.hideLoader();
    this.state = {
      showProgressBar: false,
      progressBarPercent: 0,
      userid: "",
      password: "",
      customerid: "",
      showSuccessAlert: false,
      showFailAlert: false,
      selectedIndex: 0,
      errorMessage: "",
      errorMessageColor: "#000",
      showPassword: false,
      tenantList: [],
      appuserVo: [],
      tenantId: "",
      isCompany: false,
      render: false,
    };

    FetchServerData.clearStorage();
  }

  getTenantList(email) {
    if (Utils.emailValidation(email)) {
      const postObject = {
        header: { email: email },
      };
      FetchServerData.callPostService("/tntms/getList", postObject).then(
        (output) => {
          if (output.status === "SUCCESS") {
            let tenantList = output.data;
            if (tenantList.length > 1) {
              this.setState({
                render: true,
                userid: this.state.email,
                tenantList: tenantList,
                tenantId: tenantList[0],
                isCompany: true,
              });
            } else {
              this.setState({
                isCompany: false,
                tenantList: tenantList,
                tenantId: tenantList[0],
              });
            }
          } else {
          }
        }
      );
    } else {
      if (email !== "" || email.length > 0) {
        PtsAlert.error("Enter valid User ID format john.doe@email.com");
      }
    }
  }

  handleUserChange(event) {
    let val = event.target.value;
    this.setState({ userid: val });
    if (event.target.value.endsWith(".com")) {
      this.getTenantList(val);
    }
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  async setStaticData(header) {
    this.setState({
      showProgressBar: true,
      progressBarPercent: 40,
    });
    return new Promise(function (accept, reject) {
      FetchServerData.callPostServiceWithoutHeader(
        "/staticms/getStaticData",
        header
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            store.dispatch(staticDataAction.setStaticData(output.data));
            accept(1);
          } else {
            reject(output.message);
          }
        })
        .catch((error) => {
          PtsAlert.error(error);
        });
    });
  }

  async setAppData(header) {
    this.setState({
      showProgressBar: true,
      progressBarPercent: 60,
    });
    return new Promise(function (accept, reject) {
      FetchServerData.callPostServiceWithoutHeader(
        "/dashboardms/getList",
        header
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            store.dispatch(appDataAction.setAppData(output.data));
            store.dispatch(SettingDataAction.setSettings(output.data.settings));
            store.dispatch(
              OrganizationDataAction.setOrganization(output.data.settings?.org)
            );
            accept(1);
          } else {
            reject(output.message);
          }
        })
        .catch((error) => {
          PtsAlert.error(error);
          reject("Cannot Fetch App Data From Server, Error:" + error.message);
        });
    });
  }

  async setLogo(header) {
    this.setState({
      showProgressBar: true,
      progressBarPercent: 100,
    });
    let url = store.getState().appData.logoURL;
    let postObject = header;
    postObject.url = url;

    return new Promise(function (accept, reject) {
      FetchServerData.callPostFileDownload("/filems/get", postObject)
        .then((output) => {
          if (!Utils.isNull(output.data) && "" !== output.data) {
            output.data = output.data.entity;
          }
          let logoData = {
            logo: output.data,
          };
          store.dispatch(appDataAction.setLogo(logoData));
          accept("");
        })
        .catch((error) => {
          PtsAlert.error(error);
          reject(error);
        });
    });
  }

  async loadDashboardPage(header, output) {
    this.setState({
      showProgressBar: true,
      progressBarPercent: 40,
    });
    await this.setStaticData(header)
      .then((data) => {
        this.setState({
          showProgressBar: true,
          progressBarPercent: 100,
        });
        this.setAppData(header)
          .then((data1) => {
            this.setLogo(header).then((data2) => {
              this.setState({
                showProgressBar: true,
                progressBarPercent: 100,
              });
            });
            let menus = output;
            for (let i = 0; i < menus.length; i++) {
              const menu = menus[i];
              if (menu.isactive) {
                this.props.history.push(menu.pageid.split("#/").join("/"));
                break;
              }
            }
            // this.props.history.push("/dashboard/");
          })
          .catch((error) => {
            PtsAlert.error(error);
          });
      })
      .catch((error) => {
        PtsAlert.error(error);
      });
  }

  processLoginSuccess(output_data) {
    let res = output_data.data;
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (res.appuserVo?.profile === null) {
      PtsAlert.error("User Profile is Null");
      return;
    }
    let userDetails = {
      firstName: res.appuserVo?.firstName,
      lastName: res.appuserVo?.lastName,
      designation: res.appuserVo.designation,
      email: res.appuserVo.email,
      lastLogin: res.appuserVo.lastLogin,
      lastLoginsource: res.appuserVo.lastLoginsource,
    };
    store.dispatch(userMenuAction.setUserDetails(userDetails));

    let headerObj = {
      appclient: "W",
      email: res.header.email,
      sessionid: res.header.sessionid,
      tid: res.header.tid,
      timezone: tz,
    };
    store.dispatch(headerAction.setHeader(headerObj));

    let header = {};
    header.header = headerObj;
    let appuserVo = [];
    appuserVo = output_data.data.appuserVo?.profile?.menus;

    store.dispatch(userMenuAction.setUserMenus(appuserVo));
    // let subMenuArr = [];

    // for (var i = 0; i < appuserVo.length; i++) {
    //   if (appuserVo[i].childMenus === null) {
    //     subMenuArr.push(appuserVo[i]);
    //   }
    // }
    store.dispatch(userMenuAction.setUserTopMenus(appuserVo));

    for (let i = 0; i < appuserVo.length; i++) {
      const menu = appuserVo[i];
      if (menu.isactive) {
        store.dispatch(appDataAction.setActiveMenu(menu.id));
        break;
      }
    }

    setTimeout(() => {
      this.setState({
        showSuccessAlert: false,
        appuserVo: appuserVo,
      });
    }, 2000);
    this.setState({
      showProgressBar: true,
      progressBarPercent: 20,
    });
    this.loadDashboardPage(header, appuserVo);
  }
  submitLoginHandler = (event, operation) => {
    event.preventDefault();
    this.setState({
      showSuccessLoader: true,
    });

    let loginuser = document.getElementById("userid").value;
    let loginpass = document.getElementById("password").value;
    const postObject = {
      header: {
        appclient: "W",
        tid: this.state.tenantId?.id,
        email: loginuser,
      },
      appuserVo: {
        email: loginuser,
        credential: loginpass,
      },
    };

    this.setState({
      showProgressBar: true,
      progressBarPercent: 5,
    });
    if (operation === "userLogin") {
      FetchServerData.callPostServiceWithoutHeader(
        "/userms/thubLogin",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            this.processLoginSuccess(output);
          } else {
            this.setState({
              showProgressBar: false,
            });
          }
        })
        .catch((error) => {
          PtsAlert.error(error);
        });
    } else {
      let customerId = document.getElementById("customerid").value;
      postObject.tenentvo = { id: customerId };
      FetchServerData.callPostServiceWithoutHeader("/userms/login", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            this.processLoginSuccess(output);
          } else {
            // this.setState({
            //   errorColor:'#FF0000',
            //   errorMessage:output.message,
            // });
          }
        })
        .catch((error) => {
          PtsAlert.error(error);
        });
    }
  };

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      /* 13 is the enter keycode */
      if (this.adminLoginRef) {
        this.adminLoginRef.submit();
      }
    }
  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  selectedLoginTab(index) {
    this.setState({ selectedIndex: index });
  }
  handleCompanyChange = (selectedValue) => {
    this.setState({ tenantId: selectedValue });
  };
  render() {
    // const { classes } = this.props;
    // const { value } = this.state.value;
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <Row md={12} style={{ height: "100vh" }}>
              <Col md={6} className="logoSection">
                <CardBody className="logoSection">
                  <Row
                    style={{ alignSelf: "center", justifyContent: "center" }}
                  >
                    <div md={12}>
                      <img src={Logo3} alt="" className="logoImage" />
                    </div>
                  </Row>
                  <Row
                    style={{ alignSelf: "center", justifyContent: "center" }}
                  >
                    <div md={12}>
                      <span>
                        <h2 className="logoHeding">Taxonomy Hub</h2>
                      </span>
                    </div>
                  </Row>
                  <Row
                    style={{
                      alignSelf: "center",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <div md={12}>
                      {/* <span className="logoText">
                        One Stop Solution for Business Financials along with GST
                        Compliance
                      </span> */}
                    </div>
                  </Row>
                </CardBody>
              </Col>
              <Col
                md={6}
                style={{
                  alignSelf: "center",
                  alignItems: "center",
                  height: "100%",
                  background: "#fff",
                }}
              >
                <div
                  key="tab-content"
                  className="loginPage"
                  style={{
                    padding: "20%",
                  }}
                >
                  <form
                    ref={this.adminLoginRef}
                    onSubmit={(e) => this.submitLoginHandler(e, "userLogin")}
                  >
                    <div className="signinHeding ms-4">Sign In </div>
                    <Row
                      style={{
                        alignItems: "flex-end",
                        margin: "1em 1em",
                      }}
                    >
                      <Col md={12}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          <b>
                            Email ID<span className="asterisk"> *</span>
                          </b>
                        </label>
                        <TextField
                          id="userid"
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="Enter Email ID *"
                          type="email"
                          value={this.state.userid}
                          onChange={this.handleUserChange.bind(this)}
                          s
                        />
                      </Col>
                    </Row>
                    {this.state.isCompany && (
                      <Row
                        style={{
                          alignItems: "flex-end",
                          margin: "1em 1em",
                        }}
                      >
                        <Col md={12}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                            // style={{ color: "#000" }}
                          >
                            <b>
                              Company name
                              <span className="asterisk"> *</span>
                            </b>
                          </label>
                          <Autocomplete
                            className="companyTextField"
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            id="tenantList"
                            options={this.state.tenantList}
                            value={this.state.tenantId}
                            defaultValue={this.state.tenantId}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              this.handleCompanyChange(value);
                            }}
                            inputprops={{
                              style: { textTransform: "capitalize" },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Company"
                                variant="outlined"
                                required
                                margin="normal"
                                className="regCountry"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row
                      style={{
                        alignItems: "flex-end",
                        margin: "1em",
                      }}
                    >
                      <Col md={12}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          // style={{ color: "#000" }}
                        >
                          <b>
                            Password<span className="asterisk"> *</span>
                          </b>
                        </label>
                        <TextField
                          variant="outlined"
                          // margin="normal"
                          required
                          fullWidth
                          value={this.state.password}
                          name="password"
                          placeholder="Enter Password *"
                          type={this.state.showPassword ? "text" : "password"}
                          id="password"
                          autoComplete="current-password"
                          onChange={this.handlePasswordChange.bind(this)}
                          onKeyPress={(e) => this.enterPressed(e, "userLogin")}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword.bind(
                                    this
                                  )}
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{
                        marginTop: "1.5em",
                        textAlign: "end",
                        width: "95%",
                      }}
                    >
                      <NavLink
                        to="/forgotPasswordEmail"
                        style={{ float: "right" }}
                      >
                        <strong> Forgot password?</strong>
                      </NavLink>
                      {/* </Col> */}
                    </Row>
                    <Row md={12}>
                      <button
                        id="loginButton"
                        type="submit"
                        color="primary"
                        className="loginButton mt-1"
                      >
                        Sign In
                      </button>
                      <div className="d-flex flex-row pt-3">
                        <div class="col-11 align-self-end">
                          <p
                            className="ms-5 text-end"
                            style={{ paddingLeft: "3.5em" }}
                          >
                            <span>Do not have an account?</span>&nbsp;
                            <NavLink to="/personalDetailsReg">
                              <span>Sign Up</span>
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </Row>
                    {/* <Row md={12} style={{ marginTop: "1em" }}>
                      <Col
                        md={6}
                        style={{
                          textAlign: "left",
                          marginLeft: "2.2em",
                        }}
                      >
                        <NavLink className="pt-1" to="/login">
                          <ArrowBackIosNewIcon />
                          Back Sign In
                        </NavLink>
                      </Col>
                    </Row> */}
                  </form>
                  {this.state.showProgressBar && (
                    <Row>
                      <Progress
                        animated
                        color="#1b7189"
                        value={this.state.progressBarPercent}
                        style={{
                          background: "transparent",
                          height: "4em",
                          width: "100%",
                          // fontSize: '20px',
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Setting Up Enviornment
                      </Progress>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Login);

import { Card } from "@material-ui/core";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import CancelButton from "../buttons/CancelButton";
import CreateButton from "../buttons/CreateButton";
import SaveButton from "../buttons/SaveButton";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import PtsAlert from "../ptsAlert/PtsAlert";
import BackIconButton from "../buttons/BackIconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import ApplicationStateAction from "../../../redux/applicationState/ApplicationStateAction";
// import './User.scss';

export class User extends Component {
  businessUnitsList = store.getState().organization.organization.businessUnits;
  appuserStatusList = store.getState().appData.appuserStatusList;
  UserRoleList = store.getState().userProfile.userProfileList;
  salutationList = [
    { id: 1, name: "Mr" },
    { id: 2, name: "Mrs" },
    { id: 3, name: "Miss" },
  ];
  genderList = [
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
  ];
  title = "User";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let userId = props.userId;
    let action = props.action;
    // let pageId = props.pageId;

    if (
      Utils.isNull(userId) &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      userId = props.history.location.state.userId;
      action = props.history.location.state.action;
      // pageId = props.history.location.state.pageId;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    let canRender = false;
    if (userId && userId > 0) {
      this.getUserDetails(userId);
    } else {
      canRender = true;
    }
    this.state = {
      id: userId,
      salutation: this.salutationList[0],
      fname: "",
      mname: "",
      lname: "",
      phone: "",
      email: "",
      designation: "",
      credential: "",
      userName: "",
      confPassword: "",
      userRole: null,
      businessUnit: null,
      gender: this.genderList[0],
      appuserStatus:
        this.appuserStatusList && this.appuserStatusList.length > 0
          ? this.appuserStatusList[0]
          : null,
      status: {},
      gstregstatus: "",
      tradeName: "",
      privileges: [],
      action: action,
      successMessage: "",
      errorMessages: "",
      render: canRender,
      showSuccessAlert: false,
      showFailAlert: false,
      enableMobileAccess: false,
      showPassword: false,
      showConfirmPassword: false,
      pageSize: 10,
      initialPage: 0,
    };
  }

  handleMobileAccessChange(e) {
    this.setState({ enableMobileAccess: !this.state.enableMobileAccess });
  }
  handleGenderChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      gender: selectedType,
    });
  }

  handleSalutationChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    let maleGender = "";
    let femaleGender = "";
    for (let i = 0; i < this.genderList.length; i++) {
      if (Utils.equalsIgnoreCase(this.genderList[i].name, "Female")) {
        femaleGender = this.genderList[i];
      } else {
        maleGender = this.genderList[i];
      }
    }
    if (
      Utils.equalsIgnoreCase(selectedType.name, "Miss") ||
      Utils.equalsIgnoreCase(selectedType.name, "Mrs")
    ) {
      this.setState({
        salutation: selectedType,
        gender: femaleGender,
      });
    } else {
      this.setState({
        salutation: selectedType,
        gender: maleGender,
      });
    }
  }

  handleRoleChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      userRole: selectedType,
    });
  }

  handleBusinessUnitChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      businessUnit: selectedType,
    });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleCredentailChange(e) {
    this.setState({ credential: e.target.value });
  }
  handleConfirmCredentailChange(e) {
    this.setState({ confirmCredential: e.target.value });
  }
  handleFirstNameChange(e) {
    this.setState({ fname: e.target.value });
  }

  handleMiddleNameChange(e) {
    this.setState({ mname: e.target.value });
  }

  handleLastNameChange(e) {
    this.setState({ lname: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value, userName: e.target.value });
  }

  handleDesignationChange(e) {
    this.setState({ designation: e.target.value });
  }

  handleUsernameChange(e) {
    this.setState({ userName: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleConfPasswordChange(e) {
    this.setState({ confPassword: e.target.value });
  }

  handleTradeNameChange(e) {
    this.setState({ tradeName: e.target.value });
  }

  handleUsetnforinvoicingChange(e) {
    this.setState({ usetnforinvoicing: e.target.value });
  }

  handleInvoicetandcChange(e) {
    this.setState({ invoicetandc: e.target.value });
  }

  handleShareinvoicesChange(e) {
    this.setState({ shareinvoices: !this.state.shareinvoices });
  }

  handleAddressChange(inpuAddress) {
    this.setState({ address: inpuAddress });
  }

  getStatus(input) {
    for (let i = 0; i < this.appuserStatusList.length; i++) {
      if (Utils.equalsIgnoreCase(input, this.appuserStatusList[i].status)) {
        return this.appuserStatusList[i];
      }
    }
    return null;
  }
  handleUserStatusChange() {
    if (
      this.state.appuserStatus &&
      Utils.equalsIgnoreCase(this.state.appuserStatus.status, "Active")
    ) {
      this.setState({ appuserStatus: this.getStatus("InActive") });
    } else {
      this.setState({ appuserStatus: this.getStatus("Active") });
    }
  }

  regiStatus(e) {
    this.setState({ registatus: e.target.value });
  }

  getUserDetails = (userId) => {
    const header = store.getState().header.header;
    const postObject = {
      appuserVo: {
        id: userId,
      },
      header: header,
    };
    FetchServerData.callPostService("/userms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let user = output.data;
          if (!user.appuserStatus) {
            user.appuserStatus = null;
          }
          let salutation =
            this.salutationList && this.salutationList.length > 0
              ? this.salutationList[0]
              : null;
          for (let i = 0; i < this.salutationList.length; i++) {
            if (
              Utils.equalsIgnoreCase(
                user.salutation,
                this.salutationList[i].name
              )
            ) {
              salutation = this.salutationList[i];
            }
          }

          let gender =
            this.genderList && this.genderList.length > 0
              ? this.genderList[0]
              : null;
          for (let i = 0; i < this.genderList.length; i++) {
            if (Utils.equalsIgnoreCase(user.gender, this.genderList[i].name)) {
              gender = this.genderList[i];
            }
          }
          this.setState({
            id: user.id,
            salutation: salutation,
            fname: user.firstName,
            mname: user.middleName,
            lname: user.lastName,
            gender: gender,
            phone: user.mobile,
            email: user.email,
            userName: user.uid,
            userRole: user.profile,
            designation: user.designation,
            businessUnit: user.department.businessunit,
            privileges: user.privileges,
            appuserStatus: user.appuserStatus,
            enableMobileAccess: user.enableMobileAccess,
            render: true,
          });
        } else {
        }
      }
    );
  };

  createUserInBackend = (e) => {
    const header = store.getState().header.header;
    e.preventDefault();
    // let status = 'InActive';
    // if (this.state.appuserStatus &&  === true) {
    //   status = 'Active';
    // }
    let pass = this.state.credential;
    let confirmPass = this.state.confirmCredential;
    if (pass && pass.length < 8) {
      PtsAlert.error("Password must be 8 character long");
      return;
    }
    if (!Utils.equalsIgnoreCase(pass, confirmPass)) {
      PtsAlert.error("Password and Confirm Password must be same");
      return;
    }

    const postObject = {
      appuserVo: {
        firstName: this.state.fname,
        middleName: this.state.mname,
        lastName: this.state.lname,
        panNo: "",
        enableMobileAccess: this.state.enableMobileAccess,
        designation: this.state.designation,
        uid: this.state.userName,
        credential: this.state.credential,
        email: this.state.email,
        mobile: this.state.phone,
        adhar: "",
        salutation: this.state.salutation.name,
        gender: this.state.gender.name,
        dob: "",
        appuserStatus: this.state.appuserStatus,

        appusertype: {
          name: "Internal",
        },
        profile: {
          id: this.state.userRole?.id,
        },
        department: {
          businessunit: this.state.businessUnit,
        },
      },
      header: header,
    };
    FetchServerData.callPostService("/userms/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let user = output.data;
          let UserManagementState = {
            userId: user.id,
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
          };
          store.dispatch(ApplicationStateAction.setApplicationState({ UserManagementState }));
          this.props.history.push({
            pathname: "/user/",
            state: { userId: user.id, action: "view" },
          });
        } else {
        }
      }
    );
  };

  updateUserInBackend = (e) => {
    const header = store.getState().header.header;
    e.preventDefault();
    const postObject = {
      appuserVo: {
        id: this.state.id,
        firstName: this.state.fname,
        middleName: this.state.mname,
        lastName: this.state.lname,
        designation: this.state.designation,
        uid: this.state.userName,
        email: this.state.email,
        mobile: this.state.phone,
        enableMobileAccess: this.state.enableMobileAccess,
        salutation: this.state.salutation ? this.state.salutation.name : "",
        gender: this.state.gender ? this.state.gender.name : "",
        dob: "",
        appuserStatus: this.state.appuserStatus,
        profile: {
          id: this.state.userRole?.id,
        },
        department: {
          businessunit: this.state.businessUnit,
        },
      },
      header: header,
    };

    FetchServerData.callPostService("/userms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let user = output.data;
          this.props.history.push({
            pathname: "/userview",
            state: {
              userId: user.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  deleteUser = (id) => {
    const header = store.getState().header.header;
    const postObject = {
      appuserVo: {
        id: id,
      },
      header: header,
    };
    FetchServerData.callPostService("/userms/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let user = output.data;
          this.props.history.push({
            pathname: "/user",
          });
        } else {
        }
      }
    );
  };

  getStateChange = (id) => {
    const header = store.getState().header.header;
    const postObject = {
      appuserVo: {
        id: id,
        appuserStatus: this.state.userStatus,
      },
      header: header,
    };
    FetchServerData.callPostService("/userms/changestatus", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let statusChange = output.data;
        } else {
        }
      }
    );
  };

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    if (this.state.action === "create") {
      return this.createUser();
    }
    if (this.state.action === "edit") {
      return this.editUser();
    } else {
      return this.viewUser();
    }
  }
  cancelEdit(e) {
    this.props.history.push({
      pathname: "/user/",
      state: { userId: this.state.id, action: "view" },
    });
  }
  editButtonClicked() {
    this.props.history.push({
      pathname: "/useredit",
      state: {
        userId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
      },
    });
  }
  deleteButtonClicked() {
    this.deleteUser(this.state.id);
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/user/",
      state: {},
    });
  }

  viewUser() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          name={"View " + this.title}
          addToBreadCrumb={"View " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          domainObject="User"
          backCallback={this.cancelView.bind(this)}
          editCallback={this.editButtonClicked.bind(this)}
          deleteCallback={this.deleteButtonClicked.bind(this)}
        />
        <Row md={12} style={{ margin: "1em" }}>
          <Col md={6}>
            <Card style={{ height: "100%" }}>
              <CardHeader style={{ padding: "1em" }}>
                <label className="cardHeaderTitle">
                  {" "}
                  <strong>User Details </strong>
                </label>
              </CardHeader>
              <CardBody style={{ padding: "0em 1em 1em 1em" }}>
                <Row>
                  <Col md={4}>
                    <strong>Name</strong>
                  </Col>
                  <Col md={8}>{this.state.fname + " " + this.state.lname}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <strong>Designation</strong>
                  </Col>
                  <Col md={8}>{this.state.designation}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <strong>Mobile</strong>
                  </Col>
                  <Col md={8}>{this.state.phone}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <strong>Email</strong>
                  </Col>
                  <Col md={8}>{this.state.email}</Col>
                </Row>
                <Row>
                  <Col md={4} style={{ alignSelf: "center" }}>
                    <strong>Enable Mobile Access?</strong>
                  </Col>
                  <Col md={8}>
                    {this.state.enableMobileAccess ? "Yes" : "No"}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardHeader style={{ padding: "1em" }}>
                <label className="cardHeaderTitle">
                  <strong>Account Details </strong>
                </label>
              </CardHeader>
              <CardBody style={{ padding: "0em 1em 1em 1em" }}>
                <Row>
                  <Col md={4}>
                    <strong>Profile</strong>
                  </Col>
                  <Col md={8}>{this.state.userRole?.name}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <strong>Business Unit</strong>
                  </Col>
                  <Col md={8}>
                    {null === this.state.businessUnit
                      ? ""
                      : this.state.businessUnit?.name}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <strong>UserID</strong>
                  </Col>
                  <Col md={8}>{this.state.email}</Col>
                </Row>
                <Row
                  style={{
                    paddingBottom: "18px"
                  }}
                >
                  <Col md={4} style={{ alignSelf: "center" }}>
                    <strong>User Status</strong>
                  </Col>
                  <Col md={8}>
                    {this.state.appuserStatus?.status}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div
          md={12}
          style={{ margin: "1em", textAlign: "center", display: "block" }}
        >
          <BackIconButton
            type="submit"
            onClick={(e) => this.cancelView(e)}
          ></BackIconButton>
        </div>
      </div >
    );
  }

  editUser() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          name={"Edit " + this.title}
          addToBreadCrumb={"Edit " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          domainObject="User"
        />

        <ValidatorForm
          ref="form"
          onSubmit={this.updateUserInBackend.bind(this)}
          style={{ marginTop: "1em" }}
        >
          {" "}
          <Row style={{ margin: "0.3em" }}>
            <Col md={12}>
              <Row style={{ display: "flex" }}>
                <Col md={6} style={{ display: "flex", padding: "10px" }}>
                  <Card md={12} className="expenseCard">
                    <CardBody>
                      <div
                        style={{
                          width: "100%",
                          padding: "10px",
                          // border: "0.5px solid #ece4e4",
                        }}
                      >
                        <Row
                          style={{
                            fontSize: "large",
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                            marginBottom: "1em",
                          }}
                        >
                          <strong>User Details</strong>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              First Name<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="fname"
                              placeholder="First Name"
                              value={this.state.fname}
                              onChange={this.handleFirstNameChange.bind(this)}
                              name="fname"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                          {/* <Col md={4} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Middle Name
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="mname"
                              placeholder="Middle Name"
                              value={this.state.mname}
                              onChange={this.handleMiddleNameChange.bind(this)}
                              name="mname"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col> */}

                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Last Name<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="lname"
                              placeholder="Last Name"
                              value={this.state.lname}
                              onChange={this.handleLastNameChange.bind(this)}
                              name="lname"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            // alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Designation<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="designation"
                              placeholder="Designation "
                              value={this.state.designation}
                              onChange={this.handleDesignationChange.bind(this)}
                              name="designation"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Phone Number<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="phone"
                              placeholder="Phone Number"
                              value={this.state.phone}
                              onChange={this.handlePhoneChange.bind(this)}
                              name="phone"
                              type="phone"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>

                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={12} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Email<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="email"
                              placeholder="Email"
                              value={this.state.email}
                              name="email"
                              type="email"
                              onChange={this.handleEmailChange.bind(this)}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>

                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: "1em 0em 0em 0em",
                            padding: 0,
                          }}
                        >
                          <Col md={12} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Enable Mobile Access?
                            </label>
                            <ToggleButton
                              className={this.state.enableMobileAccess ? "ToggleButtonActive" : "ToggleButtonInActive"}
                              onChange={this.handleMobileAccessChange.bind(
                                this
                              )}
                            >
                              {this.state.enableMobileAccess ? "Yes" : "No"}
                            </ToggleButton>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} style={{ display: "flex", padding: "10px" }}>
                  <Card md={12} className="expenseCard">
                    <CardBody>
                      <div
                        style={{
                          width: "100%",
                          padding: "10px",
                          // border: "0.5px solid #ece4e4",
                        }}
                      >
                        <Row
                          md={12}
                          style={{
                            fontSize: "large",
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                            marginBottom: "1em",
                          }}
                        >
                          <strong>Account Details </strong>
                        </Row>
                        <Row
                          md={12}
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Profile<span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="userRole"
                              ListboxProps={{ className: "myCustomList" }}
                              options={this.UserRoleList}
                              value={this.state.userRole}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleRoleChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  variant="outlined"
                                  placeholder="Profile"
                                  className="regCountry"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Col>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Business Unit<span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="bu"
                              ListboxProps={{ className: "myCustomList" }}
                              options={
                                null === this.businessUnitsList
                                  ? []
                                  : this.businessUnitsList
                              }
                              value={this.state.businessUnit}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleBusinessUnitChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  variant="outlined"
                                  {...params}
                                  placeholder="Business Unit "
                                  className="regCountry"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Username<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              disabled
                              variant="outlined"
                              fullWidth
                              id="userName"
                              placeholder="Username"
                              // value={this.state.userName}
                              // onChange={this.handleUsernameChange.bind(this)}
                              value={this.state.email}
                              type="email"
                              // onChange={this.handleEmailChange.bind(this)}
                              name="userName"
                              autoComplete="userName"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: "0.8em 0em 0em 0.2em",
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              User Status:
                            </label>
                            {this.state.appuserStatus &&
                              this.state.appuserStatus.status === "Active" && (
                                <ToggleButton
                                  className="ToggleButtonActive"
                                  onChange={this.handleUserStatusChange.bind(
                                    this
                                  )}
                                >
                                  Active
                                </ToggleButton>
                              )}
                            {this.state.appuserStatus &&
                              this.state.appuserStatus.status !== "Active" && (
                                <ToggleButton
                                  className="ToggleButtonInActive"
                                  onChange={this.handleUserStatusChange.bind(
                                    this
                                  )}
                                >
                                  InActive
                                </ToggleButton>
                              )}
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <CardFooter style={{ width: "100%" }}>
                <Row md={12}>
                  <Col style={{ textAlign: "center" }}>
                    <SaveButton type="submit" />
                    <CancelButton onClick={(e) => this.cancelEdit()} />
                  </Col>
                </Row>
              </CardFooter>
            </Col>
          </Row>
        </ValidatorForm >
      </div >
    );
  }
  createUser() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          name={"Create " + this.title}
          addToBreadCrumb={"Create " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          domainObject="User"
        />

        <ValidatorForm
          ref="form"
          onSubmit={this.createUserInBackend.bind(this)}
          style={{ marginTop: "1em" }}
        >
          <Row style={{ margin: "0.3em" }}>
            <Col md={12}>
              <Row style={{ display: "flex" }}>
                <Col md={6} style={{ display: "flex", padding: "10px" }}>
                  <Card md={12} className="expenseCard">
                    <CardBody>
                      <div
                        style={{
                          width: "100%",
                          padding: "10px",
                          // border: "0.5px solid #ece4e4",
                        }}
                      >
                        <Row
                          style={{
                            fontSize: "large",
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                            marginBottom: "1em",
                          }}
                        >
                          <strong>User Details</strong>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              First Name<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="fname"
                              placeholder="First Name"
                              value={this.state.fname}
                              onChange={this.handleFirstNameChange.bind(this)}
                              name="fname"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>

                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Last Name<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="lname"
                              placeholder="Last Name"
                              value={this.state.lname}
                              onChange={this.handleLastNameChange.bind(this)}
                              name="lname"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            // alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Designation<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="designation"
                              placeholder="Designation "
                              value={this.state.designation}
                              onChange={this.handleDesignationChange.bind(this)}
                              name="designation"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Phone Number<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="phone"
                              placeholder="Phone Number"
                              value={this.state.phone}
                              onChange={this.handlePhoneChange.bind(this)}
                              name="phone"
                              type="phone"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>

                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={12} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Email<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="email"
                              placeholder="Email"
                              value={this.state.email}
                              name="email"
                              type="email"
                              onChange={this.handleEmailChange.bind(this)}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: "1em 0em 0em 0em",
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Enable Mobile Access?
                            </label>
                            <ToggleButton
                              className={this.state.enableMobileAccess ? "ToggleButtonActive" : "ToggleButtonInActive"}
                              onChange={this.handleMobileAccessChange.bind(this)}
                            >
                              {this.state.enableMobileAccess ? "Yes" : "No"}
                            </ToggleButton>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} style={{ display: "flex", padding: "10px" }}>
                  <Card md={12} className="expenseCard">
                    <CardBody>
                      <div
                        style={{
                          width: "100%",
                          padding: "10px",
                          // border: "0.5px solid #ece4e4",
                        }}
                      >
                        <Row
                          md={12}
                          style={{
                            fontSize: "large",
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                            marginBottom: "1em",
                          }}
                        >
                          <strong>Account Details </strong>
                        </Row>
                        <Row
                          md={12}
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Profile<span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="userRole"
                              ListboxProps={{ className: "myCustomList" }}
                              options={this.UserRoleList}
                              value={this.state.userRole}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleRoleChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  variant="outlined"
                                  placeholder="Profile"
                                  className="regCountry"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Col>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Business Unit<span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="bu"
                              ListboxProps={{ className: "myCustomList" }}
                              options={
                                null === this.businessUnitsList
                                  ? []
                                  : this.businessUnitsList
                              }
                              value={this.state.businessUnit}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleBusinessUnitChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  variant="outlined"
                                  {...params}
                                  placeholder="Business Unit "
                                  className="regCountry"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Username<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              disabled
                              variant="outlined"
                              fullWidth
                              id="userName"
                              placeholder="Username"
                              value={this.state.userName}
                              // onChange={this.handleUsernameChange.bind(this)}
                              name="userName"
                              autoComplete="userName"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>

                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Password<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              id="password"
                              placeholder="Password"
                              type={this.state.showPassword ? "text" : "password"}
                              value={this.state.credential}
                              onChange={this.handleCredentailChange.bind(this)}
                              name="password"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword.bind(this)}
                                    >
                                      {this.state.showPassword ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Col>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Confirm Password
                              <span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              // type="password"
                              variant="outlined"
                              fullWidth
                              id="confirmPassword"
                              placeholder="Confirm Password"
                              type={this.state.showConfirmPassword ? "text" : "password"}
                              value={this.state.confirmcredential}
                              onChange={this.handleConfirmCredentailChange.bind(
                                this
                              )}
                              name="confirmPassword"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowConfirmPassword.bind(this)}
                                    >
                                      {this.state.showConfirmPassword ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000",
                            alignItems: "flex-end",
                            margin: "0.8em 0em 0em 0.2em",
                            padding: 0,
                          }}
                        >
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              User Status:
                            </label>
                            {this.state.appuserStatus &&
                              this.state.appuserStatus.status === "Active" && (
                                <ToggleButton
                                  className="ToggleButtonActive"
                                  onChange={this.handleUserStatusChange.bind(
                                    this
                                  )}
                                >
                                  Active
                                </ToggleButton>
                              )}
                            {this.state.appuserStatus &&
                              this.state.appuserStatus.status !== "Active" && (
                                <ToggleButton
                                  className="ToggleButtonInActive"
                                  onChange={this.handleUserStatusChange.bind(
                                    this
                                  )}
                                >
                                  InActive
                                </ToggleButton>
                              )}
                          </Col>
                        </Row>
                        <Row md={12} style={{ minHeight: "1.5em" }}></Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <CardFooter style={{ width: "100%" }}>
                <Row md={12}>
                  <Col style={{ textAlign: "center" }}>
                    <CreateButton type="submit" />
                    <CancelButton onClick={(e) => this.cancelView()} />
                  </Col>
                </Row>
              </CardFooter>
            </Col>
          </Row>
        </ValidatorForm>
      </div>
    );
  }
}
export default User;

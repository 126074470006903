import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { Row } from "reactstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Switch, createMuiTheme } from "@material-ui/core";
import { Buffer } from "buffer";
import TextField from "@material-ui/core/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import paymentIcon from "../../../../assets/images/paymentIcon.jpeg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Badge from "@material-ui/core/Badge";
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
// import PrintIcon from "@material-ui/icons/Print";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PaymentDetailsDialogue from "../../payments/PaymentDetailsDialogue";
import ViewReceipt from "../../attachments/ViewReceipt";
import "../LedgerManagement.scss";
import Tooltip from "@material-ui/core/Tooltip";
import {
  // createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "black",
        backgroundColor: "#F3E3A3",
        border: "1px solid black",
        cursor: "pointer",
      },
    },
  },
});
export class OutwardLedger extends Component {
  supplierList = store.getState().master.masterData.suppliers;
  businessUnitList = store.getState().master.masterData.businessUnits;
  financialsYearArray = store.getState().appData.fyList;
  printComponentRef = React.createRef();
  breadCrumb = [
    {
      pageid: "/ledgerMgmt",
      uiname: "Ledger Management",
      cssclass: "menu-icon tf-icons fas fa-book",
    },
    { pageid: "/ledgerOutward", uiname: "Outward Ledger" },
  ];
  title = "Outward Ledger";
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    let allSuppliers = {
      name: "All",
    };
    const supplierList = [allSuppliers, ...this.supplierList];
    this.state = {
      showAddcustomer: false,
      supplierList: supplierList,
      purchaseLedgerList: [],
      expenseLedgerList: [],
      privileges: [],
      fromDate: null,
      toDate: null,
      financialsYear: null,
      selectedBusinessUnit: this.businessUnitList[0],
      selectedSupplier: supplierList[0],
      selectedExcelBusinessUnit: this.businessUnitList[0],
      selectedExcelSupplier: supplierList[0],
      pageSize: 10,
      initialStep: 0,
      selectedTab: 0,
      isPaging: false,
      isFirstTimeLoading: true,
      defaultFromDate: null,
      defaultToDate: null,
      excelFromDate: null,
      excelToDate: null,
      excelFinancialsYear: null,
      downloadOpen: false,
      error: false,
      errorMassage: false,
      excelError: false,
      excelErrorMassage: false,
      swichCheck: false,
      excelSwichCheck: false,
      render: false,
    };
    this.getPurchaseOutwardLedgerData("", "", null, "", "", false);
  }

  getPurchaseOutwardLedgerData(
    suppid,
    buCodeId,
    financialsYear,
    fromDate,
    toDate,
    reset
  ) {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
      supplier: { id: suppid },
      bu: { id: buCodeId },
      financialYear: financialsYear,
      fromDate: fromDate,
      toDate: toDate,
    };

    FetchServerData.callPostService(
      "/ledgerms/getOutwardPurchase",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let purchaseOutwardData = output.data;
        let privileges = output.data.privileges;
        let purchaseLedgerList = output.data.ledgerItems;
        let isPaging = false;
        if (purchaseLedgerList.length > 10) {
          isPaging = true;
        }
        for (let i = 0; i < purchaseLedgerList.length; i++) {
          purchaseLedgerList[i].invoicedate = Utils.ptsDateFormat(
            purchaseLedgerList[i].invoicedate
          );
          purchaseLedgerList[i].fileExtension =
            purchaseLedgerList[i].receiptFilename?.split(".")[1];
          if (purchaseLedgerList[i].invoicetype === "RE") {
            purchaseLedgerList[i].advanceAmountUtilized =
              purchaseLedgerList[i].advanceAmountUsed;
            purchaseLedgerList[i].advanceGSTUtilized =
              purchaseLedgerList[i].advanceGstUsed;
          }
        }
        this.setState({
          render: true,
          open: false,
          errorMassage: false,
          isPaging: isPaging,
          purchaseLedgerList: purchaseLedgerList,
          privileges: privileges,
          isFirstTimeLoading: reset ? false : true,
          financialsYear: purchaseOutwardData.financialYear,
          excelFinancialsYear: purchaseOutwardData.financialYear,
          excelFromDate:
            purchaseOutwardData.financialYear === null
              ? purchaseOutwardData.fromDate
              : null,
          excelToDate:
            purchaseOutwardData.financialYear === null
              ? purchaseOutwardData.toDate
              : null,
          fromDate: purchaseOutwardData.fromDate,
          toDate: purchaseOutwardData.toDate,
          defaultFromDate: purchaseOutwardData.fromDateStr,
          defaultToDate: purchaseOutwardData.toDateStr,
          defaultFinancialYear: purchaseOutwardData.financialYear,
          swichCheck: financialsYear === null ? true : false,
          excelSwichCheck: financialsYear === null ? true : false,
        });
      }
    });
  }

  getExpenseOutwardLedgerData(
    buCodeId,
    financialsYear,
    fromDate,
    toDate,
    reset
  ) {
    let header = store.getState().header.header;

    const postObject = {
      header: header,
      bu: { id: buCodeId },
      financialYear: financialsYear,
      fromDate: fromDate,
      toDate: toDate,
    };

    FetchServerData.callPostService(
      "/ledgerms/getOutwardExpense",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let outwardData = output.data;
        let privileges = output.data.privileges;
        let expenseLedgerList = output.data.ledgerItems;
        let isPaging = false;
        if (expenseLedgerList.length > 10) {
          isPaging = true;
        }
        for (let i = 0; i < expenseLedgerList.length; i++) {
          expenseLedgerList[i].expensedate = Utils.ptsDateFormat(
            expenseLedgerList[i].expensedate
          );
          expenseLedgerList[i].fileExtension =
            expenseLedgerList[i].receiptFilename?.split(".")[1];
        }
        this.setState({
          render: true,
          open: false,
          errorMassage: false,
          isPaging: isPaging,
          expenseLedgerList: expenseLedgerList,
          privileges: privileges,
          isFirstTimeLoading: reset ? false : true,
          financialsYear: outwardData.financialYear,
          excelFinancialsYear: outwardData.financialYear,
          excelFromDate:
            outwardData.financialYear === null ? outwardData.fromDate : null,
          excelToDate:
            outwardData.financialYear === null ? outwardData.toDate : null,
          fromDate: outwardData.fromDate,
          toDate: outwardData.toDate,
          defaultFromDate: outwardData.fromDateStr,
          defaultToDate: outwardData.toDateStr,
          defaultFinancialYear: outwardData.financialYear,
          swichCheck: financialsYear === null ? true : false,
          excelSwichCheck: financialsYear === null ? true : false,
        });
      }
    });
  }

  cancelView() {
    this.props.history.push({ pathname: "/master/" });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  // handleToDateChange(date) {
  //   this.setState({ toDate: date });
  // }
  // handleFromDateChange(date) {
  //   this.setState({ fromDate: date });
  // }

  handleFromDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.toDate);
    if (this.state.toDate === null && date === null) {
      this.setState({
        fromDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (date === null) {
      this.setState({
        fromDate: date,
        error: false,
        errorMassage: true,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        fromDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        fromDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  handleToDateChange(date) {
    let fromDate = Date.parse(this.state.fromDate);
    let toDate = Date.parse(date);
    if (this.state.fromDate === null && date === null) {
      this.setState({
        toDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    } else if (this.state.fromDate === null) {
      this.setState({
        toDate: date,
        errorMassage: true,
        error: false,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        toDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        toDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  handleSwichCheckChange(e) {
    this.setState({ swichCheck: e.target.checked });
  }
  handleExcelSwichCheckChange(e) {
    this.setState({ excelSwichCheck: e.target.checked });
  }

  updateSupplier(e, supplier) {
    this.setState({ selectedSupplier: supplier });
  }

  updateExcelSupplier(e, supplier) {
    this.setState({ selectedExcelSupplier: supplier });
  }

  updateBusinessUnit(e, bu) {
    this.setState({ selectedBusinessUnit: bu });
  }
  updateExcelBusinessUnit(e, bu) {
    this.setState({ selectedExcelBusinessUnit: bu });
  }

  filterRecords(e) {
    if (this.state.errorMassage || this.state.error) {
      return false;
    }

    let fromDate = this.state.fromDate ? this.state.fromDate : null;
    let toDate = this.state.toDate ? this.state.toDate : null;
    let financialsYear = this.state.financialsYear
      ? this.state.financialsYear
      : null;
    let suppid = this.state.selectedSupplier
      ? this.state.selectedSupplier.id
      : "";
    let buCodeId = this.state.selectedBusinessUnit
      ? this.state.selectedBusinessUnit.id
      : "";
    if (this.state.selectedTab === 0) {
      this.getPurchaseOutwardLedgerData(
        suppid,
        buCodeId,
        financialsYear,
        fromDate,
        toDate,
        true
      );
    } else {
      this.getExpenseOutwardLedgerData(
        buCodeId,
        financialsYear,
        fromDate,
        toDate,
        true
      );
    }
  }

  resetFilter() {
    let buCodeId = "";
    let suppid = "";
    let financialsYear = null;
    let fromDate = null;
    let toDate = null;
    this.setState({
      selectedCustomer: this.state.supplierList[0],
      selectedBusinessUnit: this.businessUnitList[0],
      financialsYear: this.state.financialsYear,
      fromDate: null,
      toDate: null,
      error: false,
      errorMassage: false,
    });
    if (this.state.selectedTab === 0) {
      this.getPurchaseOutwardLedgerData(
        suppid,
        buCodeId,
        financialsYear,
        fromDate,
        toDate
      );
    } else {
      this.getExpenseOutwardLedgerData(
        buCodeId,
        financialsYear,
        fromDate,
        toDate
      );
    }
  }

  setKey(tab) {
    let fromDate = this.state.fromDate ? this.state.fromDate : null;
    let toDate = this.state.toDate ? this.state.toDate : null;
    let financialsYear = this.state.financialsYear
      ? this.state.financialsYear
      : null;
    let suppid = this.state.selectedSupplier
      ? this.state.selectedSupplier.id
      : "";
    let buCodeId = this.state.selectedBusinessUnit
      ? this.state.selectedBusinessUnit.id
      : "";
    if (tab === 0) {
      this.getPurchaseOutwardLedgerData(
        suppid,
        buCodeId,
        financialsYear,
        fromDate,
        toDate,
        true
      );
    } else {
      this.getExpenseOutwardLedgerData(
        buCodeId,
        financialsYear,
        fromDate,
        toDate,
        true
      );
    }
    this.setState({
      selectedTab: tab,
      fromDate: null,
      toDate: null,
      excelFromDate: null,
      excelToDate: null,
    });
  }

  purchasePaymentDetails(invoiceId) {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current
    ) {
      this.referenceInvoiceDialogueRef.current.showPurchasePaymentDetails(
        invoiceId
      );
    }
  }

  expensePaymentDetails(expenseId) {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current
    ) {
      this.referenceInvoiceDialogueRef.current.showExpensePaymentDetails(
        expenseId
      );
    }
  }

  ReceiptDetails(data) {
    if (
      data.fileExtension === "xlsx" ||
      data.fileExtension === "zip" ||
      data.fileExtension === "pptx" ||
      data.fileExtension === "docx"
    ) {
      if (this.receiptDialogueRef && this.receiptDialogueRef.current) {
        this.receiptDialogueRef.current.downloadDocument(data);
      }
    } else {
      let receiptUrl = data.receiptURL;
      let aid = data.id;
      if (this.receiptDialogueRef && this.receiptDialogueRef.current) {
        this.receiptDialogueRef.current.viewDocument(receiptUrl, aid);
      }
    }
  }

  handleExcelDownloadDropdownOpen = (e) => {
    this.setState({ downloadOpen: true, anchorEl1: e.currentTarget });
  };
  handleExcelDownloadDropdownClose = () => {
    this.setState({ downloadOpen: false, anchorEl1: null });
  };

  // handleExcelFromDateChange(exdate) {
  //   this.setState({ excelFromDate: exdate });
  // }

  // handleExcelToDateChange(exdate) {
  //   this.setState({ excelToDate: exdate });
  // }

  handleExcelFromDateChange(exdate) {
    let startDate = Date.parse(exdate);
    let endDate = Date.parse(this.state.excelToDate);
    if (this.state.excelToDate === null && exdate === null) {
      this.setState({
        excelFromDate: exdate,
        excelError: false,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else if (exdate === null) {
      this.setState({
        excelFromDate: exdate,
        excelError: false,
        excelErrorMassage: true,
        excelFinancialsYear: null,
      });
    } else if (startDate > endDate) {
      this.setState({
        excelFromDate: exdate,
        excelError: true,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else {
      this.setState({
        excelFromDate: exdate,
        excelError: false,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    }
  }

  handleExcelToDateChange(exdate) {
    let startDate = Date.parse(this.state.excelFromDate);
    let endDate = Date.parse(exdate);
    if (this.state.excelFromDate === null && exdate === null) {
      this.setState({
        excelToDate: exdate,
        excelError: false,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else if (this.state.excelFromDate === null) {
      this.setState({
        excelToDate: exdate,
        excelError: false,
        excelErrorMassage: true,
        excelFinancialsYear: null,
      });
    } else if (startDate > endDate) {
      this.setState({
        excelToDate: exdate,
        excelError: true,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else {
      this.setState({
        excelToDate: exdate,
        excelError: false,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    }
  }

  updateFinancialYear(e, year) {
    this.setState({ financialsYear: year, fromDate: null, toDate: null });
  }

  updateExcelFinancialYear(e, year) {
    this.setState({
      excelFinancialsYear: year,
      excelFromDate: null,
      excelToDate: null,
    });
  }

  downloadExcelFromBackend() {
    if (this.state.excelErrorMassage || this.state.excelError) {
      return false;
    }

    let header = store.getState().header.header;
    const postObject = {
      header: header,
      fromDate: this.state.excelFromDate,
      toDate: this.state.excelToDate,
      // invoiceType: invoiceTypeArray,
      supplier: {
        id: this.state.selectedExcelSupplier?.id,
      },
      bu: {
        id: this.state.selectedExcelBusinessUnit?.id,
      },
      financialYear: this.state.excelFinancialsYear,
    };

    FetchServerData.callPostService(
      "/ledgerms/createOutwardPurchaseExcel",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let excelData = output.data;
        const byteArray = new Buffer(
          excelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );

        const outputFilename = excelData.filename;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        this.setState({
          // excelFromDate: null,
          // excelToDate: null,
          // excelFinancialsYear: null,
          // selectedExcelBusinessUnit: null,
          // selectedExcelSupplier: null,
          excelError: false,
          excelErrorMassage: false,
          downloadOpen: false,
        });
      } else {
      }
    });
  }

  downloadExpenseExcelFromBackend() {
    if (this.state.excelErrorMassage || this.state.excelError) {
      return false;
    }

    let header = store.getState().header.header;
    const postObject = {
      header: header,
      fromDate: this.state.excelFromDate,
      toDate: this.state.excelToDate,
      bu: {
        id: this.state.selectedExcelBusinessUnit?.id,
      },
      financialYear: this.state.excelFinancialsYear,
    };

    FetchServerData.callPostService(
      "/ledgerms/getOutwardExpenseExcel",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let expenseExcelData = output.data;
        const byteArray = new Buffer(
          expenseExcelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );

        const outputFilename = expenseExcelData.filename;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        this.setState({
          // excelFromDate: null,
          // excelToDate: null,
          // excelFinancialsYear: null,
          // selectedExcelBusinessUnit: null,
          // selectedCustomer: null,
          excelError: false,
          excelErrorMassage: false,
          downloadOpen: false,
        });
      } else {
      }
    });
  }

  printButtonClicked() {
    if (this.state.selectedTab === 0) {
      this.props.history.push({
        pathname: "/purchaseledgerPrint",
        state: {
          breadCrumb: this.breadCrumb,
          outwardLedgerList: this.state.purchaseLedgerList,
          fromDate:
            this.state.fromDate !== null
              ? this.state.fromDate
              : this.state.defaultFromDate,
          toDate:
            this.state.toDate !== null
              ? this.state.toDate
              : this.state.defaultToDate,
          selectedBusinessUnit:
            this.state.selectedBusinessUnit !== null
              ? this.state.selectedBusinessUnit
              : this.businessUnitList[0],
        },
      });
    } else {
      this.props.history.push({
        pathname: "/expenseledgerPrint",
        state: {
          breadCrumb: this.breadCrumb,
          expenseLedgerList: this.state.expenseLedgerList,
          fromDate:
            this.state.fromDate !== null
              ? this.state.fromDate
              : this.state.defaultFromDate,
          toDate:
            this.state.toDate !== null
              ? this.state.toDate
              : this.state.defaultToDate,
          selectedBusinessUnit:
            this.state.selectedBusinessUnit !== null
              ? this.state.selectedBusinessUnit
              : this.businessUnitList[0],
        },
      });
    }
  }

  shawTooltipData(rowData) {
    if (
      (rowData.advanceAmountUsed !== 0.0 || rowData.advanceGstUsed !== 0.0) &&
      rowData.invoicetype !== "RE"
    ) {
      return (
        <p>
          Advance Used :{rowData.advanceAmountUsed}
          <br />
          Advance GST Used :{rowData.advanceGstUsed}
        </p>
      );
    } else {
      return (
        <p>
          Advance Utilized :{rowData.advanceAmountUtilized}
          <br />
          Advance GST Utilized :{rowData.advanceGSTUtilized}
        </p>
      );
    }
  }

  render() {
    this.referenceInvoiceDialogueRef = React.createRef();
    this.receiptDialogueRef = React.createRef();
    if (this.state.render === false) {
      return <div />;
    }
    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Customer",
        isFreeAction: true,
        textAlign: "left",
        onClick: (event) => this.addInwardLedger(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <>
        <PaymentDetailsDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
        />

        <ViewReceipt
          ref={this.receiptDialogueRef}
          style={{ float: "right" }}
          action="view"
        />
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        <div className="row">
          <div className="col align-items-end">
            <div className="row-12 d-flex">
              {this.state.isFirstTimeLoading ? (
                <div className="col-12">
                  <div className="row-12 d-flex">
                    <div className="col-7 ms-1 align-items-start">
                      {this.state.selectedSupplier &&
                      this.state.selectedSupplier !== null ? (
                        <span>
                          <b>Supplier : </b>
                          {this.state.selectedSupplier?.name + ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.selectedBusinessUnit &&
                      this.state.selectedBusinessUnit !== null ? (
                        <span>
                          <b>Business Unit : </b>
                          {this.state.selectedBusinessUnit?.name + ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.defaultFinancialYear !== "" &&
                        this.state.defaultFinancialYear !== null && (
                          <span>
                            <b>Financial Year : </b>
                            {this.state.defaultFinancialYear + ", "}
                          </span>
                        )}{" "}
                      &nbsp;
                      {this.state.defaultFromDate &&
                      this.state.defaultFromDate !== null ? (
                        <span>
                          <b>Start Date : </b>
                          {Utils.ptsDateFormat(this.state.defaultFromDate) +
                            ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.defaultToDate &&
                      this.state.defaultToDate !== null ? (
                        <span>
                          <b>End Date : </b>
                          {Utils.ptsDateFormat(this.state.defaultToDate)}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-5 align-items-end text-end">
                      <span
                        className="text-end me-2"
                        style={{
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={this.handleAccountDropdownOpen}
                      >
                        <FilterAltIcon
                          style={{ fontSize: "28px", color: "black" }}
                          aria-controls={
                            this.state.open ? "dropdown-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={this.state.open ? "true" : undefined}
                        />
                        Filter &nbsp;
                      </span>
                      {/* <Button
                        variant="contained"
                        size="small"
                        style={{
                          background: "#1b7189",
                          color: "#fff",
                          padding: "5px",
                          width: "120px",
                          textTransform: "capitalize",
                        }}
                        startIcon={<PrintIcon />}
                        onClick={this.printButtonClicked.bind(this)}
                      >
                        Print Preview
                      </Button>
                      &nbsp; &nbsp; */}
                      <Button
                        variant="contained"
                        size="small"
                        id="excelBtn"
                        style={{
                          background: "#5ac489",
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                        startIcon={<i class="fa fa-download"></i>}
                        onClick={this.handleExcelDownloadDropdownOpen}
                      >
                        Download Excel
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-7 ms-1 align-items-start">
                    {this.state.selectedSupplier &&
                    this.state.selectedSupplier !== null ? (
                      <span>
                        <b>Supplier : </b>
                        {this.state.selectedSupplier?.name + ", "}
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.selectedBusinessUnit &&
                    this.state.selectedBusinessUnit !== null ? (
                      <span>
                        <b>Business Unit : </b>
                        {this.state.selectedBusinessUnit?.name + ", "}
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.defaultFinancialYear !== "" &&
                      this.state.defaultFinancialYear !== null && (
                        <span>
                          <b>Financial Year : </b>
                          {this.state.defaultFinancialYear + ", "}
                        </span>
                      )}{" "}
                    &nbsp;
                    {this.state.defaultFromDate &&
                    this.state.defaultFromDate !== null ? (
                      <span>
                        <b>Start Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultFromDate) + ", "}
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.defaultToDate &&
                    this.state.defaultToDate !== null ? (
                      <span>
                        <b>End Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultToDate)}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-5 align-items-end text-end">
                    <span
                      className="text-end"
                      style={{
                        fontSize: "17px",
                        fontWeight: "500",
                        color: "black",
                        cursor: "pointer",
                      }}
                      onClick={this.handleAccountDropdownOpen}
                    >
                      <FilterAltIcon
                        style={{ fontSize: "28px", color: "black" }}
                        aria-controls={
                          this.state.open ? "dropdown-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={this.state.open ? "true" : undefined}
                      />
                      Filter
                    </span>
                    {"  "} &nbsp;
                    {this.state.isFirstTimeLoading ? (
                      ""
                    ) : (
                      <span
                        className="me-1"
                        style={{
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={this.resetFilter.bind(this)}
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                        &nbsp;
                      </span>
                    )}
                    {/* <Button
                      variant="contained"
                      size="small"
                      style={{
                        background: "#1b7189",
                        color: "#fff",
                        padding: "5px",
                        width: "120px",
                        textTransform: "capitalize",
                      }}
                      startIcon={<PrintIcon />}
                      onClick={this.printButtonClicked.bind(this)}
                    >
                      Print Preview
                    </Button>
                    &nbsp; &nbsp; */}
                    <Button
                      variant="contained"
                      size="small"
                      id="excelBtn"
                      style={{
                        background: "#5ac489",
                        color: "#fff",
                        textTransform: "capitalize",
                      }}
                      startIcon={<i class="fa fa-download"></i>}
                      onClick={this.handleExcelDownloadDropdownOpen}
                    >
                      Download Excel
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {this.state.selectedTab === 0 ? (
            <div
              className="dropdown-item"
              style={{ cursor: "pointer", width: "400px", height: "100%" }}
            >
              <div className="row">
                <h5 className="bold">Filter</h5>
                <div className="col-md-12 mt-1" id="assetFormLabel">
                  <label
                    className="form-label "
                    htmlFor="collapsible-supplierType"
                  >
                    Supplier
                  </label>
                  <Autocomplete
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    variant="outlined"
                    id="supplierList"
                    options={this.state.supplierList}
                    value={this.state.selectedSupplier}
                    defaultValue={this.state.selectedSupplier}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Select Supplier"
                    onChange={(event, value) => {
                      this.updateSupplier(event, value);
                    }}
                    style={{ background: "#fff", borderRadius: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Supplier"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-md-12 mt-1" id="assetFormLabel">
                  <label
                    className="form-label "
                    htmlFor="collapsible-supplierType"
                  >
                    Business Unit
                  </label>
                  <Autocomplete
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    variant="outlined"
                    id="businessUnit"
                    options={this.businessUnitList}
                    value={this.state.selectedBusinessUnit}
                    defaultValue={this.state.selectedBusinessUnit}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Select Business Unit"
                    style={{ background: "#fff", borderRadius: "5px" }}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="regCountry"
                        placeholder="Business Unit"
                        variant="outlined"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-md-12" id="assetFormLabel">
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0px",
                      padding: "0",
                    }}
                    control={
                      <Switch
                        variant="outlined"
                        // id="isAsset"
                        checked={this.state.swichCheck}
                        value={this.state.swichCheck}
                        onChange={this.handleSwichCheckChange.bind(this)}
                        style={{ background: "none", margin: "8px" }}
                      />
                    }
                    label={<span>Custom search </span>}
                  />
                </div>
                {!this.state.swichCheck && (
                  <div className="col-md-12 mt-1" id="assetFormLabel">
                    <label
                      className="form-label "
                      // htmlFor="collapsible-companyName"
                    >
                      Financial Year
                    </label>
                    <br />
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="supplierList"
                      options={this.financialsYearArray}
                      value={this.state.financialsYear}
                      defaultValue={this.state.financialsYear}
                      getOptionLabel={(option) => option}
                      style={{ background: "#fff", borderRadius: "5px" }}
                      onChange={(event, value) => {
                        this.updateFinancialYear(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Financial Year"
                          className="regCountry"
                          InputLabelProps={{
                            fontSize: "small !important",
                            style: { fontSize: "small !important" },
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                <div className="col-md-12 mt-1" id="assetFormLabel">
                  {this.state.swichCheck && (
                    <div className="row mt-2">
                      <div className="col-md-6" id="assetFormLabel">
                        <label className="form-label ">Start Date</label>
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              required
                              value={this.state.fromDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleFromDateChange.bind(this)}
                              helperText={
                                this.state.errorMassage
                                  ? "Start Date is Required"
                                  : ""
                              }
                              error={this.state.errorMassage}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          End Date
                        </label>{" "}
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              required
                              value={this.state.toDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleToDateChange.bind(this)}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      {this.state.error ? (
                        <lable className="danger errorMassageShow">
                          End Date should be after Start Date
                        </lable>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    className="fileterButton"
                    onClick={(e) => this.filterRecords(e)}
                  >
                    <i className="fa-solid fa-filter"></i>
                    &nbsp; Filter
                  </button>
                  &nbsp; &nbsp;
                  <button
                    className="resetButton"
                    onClick={this.resetFilter.bind(this)}
                  >
                    <i className="fa fa-refresh"></i>
                    &nbsp; Reset
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="dropdown-item"
              style={{ cursor: "pointer", width: "400px", height: "100%" }}
            >
              <div className="row">
                <h5 className="bold">Filter</h5>
                <div className="col-md-12 mt-1" id="assetFormLabel">
                  <label
                    className="form-label "
                    htmlFor="collapsible-supplierType"
                  >
                    Business Unit
                  </label>
                  <Autocomplete
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    variant="outlined"
                    id="businessUnit"
                    options={this.businessUnitList}
                    value={this.state.selectedBusinessUnit}
                    defaultValue={this.state.selectedBusinessUnit}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Select Business Unit"
                    style={{ background: "#fff", borderRadius: "5px" }}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="regCountry"
                        placeholder="Business Unit"
                        variant="outlined"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-md-12" id="assetFormLabel">
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0px",
                      padding: "0",
                    }}
                    control={
                      <Switch
                        variant="outlined"
                        // id="isAsset"
                        checked={this.state.swichCheck}
                        value={this.state.swichCheck}
                        onChange={this.handleSwichCheckChange.bind(this)}
                        style={{ background: "none", margin: "8px" }}
                      />
                    }
                    label={<span>Custom search </span>}
                  />
                </div>
                {!this.state.swichCheck && (
                  <div className="col-md-12 mt-1" id="assetFormLabel">
                    <label
                      className="form-label "
                      // htmlFor="collapsible-companyName"
                    >
                      Financial Year
                    </label>
                    <br />
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="supplierList"
                      options={this.financialsYearArray}
                      value={this.state.financialsYear}
                      defaultValue={this.state.financialsYear}
                      getOptionLabel={(option) => option}
                      style={{ background: "#fff", borderRadius: "5px" }}
                      onChange={(event, value) => {
                        this.updateFinancialYear(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Financial Year"
                          className="regCountry"
                          InputLabelProps={{
                            fontSize: "small !important",
                            style: { fontSize: "small !important" },
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                <div className="col-md-12 mt-1" id="assetFormLabel">
                  {this.state.swichCheck && (
                    <div className="row mt-2">
                      <div className="col-md-6" id="assetFormLabel">
                        <label className="form-label ">Start Date</label>
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              required
                              value={this.state.fromDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleFromDateChange.bind(this)}
                              helperText={
                                this.state.errorMassage
                                  ? "Start Date is Required"
                                  : ""
                              }
                              error={this.state.errorMassage}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          End Date
                        </label>{" "}
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              required
                              value={this.state.toDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleToDateChange.bind(this)}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      {this.state.error ? (
                        <lable className="danger errorMassageShow">
                          End Date should be after Start Date
                        </lable>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    className="fileterButton"
                    onClick={(e) => this.filterRecords(e)}
                  >
                    <i className="fa-solid fa-filter"></i>
                    &nbsp; Filter
                  </button>
                  &nbsp; &nbsp;
                  <button
                    className="resetButton"
                    onClick={this.resetFilter.bind(this)}
                  >
                    <i className="fa fa-refresh"></i>
                    &nbsp; Reset
                  </button>
                </div>
              </div>
            </div>
          )}
        </Menu>
        <Menu
          anchorEl={this.state.anchorEl1}
          id="dropdown-menu1"
          className="filterDropdownMenu"
          open={this.state.downloadOpen}
          onClose={this.handleExcelDownloadDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {this.state.selectedTab === 0 ? (
            <div
              className="dropdown-item"
              style={{ cursor: "pointer", width: "400px", height: "100%" }}
            >
              <div className="row">
                <h5 className="bold">Download Excel</h5>
                <div className="row">
                  <div className="col-md-12" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-supplierType"
                    >
                      Supplier
                    </label>
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="customerList"
                      options={this.supplierList}
                      value={this.state.selectedExcelSupplier}
                      defaultValue={this.state.selectedExcelSupplier}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Select Customer"
                      onChange={(event, value) => {
                        this.updateExcelSupplier(event, value);
                      }}
                      style={{ background: "#fff", borderRadius: "5px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className="regCountry"
                          placeholder="Supplier"
                          InputLabelProps={{
                            fontSize: "small !important",
                            style: { fontSize: "small !important" },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-supplierType"
                    >
                      Business Unit
                    </label>
                    <Autocomplete
                      margin="normal"
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      className="expenseAutoComplete"
                      id="businessUnit"
                      style={{ background: "#fff", borderRadius: "5px" }}
                      options={this.businessUnitList}
                      value={this.state.selectedExcelBusinessUnit}
                      defaultValue={this.state.selectedExcelBusinessUnit}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.updateExcelBusinessUnit(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Business Unit"
                          {...params}
                          style={{}}
                          variant="outlined"
                          className="regCountry"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row mt-2 d-flex">
                  <div className="col-md-12" id="assetFormLabel">
                    <FormControlLabel
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0px",
                        padding: "0",
                      }}
                      control={
                        <Switch
                          variant="outlined"
                          // id="isAsset"
                          checked={this.state.excelSwichCheck}
                          value={this.state.excelSwichCheck}
                          onChange={this.handleExcelSwichCheckChange.bind(this)}
                          style={{ background: "none", margin: "8px" }}
                        />
                      }
                      label={<span>Custom search </span>}
                    />
                  </div>
                </div>
                <div className="row mt-1">
                  {!this.state.excelSwichCheck && (
                    <div className="col-md-12" id="assetFormLabel">
                      <label
                        className="form-label "
                        // htmlFor="collapsible-companyName"
                      >
                        Financial Year
                      </label>
                      <br />
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        variant="outlined"
                        id="supplierList"
                        options={this.financialsYearArray}
                        value={this.state.excelFinancialsYear}
                        defaultValue={this.state.excelFinancialsYear}
                        getOptionLabel={(option) => option}
                        style={{ background: "#fff", borderRadius: "5px" }}
                        onChange={(event, value) => {
                          this.updateExcelFinancialYear(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Financial Year"
                            className="regCountry"
                            InputLabelProps={{
                              fontSize: "small !important",
                              style: { fontSize: "small !important" },
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  {this.state.excelSwichCheck && (
                    <>
                      <div className="col-md-6" id="assetFormLabel">
                        <label className="form-label ">Start Date</label>
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.excelFromDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleExcelFromDateChange.bind(
                                this
                              )}
                              helperText={
                                this.state.excelErrorMassage
                                  ? "Start Date is Required"
                                  : ""
                              }
                              error={this.state.excelErrorMassage}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          End Date
                        </label>{" "}
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.excelToDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleExcelToDateChange.bind(this)}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      {this.state.excelError ? (
                        <lable className="danger errorMassageShow">
                          End Date should be after Start Date
                        </lable>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>

                <div className="col-md-12 mt-4">
                  <button
                    className="fileterButton"
                    onClick={this.downloadExcelFromBackend.bind(this)}
                  >
                    <i className="fa fa-download"></i>
                    &nbsp; Download
                  </button>
                  &nbsp; &nbsp;
                </div>
              </div>
            </div>
          ) : (
            <div
              className="dropdown-item"
              style={{ cursor: "pointer", width: "400px", height: "100%" }}
            >
              <div className="row">
                <h5 className="bold">Download Excel</h5>
                <div className="row">
                  <div className="col-md-12" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-supplierType"
                    >
                      Business Unit
                    </label>
                    <Autocomplete
                      margin="normal"
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      className="expenseAutoComplete"
                      id="businessUnit"
                      style={{ background: "#fff", borderRadius: "5px" }}
                      options={this.businessUnitList}
                      value={this.state.selectedExcelBusinessUnit}
                      defaultValue={this.state.selectedExcelBusinessUnit}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.updateExcelBusinessUnit(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Business Unit"
                          {...params}
                          style={{}}
                          variant="outlined"
                          className="regCountry"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-12" id="assetFormLabel">
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0px",
                      padding: "0",
                    }}
                    control={
                      <Switch
                        variant="outlined"
                        // id="isAsset"
                        checked={this.state.excelSwichCheck}
                        value={this.state.excelSwichCheck}
                        onChange={this.handleExcelSwichCheckChange.bind(this)}
                        style={{ background: "none", margin: "8px" }}
                      />
                    }
                    label={<span>Custom search </span>}
                  />
                </div>
                <div className="row mt-2">
                  {!this.state.excelSwichCheck && (
                    <div className="col-md-12 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        // htmlFor="collapsible-companyName"
                      >
                        Financial Year
                      </label>
                      <br />
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        variant="outlined"
                        id="supplierList"
                        options={this.financialsYearArray}
                        value={this.state.excelFinancialsYear}
                        defaultValue={this.state.excelFinancialsYear}
                        getOptionLabel={(option) => option}
                        style={{ background: "#fff", borderRadius: "5px" }}
                        onChange={(event, value) => {
                          this.updateExcelFinancialYear(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Financial Year"
                            className="regCountry"
                            InputLabelProps={{
                              fontSize: "small !important",
                              style: { fontSize: "small !important" },
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  {this.state.excelSwichCheck && (
                    <>
                      <div className="col-md-6" id="assetFormLabel">
                        <label className="form-label ">Start Date</label>
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.excelFromDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleExcelFromDateChange.bind(
                                this
                              )}
                              helperText={
                                this.state.excelErrorMassage
                                  ? "Start Date is Required"
                                  : ""
                              }
                              error={this.state.excelErrorMassage}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          End Date
                        </label>{" "}
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.excelToDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleExcelToDateChange.bind(this)}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      {this.state.excelError ? (
                        <lable className="danger errorMassageShow">
                          End Date should be after Start Date
                        </lable>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>

                <div className="col-md-12 mt-4">
                  <button
                    className="fileterButton"
                    onClick={this.downloadExpenseExcelFromBackend.bind(this)}
                  >
                    <i className="fa fa-download"></i>
                    &nbsp; Download
                  </button>
                  &nbsp; &nbsp;
                </div>
              </div>
            </div>
          )}
        </Menu>
        <div
          className="mt-2"
          style={{ background: "transparent", width: "100%" }}
        >
          {/* <div className="row mt-2 row justify-content-end">
						<div className="col-sm-8 text-end m-2">
							<ReactToPrint
								trigger={
									() => (
										<Button
											variant="contained"
											size="small"
											style={{
												background: "#1b7189",
												color: "#fff",
												padding: "5px",
												width: "90px",
											}}
											startIcon={<PrintIcon />}
										>
											Print
										</Button>
									)
								}
								content={() => this.printComponentRef}
							/>
							&nbsp; &nbsp;
							<Button
								variant="contained"
								size="small"
								id="excelBtn"
								style={{
									background: "#5ac489",
									color: "#fff",
									textTransform: "capitalize",
								}}
								startIcon={<i class="fa fa-download"></i>}
								onClick={this.handleExcelDownloadDropdownOpen}
							>
								Download Excel
							</Button>
						</div>
					</div> */}

          <Tabs
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
            className="settingTab"
            defaultIndex={this.state.selectedTab}
            onSelect={(k) => this.setKey(k)}
          >
            <TabList style={{ color: "#1b7189", outline: "none", margin: "0" }}>
              <Tab>
                {" "}
                <span style={{ fontWeight: "600" }}>Purchase</span>
              </Tab>
              <Tab>
                {" "}
                <span style={{ fontWeight: "600" }}>Expense</span>
              </Tab>
            </TabList>
            <TabPanel>
              <Row md={12}>
                <div
                  className="ledgerTable"
                  style={{ width: "100%", padding: "0em 1em" }}
                >
                  <MaterialTable
                    icons={this.tableIcons}
                    tableRef={(el) => (this.printComponentRef = el)}
                    title="Purchase Ledger List"
                    columns={[
                      {
                        title: "#",
                        render: (rowData) => rowData.tableData.id + 1,
                        cellStyle: {
                          width: "3%",
                        },
                      },
                      {
                        title: "Date",
                        field: "invoicedate",
                        align: "left",
                        cellStyle: {
                          width: "6%",
                        },
                      },
                      {
                        title: "Invnumber",
                        // field: "invnumber",
                        align: "left",
                        // cellStyle: {
                        // 	width: "10%",
                        // },
                        render: (rowData) => {
                          return (
                            <>
                              {/* this.shawTooltipData(rowData) */}
                              {rowData.advanceAmountUsed !== 0.0 ||
                              rowData.advanceGstUsed !== 0.0 ? (
                                <MuiThemeProvider theme={theme}>
                                  <Tooltip
                                    title={this.shawTooltipData(rowData)}
                                  >
                                    <div style={{ cursor: "pointer" }}>
                                      <b>{rowData.invnumber}</b>
                                    </div>
                                  </Tooltip>
                                </MuiThemeProvider>
                              ) : (
                                <div>{rowData.invnumber}</div>
                              )}
                            </>
                          );
                        },
                        sorting: false,
                      },
                      {
                        title: "Type",
                        field: "invoicetypeDescription",
                        align: "left",
                        cellStyle: {
                          width: "8%",
                          wordBreak: "break-word",
                        },
                        sorting: false,
                      },
                      {
                        title: "Status",
                        // field: "status",
                        render: (rowData) =>
                          Utils.toCamelCaseUL(rowData.status),
                        align: "left",
                        cellStyle: {
                          width: "10%",
                        },
                        sorting: false,
                      },
                      {
                        title: "Supplier Name",
                        field: "supplierName",
                        align: "left",
                        cellStyle: {
                          width: "12%",
                        },
                        sorting: false,
                      },
                      {
                        title: "GST",
                        render: (rowData) =>
                          rowData.currencySymbol +
                          " " +
                          parseFloat(rowData.totalgst).toFixed(2),
                        align: "right",
                        // cellStyle: {
                        // 	width: "7%",
                        // },
                        sorting: false,
                      },
                      {
                        title: "Taxable",
                        render: (rowData) =>
                          rowData.currencySymbol +
                          " " +
                          parseFloat(rowData.totaltaxable).toFixed(2),
                        align: "right",
                        // cellStyle: {
                        // 	width: "8%",
                        // },
                        sorting: false,
                      },
                      {
                        title: "Total",
                        render: (rowData) =>
                          rowData.currencySymbol +
                          " " +
                          parseFloat(rowData.totalamount).toFixed(2),
                        align: "right",
                        // cellStyle: {
                        // 	width: "8%",
                        // },
                        sorting: false,
                      },
                      {
                        title: "EX Rate",
                        // field: "exchangerate",
                        render: (rowData) =>
                          rowData.baseCurrencySymbol +
                          " " +
                          parseFloat(rowData.exchangerate).toFixed(2),
                        align: "right",
                        // cellStyle: {
                        // 	width: "6%",
                        // },
                        sorting: false,
                      },
                      {
                        title: "Credit",
                        render: (rowData) =>
                          rowData.baseCurrencySymbol +
                          " " +
                          parseFloat(rowData.creditAmount).toFixed(2),
                        align: "right",
                        // cellStyle: {
                        // 	width: "8%",
                        // },
                        sorting: false,
                      },
                      {
                        title: "Debit",
                        render: (rowData) =>
                          rowData.baseCurrencySymbol +
                          " " +
                          parseFloat(rowData.debitAmount).toFixed(2),
                        align: "right",
                        // cellStyle: {
                        // 	width: "8%",
                        // },
                        sorting: false,
                      },
                      {
                        title: "Balance Amount",
                        render: (rowData) =>
                          rowData.baseCurrencySymbol +
                          " " +
                          parseFloat(rowData.balanceAmount).toFixed(2),
                        align: "right",
                        // cellStyle: {
                        // 	width: "10%",
                        // },
                        sorting: false,
                      },
                    ]}
                    data={this.state.purchaseLedgerList}
                    options={{
                      maxBodyHeight: 435,
                      overflowY: "none",
                      paging: false,
                      // pageSize: this.state.pageSize,
                      // pageSizeOptions: [5, 10, 20, 50, 100, 200],
                      // initialPage: this.state.initialPage,
                      sorting: true,
                      toolbar: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        margin: 0,
                        backgroundColor: "#1b7189",
                        // border: "1px solid #e2dddd",
                        fontWeight: "bold",
                        color: "#fff",
                        align: "center",
                      },
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.currencySymbol !== "₹" ? "#FEF5E7" : "",
                        height: "3em",
                        textTransform: "capitalize",
                      }),
                    }}
                    onChangePage={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          rowData.hasReceipt === true ? (
                            <>
                              {Utils.getViewDocuments(rowData.fileExtension)}
                              &nbsp; &nbsp;
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft: "1.4em",
                                fontSize: "1.5rem",
                              }}
                            >
                              &nbsp;&nbsp;
                            </div>
                          ),
                        onClick: () => this.ReceiptDetails(rowData),
                        tooltip: "Receipt",
                      }),
                      (rowData) => ({
                        icon: () =>
                          rowData.hasPayment === true ? (
                            <>
                              <Badge color="primary" overlap="rectangular">
                                <img
                                  alt=""
                                  src={paymentIcon}
                                  style={{
                                    height: "28px",
                                    maxWidth: "none",
                                    cursor: "pointer",
                                  }}
                                />
                              </Badge>
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft: "1.4em",
                                fontSize: "1.5rem",
                              }}
                            ></div>
                          ),
                        onClick: () => this.purchasePaymentDetails(rowData.id),
                        tooltip: "Payment Details",
                      }),
                    ]}
                  />
                </div>
              </Row>
            </TabPanel>
            <TabPanel>
              <Row md={12}>
                <div
                  className="ledgerExpenseTable"
                  style={{ width: "100%", padding: "0em 1em" }}
                >
                  <MaterialTable
                    icons={this.tableIcons}
                    tableRef={(el) => (this.printComponentRef = el)}
                    title="Expense Ledger List"
                    columns={[
                      {
                        title: "#",
                        render: (rowData) => rowData.tableData.id + 1,
                        cellStyle: {
                          width: "5%",
                        },
                      },
                      {
                        title: "Date",
                        field: "expensedate",
                        align: "left",
                        cellStyle: {
                          width: "12%",
                        },
                      },
                      {
                        title: "Expense Number",
                        field: "expnumber",
                        align: "left",
                        cellStyle: {
                          width: "15%",
                        },
                        sorting: false,
                      },
                      {
                        title: "Bill Number",
                        field: "billNumber",
                        align: "left",
                        cellStyle: {
                          width: "15%",
                        },
                        sorting: false,
                      },
                      {
                        title: "Supplier Name",
                        field: "supplierName",
                        align: "left",
                        cellStyle: {
                          width: "15%",
                        },
                        sorting: false,
                      },
                      {
                        title: "Total",
                        // render: (rowData) => Utils.getFloatValue(rowData.totalamount),
                        render: (rowData) =>
                          rowData.currencySymbol +
                          " " +
                          parseFloat(rowData.totalamount).toFixed(2),
                        align: "right",
                        cellStyle: {
                          width: "15%",
                        },
                        sorting: false,
                      },
                      {
                        title: "Exchange Rate",
                        render: (rowData) =>
                          rowData.baseCurrencySymbol +
                          " " +
                          parseFloat(rowData.exchangerate).toFixed(2),
                        align: "right",
                        cellStyle: {
                          width: "10%",
                        },
                        sorting: false,
                      },
                      {
                        title: "Balance Amount",
                        render: (rowData) =>
                          rowData.baseCurrencySymbol +
                          " " +
                          parseFloat(rowData.balanceAmount).toFixed(2),
                        // render: (rowData) => Utils.getFloatValue(rowData.balanceAmount),
                        align: "right",
                        cellStyle: {
                          width: "15%",
                        },
                        sorting: false,
                      },
                    ]}
                    data={this.state.expenseLedgerList}
                    options={{
                      maxBodyHeight: 430,
                      overflowY: "none",
                      paging: false,
                      // pageSize: this.state.pageSize,
                      // pageSizeOptions: [5, 10, 20, 50, 100, 200],
                      // initialPage: this.state.initialPage,
                      sorting: true,
                      toolbar: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        margin: 0,
                        backgroundColor: "#1b7189",
                        // border: "1px solid #e2dddd",
                        fontWeight: "bold",
                        color: "#fff",
                        align: "center",
                      },
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.currencySymbol !== "₹" ? "#FEF5E7" : "",
                        height: "3em",
                        textTransform: "capitalize",
                      }),
                    }}
                    onChangePage={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          rowData.hasReceipt === true ? (
                            <>
                              {Utils.getViewDocuments(rowData.fileExtension)}
                              &nbsp;&nbsp;
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft: "1.4em",
                                fontSize: "1.5rem",
                              }}
                            >
                              &nbsp;&nbsp;
                            </div>
                          ),
                        onClick: () => this.ReceiptDetails(rowData),
                        tooltip: "Receipt",
                      }),
                      (rowData) => ({
                        icon: () =>
                          rowData.hasPayment === true ? (
                            <>
                              <Badge color="primary" overlap="rectangular">
                                <img
                                  alt=""
                                  src={paymentIcon}
                                  style={{
                                    height: "28px",
                                    maxWidth: "none",
                                    cursor: "pointer",
                                  }}
                                />
                              </Badge>
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft: "1.4em",
                                fontSize: "1.5rem",
                              }}
                            ></div>
                          ),
                        onClick: () => this.expensePaymentDetails(rowData.id),
                        tooltip: "Payment Details",
                      }),
                    ]}
                  />
                </div>
              </Row>
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  }
}
export default OutwardLedger;

import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// import AutorenewIcon from "@material-ui/icons/Autorenew";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { Autocomplete, InputAdornment } from "@mui/material";
import { format } from "date-fns";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import SalesUtils from "../../../../provider/SalesUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import Address from "../../address/Address";
import AuditNoteList from "../../auditNote/AuditNoteList";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import SalesInvoiceDialogue from "../../sales/SalesInvoiceDialogue";
import "./ImportSalesInvoices.scss";
import ImportSalesVoucherItem from "./ImportSalesVoucherItem";
export default class ImportSalesVoucher extends Component {
  /* References Used */
  itemReferenceList = [];
  billingAddressRef = null;
  shippingAddressRef = null;
  referenceInvoiceDialogueRef = React.createRef();
  menuWithBreadScrumRef = null;
  auditNoteListRef = null;
  maxDate = new Date().getMonth() + 1;
  minDate = new Date().getFullYear() - 1;

  /* Data Used from Store */
  header = store.getState().header.header;
  currencyList = store.getState().staticData.currencyList;
  countryList = store.getState().staticData.countryList;
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  exportInvoiceTypeList = store.getState().appData.expInvoiceTypeList;
  sezInvoiceTypeList = store.getState().appData.sezInvoiceTypeList;
  /* Data Passed from Parent Component */
  customerList = [];
  businessUnitList = store.getState().organization.organization.businessUnits;

  productList = [];
  custHsnCodeList = [];
  privilegeList = [];
  yesNoList = ["No", "Yes"];
  /* URL for Payment Transaction Component */
  addPaymentTransactionServiceUrl = "/salespaymenttxms/create";
  updatePaymentTransactionServiceUrl = "/salespaymenttxms/update";
  deletePaymentTransactionServiceUrl = "/salespaymenttxms/delete";
  customerAdvanceVoucherList = [];
  title = "Sales Invoice Import";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let invoiceId = props.invoiceId;
    let action = props.action;
    let isHistory = props.source && props.source === "history" ? true : false;

    /* Set Data From history */
    if (props.history.location.state) {
    }
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      invoiceId = props.history.location.state.invoiceId;
      action = props.history.location.state.action;
      this.customerList = props.history.location.state.customerList;
      this.productList = props.history.location.state.productList;
      this.custHsnCodeList = props.history.location.state.custHsnCodeList;
      // this.businessUnitList = props.history.location.state.businessUnitList;
      this.breadCrumb = props.history.location.state.breadCrumb;
      isHistory = props.history.location.state.isHistory
        ? props.history.location.state.isHistory
        : false;
    }

    /* Set Empty SalesInvoice Data */
    let emptyInvoice = SalesUtils.getEmptyInvoice();
    emptyInvoice.id = invoiceId;
    emptyInvoice.action = action;
    emptyInvoice.render = false;
    emptyInvoice.advanceNumber = null;
    this.state = emptyInvoice;
    this.state.isHistory = isHistory;
    this.state.isimported = true;
    this.state.bu =
      this.businessUnitList && this.businessUnitList.length > 0
        ? this.businessUnitList[0]
        : null;
    // this.state.invoicetypeVo = {
    //   type: 'SA',
    // };
    /*If Create Operation then Show Form Directly */
    if ("create" === action) {
      let year = "";
      let month = "";
      if (Utils.isNotNullAndEmpty(emptyInvoice.invoicedate)) {
        let date = new Date(emptyInvoice.invoicedate, "yyyy-MM-dd");
        year = date.split("-")[0];
        month = Utils.getMonth(date.split("-")[1]);
      }
      this.state.year = year;
      this.state.month = month;
      this.state.render = true;
      let item = SalesUtils.createEmptyInvoiceItem();
      this.state.invoiceItems = [];
      this.state.invoiceItems.push(item);
    } else {
      /* For Edit and View get data */
      this.state.render = false;
      this.getImportSalesVoucherFromBackend(invoiceId);
    }
  }

  editButtonClicked() {
    this.props.history.push({
      pathname: "/importSalesVoucherEdit",
      state: {
        invoiceId: this.state.id,
        action: "edit",
        customerList: this.customerList,
        productList: this.productList,
        businessUnitList: this.businessUnitList,
        custHsnCodeList: this.custHsnCodeList,
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
    // this.setState({ action: 'edit' });
  }
  deleteButtonClicked() {
    this.deleteImportSalesVoucherInBackend();
  }

  /* Set Invoice Data to Component State */
  setResponseToState(invoice) {
    if (Utils.isNull(invoice.privileges)) {
      invoice.privileges = this.state.privileges;
    }
    if (invoice.invoicedate) {
      try {
        let dat = new Date(invoice.invoicedate);
        let todate = dat.getDate();
        if (todate < 10) {
          todate = "0" + todate;
        }
        let tomonth = dat.getMonth() + 1;
        if (tomonth < 10) {
          tomonth = "0" + tomonth;
        }
        let toyear = dat.getFullYear();
        let original_date = toyear + "-" + tomonth + "-" + todate;
        invoice.invoicedate = original_date;
      } catch (e) {
        invoice.invoicedate = new Date(invoice.invoicedate);
      }
    }
    this.setState({
      advanceAmountUsed: invoice.advanceAmountUsed,
      advanceGstUsed: invoice.advanceGstUsed,
      auditnotes: invoice.auditnotes,
      bankaccount: invoice.bankaccount,
      barcode: invoice.barcode,
      barcodeImage: invoice.barcodeImage,
      billtoaddress: invoice.billtoaddress,
      shiptoaddress: invoice.shiptoaddress,
      bu: invoice.bu,
      cdnreason: invoice.cdnreason,
      cess: invoice.cess,
      cesspercent: invoice.cesspercent,
      cgst: invoice.cgst,
      createdon: invoice.createdon,
      currency: invoice.currency,
      customerVo: invoice.customerVo,
      description: invoice.description,
      electronicreference: invoice.electronicreference,
      etin: invoice.etin,
      exchangerate: invoice.exchangerate,
      expinvoicetype: invoice.expinvoicetype,
      freez: invoice.freez,
      fright: invoice.fright,
      gstdate: invoice.gstdate,
      id: invoice.id,
      igst: invoice.igst,
      invnumber: invoice.invnumber,
      invoicedate: invoice.invoicedate,
      invoicesource: invoice.invoicesource,
      invoiceStatusList: invoice.invoiceStatusList,
      isAsset: invoice.isAsset,
      isbillingshippingsame: invoice.isbillingshippingsame,
      isexport: invoice.isexport,
      isimported: invoice.isimported,
      ismanualrefinv: invoice.ismanualrefinv,
      isnilrated: invoice.isnilrated,
      ispregst: invoice.ispregst,
      isrcmsale: invoice.isrcmsale,
      isRecurring: invoice.isRecurring,
      isroundup: invoice.isroundup,
      issez: invoice.issez,
      isSystemGenerated: invoice.isSystemGenerated,
      loadingpackaging: invoice.loadingpackaging,
      manualrefinv: invoice.manualrefinv,
      miscellaneous: invoice.miscellaneous,
      month: invoice.month,
      payment: invoice.payment,
      privileges: invoice.privileges,
      purchasedate: invoice.purchasedate,
      purchaseorder: invoice.purchaseorder,
      referencedInvoice: invoice.referencedInvoice,
      referenceinvnumber: invoice.referenceinvnumber,
      referencingInvoices: invoice.referencingInvoices,
      refinvoice: invoice.refinvoice,
      salesthrecom: invoice.salesthrecom,
      satype: invoice.satype,
      sezinvoicetype: invoice.sezinvoicetype,
      sgst: invoice.sgst,
      sharewithcust: invoice.sharewithcust,
      supplytype: invoice.supplytype,
      status: invoice.status,
      totaladvance: invoice.totaladvance,
      totalamount: invoice.totalamount,
      totalgst: invoice.totalgst,
      totalinwords: invoice.totalinwords,
      totaltaxable: invoice.totaltaxable,
      totaltaxableinwords: invoice.totaltaxableinwords,
      utgst: invoice.utgst,
      year: invoice.year,
      invoiceItems: invoice.invoiceItems,
      invoicetypeVo: invoice.invoicetypeVo,
      render: true,
    });
    this.getMenuIconsAsPerStatusAndPermission();
    if (this.auditNoteListRef && this.auditNoteListRef.current) {
      this.auditNoteListRef.current.setState({
        auditNoteList: this.state.auditnotes,
      });
    }
  }

  /*Handlers for each Element on Component */
  handleModelOpen() {
    this.setState({ isModelOpen: true });
  }

  handleModelClose() {
    this.setState({ isModelOpen: false });
  }
  setMonthYear(invdate) {
    let date = new Date(invdate);
    let year = date.getFullYear();
    let month = Utils.getMonth(date.getMonth() + 1);
    this.setState({ month: month, year: year });
  }
  handleImportSalesVoucherDateChange(e) {
    let invdate = format(new Date(e.target.value), "yyyy-MM");
    this.setMonthYear(invdate);
    this.setState({ invoicedate: e.target.value, gstdate: e.target.value });
  }

  handlePODateChange(e) {
    this.setState({ purchasedate: e.target.value });
  }
  handleGstDateChange(e) {
    this.setState({ gstdate: e.target.value });
  }
  handleImportSalesVoucherDateBlurChange(e) {
    this.setExchangeRate(this.state.currency);
  }
  handleMiscellaneousChange(e) {
    this.setState({ miscellaneous: e.target.value });
  }
  handleMiscellaneousBlurChange(e) {
    this.doCalculations();
  }

  handleEtinChange(e) {
    this.setState({ etin: e.target.value });
  }

  validateGSTN(gstn, elementname, elementlabel) {
    if (null === gstn || gstn.length <= 0) {
      document.getElementById(elementname).focus();
      PtsAlert.error("Enter " + elementlabel);
      return false;
    } else if (gstn.length < 15) {
      document.getElementById(elementname).focus();
      PtsAlert.error(elementlabel + " Must be 15 Characters long");
      return false;
    } else {
      let gstnPattern =
        "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
      let patternArray = gstn.match(gstnPattern);
      if (patternArray === null) {
        PtsAlert.error(
          "Enter Valid " +
          elementlabel +
          " No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
        );
        return false;
      }
      return true;
    }
  }
  getETINDetails(e) {
    let etin = this.state.etin;
    if (this.validateGSTN(etin, "etin", "ETIN")) {
      /*Call Service to Get ETIN Details */
    } else {
    }
  }
  handleImportSalesVoucherNumberChange(e) {
    this.setState({ invnumber: e.target.value });
  }
  handleImportSalesVoucherparseFloatOnBlur(e) {
    this.setState({ invnumber: e.target.value });
  }

  handleIsSEZChange(e) {
    if (e.target.checked) {
      this.setState({ issez: "Yes" });
    } else {
      this.setState({ issez: "No" });
    }
  }
  handleSalesThroughECOMChange(e) {
    this.setState({ salesthrecom: e.target.checked });
  }
  handleIsAdvanceReceivedChange(e) {
    if (
      Utils.isNull(this.state.customerVo) ||
      Utils.isNull(this.state.customerVo.id)
    ) {
      PtsAlert.error("Please Select Customer");
      document.getElementById("customer").focus();
      // this.setState({ isAdvanceReceived: e.target.checked });
      return;
    }
    if (e.target.checked === true) {
      /*Get Advance Voucher for Customer*/
      let postObject = {
        customerVo: {
          id: this.state.customerVo.id,
        },
        header: this.header,
      };
      this.setState({ isAdvanceReceived: e.target.checked });
      FetchServerData.callPostService(
        "/customerms/getCustReceipts",
        postObject
      ).then((output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          this.customerAdvanceVoucherList = output.data.invoices;
          this.setState({ render: true });
        } else {
        }
      });
    } else {
      this.customerAdvanceVoucherList = [];
      this.setState({ isAdvanceReceived: e.target.checked, refinvoice: null });
    }
  }
  handleAssetChange(e) {
    this.setState({ isAsset: e.target.checked });
  }
  handleIsNillRatedChange(e) {
    if (e.target.checked) {
      this.setState({ isnilrated: "Yes" });
    } else {
      this.setState({ isnilrated: "No" });
    }
  }

  handleBillingShippingSame(e) {
    if (e.target.checked) {
      this.setState({ isbillingshippingsame: "Yes" });
    } else {
      this.setState({ isbillingshippingsame: "No" });
    }
  }
  handleShareWithCustomerChange(e) {
    this.setState({ sharewithcust: e.target.checked });
  }
  handleIsSupplierChange(e) {
    let supplier = this.state.supplier;
    if (e.target.checked === false) {
      supplier = null;
    }
    this.setState({ isSupplier: e.target.checked, supplier: supplier });
  }
  handleIsRecurringChange(e) {
    this.setState({ isRecurring: e.target.checked });
  }

  handleRemarkChange(e) {
    this.setState({ description: e.target.value });
  }

  handleExchangeRateChange(e) {
    this.setState({ exchangerate: e.target.value });
  }

  handlePurchaseOrderChange(e) {
    this.setState({ purchaseorder: e.target.value });
  }

  addAuditNote(e) {
    this.setState({ auditNote: e.target.value });
  }
  updateCustomer(e, customer) {
    if (Utils.isNotNull(customer.addressvo)) {
      this.setState({
        customerVo: customer,
        billtoaddress: customer.addressvo,
      });
      if (
        Utils.isNotNull(customer.addressvo.statevo) &&
        Utils.isNotNull(customer.addressvo.statevo.countryvo)
      ) {
        this.setState({
          currency: customer.addressvo.statevo.countryvo.currency,
        });
        this.setExchangeRateForCustomer(customer);
      }
    } else {
      this.setState({ customerVo: customer });
    }
  }

  updateBusinessUnit(e, bu) {
    this.setState({ bu: bu });
  }

  updateAdvanceVoucher(e, av) {
    this.setState({ advanceNumber: av });
  }
  updateReferenceAdvanceVoucher(e, voucher) {
    this.setState({
      refinvoice: voucher,
      referenceinvnumber: voucher.invnumber,
    });
  }
  updateSezInvoiceType(e, seztype) {
    this.setState({ sezinvoicetype: seztype });
  }
  setExchangeRate(currency) {
    if (Utils.isNull(currency)) {
      return;
    }
    if (
      Utils.isExchangeRateApplicableForCurrency(currency, this.defaultCurrency)
    ) {
      this.setState({ isexport: true });
    } else {
      this.setState({ exchangerate: 1, isexport: false });
      return;
    }
    let base = this.defaultCurrency.isocode;
    let invoiceDate = this.state.invoicedate;
    if (
      !Utils.isNotNullAndEmpty(currency) ||
      !Utils.isNotNullAndEmpty(invoiceDate)
    ) {
      return;
    }
    if (!Utils.isSupportedCurrency(currency.isocode)) {
      PtsAlert.warn('Currency "' + currency.name + '" Not Supported');
      return;
    }

    let postObject = {
      targetCurrency: currency.isocode,
      currencyRate: {
        basecurrency: base,
        date: invoiceDate,
      },
      header: this.header,
    };

    FetchServerData.callPostService(
      "/exchangeratems/getExchangeRate",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        // let rates = JSON.parse(output.data.rates);
        let rate = output.data;
        if (Utils.isNotNull(rate.value)) {
          let value = rate.value;
          let exchangeRate = 1 / parseFloat(value);
          this.setState({ exchangerate: exchangeRate });
          return;
        } else {
          this.setState({ exchangerate: "" });
        }
      } else {
      }
    });
  }
  setExchangeRateForCustomer(customer) {
    if (
      Utils.isNull(customer) ||
      Utils.isNull(customer.addressvo) ||
      Utils.isNull(customer.addressvo.statevo) ||
      Utils.isNull(customer.addressvo.statevo.countryvo)
    ) {
      return;
    }
    if (
      Utils.isExchangeRateApplicableForCountry(
        customer.addressvo.statevo.countryvo,
        this.defaultCountry
      )
    ) {
      this.setState({ isexport: true });
    } else {
      this.setState({ exchangerate: 1, isexport: false });
      return;
    }
    let currency = customer.addressvo.statevo.countryvo.currency;
    this.setExchangeRate(currency);
  }

  updateExportType(e, expType) {
    this.setState({ expinvoicetype: expType });
  }
  updateCurrency(e, currency) {
    this.state.currency = currency;
    if (currency.isocode === this.defaultCurrency.isocode) {
    } else {
      this.setExchangeRate(currency);
    }
    this.setState({ currency: currency });
    this.doCalculations();
  }

  doCalculations() {
    let totalAmount = 0.0;
    let totalTaxable = 0.0;
    let totalCgst = 0.0;
    let totalSgst = 0.0;
    let totalIgst = 0.0;
    let totalCess = 0.0;

    for (let i = 0; i < this.itemReferenceList.length; i++) {
      if (this.itemReferenceList[i] && this.itemReferenceList[i].current) {
        let taxable = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.taxable
        );
        let cgst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.cgst
        );
        let sgst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.sgst
        );
        let igst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.igst
        );
        let cess = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.cess
        );
        let total = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.total
        );
        if (cess > 0) {
          let calculatedCess = (parseFloat(taxable) * parseFloat(cess)) / 100;
          totalCess = parseFloat(totalCess) + parseFloat(calculatedCess);
        }
        totalTaxable = parseFloat(totalTaxable) + parseFloat(taxable);
        totalCgst = parseFloat(totalCgst) + parseFloat(cgst);
        totalSgst = parseFloat(totalSgst) + parseFloat(sgst);
        totalIgst = parseFloat(totalIgst) + parseFloat(igst);
        total =
          parseFloat(taxable) +
          parseFloat(cgst) +
          parseFloat(sgst) +
          parseFloat(igst) +
          parseFloat(totalCess);
        totalAmount = parseFloat(totalAmount) + parseFloat(total);
      }
    }
    let miscellaneous = Utils.getFloatValue(this.state.miscellaneous);
    totalAmount = parseFloat(totalAmount) + parseFloat(miscellaneous);

    // Utils.getAmountInWords(totalAmount, this.state.currency).then((output) => {
    //   this.setState({ totalinwords: output });
    // });
    let amountInWords = Utils.getAmountInWords(
      totalAmount,
      this.state.currency
    );

    /* Advance Amount */
    let advanceUsed = 0.0;
    let gstUsed = 0.0;
    if (Utils.isNotNull(this.state.refinvoice)) {
      let remainingAdvanceTaxable =
        parseFloat(this.state.refinvoice.totaltaxable) -
        parseFloat(this.state.refinvoice.utilizedAmount);

      let advanceVoucherGst =
        parseFloat(
          this.state.refinvoice.cgst ? this.state.refinvoice.cgst : 0.0
        ) +
        parseFloat(
          this.state.refinvoice.sgst ? this.state.refinvoice.sgst : 0.0
        ) +
        parseFloat(
          this.state.refinvoice.igst ? this.state.refinvoice.igst : 0.0
        );

      let remainingAdvanceGst =
        parseFloat(advanceVoucherGst) -
        parseFloat(this.state.refinvoice.utilizedGst);

      let currentInvoiceGst =
        parseFloat(totalCgst) + parseFloat(totalSgst) + parseFloat(totalIgst);
      if (parseFloat(totalTaxable) >= parseFloat(remainingAdvanceTaxable)) {
        advanceUsed = parseFloat(remainingAdvanceTaxable);
      } else {
        advanceUsed = parseFloat(totalTaxable);
      }

      if (parseFloat(currentInvoiceGst) >= parseFloat(remainingAdvanceGst)) {
        gstUsed = parseFloat(remainingAdvanceGst);
      } else {
        gstUsed = parseFloat(currentInvoiceGst);
      }
      // advanceUsed = parseFloat(this.state.totaltaxable) - parseFloat(remainingAdvanceTaxable);
      // gstUsed = parseFloat(currentInvoiceGst) - parseFloat(remainingAdvanceGst);
      //let remainingAdvanceGst = parseFloat(advanceVoucherGst) - parseFloat(remainingAdvanceGst);
    }

    this.setState({
      totaltaxable: parseFloat(totalTaxable).toFixed(2),
      sgst: parseFloat(totalSgst).toFixed(2),
      cgst: parseFloat(totalCgst).toFixed(2),
      igst: parseFloat(totalIgst).toFixed(2),
      cess: parseFloat(totalCess).toFixed(2),
      totalamount: parseFloat(totalAmount).toFixed(2),
      totalinwords: amountInWords,
      advanceAmountUsed: advanceUsed,
      advanceGstUsed: gstUsed,
    });
  }

  invoiceItemChanged(item) {
    this.doCalculations();
  }
  invoiceItemDeleted(item) {
    if (!item.operation || "update" === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index - 1;
      let items = [];
      for (let i = 0; i < this.state.invoiceItems.length; i++) {
        let item = this.state.invoiceItems[i];
        if (index === i) {
          item.operation = "delete";
        }
        items.push(item);
      }
      this.setState({ invoiceItems: items });
    }
    if ("add" === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index - 1;
      let items = [];
      for (let i = 0; i < this.state.invoiceItems.length; i++) {
        if (index === i) {
          this.itemReferenceList.splice(i, 1);
          continue;
        }
        items.push(this.state.invoiceItems[i]);
      }
      this.setState({ invoiceItems: items });
    }
  }

  createAddressObject(address) {
    let addressvo = {
      id: address.id,
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      pin: address.pincode,
      statevo: {
        id: address.state.id,
        name: address.state.name,
        code: address.state.code,
        countryvo: {
          id: address.country.id,
          name: address.country.name,
          code: address.country.code,
        },
      },
    };

    return addressvo;
  }
  /* Validator */
  validateImportSalesVoucher(e) {
    e.preventDefault();
    /* Validate Items */
    for (let k = 0; k < this.state.invoiceItems.length; k++) {
      if (this.itemReferenceList[k] && this.itemReferenceList[k].current) {
        /* Set latest Values to Object */
        let currentItem = SalesUtils.createInvoiceItem(
          this.itemReferenceList[k].current.state
        );
        this.state.invoiceItems[k] = currentItem;
        if (!this.itemReferenceList[k].current.validateItem()) {
          return false;
        }
      }
    }
    let isBillingShippingSame = this.state.isbillingshippingsame;
    if (Utils.equalsIgnoreCase(isBillingShippingSame, "No")) {
      if (this.shippingAddressRef && this.shippingAddressRef.current) {
        /*Validate Shipping Address */
        if (!this.shippingAddressRef.current.validateAddress()) {
          return false;
        } else {
          let shippingAddress = this.createAddressObject(
            this.shippingAddressRef.current.state
          );
          this.state.shiptoaddress = shippingAddress;
        }
      } else {
        PtsAlert.error("Shipping Address: not provided");
        return;
      }
    }

    if (Utils.isNull(this.state.invoicedate)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Sales Invoice Date is required");
      return false;
    } else {
      let currentDate = new Date();
      let invDate = new Date(this.state.invoicedate);
      let Difference_In_Time = currentDate.getTime() - invDate.getTime();
      // To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 540) {
        PtsAlert.error("Sales Invoice Date is too old");
        return false;
      }

      Difference_In_Time = invDate.getTime() - currentDate.getTime();
      // To calculate the no. of days between two dates
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 31) {
        PtsAlert.error("Future Sales Invoice Date");
        return false;
      }
    }

    if (Utils.isNull(this.state.gstdate)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Sales Invoice GST Date is required");
      return false;
    }
    if (Utils.isNull(this.state.invnumber)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Sales Invoice Number is required");
      return false;
    }

    if (Utils.isNull(this.state.bu)) {
      PtsAlert.error("Please Select Business Unit ");
      return false;
    }

    if (
      Utils.isNotNull(this.state.isAdvanceReceived) &&
      this.state.isAdvanceReceived
    ) {
      if (
        Utils.isNull(this.state.advanceNumber) ||
        Utils.isNull(this.state.refinvoice)
      ) {
        // document.getElementById('advanceReference').focus();
        PtsAlert.error("Please Select Reference Advance Voucher");
        return false;
      }
    }
    return true;
  }

  /* Backend Call to Populate Advance Voucher */
  populateAdavnceVoucher(e) {
    if (
      Utils.isNull(this.state.advanceNumber) ||
      Utils.isNull(this.state.advanceNumber.id)
    ) {
      return;
    }
    const postObject = {
      // invoice: { id: this.state.advanceNumber.id },
      invoiceid: this.state.advanceNumber.id,
      header: this.header,
    };
    FetchServerData.callPostService("/rems/get", postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        let receipt = output.data;
        let newItemList = [];
        this.setState({ invoiceItems: [] });
        for (let i = 0; i < receipt.invoiceItems.length; i++) {
          let item = receipt.invoiceItems[i];
          item.id = null;
          item.quantity = 1;
          item.rate = item.taxable;
          item.operation = "add";
          newItemList.push(item);
          // this.itemReferenceList.push(React.createRef());
        }
        this.setState({
          refinvoice: receipt,
          invoiceItems: newItemList,
          referenceinvnumber: receipt.invnumber,
        });
        this.doCalculations();
      } else {
      }
    });
  }
  /* Backend Calls to CREATE/GET/UPDATE/DELETE SalesInvoice */
  deleteImportSalesVoucherInBackend() {

    const postObject = {
      invoiceid: this.state.id,
      header: this.header,
    };

    FetchServerData.callPostService("/impexp/delete", postObject).then(
      (output) => {

        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "Imported Sales Invoice " + this.state.invnumber + "Deleted "
          );
          this.props.history.push({
            pathname: "/sa/imp/",
            state: {
              invoiceId: this.state.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  }

  importSalesVoucherIntoMain(id) {
    const postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/impexp/imp", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          // let invoice = output.data;
          this.cancelView();
        } else {
        }
      }
    );
  }

  getImportSalesVoucherFromBackend(id) {
    const postObject = {
      invoiceid: id,
      header: this.header,
    };
    FetchServerData.callPostService("/impexp/get", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          if (Utils.isNotNull(invoice.refinvoice)) {
            let advanceNumber = {
              id: invoice.refinvoice.id,
              invoiceNumber: invoice.refinvoice.invnumber,
            };

            this.setState({
              isAdvanceReceived: true,
              advanceNumber: advanceNumber,
            });
          }

          /*Set Business Unit*/
          let bu = this.state.bu;
          if (Utils.isNullOrEmpty(bu)) {
            bu =
              this.businessUnitList && this.businessUnitList.length > 0
                ? this.businessUnitList[0]
                : null;
          }
          invoice.bu = bu;
          for (let i = 0; i < invoice.invoiceItems.length; i++) {
            // let ref = React.createRef();
            // this.itemReferenceList.push(ref);
            invoice.invoiceItems[i].operation = "update";
          }

          this.privilegeList = invoice.privileges;

          // this.shippingAddressRef = React.createRef;
          // this.billingAddressRef = React.createRef;
          this.setResponseToState(invoice);
          /*Set Title */
          if (invoice.invoicetypeVo) {
            this.title = invoice.invoicetypeVo.description;
            if (
              this.menuWithBreadScrumRef &&
              this.menuWithBreadScrumRef.current
            ) {
              // this.menuWithBreadScrumRef.current.state.name = this.title;
              this.menuWithBreadScrumRef.current.setState({
                name: this.title,
                addToBreadCrumb: this.title,
              });
            }
          }
        } else {
        }
      }
    );
  }

  updateImportSalesVoucherInBackend(e) {
    if (!this.validateImportSalesVoucher(e)) {
      return;
    }

    let invoiceData = {};
    invoiceData = SalesUtils.setImportInvoice(invoiceData, this.state);
    invoiceData.isAdvanceReceived = this.state.isAdvanceReceived;
    const postObject = {
      invoice: invoiceData,
      header: this.header,
    };

    FetchServerData.callPostService("/impexp/update", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success("Updated Successfully");
          this.props.history.push({
            pathname: "/importSalesVoucher",
            state: {
              invoiceId: this.state.id,
              action: "view",
              customerList: this.customerList,
              productList: this.productList,
              businessUnitList: this.businessUnitList,
              custHsnCodeList: this.custHsnCodeList,
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
        } else {
        }
      }
    );
  }

  createImportSalesVoucherInBackend(e) {
    e.preventDefault();
    if (!this.validateImportSalesVoucher(e)) {
      return;
    }
    let invoiceData = {};
    invoiceData = SalesUtils.setInvoice(invoiceData, this.state);

    const postObject = {
      invoice: invoiceData,
      header: this.header,
    };

    FetchServerData.callPostService("/impexp/create", postObject).then(
      (output) => {

        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          for (let i = 0; i < invoice.invoiceItems.length; i++) {
            invoice.invoiceItems[i].operation = "update";
          }
          this.setResponseToState(invoice);
          this.props.history.push({
            pathname: "/importSalesVoucher",
            state: {
              invoiceId: this.state.id,
              action: "view",
              customerList: this.customerList,
              productList: this.productList,
              businessUnitList: this.businessUnitList,
              custHsnCodeList: this.custHsnCodeList,
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
        } else {
        }
      }
    );
  }

  /* Go Back Functionality Implementation */
  cancelEdit(e) {
    // this.setState({ action: 'view', render: false });
    // this.getImportSalesVoucherFromBackend(this.state.id);
    // this.setState({ action: 'view' });
    this.props.history.push({
      pathname: "/importSalesVoucher",
      state: {
        invoiceId: this.state.id,
        action: "view",
        customerList: this.customerList,
        productList: this.productList,
        businessUnitList: this.businessUnitList,
        custHsnCodeList: this.custHsnCodeList,
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/sa/imp/",
      state: { breadCrumb: this.breadCrumb },
    });
  }

  /* Get Bttons Based on SalesInvoice Status */
  getButtonsAsPerStatusAndPermission() {
    // let status = this.state.status && this.state.status.status ? this.state.status.status : null;
    // if (null === status) {

    if (
      this.state.action &&
      Utils.equalsIgnoreCase(this.state.action, "view")
    ) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowUpwardIcon />}
            onClick={(e) => this.importSalesVoucherIntoMain(e)}
          >
            Import
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    }

    // }
  }

  /* Get Menu Buttons based on Invoice Status and Permissions */
  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];

    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }
    if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      buttonList = [...buttonList, "EDIT"]
    }
    if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      buttonList = [...buttonList, "DELETE"]
    }
    // if (this.state.status) {
    //   if (Utils.equalsIgnoreCase(this.state.status.status, "CREATED")) {
    //     if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
    //       buttonList = [...buttonList, "EDIT"]
    //     }
    //     if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
    //       buttonList = [...buttonList, "DELETE"]
    //     }
    //   } else if (
    //     Utils.equalsIgnoreCase(
    //       this.state.status.status,
    //       "VERIFICATIONREQUESTED"
    //     )
    //   ) {
    //     // buttonList = ['PRINT'];
    //   } else if (
    //     Utils.equalsIgnoreCase(this.state.status.status, "VERIFICATIONREJECTED")
    //   ) {
    //     // buttonList = ['PRINT'];
    //   } else {
    //     // buttonList = ['PRINT'];
    //   }
    // }
    // if (
    //   Utils.hasPrivilege("UPDATE", this.state.privileges) &&
    //   Utils.hasPrivilege("DELETE", this.state.privileges)
    // ) {
    // } else if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
    //   for (let i = 0; i < buttonList.length; i++) {
    //     if (buttonList[i] === "DELETE") {
    //       buttonList.splice(i, 1);
    //     }
    //   }
    // } else if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
    //   for (let i = 0; i < buttonList.length; i++) {
    //     if (buttonList[i] === "UPDATE") {
    //       buttonList.splice(i, 1);
    //     }
    //   }
    // }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      let printCallback = null;

      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      if (buttonList.includes("PRINT")) {
        printCallback = this.printButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
        printCallback: printCallback,
      });
    }
  }

  getAdvanceVoucherDetails() {
    if (
      true === this.state.isAdvanceReceived &&
      Utils.isNotNullAndEmpty(this.state.refinvoice)
    ) {
      return (
        <>
          <Row md={12} style={{ padding: "5px", margin: "0" }}>
            <Col
              md={12}
              style={{
                background: "#c5dde4",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
              }}
            >
              Advance Voucher Details
            </Col>
          </Row>

          <Row md={12} style={{ padding: "5px", margin: "0" }}>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Advance Amt.
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              CESS %
            </Col>

            {Utils.isIgstApplicable(this.state.customerVo) !== true && (
              <>
                <Col
                  md={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  CGST
                </Col>
                <Col
                  md={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  SGST
                </Col>
              </>
            )}

            {Utils.isIgstApplicable(this.state.customerVo) !== false && (
              <Col
                md={2}
                style={{
                  background: "#ececec",
                  color: "#000",
                  fontWeight: "bold",
                  border: "0.5px solid #ece6e6",
                  padding: "0.5em",
                  textAlign: "right",
                }}
              >
                IGST
              </Col>
            )}

            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Total
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Utilized GST
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Utilized Taxable
            </Col>
          </Row>
          <Row md={12} style={{ padding: "5px", margin: "0" }}>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.totaltaxable}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.cess}
            </Col>

            {Utils.isIgstApplicable(this.state.customerVo) !== true && (
              <>
                <Col
                  md={1}
                  style={{
                    border: "0.5px solid #ece6e6",
                    textAlign: "right",
                    color: "#000",
                  }}
                >
                  {this.state.refinvoice.cgst}
                </Col>
                <Col
                  md={1}
                  style={{
                    border: "0.5px solid #ece6e6",
                    textAlign: "right",
                    color: "#000",
                  }}
                >
                  {this.state.refinvoice.sgst}
                </Col>
              </>
            )}

            {Utils.isIgstApplicable(this.state.customerVo) !== false && (
              <Col
                md={2}
                style={{
                  border: "0.5px solid #ece6e6",
                  textAlign: "right",
                  color: "#000",
                }}
              >
                {this.state.refinvoice.igst}
              </Col>
            )}

            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.totalamount}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.utilizedGst}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.utilizedAmount}
            </Col>
          </Row>
        </>
      );
    } else {
      return "";
    }
  }

  /*Main Render Function */
  render() {
    if (this.state.render === false) {
      return "";
    }
    /* Create References for Calculations */
    this.billingAddressRef = React.createRef();
    this.shippingAddressRef = React.createRef();
    this.menuWithBreadScrumRef = React.createRef();
    this.auditNoteListRef = React.createRef();
    this.state.invoiceItems &&
      this.state.invoiceItems.forEach((element, index) => {
        this.itemReferenceList[index] = React.createRef();
      });
    if (this.state.action === "edit") {
      return this.editImportSalesVoucher();
    } else {
      return this.viewImportSalesVoucher();
    }
  }

  editImportSalesVoucher() {
    let invoiceItemsList = [];
    /* Create List of SalesInvoice Item */
    let isIgstApplicable = Utils.isIgstApplicable(this.state.customerVo);

    this.state.invoiceItems &&
      this.state.invoiceItems.forEach((element, index) => {
        if ("delete" !== element.operation) {
          invoiceItemsList.push(
            <ImportSalesVoucherItem
              isIgstApplicable={isIgstApplicable}
              ref={this.itemReferenceList[index]}
              key={index}
              data={element}
              srno={index}
              action="edit"
              productList={this.productList}
              custHsnCodeList={this.custHsnCodeList}
              doCalculations={this.doCalculations.bind(this)}
              onDelete={this.invoiceItemDeleted.bind(this)}
              onChange={this.invoiceItemChanged.bind(this)}
            />
          );
        }
      });

    let billingAddress = (
      <Address
        ref={this.billingAddressRef}
        title="Billing Address"
        data={this.state.billtoaddress}
        action="view"
      />
    );

    let shippingAddress = "";
    if (Utils.equalsIgnoreCase(this.state.isbillingshippingsame, "No")) {
      shippingAddress = (
        <Address
          ref={this.shippingAddressRef}
          title="Shipping Address"
          data={this.state.shiptoaddress}
          action="create"
        />
      );
    }

    /* Check if Currency Different than Default Currency */
    let isExchangeRateMandator = false;
    if (
      undefined !== this.state.customerVo &&
      this.state.customerVo &&
      this.state.customerVo.addressvo
    ) {
      let address = this.state.customerVo.addressvo;
      if (
        (isExchangeRateMandator = Utils.isExchangeRateApplicableForCountry(
          address.statevo.countryvo,
          this.defaultCountry
        ))
      ) {
        this.state.exchangeRate = 1;
        isExchangeRateMandator = true;
      }
    }

    return (
      <>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Edit Import Sales Voucher"
          history={this.props.history}
          name="Edit Import Sales Voucher"
        />

        <ValidatorForm
          style={{ width: "100%", color: "#000", padding: "0.5em" }}
          ref="form"
          onSubmit={this.updateImportSalesVoucherInBackend.bind(this)}
        >
          <Card md={12}>
            <CardHeader style={{ margin: "0px", padding: "0.5em" }}>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Customer<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    disableClearable
                    disabled
                    ListboxProps={{
                      className: "myCustomList"
                    }}
                    id="customer"
                    options={
                      undefined === this.customerList ||
                        null === this.customerList
                        ? []
                        : this.customerList
                    }
                    value={this.state.customerVo}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateCustomer(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="outlined"
                        fullWidth={true}
                        {...params}
                        placeholder="Customer"
                        InputLabelProps={{ shrink: true }}
                        className="regCountry"
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Invoice Date<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    required
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    id="invoicedate"
                    onChange={this.handleImportSalesVoucherDateChange.bind(
                      this
                    )}
                    onBlur={this.handleImportSalesVoucherDateBlurChange.bind(
                      this
                    )}
                    value={this.state.invoicedate}
                    placeholder="Invoice Date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Invoice Number<span className="asterisk"> *</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      fullWidth={true}
                      variant="outlined"
                      inputProps={{ style: {} }}
                      id="invnumber"
                      type="text"
                      placeholder="Invoice Number"
                      validators={["required"]}
                      onChange={this.handleImportSalesVoucherNumberChange.bind(
                        this
                      )}
                      value={this.state.invnumber ? this.state.invnumber : ""}
                      name="invnumber"
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Remark
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    inputProps={{ style: {} }}
                    id="invoicenote"
                    type="text"
                    placeholder="Remark"
                    validators={["required"]}
                    onChange={this.handleRemarkChange.bind(this)}
                    // onBlur={this.handleNoteOnBlur.bind(this)}
                    value={this.state.description ? this.state.description : ""}
                    name="invoicenote"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Business Unit<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    // className="invoiceAutoComplete"
                    // style={{ padding: '0.5em' }}
                    disableClearable
                    id="businessUnit"
                    ListboxProps={{
                      className: "myCustomList"
                    }}
                    options={this.businessUnitList ? this.businessUnitList : []}
                    value={this.state.bu ? this.state.bu : ""}
                    defaultValue={this.state.bu}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        variant="outlined"
                        placeholder="Business Unit"
                        InputLabelProps={{ shrink: true }}
                        className="regCountry"
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{ color: "#000", fontSize: "small !important" }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isnilrated"
                        //checked={Utils.equalsIgnoreCase(this.state.isnilrated,'Yes')? true : false}
                        value={
                          Utils.equalsIgnoreCase(this.state.isnilrated, "Yes")
                            ? true
                            : false
                        }
                        onChange={this.handleIsNillRatedChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Nil Rated?</span>}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Purhcase Order
                  </label>
                  <TextField
                    variant="outlined"
                    inputProps={{ style: {} }}
                    fullWidth
                    id="po"
                    type="text"
                    placeholder="Purhcase Order"
                    onChange={this.handlePurchaseOrderChange.bind(this)}
                    // onBlur={this.handleGSTNOnBlur.bind(this)}
                    value={
                      this.state.purchaseorder ? this.state.purchaseorder : ""
                    }
                    name="purchaseorder"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Purchase Date
                  </label>
                  <TextField
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    id="podate"
                    onChange={this.handlePODateChange.bind(this)}
                    value={
                      this.state.purchasedate ? this.state.purchasedate : " "
                    }
                    placeholder="Purchase Date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    GST Date<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    required
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    onChange={this.handleGstDateChange.bind(this)}
                    value={this.state.gstdate ? this.state.gstdate : ""}
                    placeholder="GST Date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                {isExchangeRateMandator === true && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Currency<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        ListboxProps={{
                          className: "myCustomList"
                        }}
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="currency"
                        options={this.currencyList ? this.currencyList : []}
                        value={this.state.currency}
                        defaultValue={this.state.currency}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.updateCurrency(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            style={{}}
                            variant="outlined"
                            placeholder="Currency"
                            InputLabelProps={{ shrink: true }}
                            className="regCountry"
                          />
                        )}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Exchange Rate<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        variant="outlined"
                        required
                        inputProps={{ style: {} }}
                        fullWidth
                        id="exchangeRate"
                        type="text"
                        placeholder="Exchange Rate"
                        onChange={this.handleExchangeRateChange.bind(this)}
                        // onBlur={this.handleGSTNOnBlur.bind(this)}
                        value={
                          this.state.exchangerate ? this.state.exchangerate : ""
                        }
                        name="exchangeRate"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Export Type<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        ListboxProps={{
                          className: "myCustomList"
                        }}
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="expinvoicetype"
                        options={
                          this.exportInvoiceTypeList
                            ? this.exportInvoiceTypeList
                            : []
                        }
                        value={this.state.expinvoicetype}
                        defaultValue={this.state.expinvoicetype}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => {
                          this.updateExportType(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            {...params}
                            style={{}}
                            placeholder="Export Type"
                            InputLabelProps={{ shrink: true }}
                            className="regCountry"
                          />
                        )}
                      />
                    </Col>
                  </>
                )}
              </Row>

              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAdvanceReceived"
                        label="Is Advance Received"
                        checked={
                          this.state.isAdvanceReceived
                            ? this.state.isAdvanceReceived
                            : false
                        }
                        value={
                          this.state.isAdvanceReceived
                            ? this.state.isAdvanceReceived
                            : false
                        }
                        onChange={this.handleIsAdvanceReceivedChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Advance Received?</span>}
                  />
                </Col>
                {this.state.isAdvanceReceived &&
                  this.state.isAdvanceReceived === true && (
                    <>
                      <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Advance Voucher<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          // className="invoiceAutoComplete"
                          // style={{ padding: '0.5em' }}
                          disableClearable
                          ListboxProps={{
                            className: "myCustomList"
                          }}
                          id="advanceReference"
                          options={
                            this.customerAdvanceVoucherList
                              ? this.customerAdvanceVoucherList
                              : []
                          }
                          value={
                            this.state.advanceNumber
                              ? this.state.advanceNumber
                              : null
                          }
                          getOptionLabel={(option) => option.invoiceNumber}
                          onChange={(event, value) => {
                            this.updateAdvanceVoucher(event, value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              fullWidth={true}
                              variant="outlined"
                              {...params}
                              placeholder="Advance Voucher"
                              InputLabelProps={{ shrink: true }}
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                      <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                        <Button
                          variant="contained"
                          style={{
                            background: "#1b7189",
                            color: "#fff",
                            marginRight: "1rem",
                          }}
                          startIcon={<SaveIcon />}
                          onClick={(e) => this.populateAdavnceVoucher(e)}
                        >
                          Verify
                        </Button>
                      </Col>
                    </>
                  )}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isSEZDeemedExport"
                        checked={
                          this.state.issez &&
                            Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        value={
                          this.state.issez &&
                            Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        onChange={this.handleIsSEZChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>SEZ/Deemed Export?</span>}
                  />
                </Col>
                {Utils.equalsIgnoreCase(this.state.issez, "Yes") && (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      SEZ Category<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      disableClearable
                      ListboxProps={{
                        className: "myCustomList"
                      }}
                      id="sezCategory"
                      options={this.sezInvoiceTypeList}
                      value={this.state.sezinvoicetype}
                      defaultValue={this.state.sezinvoicetype}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, value) => {
                        this.updateSezInvoiceType(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          variant="outlined"
                          fullWidth={true}
                          {...params}
                          placeholder="SEZ Category"
                          InputLabelProps={{ shrink: true }}
                          className="regCountry"
                        />
                      )}
                    />
                  </Col>
                )}

                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isSalesThroughECOM"
                        checked={
                          this.state.salesthrecom &&
                            this.state.salesthrecom === true
                            ? true
                            : false
                        }
                        value={
                          this.state.salesthrecom &&
                            this.state.salesthrecom === true
                            ? true
                            : false
                        }
                        onChange={this.handleSalesThroughECOMChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>Sales Through ECOM?</span>}
                  />
                </Col>
                {this.state.salesthrecom &&
                  this.state.salesthrecom === true && (
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        ETIN<span className="asterisk"> *</span>
                      </label>
                      <div style={{ display: "flex" }}>
                        <TextField
                          required
                          fullWidth={true}
                          variant="outlined"
                          inputProps={{ style: {} }}
                          id="etin"
                          type="text"
                          placeholder="ETIN"
                          onChange={this.handleEtinChange.bind(this)}
                          value={this.state.etin}
                          name="invnumber"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {/* <GenerateIconButton
      
                                        /> */}
                                <RefreshIcon
                                  className="gstnBtn"
                                  onClick={(e) => this.getETINDetails()}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {/* <IconButton onClick={(e) => this.getETINDetails()}>
                          <RefreshIcon
                            className="gstnBtn"
                            onClick={(e) => this.getETINDetails()}
                          />
                        </IconButton> */}
                      </div>
                    </Col>
                  )}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isAsset}
                        value={this.state.isAsset}
                        onChange={this.handleAssetChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Asset Sale?</span>}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="shareWithCustomer"
                        checked={this.state.sharewithcust}
                        value={this.state.sharewithcust}
                        onChange={this.handleShareWithCustomerChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>Share With Customer?</span>}
                  />
                </Col>
              </Row>
              {this.getAdvanceVoucherDetails()}
              {this.state.customerVo && (
                <>
                  <Row
                    md={12}
                    style={{ alignItems: "center", margin: "0", padding: "0" }}
                  >
                    <Col md={8}></Col>
                    <Col
                      md={4}
                      style={{
                        padding: "0",
                        margin: "0",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControlLabel
                        style={{
                          color: "#000",
                          width: "100%",
                          margin: "0",
                          padding: "0",
                        }}
                        control={
                          <Checkbox
                            variant="outlined"
                            id="shareWithCustomer"
                            checked={
                              Utils.equalsIgnoreCase(
                                this.state.isbillingshippingsame,
                                "Yes"
                              )
                                ? true
                                : false
                            }
                            value={
                              Utils.equalsIgnoreCase(
                                this.state.isbillingshippingsame,
                                "Yes"
                              )
                                ? true
                                : false
                            }
                            onChange={this.handleBillingShippingSame.bind(this)}
                            style={{ color: "#000", background: "none" }}
                          />
                        }
                        label={<span style={{}}>Billing Shipping Same</span>}
                      />
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    style={{
                      overflow: "scroll",
                      display: "flex",
                      margin: "5px",
                    }}
                  >
                    <Col
                      md={4}
                      style={{
                        // paddingLeft: "20px",
                        // margin: "0",
                        // display: "block",
                        border: "0.5px solid white",
                      }}
                    >
                      <Row md={12} style={{ marginLeft: "-0.3em" }}>
                        <label style={{ fontWeight: "600", color: "#1b7189", fontSize: "16px" }}>
                          <b>Customer Details</b>
                        </label>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Customer Name</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.name}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Customer Type</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.customertype.description}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>GSTN</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.gstn}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>PAN</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.pan}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Currency</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.currency ? this.state.currency.name : ""}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={4}
                      style={{
                        paddingLeft: "20px",
                        margin: "0",
                        display: "block",
                        border: "0.5px solid #f3f4f5",
                      }}
                    >
                      {billingAddress}
                    </Col>
                    <Col
                      md={4}
                      style={{
                        paddingLeft: "20px",
                        margin: "0",
                        display: "block",
                        border: "0.5px solid #f3f4f5",
                      }}
                    >
                      {shippingAddress}
                    </Col>
                  </Row>
                </>
              )}
            </CardHeader>
            <CardBody>
              <Row
                md={12}
                style={{ padding: "5px", margin: "0" }}
              >
                <Col
                  md={12}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                  }}
                >
                  Import Sales Invoice Items
                </Col>
              </Row>
              {invoiceItemsList}

              <Row
                md={12}
                style={{ padding: "0px", margin: "0", marginTop: "1em" }}
              >
                <Col
                  md={8}
                  style={{ margin: "0", padding: "1em" }}
                ></Col>
                <Col md={4} style={{ margin: "0", padding: "0" }}>
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Value Of Supply</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {this.state.totaltaxable}
                    </Col>
                  </Row>
                  {Utils.isIgstApplicable(this.state.customerVo) === false && (
                    <>
                      <Row
                        md={12}
                        style={{ padding: "5px", margin: "0" }}
                      >
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            // fontWeight: '600',
                            // //border: '0.5px solid #ece6e6',
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>CGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            //border: '0.5px solid #ece6e6',
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.cgst)}
                        </Col>
                      </Row>
                      <Row
                        md={12}
                        style={{ padding: "5px", margin: "0" }}
                      >
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            // fontWeight: '600',
                            // //border: '0.5px solid #ece6e6',
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>SGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            //border: '0.5px solid #ece6e6',
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.sgst)}
                        </Col>
                      </Row>
                    </>
                  )}
                  {Utils.isIgstApplicable(this.state.customerVo) === true && (
                    <Row
                      md={12}
                      style={{ padding: "5px", margin: "0" }}
                    >
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          // fontWeight: '600',
                          // //border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>IGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          //border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.igst)}
                      </Col>
                    </Row>
                  )}

                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>CESS</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.cess)}
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Misc.(If Any)</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        required
                        type="text"
                        // variant="outlined"
                        id="miscellaneous"
                        onChange={this.handleMiscellaneousChange.bind(this)}
                        onBlur={this.handleMiscellaneousBlurChange.bind(this)}
                        value={this.state.miscellaneous}
                      />
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Total Amount</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {Utils.getFloatValue(this.state.totalamount)}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                md={12}
                style={{ padding: "5px", margin: "0" }}
              >
                <Col
                  md={12}
                  style={{
                    // fontWeight: '600',
                    color: "#000",
                    // //border: '0.5px solid #ece6e6',
                    padding: "0.1em",
                    textAlign: "right",
                  }}
                >
                  {this.state.totalinwords}
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                </Col>
                <Col md={4}>
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0", alignItems: "end" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <span>Advance Used</span>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.advanceAmountUsed)}
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <span>Advance GST Used</span>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.advanceGstUsed)}
                    </Col>
                  </Row>
                </Col>
              </Row>

            </CardBody>
          </Card>
          <CardFooter>
            <Row md={12}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  startIcon={<SaveIcon />}
                // onClick={e => this.formValidation(e)}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  style={{
                    background: "#717373",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  startIcon={<CloseIcon />}
                  onClick={(e) => this.cancelEdit()}
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </CardFooter>
        </ValidatorForm>
        {/* </Row> */}
      </>
    );
  }

  showReferenceInvoice() {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current &&
      this.state.referenceinvnumber
    ) {

      this.referenceInvoiceDialogueRef.current.showInvoice(
        this.state.referenceinvnumber
      );
    }
  }

  getHeader() {
    return (
      <Row md={12} style={{ padding: "5px", margin: "0" }}>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          <span style={{ float: "left" }}>#</span>
          <span style={{ float: "right" }}>SAC/HSN</span>
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          Item Details
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          UOM
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          Qty
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          Unit Price
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Gross Value
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Discount
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Net Value
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          CESS %
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Tax Rate %
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          GST
        </Col>

        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Total
        </Col>
      </Row>
    );
  }
  viewImportSalesVoucher() {
    let invoiceItemsList = [];
    let isIgstApplicable = Utils.isIgstApplicable(this.state.customerVo);

    this.state.invoiceItems &&
      this.state.invoiceItems.forEach((element, index) => {
        invoiceItemsList.push(
          <ImportSalesVoucherItem
            isIgstApplicable={isIgstApplicable}
            key={index}
            data={element}
            srno={index}
            action="view"
            productList={this.productList}
            custHsnCodeList={this.custHsnCodeList}
            doCalculations={this.doCalculations.bind(this)}
            onDelete={this.invoiceItemDeleted.bind(this)}
            onChange={this.invoiceItemChanged.bind(this)}
          />
        );
      });

    let menuWithBreadScrum = (
      <MenuWithBreadScrum
        ref={this.menuWithBreadScrumRef}
        breadCrumb={this.breadCrumb}
        addToBreadCrumb="View Import Sales Voucher"
        history={this.props.history}
        name="View Import Sales Voucher"
        docTitle={this.state.invnumber}
      />
    );

    return (
      <>
        <SalesInvoiceDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
        />

        {menuWithBreadScrum}

        <Card
          md={12}
          style={{ width: "100%", color: "#000", padding: "0.5em" }}
        >
          <CardHeader>
            <Row style={{ width: "100%" }}>
              <Col md={12} style={{ marginLeft: "-0.6em" }}>
                <p
                  style={{
                    color: "#000",
                    width: "40%",
                    margin: "0",
                    padding: "0",
                    textAlign: "left",
                  }}
                >
                  <strong>{this.state.invnumber}</strong>
                </p>
              </Col>
            </Row>
            <Row md={12} style={{ margin: "0", padding: "0", display: "flex" }}>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%" }}>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Customer Name</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.customerVo.name}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Customer Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.customerVo.customertype.description}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>GSTN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.customerVo.gstn}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>PAN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.customerVo.pan}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Share With Customer</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.sharewithcust &&
                        this.state.sharewithcust === true
                        ? "Yes"
                        : "No"}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Invoice Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoicetypeVo
                        ? this.state.invoicetypeVo.description
                        : ""}
                    </label>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Business Unit</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.bu ? this.state.bu.name : ""}
                    </label>
                  </Row>
                  {Utils.isExchangeRateApplicableForCustomer(
                    this.state.customerVo
                  ) && (
                      <Row style={{ width: "100%" }}>
                        <label
                          style={{
                            color: "#000",
                            width: "40%",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Currency Rate</strong>
                        </label>
                        <label
                          style={{
                            width: "60%",
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.exchangerate}
                        </label>
                      </Row>
                    )}
                </div>
              </Col>
              <Col md={4} style={{ display: "flex" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Row style={{ flex: 1 }}>
                    <Address
                      ref={this.billingAddressRef}
                      title="Billing Address"
                      data={this.state.billtoaddress}
                      action="view"
                    />
                  </Row>
                  <Row style={{ flex: 1 }}>
                    <Address
                      ref={this.shippingAddressRef}
                      title="Shipping Address"
                      data={this.state.shiptoaddress}
                      action="view"
                    />
                  </Row>
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%" }}>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Created On</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* { this.state.createdon ? format(new Date(), 'yyyy-MM-dd') : ''} */}
                      {Utils.ptsDateFormat(this.state.createdon)}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Invoice Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.invoicedate} */}
                      {Utils.ptsDateFormat(this.state.invoicedate)}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>GST Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.gstdate} */}
                      {Utils.ptsDateFormat(this.state.gstdate)}
                    </label>
                  </Row>

                  {(this.state.isexport === true ||
                    Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
                      <Row style={{ width: "100%" }}>
                        <label
                          style={{
                            color: "#000",
                            width: "40%",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Is Export</strong>
                        </label>
                        <label
                          style={{
                            width: "60%",
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          Yes
                        </label>
                      </Row>
                    )}

                  {this.state.isAsset === true && (
                    <Row style={{ width: "100%" }}>
                      <label
                        style={{
                          color: "#000",
                          width: "40%",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Asset Sale</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </label>
                    </Row>
                  )}
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Status</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.status ? this.state.status.description : ""}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>PO </strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.purchaseorder ? this.state.purchaseorder : ""}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>PO Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.purchasedate ? this.state.purchasedate : ''} */}
                      {Utils.ptsDateFormat(this.state.purchasedate)}
                    </label>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Reference Invoice</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <Link
                        style={{ cursor: "pointer" }}
                        onClick={this.showReferenceInvoice.bind(this)}
                      >
                        <strong>
                          {this.state.referenceinvnumber
                            ? this.state.referenceinvnumber
                            : ""}
                        </strong>
                      </Link>
                    </label>
                  </Row>

                  {Utils.isNotNullAndEmpty(this.state.refinvoice) && (
                    <Row style={{ width: "100%" }}>
                      <label
                        style={{
                          color: "#000",
                          width: "40%",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Advance Voucher</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.referenceinvnumber
                          ? this.state.referenceinvnumber
                          : ""}
                        {/* <a onClick={this.handleModelOpen.bind(this)}>
                          {undefined !== this.state.referenceinvnumber ? this.state.referenceinvnumber : ''}
                        </a> */}
                      </label>
                      {/* <Modal
                        open={this.state.isModelOpen}
                        onClose={this.handleModelClose.bind(this)}
                        // aria-labelledby="simple-modal-title"
                        // aria-describedby="simple-modal-description"
                      >
                        <h1>Hello World</h1>
                        <AdvanceVoucher action="view" invnumber={this.state.referenceinvnumber} />
                      </Modal> */}
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <table>
              <thead>
                <tr md={12} style={{ padding: "0px", margin: "0" }}>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    <span style={{ float: "left" }}>#</span>
                    <span style={{ float: "right" }}>SAC/HSN</span>
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "20%",
                    }}
                  >
                    Item Details
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    UOM
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "3%",
                    }}
                  >
                    Qty
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "8%",
                    }}
                  >
                    Unit Price
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Gross Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Discount %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Net Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "5%",
                    }}
                  >
                    CESS %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    Tax Rate %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    GST
                  </td>

                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Total
                  </td>
                </tr>
              </thead>
              {invoiceItemsList}
            </table>
            <Row
              md={12}
              style={{ padding: "0px", margin: "0", marginTop: "1em" }}
            >
              <Col
                md={8}
                style={{ margin: "0", padding: "1em" }}
              >
                {this.state.status &&
                  Utils.equalsIgnoreCase(
                    "VERIFICATIONREQUESTED",
                    this.state.status ? this.state.status?.status : ""
                  ) && (
                    <Row md={12} style={{ flexDirection: "column" }}>
                      <TextField
                        id="auditnote"
                        type="text"
                        variant="outlined"
                        placeholder="Audit Note"
                        multiline={true}
                        rows={2}
                        value={
                          this.state.auditNote ? this.state.auditNote : " "
                        }
                        onChange={this.addAuditNote.bind(this)}
                      />
                    </Row>
                  )}
                <Row md={12} style={{ flexDirection: "column" }}>
                  <AuditNoteList
                    ref={this.auditNoteListRef}
                    data={this.state.auditnotes}
                    aid={this.state.id}
                    title="Audit Notes"
                    history={this.props.history}
                    addPaymentTransactionServiceUrl={
                      this.addPaymentTransactionServiceUrl
                    }
                    updatePaymentTransactionServiceUrl={
                      this.updatePaymentTransactionServiceUrl
                    }
                    deletePaymentTransactionServiceUrl={
                      this.deletePaymentTransactionServiceUrl
                    }
                  />
                </Row>
              </Col>
              <Col md={4} style={{ margin: "0", padding: "0" }}>
                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Value Of Supply</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.currency.symbol !== null
                      ? this.state.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.totaltaxable)}
                  </Col>
                </Row>
                {Utils.isIgstApplicable(this.state.customerVo) === false && (
                  <>
                    <Row
                      md={12}
                      style={{ padding: "5px", margin: "0" }}
                    >
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          // fontWeight: '600',
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>CGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.cgst)}
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{ padding: "5px", margin: "0" }}
                    >
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          // fontWeight: '600',
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>SGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.sgst)}
                      </Col>
                    </Row>
                  </>
                )}

                {Utils.isIgstApplicable(this.state.customerVo) === true && (
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>IGST</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.igst)}
                    </Col>
                  </Row>
                )}

                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>CESS</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.cess)}
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Misc.(If Any)</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.miscellaneous)}
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Total Amount</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.currency.symbol !== null
                      ? this.state.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.totalamount)}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              md={12}
              style={{ padding: "5px", margin: "0" }}
            >
              <Col
                md={12}
                style={{
                  color: "#000",
                  // border: '0.5px solid #ece6e6',
                  padding: "0.1em",
                  textAlign: "right",
                }}
              >
                {this.state.totalinwords}
              </Col>
            </Row>
            <Row>
              <Col md={8}>
              </Col>
              <Col md={4}>
                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0", alignItems: "end" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <span>Advance Used</span>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.advanceAmountUsed)}
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <span>Advance GST Used</span>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.advanceGstUsed)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <CardFooter>
          <Row md={12}>{this.getButtonsAsPerStatusAndPermission()}</Row>
        </CardFooter>
      </>
    );
  }
}

import React, { Component } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ptsLogo from "../../../assets/images/ptsLogo.png";
import logo3 from "../../../assets/images/logo3.png";
class PtsAboutUs extends Component {
  title = "About Us";
  breadCrumb = [];
  constructor(props) {
    super(props);
    this.state = {};
    window.scrollTo(0, 0);
  }
  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }
  render() {
    return (
      <>
        <div>
          <nav
            id="ptsHomeHeader"
            style={{ padding: 0 }}
            className="navbar navbar-default bootsnav navbar-fixed no-background white"
          >
            <div style={{ backgroundColor: "#054e63" }}>
              <div className="navbar-header">
                <button
                  type="button"
                  className="mobile-nav-toggle d-lg-none text-end"
                  style={{ marginRight: "-5em", marginTop: "2em" }}
                  onClick={(e) => this.openMenu()}
                >
                  <i className="fa fa-bars" style={{ fontSize: "1.5em" }}></i>
                </button>{" "}
                <span style={{ display: "inline-flex", margin: "1.5em 8.5em" }}>
                  <img
                    src={logo3}
                    className="logo"
                    alt=""
                    style={{ width: "44px" }}
                    width="auto"
                    height="auto"
                  />
                  &nbsp;&nbsp;
                  <h3
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      margin: "auto",
                    }}
                  >
                    Taxonomy Hub
                  </h3>
                </span>
                {/* </a> */}
              </div>
              <div className="collapse navbar-collapse" id="navbar-menu">
                <ul
                  className="nav navbar-nav navbar-right"
                  style={{ flexDirection: "inherit" }}
                >
                  <li>
                    <a
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={() => this.navigateToLink("home")}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => this.navigateToLink("/login")}
                      style={{ cursor: "pointer" }}
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => this.navigateToLink("/personalDetailsReg")}
                      style={{ cursor: "pointer" }}
                    >
                      Register
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div
          className="termsBanner"
          id="top"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
          style={{ height: "650px" }}
        >
          {/* <div
            className="container termsConditionsUI"
            style={{ border: "1px solid lightgray", padding: "2em" }}
          > */}
          <>
            <Row style={{ padding: "1em", margin: "5em" }}>
              <Col md={12}>
                <Card style={{ padding: "1em", textAlign: "center" }}>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <h3 style={{ color: "#000" }}>
                          <b>
                            <u>TaxonomyHub</u>
                          </b>
                        </h3>
                      </Col>
                    </Row>
                    <p>
                      I have full control of my Business Financial along with
                      GST Compliance It is Simple, Secure and Super fast
                    </p>
                    <br />

                    <br />
                    <Row>
                      <Col md={12} style={{ textAlign: "center" }}>
                        <h5 style={{ color: "#2471A3" }}>
                          <b>Product Of Proto Technology Solutions</b>
                        </h5>
                        {/* <p>Proto technology Solutions</p> */}
                        <img src={ptsLogo} alt="" style={{ height: "100px" }} />
                        <br />
                        <a
                          href="https://prototsolutions.com/"
                          target="_blank"
                          style={{ cursor: "pointer" }}
                        >
                          www.prototsolutions.com
                        </a>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
          {/* </div> */}
        </div>
        <footer style={{ background: "#000" }}>
          <div className="container termsConditionsUI">
            <div className="row">
              <div className="col-lg-6" style={{ margin: "auto" }}>
                <div
                  className="copyright"
                  style={{ fontSize: "12px", color: "#fff" }}
                >
                  © Copyright
                  <strong>
                    <span> AssetTrackifier</span>
                  </strong>
                  . All Rights Reserved
                </div>
              </div>
              <div className="col-lg-3"></div>
              <div className="col-lg-3">
                <ul
                  style={{
                    float: "right",
                    listStyle: "none",
                    lineHeight: "3em",
                    margin: "0 100px 0px 0",
                  }}
                >
                  <li style={{ textAlign: "left" }}>
                    <i
                      className="fa fa-angle-right"
                      style={{ color: "#fff" }}
                    />

                    <a
                      onClick={() => this.navigateToLink("/termsConditions")}
                      style={{ color: "#fff", cursor: "pointer" }}
                    >
                      {" "}
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li style={{ textAlign: "left" }}>
                    <i
                      className="fa fa-angle-right"
                      style={{ color: "#fff" }}
                    />

                    <a
                      onClick={() => this.navigateToLink("/privacypolicy")}
                      style={{ color: "#fff", cursor: "pointer" }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default PtsAboutUs;

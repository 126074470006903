import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Autocomplete, InputAdornment } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import Address from "../../address/Address";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./ImportCustomer.scss";

export class ImportCustomer extends Component {
  addressRef = React.createRef();
  countryList = store.getState().staticData.countryList;
  customerTypeList = store.getState().appData.customerTypeList;
  businessTypeList = store.getState().appData.businessTypeList;
  customerCategoryList = store.getState().appData.customerCategoryList;
  title = "Customer Import";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let customerId = props.customerId;
    let action = props.action;
    /* Set Data From history */

    if (
      Utils.isNull(customerId) &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      customerId = props.history.location.state.customerId;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    let canRender = false;
    if (customerId && customerId > 0) {
      this.getCustomerDetails(customerId);
    } else {
      canRender = true;
    }
    this.state = {
      id: customerId,
      name: "",
      description: "",
      gstnverified: "",
      pan: "",
      gstn: "",
      phone: "",
      email: "",
      tradeName: "",
      usetnforinvoicing: "",
      isTradeNameApplicable: false,
      invoicetandc: "",
      shareinvoices: false,
      customerType: this.customerTypeList[0],
      businesstype: this.businessTypeList[0],
      address: {},
      custCategory: this.customerCategoryList[0],
      //custStatus:{},
      custStatus: false,
      customerStatus: {},
      gstregstatus: "",
      privileges: [],
      action: action,
      successMessage: "",
      errorMessages: "",
      render: canRender,
      showSuccessAlert: false,
      showFailAlert: false,
    };
  }

  getCustomerDetails = (customerId) => {
    const header = store.getState().header.header;
    const postObject = {
      custid: customerId,
      header: header,
    };

    FetchServerData.callPostService("/custimpexp/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customer = output.data;
          let isTradeNameApplicable = false;
          if (customer.gstn && customer.gstn.substr(5, 1) === "P") {
            isTradeNameApplicable = true;
          }
          this.setState({
            id: customer.id,
            name: customer.name,
            businesstype: customer.businesstype,
            custCategory: customer.custcategory,
            customerType: customer.customertype,
            gstn: customer.gstn,
            pan: customer.pan,
            description: customer.description,
            phone: customer.phone,
            email: customer.email,
            registatus: customer.gstregstatus,
            custStatus: customer.custstatus?.status,
            address: {
              id: customer.addressvo.id,
              line1: customer.addressvo.line1,
              line2: customer.addressvo.line2,
              city: customer.addressvo.city,
              pincode: customer.addressvo.pin,
              state: customer.addressvo.statevo,
              country: customer.addressvo.statevo
                ? customer.addressvo.statevo.countryvo
                : {},
            },
            gstnverified: customer.gstnverified,
            invoicetandc: customer.invoicetandc,
            shareinvoices:
              null === customer.shareinvoices ? false : customer.shareinvoices,
            usetnforinvoicing: customer.usetnforinvoicing,
            tradeName: customer.tradeName,
            gstregstatus: customer.gstregstatus,
            privileges: customer.privileges,
            isTradeNameApplicable: isTradeNameApplicable,
            showGSTN:
              customer.customertype?.type === "REGISTERED" ? true : false,
            render: true,
          });
          this.getMenuIconsAsPerStatusAndPermission();
        } else {
        }
      }
    );
  };

  handleCustomerCategoryChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      custCategory: selectedType,
    });
  }

  handleCustomerTypeChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }

    if (Utils.equalsIgnoreCase(selectedType.type, "UNREGISTERED")) {
      // document.getElementById("gstn").disabled = true;
      this.setState({
        customerType: selectedType,
        custCategory: this.customerCategoryList[1],
        gstn: this.state.gstn,
        isTradeNameApplicable: false,
        showGSTN: false,
      });
    } else {
      // document.getElementById("gstn").disabled = false;
      this.setState({
        customerType: selectedType,
        custCategory: this.customerCategoryList[0],
        gstn: this.state.gstn,
        showGSTN: true,
      });
    }
  }

  handleBusinessTypeChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      businesstype: selectedType,
    });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleGstVerifiedChange(e) {
    this.setState({ gstnverified: e.target.value });
  }

  handlePanChange(e) {
    let val = e.target.value.toUpperCase();
    this.setState({ pan: val });
  }

  handleGstnChange(e) {
    let val = e.target.value.toUpperCase();
    this.setState({ gstn: val });
  }
  handleGstnOnBlur(e) {
    let gstn = this.state.gstn;
    if (gstn && gstn.substr(5, 1) === "P") {
      this.setState({ isTradeNameApplicable: true });
    } else {
      this.setState({ isTradeNameApplicable: false });
    }
    if (
      Utils.equalsIgnoreCase(
        this.state.customerType && this.state.customerType.type,
        "REGISTERED"
      )
    ) {
      if (null === gstn || gstn.length <= 0) {
        document.getElementById("gstn").focus();
        PtsAlert.error("Enter GSTN");
        return false;
      } else if (gstn.length < 15) {
        document.getElementById("gstn").focus();
        PtsAlert.error("GSTN Must be 15 Characters long");
        return false;
      } else {
        let gstnPattern =
          "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
        let patternArray = gstn.match(gstnPattern);
        if (patternArray === null) {
          PtsAlert.error(
            "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
          );
          return false;
        } else {
          let panNo = gstn.substr(2, 10);
          this.setState({ pan: panNo });
        }
      }
    }
  }
  handleTradeNameChange(e) {
    this.setState({ tradeName: e.target.value });
  }

  handleUsetnforinvoicingChange(e) {
    this.setState({ usetnforinvoicing: !this.state.usetnforinvoicing });
  }

  handleInvoicetandcChange(e) {
    this.setState({ invoicetandc: e.target.value });
  }

  handleShareinvoicesChange(selectedState) {
    this.setState({ shareinvoices: !this.state.shareinvoices });
  }

  handleAddressChange(inpuAddress) {
    this.setState({ address: inpuAddress });
  }

  handleCustStatusChange(e) {
    if (
      this.state.custStatus &&
      Utils.equalsIgnoreCase(this.state.custStatus.status, "Active")
    ) {
      this.setState({ custStatus: { status: "InActive" } });
    } else {
      this.setState({ custStatus: { status: "Active" } });
    }
  }

  regiStatus(e) {
    this.setState({ registatus: e.target.value });
  }

  formValidation(e) {
    let gstn = this.state.gstn;
    let panNo = this.state.pan;
    e.preventDefault();

    if (this.addressRef && this.addressRef.current) {
      if (!this.addressRef.current.validateAddress()) {
        return;
      }
    }
    let customerType = this.state.customerType;
    if (
      Utils.equalsIgnoreCase(customerType && customerType.type, "REGISTERED")
    ) {
      /* GSTN Validation */
      document.getElementById("gstn").required = true;
      if (null === gstn || gstn.length <= 0) {
        document.getElementById("gstn").focus();
        PtsAlert.error("Enter GSTN");
        return false;
      } else if (gstn.length < 15) {
        document.getElementById("gstn").focus();
        PtsAlert.error("GSTN Must be 15 Characters long");
        return false;
      } else {
        let gstnPattern =
          "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
        let patternArray = gstn.match(gstnPattern);
        if (patternArray === null) {
          PtsAlert.error(
            "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
          );
          return false;
        }
      }

      /* PAN Number Validation */
      if (null === panNo || panNo.length <= 0) {
        document.getElementById("pan").focus();
        PtsAlert.error("Enter PAN Number");
        return false;
      } else if (panNo.length < 10) {
        document.getElementById("pan").focus();
        PtsAlert.error("PAN Number Must be 10 Characters long");
      } else {
        let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        let panPatternArray = panNo.match(regpan);
        if (panPatternArray === null) {
          PtsAlert.error(
            "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Characte"
          );
          return false;
        }
      }
    } else if (
      Utils.equalsIgnoreCase(customerType && customerType.type, "UNREGISTERED")
    ) {
      this.setState({ gstn: "" });
      /* Check if Customer is from India or Out Of India */
      /*Indian Customer required Pan*/
      let address = this.state.address;
      if (address && address.country) {
        if (address.country.name !== "India") {
          /* Foreign Customer */
        } else {
          /* PAN Number Validation */
          if (null === panNo || panNo.length <= 0) {
            document.getElementById("pan").focus();
            PtsAlert.error("Enter PAN Number");
            return false;
          } else if (panNo.length < 10) {
            document.getElementById("pan").focus();
            PtsAlert.error("PAN Number Must be 10 Characters long");
          } else {
            let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
            let panPatternArray = panNo.match(regpan);
            if (panPatternArray === null) {
              PtsAlert.error(
                "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Characte"
              );
              return false;
            }
          }
        }
      }
    }

    if (this.state.action === "create") {
      return this.createCustomerInBackend(e);
    } else if (this.state.action === "edit") {
      return this.updateCustomerInBackend(e);
    }
  }

  createCustomerInBackend = (e) => {
    //   e.preventDefault();
    const header = store.getState().header.header;
    const postObject = {
      customerVo: {
        name: this.state.name,
        tradeName: this.state.tradeName,
        usetnforinvoicing: this.state.usetnforinvoicing,
        pan: this.state.pan,
        gstn: this.state.gstn,
        invoicetandc: this.state.invoicetandc,
        description: this.state.description,
        shareinvoices: this.state.shareinvoices,
        phone: this.state.phone,
        email: this.state.email,
        gstregstatus: this.state.registatus,
        customertype: this.state.customerType,
        custcategory: this.state.custCategory,
        businesstype: this.state.businesstype,
        addressvo: {
          id: this.state.address.id,
          line1: this.state.address.line1,
          line2: this.state.address.line2,
          city: this.state.address.city,
          pin: this.state.address.pincode,
          statevo: {
            id: this.state.address.state.id,
            name: this.state.address.state.name,
            code: this.state.address.state.code,
            countryvo: {
              id: this.state.address.country.id,
              name: this.state.address.country.name,
              code: this.state.address.country.code,
            },
          },
        },
        // gstnverified: this.state.gstnverified,
        // shareinvoices: this.state.shareinvoices,
      },
      header: header,
    };

    FetchServerData.callPostService("/custimpexp/imp", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customer = output.data;
          this.props.history.push({
            pathname: "/importCustomerView",
            state: {
              customerId: customer.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  importCustomer = (e) => {
    const header = store.getState().header.header;
    const postObject = {
      customerVo: {
        id: this.state.id,
        name: this.state.name,
        tradeName: this.state.tradeName,
        pan: this.state.pan,
        gstn: this.state.gstn,
        invoicetandc: this.state.invoicetandc,
        description: this.state.description,
        shareinvoices:
          null === this.state.shareinvoices ? false : this.state.shareinvoices,
        phone: this.state.phone,
        email: this.state.email,
        gstregstatus: this.state.registatus,
        customertype: this.state.customerType,
        custcategory: this.state.custCategory,
        // custstatus: {
        //   status: this.state.custStatus,
        // },

        businesstype: this.state.businesstype,
        addressvo: {
          id: this.state.address.id,
          line1: this.state.address.line1,
          line2: this.state.address.line2,
          city: this.state.address.city,
          pin: this.state.address.pincode,
          statevo: {
            id: this.state.address.state.id,
            name: this.state.address.state.name,
            code: this.state.address.state.code,
            countryvo: {
              id: this.state.address.country.id,
              name: this.state.address.country.name,
              code: this.state.address.country.code,
            },
          },
        },
        gstnverified: this.state.gstnverified,
        // shareinvoices: this.state.shareinvoices,
        usetnforinvoicing: this.state.usetnforinvoicing,
      },
      header: header,
    };

    FetchServerData.callPostService("/custimpexp/imp", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customer = output.data;
          this.props.history.push({
            pathname: "/custImport",
            state: { customerId: customer.id, action: "view" },
          });
        } else {
        }
      }
    );
  };

  updateCustomerInBackend = (e) => {
    const header = store.getState().header.header;
    let address = this.addressRef.current.state;
    const postObject = {
      customerVo: {
        id: this.state.id,
        name: this.state.name,
        tradeName: this.state.tradeName,
        pan: this.state.pan,
        gstn: this.state.gstn,
        invoicetandc: this.state.invoicetandc,
        description: this.state.description,
        shareinvoices:
          null === this.state.shareinvoices ? false : this.state.shareinvoices,
        phone: this.state.phone,
        email: this.state.email,
        gstregstatus: this.state.registatus,
        customertype: this.state.customerType,
        custcategory: this.state.custCategory,
        custstatus: this.state.custStatus,
        businesstype: this.state.businesstype,
        addressvo: {
          id: address.id,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          pin: address.pincode,
          statevo: {
            id: address.state.id,
            name: address.name,
            code: address.state.code,
            countryvo: {
              id: address.country.id,
              name: address.country.name,
              code: address.country.code,
            },
          },
        },
        gstnverified: this.state.gstnverified,
        // shareinvoices: this.state.shareinvoices,
        usetnforinvoicing: this.state.usetnforinvoicing,
      },
      header: header,
    };

    FetchServerData.callPostService("/custimpexp/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customer = output.data;
          this.props.history.push({
            pathname: "/importCustomerView",
            state: {
              customerId: customer.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  deleteCustomer = (id) => {
    const header = store.getState().header.header;

    const postObject = {
      custid: id,
      header: header,
    };
    FetchServerData.callPostService("/custimpexp/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let customer = output.data;
          this.props.history.push({
            pathname: "/custImport",
            state: { customerId: null, action: "view" },
          });
        } else {
        }
      }
    );
  };

  getGstn() {
    const header = store.getState().header.header;
    const postObject = {
      gstin: this.state.gstn,
      header: header,
    };

    FetchServerData.callPostService(
      "/publicapi/searchTxPayer",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let gstnDetails = output.data;
        this.setState({
          name: gstnDetails.legalname,
          state: gstnDetails.state,
          address: gstnDetails.address,
          registatus: gstnDetails.status,
          action: this.state.action,
          gstnverified: true,
        });
      } else {
      }
    });
  }

  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];
    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }

    if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      buttonList = [...buttonList, "EDIT"]
    }
    if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      buttonList = [...buttonList, "DELETE"]
    }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
      });
    }
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.menuWithBreadScrumRef = React.createRef();
    this.addressRef = React.createRef();

    if (this.state.action === "create") {
      return this.createCustomer();
    }
    if (this.state.action === "edit") {
      return this.editCustomer();
    } else {
      return this.viewCustomer();
    }
  }

  editButtonClicked() {
    this.props.history.push({
      pathname: "/importCustomerEdit",
      state: {
        customerId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
      },
    });
  }
  deleteButtonClicked() {
    this.deleteCustomer(this.state.id);
  }
  cancelEdit() {
    this.props.history.push({
      pathname: "/importCustomerView/",
      state: {
        customerId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  }

  cancelView() {
    this.props.history.push({ pathname: "/custImport" });
  }
  editCustomer() {
    // let shareInvoices = null;
    // if (
    //   this.state.shareinvoices !== null &&
    //   this.state.shareinvoices === true
    // ) {
    //   shareInvoices = (
    //     <ToggleButton
    //       className="ToggleButtonActive"
    //       onChange={this.handleShareinvoicesChange.bind(this)}
    //     >
    //       Yes
    //     </ToggleButton>
    //   );
    // } else {
    //   shareInvoices = (
    //     <ToggleButton
    //       className="ToggleButtonInActive"
    //       onChange={this.handleShareinvoicesChange.bind(this)}
    //     >
    //       No
    //     </ToggleButton>
    //   );
    // }

    // let isActiveCustomer = null;

    // if (this.state.custStatus && this.state.custStatus.status === "Active") {
    //   isActiveCustomer = (
    //     <ToggleButton
    //       className="ToggleButtonActive"
    //       onChange={this.handleCustStatusChange.bind(this)}
    //     >
    //       Active
    //     </ToggleButton>
    //   );
    // } else {
    //   isActiveCustomer = (
    //     <ToggleButton
    //       className="ToggleButtonInActive"
    //       onChange={this.handleCustStatusChange.bind(this)}
    //     >
    //       InActive
    //     </ToggleButton>
    //   );
    // }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Edit Customer Import"
          history={this.props.history}
          name="Edit Customer Import"
        />
        <div style={{ padding: "1em", width: "100%" }} className="customer_gst">
          <ValidatorForm ref="form" onSubmit={this.formValidation.bind(this)}>
            <Row
              style={{ padding: "1em", height: "100%", position: "relative" }}
            >
              <Col
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1em",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Card style={{ flexGrow: "1", padding: "1em" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Customer Details</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Customer Type<span className="asterisk"> *</span>
                        </label>

                        <Autocomplete
                          ListboxProps={{
                            className: "myCustomList"
                          }}
                          id="customerType"
                          options={
                            null === this.customerTypeList
                              ? []
                              : this.customerTypeList
                          }
                          value={this.state.customerType}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleCustomerTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              required
                              {...params}
                              // label="Customer Type"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>

                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Business Type<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="businessType"
                          ListboxProps={{
                            className: "myCustomList"
                          }}
                          options={
                            null === this.businessTypeList
                              ? []
                              : this.businessTypeList
                          }
                          value={this.state.businesstype}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleBusinessTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              required
                              {...params}
                              // label="Business Type"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    {this.state.showGSTN ? (
                      <Row style={{ alignItems: "flex-end" }}>
                        <Col md={6} style={{ padding: "0.5em" }}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            GSTN
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="gstn"
                            type="text"
                            placeholder="GSTN"
                            validators={["required"]}
                            errorMessages={["GSTN is required"]}
                            onChange={this.handleGstnChange.bind(this)}
                            onBlur={this.handleGstnOnBlur.bind(this)}
                            value={this.state.gstn}
                            name="gstn"
                            //InputLabelProps={{ shrink: true,  }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <RefreshIcon
                                    className="gstnBtn"
                                    onClick={(e) => this.getGstn()}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row></Row>
                    )}
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={12} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          // label="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                          placeholder="Name"
                          name="name"
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>
                    </Row>
                    {true === this.state.isTradeNameApplicable && (
                      <>
                        <Row style={{ alignItems: "flex-end" }}>
                          <Col md={6} style={{ padding: "0.5em" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Trade Name
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="tradeName"
                              // label="Trade Name"
                              value={this.state.tradeName}
                              onChange={this.handleTradeNameChange.bind(this)}
                              name="tradename"
                              placeholder="Trade Name"
                            //InputLabelProps={{ shrink: true,  }}
                            />
                          </Col>
                        </Row>
                        <Row style={{ alignItems: "flex-end" }}>
                          <Col md={12} style={{ padding: "0.5em" }}>
                            <label style={{ width: "50%", fontWeight: "500" }}>
                              Use Trade Name For Invoice?
                            </label>
                            {this.state.usetnforinvoicing === true && (
                              <ToggleButton
                                className="ToggleButtonActive"
                                onChange={this.handleUsetnforinvoicingChange.bind(
                                  this
                                )}
                              >
                                Yes
                              </ToggleButton>
                            )}
                            {this.state.usetnforinvoicing === false && (
                              <ToggleButton
                                className="ToggleButtonInActive"
                                onChange={this.handleUsetnforinvoicingChange.bind(
                                  this
                                )}
                              >
                                No
                              </ToggleButton>
                            )}
                          </Col>
                        </Row>{" "}
                      </>
                    )}
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={12} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Description
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="description"
                          // label="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                          autoComplete="description"
                          placeholder="Description"
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          PAN<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="pan"
                          // label="PAN"
                          value={this.state.pan}
                          onChange={this.handlePanChange.bind(this)}
                          validators={["required"]}
                          errorMessages={["PAN is Required"]}
                          name="pan"
                          autoComplete="pan"
                          placeholder="PAN"
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>

                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Customer Category
                        </label>
                        <Autocomplete
                          id="category"
                          ListboxProps={{
                            className: "myCustomList"
                          }}
                          disabled
                          options={
                            null === this.customerCategoryList
                              ? []
                              : this.customerCategoryList
                          }
                          value={this.state.custCategory}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerCategoryChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              placeholder="Customer Category"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Phone Number<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type="phone"
                          id="phone"
                          placeholder="Phone Number"
                          // label="Phone Number"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                          name="phone"
                          autoComplete="phone"
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>

                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Email
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="email"
                          // label="Email"
                          placeholder="Email"
                          value={this.state.email}
                          name="email"
                          autoComplete="email"
                          type="email"
                          onChange={this.handleEmailChange.bind(this)}
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Row style={{ paddingTop: "1em" }}>
                  <Col md={12}>
                    <Card style={{ flexGrow: "1", padding: "1em" }}>
                      <CardHeader className="cardHeaderStyle">
                        <label className="cardHeaderTitle">
                          <strong>Registered Address</strong>
                        </label>
                      </CardHeader>
                      {/* <hr></hr> */}
                      <CardBody>
                        <Address
                          ref={this.addressRef}
                          data={this.state.address}
                          onChange={this.handleAddressChange.bind(this)}
                          action="edit"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "1em", height: "100%" }}>
                  <Col md={12}>
                    <Card
                      style={{
                        width: "-webkit-fill-available",
                        padding: "1em",
                        position: "relative",
                        height: "100%",
                      }}
                    >
                      <CardHeader className="cardHeaderStyle">
                        <label className="cardHeaderTitle">
                          <strong>Other Information</strong>
                        </label>
                      </CardHeader>
                      {/* <hr></hr> */}
                      <CardBody>
                        <Row style={{ padding: "5px", alignItems: "flex-end" }}>
                          <Col md={6} style={{ padding: "0.5em" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              GST verified
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              disabled
                              id="gstverified"
                              // label="GST verified"
                              placeholder="GST verified"
                              value={this.state.gstnverified ? "Yes" : "No"}
                              onChange={this.handleGstVerifiedChange.bind(this)}
                              name="gstverified"
                              autoComplete="gstverified"
                            //InputLabelProps={{ shrink: true,  }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row md={12} style={{ marginTop: "2em" }}>
              <Col>
                <CardFooter style={{ width: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: "#1b7189",
                        color: "#fff",
                        marginRight: "1rem",
                      }}
                      startIcon={<SaveIcon />}
                    //  onClick={e => this.formValidation(e)}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        background: "#717373",
                        color: "#fff",
                        marginRight: "1rem",
                      }}
                      startIcon={<CloseIcon />}
                      onClick={(e) => this.cancelEdit()}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </ValidatorForm>
        </div>
      </>
    );
  }
  viewCustomer() {
    return (
      <>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          addToBreadCrumb="View Customer Import"
          name="View Customer"
          docTitle={this.state.name}
          // editCallback={this.editButtonClicked.bind(this)}
          // deleteCallback={this.deleteButtonClicked.bind(this)}
          backCallback={this.cancelView.bind(this)}
        />
        <div style={{ width: "100%" }} className="customer_gst">
          <Row md={12} style={{ margin: "auto" }}>
            <Col
              md={6}
              style={{
                padding: "1em",
                display: "flex",
                flexGrow: "1",
                flexDirection: "column",
              }}
            >
              <Card
                style={{
                  height: "100%",
                  shadowOpacity: "0.5",
                  shadowRadius: "10",
                }}
              >
                <CardHeader
                  className="cardHeaderStyle"
                // style={{ borderBottom: "1px solid lightgrey" }}
                >
                  <label className="cardHeaderTitle">
                    <strong>Customer Information</strong>
                  </label>
                </CardHeader>
                {/* <hr></hr> */}
                <CardBody>
                  {true === this.state.isTradeNameApplicable && (
                    <Row>
                      <Col md={4}>
                        <label style={{ color: "#000" }}>
                          <strong>Trade Name</strong>
                        </label>
                      </Col>
                      <Col md={8} style={{ color: "#000" }}>
                        {this.state.tradeName}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Business Type</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {null === this.state.businesstype
                        ? ""
                        : this.state.businesstype.type}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Category</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {null === this.state.custCategory
                        ? ""
                        : this.state.custCategory.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Customer Type</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {null === this.state.customerType
                        ? ""
                        : this.state.customerType.type}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>GSTN</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.gstn}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>PAN</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.pan}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Description</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.description}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Phone</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.phone}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Email</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.email}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Customer Status</strong>
                      </label>
                    </Col>
                    <Col md={8}>
                      {this.state.custStatus
                        ? this.state.custStatus.status
                        : ""}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              style={{
                padding: "1em",
                display: "flex",
                flexGrow: "1",
                flexDirection: "column",
              }}
            >
              <div md={12}>
                <Card md={12}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Registered Address</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Address
                      ref={this.addressRef}
                      data={this.state.address}
                      action="view"
                    />
                  </CardBody>
                </Card>
              </div>
              <div md={12} style={{ marginTop: "1em", height: "100%" }}>
                <Card md={12} style={{ flexGrow: "1", height: "100%" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Other Information</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <label style={{ color: "#000" }}>
                          <strong>GSTN Verified</strong>
                        </label>
                      </Col>
                      <Col md={6}>
                        {this.state.gstnverified &&
                          true === this.state.gstnverified
                          ? "Yes"
                          : "No"}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>

          <Row md={12} style={{ marginTop: "2em" }}>
            <Col>
              <CardFooter style={{ width: "100%" }}>
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "#717373",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={(e) => this.cancelView()}
                  >
                    Back
                  </Button>
                </div>
              </CardFooter>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  createCustomer() {
    // let shareInvoices = null;
    // if (
    //   this.state.shareinvoices !== null &&
    //   this.state.shareinvoices === true
    // ) {
    //   shareInvoices = (
    //     <ToggleButton
    //       className="ToggleButtonActive"
    //       onChange={this.handleShareinvoicesChange.bind(this)}
    //     >
    //       Yes
    //     </ToggleButton>
    //   );
    // } else {
    //   shareInvoices = (
    //     <ToggleButton
    //       className="ToggleButtonInActive"
    //       onChange={this.handleShareinvoicesChange.bind(this)}
    //     >
    //       No
    //     </ToggleButton>
    //   );
    // }

    // let isActiveCustomer = null;

    // if (
    //   this.state.custStatus &&
    //   Utils.equalsIgnoreCase(this.state.custStatus.status, "Active")
    // ) {
    //   isActiveCustomer = (
    //     <ToggleButton
    //       className="ToggleButtonActive"
    //       onChange={this.handleCustStatusChange.bind(this)}
    //     >
    //       Active
    //     </ToggleButton>
    //   );
    // } else {
    //   isActiveCustomer = (
    //     <ToggleButton
    //       className="ToggleButtonInActive"
    //       onChange={this.handleCustStatusChange.bind(this)}
    //     >
    //       InActive
    //     </ToggleButton>
    //   );
    // }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Create Customer Import"
          history={this.props.history}
          name="Create Customer Import"
        />
        <div style={{ padding: "1em", width: "100%" }} className="customer_gst">
          <ValidatorForm ref="form" onSubmit={this.formValidation.bind(this)}>
            <Row style={{ padding: "1em" }}>
              <Col md={6} style={{ display: "flex", flexDirection: "column" }}>
                <Card style={{ flexGrow: "1", padding: "1em" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Customer Details</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Customer Type<span className="asterisk"> *</span>
                        </label>

                        <Autocomplete
                          id="customerType"
                          ListboxProps={{
                            className: "myCustomList"
                          }}
                          options={
                            null === this.customerTypeList
                              ? []
                              : this.customerTypeList
                          }
                          value={this.state.customerType}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleCustomerTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              // label="Customer Type"
                              className="regCountry"
                              variant="outlined"
                            />
                          )}
                        />
                      </Col>

                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Business Type<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="businessType"
                          ListboxProps={{
                            className: "myCustomList"
                          }}
                          options={
                            null === this.businessTypeList
                              ? []
                              : this.businessTypeList
                          }
                          value={this.state.businesstype}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleBusinessTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              required
                              {...params}
                              // label="Business Type"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    {this.state.showGSTN ? (
                      <Row style={{ alignItems: "flex-end" }}>
                        <Col md={6} style={{ padding: "0.5em" }}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            GSTN
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="gstn"
                            type="text"
                            placeholder="GSTN"
                            validators={["required"]}
                            errorMessages={["GSTN is required"]}
                            onChange={this.handleGstnChange.bind(this)}
                            onBlur={this.handleGstnOnBlur.bind(this)}
                            value={this.state.gstn}
                            name="gstn"
                            //InputLabelProps={{ shrink: true,  }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <RefreshIcon
                                    className="gstnBtn"
                                    onClick={(e) => this.getGstn()}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row></Row>
                    )}
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={12} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          // label="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                          placeholder="Name"
                          name="name"
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>
                    </Row>
                    {true === this.state.isTradeNameApplicable && (
                      <>
                        <Row style={{ alignItems: "flex-end" }}>
                          <Col md={6} style={{ padding: "0.5em" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Trade Name
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="tradeName"
                              // label="Trade Name"
                              value={this.state.tradeName}
                              onChange={this.handleTradeNameChange.bind(this)}
                              name="tradename"
                              placeholder="Trade Name"
                            //InputLabelProps={{ shrink: true,  }}
                            />
                          </Col>
                        </Row>
                        <Row style={{ alignItems: "flex-end" }}>
                          <Col md={12} style={{ padding: "0.5em" }}>
                            <label style={{ width: "50%", fontWeight: "500" }}>
                              Use Trade Name For Invoice?
                            </label>
                            {this.state.usetnforinvoicing === true && (
                              <ToggleButton
                                className="ToggleButtonActive"
                                onChange={this.handleUsetnforinvoicingChange.bind(
                                  this
                                )}
                              >
                                Yes
                              </ToggleButton>
                            )}
                            {this.state.usetnforinvoicing === false && (
                              <ToggleButton
                                className="ToggleButtonInActive"
                                onChange={this.handleUsetnforinvoicingChange.bind(
                                  this
                                )}
                              >
                                No
                              </ToggleButton>
                            )}
                          </Col>
                        </Row>{" "}
                      </>
                    )}
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={12} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Description
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="description"
                          // label="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                          autoComplete="description"
                          placeholder="Description"
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          PAN<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          id="pan"
                          // label="PAN"
                          value={this.state.pan}
                          onChange={this.handlePanChange.bind(this)}
                          validators={["required"]}
                          errorMessages={["PAN is Required"]}
                          name="pan"
                          autoComplete="pan"
                          placeholder="PAN"
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>

                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Customer Category
                        </label>
                        <Autocomplete
                          id="category"
                          ListboxProps={{
                            className: "myCustomList"
                          }}
                          disabled
                          options={
                            null === this.customerCategoryList
                              ? []
                              : this.customerCategoryList
                          }
                          value={this.state.custCategory}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerCategoryChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              placeholder="Customer Category"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end" }}>
                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Phone Number<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          type="phone"
                          id="phone"
                          placeholder="Phone Number"
                          // label="Phone Number"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                          name="phone"
                          autoComplete="phone"
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>

                      <Col md={6} style={{ padding: "0.5em" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Email
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="email"
                          // label="Email"
                          placeholder="Email"
                          value={this.state.email}
                          name="email"
                          autoComplete="email"
                          type="email"
                          onChange={this.handleEmailChange.bind(this)}
                        //InputLabelProps={{ shrink: true,  }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} style={{ display: "flex", flexDirection: "column" }}>
                <Row style={{ marginBottom: "1em" }}>
                  <Card style={{ flexGrow: "1", padding: "1em" }}>
                    <CardHeader className="cardHeaderStyle">
                      <label className="cardHeaderTitle">
                        <strong>Registered Address</strong>
                      </label>
                    </CardHeader>
                    {/* <hr></hr> */}
                    <CardBody>
                      <Address
                        ref={this.addressRef}
                        data={this.state.address}
                        onChange={this.handleAddressChange.bind(this)}
                        action="edit"
                      />
                    </CardBody>
                  </Card>
                </Row>
                <Row>
                  <Card
                    style={{ width: "-webkit-fill-available", padding: "1em" }}
                  >
                    <CardHeader className="cardHeaderStyle">
                      <label className="cardHeaderTitle">
                        <strong>Other Information</strong>
                      </label>
                    </CardHeader>
                    {/* <hr></hr> */}
                    <CardBody>
                      <Row style={{ padding: "5px", alignItems: "flex-end" }}>
                        <Col md={6} style={{ padding: "0.5em" }}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            GST verified
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            disabled
                            id="gstverified"
                            // label="GST verified"
                            placeholder="GST verified"
                            value={this.state.gstnverified ? "Yes" : "No"}
                            onChange={this.handleGstVerifiedChange.bind(this)}
                            name="gstverified"
                            autoComplete="gstverified"
                          //InputLabelProps={{ shrink: true,  }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>
            <Row md={12} style={{ marginTop: "2em" }}>
              <Col>
                <CardFooter style={{ width: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: "#1b7189",
                        color: "#fff",
                        marginRight: "1rem",
                      }}
                      startIcon={<SaveIcon />}
                    //  onClick={e => this.formValidation(e)}
                    >
                      Create
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        background: "#717373",
                        color: "#fff",
                        marginRight: "1rem",
                      }}
                      startIcon={<CloseIcon />}
                      onClick={(e) => this.cancelView()}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </ValidatorForm>
        </div>
      </>
    );
  }
}
export default ImportCustomer;

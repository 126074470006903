import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DoneIcon from "@mui/icons-material/Done";
import { CopyToClipboard } from "react-copy-to-clipboard";
import RefreshIcon from "@material-ui/icons/Refresh";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CancelIcon from "@material-ui/icons/Cancel";
import RepeatIcon from "@material-ui/icons/Repeat";
import SaveIcon from "@material-ui/icons/Save";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import UpdateIcon from "@material-ui/icons/Update";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Autocomplete, InputAdornment } from "@mui/material";
import { format } from "date-fns";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import SalesUtils from "../../../../provider/SalesUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import Address from "../../address/Address";
import AuditNoteList from "../../auditNote/AuditNoteList";
import CancelButton from "../../buttons/CancelButton";
import CreateButton from "../../buttons/CreateButton";
// import GenerateIconButton from "../../buttons/GenerateIconButton";
import SaveButton from "../../buttons/SaveButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PaymentTransactionList from "../../payments/PaymentTransactionList";
import PtsAlert from "../../ptsAlert/PtsAlert";
import SalesInvoiceDialogue from "../SalesInvoiceDialogue";
import RefundVoucherItem from "./RefundVoucherItem";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});
export default class RefundVoucher extends Component {
  /* References Used */
  itemReferenceList = [];
  billingAddressRef = null;
  shippingAddressRef = null;
  auditNoteListRef = null;
  maxDate = new Date().getMonth() + 1;
  minDate = new Date().getFullYear() - 1;

  /* Data Used from Store */
  header = store.getState().header.header;
  currencyList = store.getState().staticData.currencyList;
  countryList = store.getState().staticData.countryList;
  // bankAccountList = store.getState().appData.settings.bankAccounts;
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  issalesthrecom = store.getState().setting.general.issalesthrecom;
  exportInvoiceTypeList = store.getState().appData.expInvoiceTypeList;
  sezInvoiceTypeList = store.getState().appData.sezInvoiceTypeList;
  customerList = store.getState().master.masterData.customers;
  businessUnitList = store.getState().master.masterData.businessUnits;
  bankAccountList = store.getState().master.masterData.bankaccounts;
  etinInfoList = store.getState().master.masterData.entinList;
  custHsnCodeList = store.getState().master.masterData.custhsncodes;

  /* Data Passed from Parent Component */
  privilegeList = [];
  yesNoList = ["No", "Yes"];
  /* URL for Payment Transaction Component */
  addPaymentTransactionServiceUrl = "/salespaymenttxms/create";
  updatePaymentTransactionServiceUrl = "/salespaymenttxms/update";
  deletePaymentTransactionServiceUrl = "/salespaymenttxms/delete";
  customerSalesInvoiceList = [];
  title = "Refund";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let invoiceId = props.invoiceId;
    let action = props.action;
    let isHistory = props.source && props.source === "history" ? true : false;
    let isDuplicate = null;
    let inputInvoice = null;
    /* Set Data From history */
    if (
      !invoiceId &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      invoiceId = props.history.location.state.invoiceId;
      isDuplicate = props.history.location.state.isDuplicate;
      inputInvoice = props.history.location.state.inputInvoice;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
      isHistory = props.history.location.state.isHistory
        ? props.history.location.state.isHistory
        : false;
    }

    /* Set Empty RefundVoucher Data */
    let emptyInvoice = SalesUtils.getEmptyInvoice();
    emptyInvoice.id = invoiceId;
    emptyInvoice.action = action;
    emptyInvoice.render = false;
    emptyInvoice.salesInvoiceNumber = null;
    this.state = emptyInvoice;
    this.state.isHistory = isHistory;
    this.state.invoicetypeVo = {
      type: "RF",
    };

    this.setDefaultBankAccount();
    /*If Create Operation then Show Form Directly */
    if ("create" === action) {
      if (isDuplicate && isDuplicate === true && inputInvoice) {
        /*Create Duplicate Invoice Functionality */
        this.state = inputInvoice;
        this.state.action = action;
        this.state.id = null;
        this.state.year = "";
        this.state.month = "";
        this.state.invnumber = "";
        this.state.invoicedate = null;
        this.state.gstdate = null;
        this.state.payment = null;
        this.state.referencingInvoices = [];
        this.state.status = null;
        this.state.paymentTransactions = [];
        this.state.auditnotes = [];
        this.state.refinvoice = null;
        this.state.isAdvanceReceived = false;
        this.referenceinvnumber = null;
        this.state.salesInvoiceNumber = "";
        this.state.invoiceItems = [];
        this.updateCustomer(null, this.state.customerVo);
        this.state.pageSize = 10;
        this.state.initialPage = 0;
      } else {
        let year = "";
        let month = "";
        if (Utils.isNotNullAndEmpty(emptyInvoice.invoicedate)) {
          let date = new Date(emptyInvoice.invoicedate, "yyyy-MM-dd");
          year = date.split("-")[0];
          month = Utils.getMonth(date.split("-")[1]);
        }
        this.state.year = year;
        this.state.month = month;
        let item = SalesUtils.createEmptyInvoiceItem();
        this.state.invoiceItems = [];
        this.state.invoiceItems.push(item);
        this.state.pageSize = 10;
        this.state.initialPage = 0;
      }
      this.state.render = true;
    } else {
      /* For Edit and View get data */
      this.state.render = false;
      this.getRefundVoucherFromBackend(invoiceId);
    }
  }

  setDefaultBankAccount() {
    if (this.bankAccountList) {
      for (let i = 0; i < this.bankAccountList.length; i++) {
        let bankAccount = this.bankAccountList[i];
        if (bankAccount && bankAccount.isdefault === true) {
          this.state.bankaccount = bankAccount;
        }
      }
    }
  }
  /* ToolBar Handlers */
  printButtonClicked() {
    this.props.history.push({
      pathname: "/refundVoucherPrint",
      state: {
        invoice: this.state,
        action: "view",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  createSimilarButtonClicked() {
    this.props.history.push({
      pathname: "/refundVoucherCreate",
      state: {
        action: "create",
        isDuplicate: true,
        inputInvoice: this.state,
        breadCrumb: this.breadCrumb,
      },
    });
  }
  editButtonClicked() {
    this.props.history.push({
      pathname: "/refundVoucherEdit",
      state: {
        invoiceId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
    // this.setState({ action: 'edit' });
  }
  deleteButtonClicked() {
    this.deleteRefundVoucherInBackend();
  }

  /* Set Invoice Data to Component State */
  setResponseToState(invoice) {
    if (Utils.isNull(invoice.privileges)) {
      invoice.privileges = this.state.privileges;
    }
    this.setState({
      // advanceAmountUsed: invoice.advanceAmountUsed,
      // advanceGstUsed: invoice.advanceGstUsed,
      paymentTransactions: invoice.paymentTransactions,
      privileges: invoice.privileges,
      auditnotes: invoice.auditnotes,
      bankaccount: invoice.bankaccount,
      barcode: invoice.barcode,
      barcodeImage: invoice.barcodeImage,
      billtoaddress: invoice.billtoaddress,
      shiptoaddress: invoice.shiptoaddress,
      bu: invoice.bu,
      cdnreason: invoice.cdnreason,
      cess: invoice.cess,
      cesspercent: invoice.cesspercent,
      cgst: invoice.cgst,
      createdon: invoice.createdon,
      currency: invoice.currency,
      customerVo: invoice.customerVo,
      description: invoice.description,
      electronicreference: invoice.electronicreference,
      etin: { etin: invoice.etin },
      exchangerate: invoice.exchangerate,
      expinvoicetype: invoice.expinvoicetype,
      freez: invoice.freez,
      fright: invoice.fright,
      gstdate: invoice.gstdate,
      id: invoice.id,
      igst: invoice.igst,
      invnumber: invoice.invnumber,
      invoicedate: invoice.invoicedate,
      invoicesource: invoice.invoicesource,
      invoiceStatusList: invoice.invoiceStatusList,
      isAsset: invoice.isAsset,
      isbillingshippingsame: invoice.isbillingshippingsame,
      isexport: invoice.isexport,
      isimported: invoice.isimported,
      ismanualrefinv: invoice.ismanualrefinv,
      isnilrated: invoice.isnilrated,
      ispregst: invoice.ispregst,
      isrcmsale: invoice.isrcmsale,
      isRecurring: invoice.isRecurring,
      isroundup: invoice.isroundup,
      issez: invoice.issez,
      isSystemGenerated: invoice.isSystemGenerated,
      loadingpackaging: invoice.loadingpackaging,
      manualrefinv: invoice.manualrefinv,
      miscellaneous: invoice.miscellaneous,
      month: invoice.month,
      payment: invoice.payment,
      purchasedate: invoice.purchasedate,
      purchaseorder: invoice.purchaseorder,
      referencedInvoice: invoice.referencedInvoice,
      referenceinvnumber: invoice.referenceinvnumber,
      referencingInvoices: invoice.referencingInvoices,
      refinvoice: invoice.refinvoice,
      salesthrecom: invoice.salesthrecom,
      satype: invoice.satype,
      sezinvoicetype: invoice.sezinvoicetype,
      sgst: invoice.sgst,
      sharewithcust: invoice.sharewithcust,
      supplytype: invoice.supplytype,
      status: invoice.status,
      totaladvance: invoice.totaladvance,
      totalamount: invoice.totalamount,
      totalgst: invoice.totalgst,
      totalinwords: invoice.totalinwords,
      totaltaxable: invoice.totaltaxable,
      totaltaxableinwords: invoice.totaltaxableinwords,
      utgst: invoice.utgst,
      year: invoice.year,
      invoiceItems: invoice.invoiceItems,
      render: true,
    });
    this.getMenuIconsAsPerStatusAndPermission();
    if (this.auditNoteListRef && this.auditNoteListRef.current) {
      this.auditNoteListRef.current.setState({
        auditNoteList: this.state.auditnotes,
      });
    }
  }

  /*Handlers for each Element on Component */
  handleModelOpen() {
    this.setState({ isModelOpen: true });
  }

  handleModelClose() {
    this.setState({ isModelOpen: false });
  }
  setMonthYear(invdate) {
    let date = new Date(invdate);
    let year = date.getFullYear();
    let month = Utils.getMonth(date.getMonth() + 1);
    this.setState({ month: month, year: year });
  }
  handleRefundVoucherDateChange(date) {
    let invdate = format(new Date(date), "yyyy-MM");
    this.setMonthYear(invdate);
    this.setState({ invoicedate: date, gstdate: date });
  }

  handlePODateChange(e) {
    // let podate = format(new Date(e.target.value), "yyyy-MM");
    this.setState({ purchasedate: e.target.value });
  }
  handleGstDateChange(date) {
    this.setState({ gstdate: date });
  }
  handleRefundVoucherDateBlurChange(e) {
    this.setExchangeRate(this.state.currency);
  }
  handleMiscellaneousChange(e) {
    this.setState({ miscellaneous: e.target.value });
  }
  handleMiscellaneousBlurChange(e) {
    this.doCalculations();
  }

  handleEtinChange(e, selected) {
    this.setState({ etin: selected });
  }

  validateGSTN(gstn, elementname, elementlabel) {
    if (null === gstn || gstn.length <= 0) {
      document.getElementById(elementname).focus();
      PtsAlert.error("Enter " + elementlabel);
      return false;
    } else if (gstn.length < 15) {
      document.getElementById(elementname).focus();
      PtsAlert.error(elementlabel + " Must be 15 Characters long");
      return false;
    } else {
      let gstnPattern =
        "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
      let patternArray = gstn.match(gstnPattern);
      if (patternArray === null) {
        PtsAlert.error(
          "Enter Valid " +
            elementlabel +
            " No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
        );
        return false;
      }
      return true;
    }
  }
  getETINDetails(e) {
    let etin = this.state.etin;
    if (this.validateGSTN(etin, "etin", "ETIN")) {
      /*Call Service to Get ETIN Details */
    } else {
    }
  }
  handleRefundVoucherNumberChange(e) {
    this.setState({ invnumber: e.target.value });
  }
  handleRefundVoucherparseFloatOnBlur(e) {
    this.setState({ invnumber: e.target.value });
  }
  handleSupplierChange(e) {
    let supplier = {};
    if (this.state.supplier) {
      supplier = this.state.supplier;
    } else {
      supplier.country = this.defaultCountry;
    }
    supplier.name = e.target.value;

    this.setState({ supplier: supplier });
  }
  handleIsSEZChange(e) {
    if (e.target.checked) {
      this.setState({ issez: "Yes" });
    } else {
      this.setState({ issez: "No" });
    }
  }
  handleSalesThroughECOMChange(e) {
    this.setState({ salesthrecom: e.target.checked });
  }

  handleAssetChange(e) {
    this.setState({ isAsset: e.target.checked });
  }
  handleIsNillRatedChange(e) {
    // if (e.target.checked) {
    this.setState({ isnilrated: e.target.checked });
    // } else {
    //   this.setState({ isnilrated: "No" });
    // }
  }

  handleBillingShippingSame(e) {
    if (e.target.checked) {
      this.setState({ isbillingshippingsame: "Yes" });
    } else {
      this.setState({ isbillingshippingsame: "No" });
    }
  }
  handleShareWithCustomerChange(e) {
    this.setState({ sharewithcust: e.target.checked });
  }
  handleIsSupplierChange(e) {
    let supplier = this.state.supplier;
    if (e.target.checked === false) {
      supplier = null;
    }
    this.setState({ isSupplier: e.target.checked, supplier: supplier });
  }
  handleIsRecurringChange(e) {
    this.setState({ isRecurring: e.target.checked });
  }

  handleRemarkChange(e) {
    this.setState({ description: e.target.value });
  }

  handleExchangeRateChange(e) {
    this.setState({ exchangerate: e.target.value });
  }

  handlePurchaseOrderChange(e) {
    this.setState({ purchaseorder: e.target.value });
  }

  addAuditNote(e) {
    this.setState({ auditNote: e.target.value });
  }
  updateBankAccount(e, bankAccount) {
    if (Utils.isNotNull(bankAccount)) {
      this.setState({ bankaccount: bankAccount });
    }
  }
  updateCustomer(e, customer) {
    if (Utils.isNotNull(customer.addressvo)) {
      this.setState({
        customerVo: customer,
        billtoaddress: customer.addressvo,
        sharewithcust: customer.shareinvoices,
      });
      if (
        Utils.isNotNull(customer.addressvo.statevo) &&
        Utils.isNotNull(customer.addressvo.statevo.countryvo)
      ) {
        this.setState({
          currency: customer.addressvo.statevo.countryvo.currency,
        });
        this.setExchangeRateForCustomer(customer);
      }
      this.customerSalesInvoiceList = [];
      this.setState({ salesInvoiceNumber: "" });
      let postObject = {
        customerVo: customer,
        header: this.header,
      };
      /* Get SA Invoice For Slected Customer */
      FetchServerData.callPostService(
        "/customerms/getSalesInvoices",
        postObject
      ).then((output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoices = output.data.invoices;
          if (Utils.isNotNull(invoices)) {
            this.customerSalesInvoiceList = invoices;
            this.setState({ customerVo: customer });
          }
        } else {
        }
      });
    } else {
      this.setState({ customerVo: customer });
    }
  }

  updateBusinessUnit(e, bu) {
    this.setState({ bu: bu });
  }

  updateSalesInvoice(e, av) {
    this.setState({ salesInvoiceNumber: av });
  }
  updateReferenceSalesInvoice(e, voucher) {
    this.setState({
      refinvoice: voucher,
      referenceinvnumber: voucher.invnumber,
    });
  }
  updateSezInvoiceType(e, seztype) {
    this.setState({ sezinvoicetype: seztype });
  }
  setExchangeRate(currency) {
    if (Utils.isNull(currency)) {
      return;
    }
    if (
      Utils.isExchangeRateApplicableForCurrency(currency, this.defaultCurrency)
    ) {
      this.setState({ isexport: true });
    } else {
      this.setState({ exchangerate: 1, isexport: false });
      return;
    }
    let base = this.defaultCurrency.isocode;
    let invoiceDate = this.state.invoicedate;
    if (
      !Utils.isNotNullAndEmpty(currency) ||
      !Utils.isNotNullAndEmpty(invoiceDate)
    ) {
      return;
    }
    if (!Utils.isSupportedCurrency(currency.isocode)) {
      PtsAlert.warn('Currency "' + currency.name + '" Not Supported');
      return;
    }

    let postObject = {
      targetCurrency: currency.isocode,
      currencyRate: {
        basecurrency: base,
        date: invoiceDate,
      },
      header: this.header,
    };

    FetchServerData.callPostService(
      "/exchangeratems/getExchangeRate",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        // let rates = JSON.parse(output.data.rates);
        let rate = output.data;
        if (Utils.isNotNull(rate.value)) {
          let value = rate.value;
          let exchangeRate = 1 / parseFloat(value);
          this.setState({ exchangerate: exchangeRate });
          return;
        } else {
          this.setState({ exchangerate: "" });
        }
      } else {
      }
    });
  }
  setExchangeRateForCustomer(customer) {
    if (
      Utils.isNull(customer) ||
      Utils.isNull(customer.addressvo) ||
      Utils.isNull(customer.addressvo.statevo) ||
      Utils.isNull(customer.addressvo.statevo.countryvo)
    ) {
      return;
    }
    if (
      Utils.isExchangeRateApplicableForCountry(
        customer.addressvo.statevo.countryvo,
        this.defaultCountry
      )
    ) {
      this.setState({ isexport: true });
    } else {
      this.setState({ exchangerate: 1, isexport: false });
      return;
    }
    let currency = customer.addressvo.statevo.countryvo.currency;
    this.setExchangeRate(currency);
  }
  etExchangeRate;

  updateExportType(e, expType) {
    this.setState({ expinvoicetype: expType });
  }
  updateCurrency(e, currency) {
    this.state.currency = currency;
    if (currency.isocode === this.defaultCurrency.isocode) {
    } else {
      this.setExchangeRate(currency);
    }
    this.setState({ currency: currency });
    this.doCalculations();
  }
  onPaymentChange(paymentList) {
    // this.state.paymentTransactions = paymentList;
    this.setState({ paymentTransactions: paymentList });
    // this.setState({ paymentTransactions: paymentList });
  }

  /* RefundVoucher Item Functionality */
  addRefundVoucherItem() {
    let items = this.state.invoiceItems;

    let item = SalesUtils.createEmptyInvoiceItem();
    items.push(item);
    // this.itemReferenceList.push(React.createRef());
    this.setState({ invoiceitems: items });
  }

  doCalculations() {
    let totalAmount = 0.0;
    let totalTaxable = 0.0;
    let totalCgst = 0.0;
    let totalSgst = 0.0;
    let totalIgst = 0.0;
    let totalCess = 0.0;

    for (let i = 0; i < this.itemReferenceList.length; i++) {
      if (this.itemReferenceList[i] && this.itemReferenceList[i].current) {
        let taxable = 0.0;
        let cgst = 0.0;
        let sgst = 0.0;
        let igst = 0.0;
        let cess = 0.0;
        let total = 0.0;
        if (!this.itemReferenceList[i].current?.state?.isDelete) {
          taxable = Utils.getFloatValue(
            this.itemReferenceList[i].current?.state.taxable
          );
          cgst = Utils.getFloatValue(
            this.itemReferenceList[i].current?.state.cgst
          );
          sgst = Utils.getFloatValue(
            this.itemReferenceList[i].current?.state.sgst
          );
          igst = Utils.getFloatValue(
            this.itemReferenceList[i].current?.state.igst
          );
          cess = Utils.getFloatValue(
            this.itemReferenceList[i].current?.state.cess
          );
          total = Utils.getFloatValue(
            this.itemReferenceList[i].current?.state.total
          );
        }
        if (cess > 0) {
          let calculatedCess = (parseFloat(taxable) * parseFloat(cess)) / 100;
          totalCess = parseFloat(totalCess) + parseFloat(calculatedCess);
        }
        totalTaxable = parseFloat(totalTaxable) + parseFloat(taxable);
        totalCgst = parseFloat(totalCgst) + parseFloat(cgst);
        totalSgst = parseFloat(totalSgst) + parseFloat(sgst);
        totalIgst = parseFloat(totalIgst) + parseFloat(igst);
        total =
          parseFloat(taxable) +
          parseFloat(cgst) +
          parseFloat(sgst) +
          parseFloat(igst) +
          parseFloat(totalCess);
        totalAmount = parseFloat(totalAmount) + parseFloat(total);
      }
    }
    let miscellaneous = Utils.getFloatValue(this.state.miscellaneous);
    totalAmount = parseFloat(totalAmount) + parseFloat(miscellaneous);
    let amountInWords = Utils.getAmountInWords(
      totalAmount,
      this.state.currency
    );

    this.setState({
      totaltaxable: parseFloat(totalTaxable).toFixed(2),
      sgst: parseFloat(totalSgst).toFixed(2),
      cgst: parseFloat(totalCgst).toFixed(2),
      igst: parseFloat(totalIgst).toFixed(2),
      cess: parseFloat(totalCess).toFixed(2),
      totalamount: parseFloat(totalAmount).toFixed(2),
      totalinwords: amountInWords,
      // advanceAmountUsed: advanceUsed,
      // advanceGstUsed: gstUsed,
    });
  }

  invoiceItemChanged(item) {
    this.doCalculations();
  }

  invoiceItemDeleted(item) {
    let items = [];
    let itemToTrash = -999;
    for (let i = 0; i < this.state.invoiceItems.length; i++) {
      let invItem = this.state.invoiceItems[i];
      if (Utils.equalsIgnoreCase(this.state.action, "create")) {
        if (item.isDelete === true && item.index === i) {
          itemToTrash = i;
          continue;
        } else {
          invItem =
            this.itemReferenceList[i] && this.itemReferenceList[i].current
              ? this.itemReferenceList[i].current.state
              : invItem;
          items.push(invItem);
        }
      } else {
        if (item.isDelete === true && item.index === i) {
          if (Utils.equalsIgnoreCase(item.operation, "add")) {
            itemToTrash = i;
            continue;
          }
          invItem.operation = "delete";
        } else {
          invItem =
            this.itemReferenceList[i] && this.itemReferenceList[i].current
              ? this.itemReferenceList[i].current.state
              : invItem;
        }
        items.push(invItem);
      }
    }

    for (let i = 0; i < this.state.invoiceItems.length; i++) {
      if (i === itemToTrash) {
      }
    }

    let randomKey = Math.random();
    this.setState({ refreshKey: randomKey, invoiceItems: items });
    this.doCalculations();
  }

  generateRefundVoucherNumber(e) {
    // let invDate = this.state.invdate;
    let invDate = moment(this.state.invoicedate).format("YYYY-MM-DD");
    if (undefined === invDate && null === invDate && invDate.length <= 0) {
      document.getElementById("invoicedate").focus();
      PtsAlert.error("Please Select Invoice Date");
      return;
    }
    let documentFormat = {
      forDate: invDate,
      documenttype: this.state.invoicetypeVo,
    };
    let postObject = {
      documentparseFloat: documentFormat,
      header: this.header,
    };
    FetchServerData.callPostService("/docnumms/generateSales", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let newparseFloat = output.data.genratedparseFloat;
          this.setState({ invnumber: newparseFloat });
        } else {
        }
      }
    );
  }

  createAddressObject(address) {
    let addressvo = {
      id: address.id,
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      pin: address.pincode,
      statevo: {
        id: address.state.id,
        name: address.state.name,
        code: address.state.code,
        countryvo: {
          id: address.country.id,
          name: address.country.name,
          code: address.country.code,
        },
      },
    };
    return addressvo;
  }
  /* Validator */
  validateRefundVoucher(e) {
    e.preventDefault();
    /* Validate Items */
    for (let k = 0; k < this.state.invoiceItems.length; k++) {
      if (this.itemReferenceList[k] && this.itemReferenceList[k].current) {
        /* Set latest Values to Object */
        let currentItem = SalesUtils.createInvoiceItem(
          this.itemReferenceList[k].current.state
        );
        this.state.invoiceItems[k] = currentItem;
        if (!this.itemReferenceList[k].current.validateItem()) {
          return false;
        }
      }
    }
    let isBillingShippingSame = this.state.isbillingshippingsame;
    if (Utils.equalsIgnoreCase(isBillingShippingSame, "No")) {
      if (this.shippingAddressRef && this.shippingAddressRef.current) {
        /*Validate Shipping Address */
        if (!this.shippingAddressRef.current.validateAddress()) {
          return false;
        } else {
          let shippingAddress = this.createAddressObject(
            this.shippingAddressRef.current.state
          );
          this.state.shiptoaddress = shippingAddress;
        }
      } else {
        PtsAlert.error("Shipping Address not provided");
        return;
      }
    }

    if (Utils.isNull(this.state.invoicedate)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Refund Voucher Date is required");
      return false;
    } else {
      let currentDate = new Date();
      let invDate = new Date(this.state.invoicedate);
      let Difference_In_Time = currentDate.getTime() - invDate.getTime();
      // To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 540) {
        PtsAlert.error("Refund Voucher Date is too old");
        return false;
      }

      Difference_In_Time = invDate.getTime() - currentDate.getTime();
      // To calculate the no. of days between two dates
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 31) {
        PtsAlert.error("Future Refund Voucher Date");
        return false;
      }
    }

    if (Utils.isNull(this.state.gstdate)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Refund Voucher GST Date is required");
      return false;
    }
    if (Utils.isNull(this.state.invnumber)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Refund Voucher Number is required");
      return false;
    }

    if (Utils.isNull(this.state.bu)) {
      PtsAlert.error("Please Select Business Unit ");
      return false;
    }

    return true;
  }
  /* CallBack Functions for Dynamic Buttons created Based on RefundVoucher Status */
  requestVerification(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/workflow/sentToAudit", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "Verification Requested for RefundVoucher " + this.state.invnumber
          );
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setResponseToState(invoice);
          // this.props.history.push({
          //   pathname: '/invoiceView',
          //   state: { invoiceId: this.state.id, action: 'view' },
          // });

          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/sales_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/rf/",
            });
          }
        } else {
        }
      }
    );
  }

  rejectVerification(e) {
    let rejectionNote = this.state.auditNote ? this.state.auditNote : "";
    rejectionNote = rejectionNote.toString().trim();
    let auditNotes = [];
    if (rejectionNote.length > 0) {
      auditNotes.push({
        note: rejectionNote,
        createddate: new Date(),
      });
    } else {
    }

    let postObject = {
      invoice: { id: this.state.id, auditnotes: auditNotes },
      header: this.header,
    };
    FetchServerData.callPostService("/workflow/rejectAudit", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setState({ auditnotes: invoice.auditnotes, render: true, action: 'view' });
          PtsAlert.success(
            "Verification Rejected On Refund Voucher " + this.state.invnumber
          );
          // this.setResponseToState(invoice);
          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/sales_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/rf/",
            });
          }
        } else {
        }
      }
    );
  }

  verify(e) {
    let verificationNote = this.state.auditNote ? this.state.auditNote : "";
    verificationNote = verificationNote.toString().trim();
    let auditNotes = [];
    if (verificationNote.length > 0) {
      auditNotes.push({
        note: verificationNote,
        createddate: new Date(),
      });
    } else {
    }
    let postObject = {
      invoice: { id: this.state.id, auditnotes: auditNotes },
      header: this.header,
    };
    FetchServerData.callPostService("/workflow/approveAudit", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "Refund Voucher " + this.state.invnumber + " Verified"
          );
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setResponseToState(invoice);

          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/sales_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/rf/",
            });
          }
        } else {
        }
      }
    );
  }
  revertVerification(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/workflow/revertAudit", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "Verification Reverted On RefundVoucher " +
              this.state.invnumber +
              " "
          );
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setResponseToState(invoice);
          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/sales_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/rf/",
            });
          }
        } else {
        }
      }
    );
  }
  modify(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/workflow/correctRejected",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        PtsAlert.success(
          "Refund Voucher " + this.state.invnumber + " Set for Modification"
        );
        // let invoice = output.data;
        // invoice.privileges = this.state.privileges;
        // this.setResponseToState(invoice);
        if (this.state.isHistory && this.state.isHistory === true) {
          this.props.history.push({
            pathname: "/sales_invoicesearch/",
          });
        } else {
          this.props.history.push({
            pathname: "/rf/",
          });
        }
      } else {
      }
    });
  }

  markCancel(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/workflow/cancel", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "Refund Voucher " + this.state.invnumber + " Set for Modification"
          );
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setResponseToState(invoice);
          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/sales_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/rf/",
            });
          }
        } else {
        }
      }
    );
  }

  revertCancel(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/workflow/revertCancel", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "Refund Voucher " + this.state.invnumber + " Set for Modification"
          );
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setResponseToState(invoice);
          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/sales_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/rf/",
            });
          }
        } else {
        }
      }
    );
  }

  /* Backend Call to Populate Refund Voucher */
  populateSalesInvoice(e) {
    if (
      Utils.isNull(this.state.salesInvoiceNumber) ||
      Utils.isNull(this.state.salesInvoiceNumber.id)
    ) {
      return;
    }
    const postObject = {
      invoice: { id: this.state.salesInvoiceNumber.id },
      header: this.header,
    };
    FetchServerData.callPostService("/sams/get", postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        let salesInvoice = output.data;
        let newItemList = [];
        this.setState({ invoiceItems: [] });
        for (let i = 0; i < salesInvoice.invoiceItems.length; i++) {
          let item = salesInvoice.invoiceItems[i];
          item.operation = "add";
          newItemList.push(item);
          // this.itemReferenceList.push(React.createRef());
        }
        if (salesInvoice.shiptoaddress) {
          salesInvoice.shiptoaddress.id = null;
          // this.shippingAddressRef = React.createRef();
        }
        this.setState({
          expinvoicetype: salesInvoice.expinvoicetype,
          sezinvoicetype: salesInvoice.sezinvoicetype,
          shiptoaddress: salesInvoice.shiptoaddress,
          bu: salesInvoice.bu,
          sharewithcust: salesInvoice.sharewithcust,
          isAsset: salesInvoice.isAsset,
          isbillingshippingsame: salesInvoice.isbillingshippingsame,
          isexport: salesInvoice.isexport,
          isimported: salesInvoice.isimported,
          ismanualrefinv: salesInvoice.ismanualrefinv,
          isnilrated: salesInvoice.isnilrated,
          ispregst: salesInvoice.ispregst,
          isrcmsale: salesInvoice.isrcmsale,
          isRecurring: salesInvoice.isRecurring,
          isroundup: salesInvoice.isroundup,
          issez: salesInvoice.issez,
          refinvoice: salesInvoice,
          invoiceItems: newItemList,
          referenceinvnumber: salesInvoice.invnumber,
          salesthrecom: salesInvoice.salesthrecom,
          etin: { etin: salesInvoice.etin },
        });
        this.doCalculations();
      } else {
      }
    });
  }
  /* Backend Calls to CREATE/GET/UPDATE/DELETE RefundVoucher */
  deleteRefundVoucherInBackend() {
    const postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };

    FetchServerData.callPostService("/rfms/delete", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success("Refund Voucher Deleted ");
          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/sales_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/rf/",
              state: { invoiceId: this.state.id, action: "view" },
            });
          }
        } else {
        }
      }
    );
  }

  getRefundVoucherFromBackend(id) {
    const postObject = {
      invoice: { id: id },
      header: this.header,
    };
    FetchServerData.callPostService("/rfms/get", postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        let invoice = output.data;
        if (Utils.isNotNull(invoice.refinvoice)) {
          // let isAdvanceReceived = true;
          let salesInvoiceNumber = {
            id: invoice.refinvoice.id,
            invoiceNumber: invoice.refinvoice.invnumber,
          };
          this.setState({
            isAdvanceReceived: true,
            salesInvoiceNumber: salesInvoiceNumber,
          });
        }
        for (let i = 0; i < invoice.invoiceItems.length; i++) {
          // let ref = React.createRef();
          // this.itemReferenceList.push(ref);
          invoice.invoiceItems[i].operation = "update";
        }

        // this.privilegeList = invoice.privileges;
        // this.shippingAddressRef = React.createRef;
        // this.billingAddressRef = React.createRef;
        this.setResponseToState(invoice);
      } else {
      }
    });
  }

  updateRefundVoucherInBackend(e) {
    if (!this.validateRefundVoucher(e)) {
      return;
    }

    let invoiceData = {};
    invoiceData = SalesUtils.setInvoice(invoiceData, this.state);
    invoiceData.isAdvanceReceived = this.state.isAdvanceReceived;
    for (let i = 0; i < invoiceData.invoiceItems.length; i++) {
      const element = invoiceData.invoiceItems[i];
      if (element.quantity <= 0) {
        PtsAlert.error(
          `Quantity for ${element.name} should be greater then zero`
        );
        return;
      }
    }
    const postObject = {
      invoice: invoiceData,
      header: this.header,
    };

    FetchServerData.callPostService("/rfms/update", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success("Updated Successfully");
          // let invoice = output.data;
          // for (let i = 0; i < invoice.invoiceItems.length; i++) {
          //   let ref = React.createRef();
          //   this.itemReferenceList.push(ref);
          //   invoice.invoiceItems[i].operation = 'update';
          // }
          // this.setResponseToState(invoice);

          // this.setState({ render: true, action: 'view' });
          this.props.history.push({
            pathname: "/refundVoucher",
            state: {
              invoiceId: this.state.id,
              action: "view",
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
        } else {
        }
      }
    );
  }

  createRefundVoucherInBackend(e) {
    e.preventDefault();
    if (!this.validateRefundVoucher(e)) {
      return;
    }
    let invoiceData = {};
    invoiceData = SalesUtils.setInvoice(invoiceData, this.state);
    for (let i = 0; i < invoiceData.invoiceItems.length; i++) {
      const element = invoiceData.invoiceItems[i];
      if (element.quantity <= 0) {
        PtsAlert.error(
          `Quantity for ${element.name} should be greater then zero`
        );
        return;
      }
    }
    const postObject = {
      invoice: invoiceData,
      header: this.header,
    };

    FetchServerData.callPostService("/rfms/create", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          for (let i = 0; i < invoice.invoiceItems.length; i++) {
            // let ref = React.createRef();
            // this.itemReferenceList.push(ref);
            invoice.invoiceItems[i].operation = "update";
          }
          this.setResponseToState(invoice);
          // this.setState({ render: true, action: 'view' });
          let salesRefundNoteState = {
            invoiceId: invoice.id,
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
          };
          store.dispatch(
            ApplicationStateAction.setApplicationState({ salesRefundNoteState })
          );
          this.props.history.push({
            pathname: "/refundVoucher",
            state: {
              invoiceId: this.state.id,
              action: "view",
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
        } else {
        }
      }
    );
  }

  /* Go Back Functionality Implementation */
  cancelEdit(e) {
    // this.setState({ action: 'view', render: false });
    // this.getRefundVoucherFromBackend(this.state.id);
    // this.setState({ action: 'view' });
    this.props.history.push({
      pathname: "/refundVoucher",
      state: {
        invoiceId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  cancelView(e) {
    if (this.state.isHistory && this.state.isHistory === true) {
      this.props.history.push({
        pathname: "/sales_invoicesearch/",
      });
    } else {
      this.props.history.push({
        pathname: "/rf/",
        state: {},
      });
    }
  }

  generateInvoiceNumber(e) {
    if (this.state.invoicedate) {
    } else {
      PtsAlert.error("Please Select Invoice Date");
      return;
    }
    let invDate = moment(this.state.invoicedate).format("YYYY-MM-DD");

    let documentFormat = {
      forDate: invDate,
      documenttype: this.state.invoicetypeVo,
    };
    let postObject = {
      documentNumber: documentFormat,
      header: this.header,
    };
    FetchServerData.callPostService("/docnumms/generateSales", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let newNumber = output.data.genratedNumber;
          this.setState({ invnumber: newNumber });
        } else {
        }
      }
    );
  }
  backToHistory(e) {
    this.props.history.push({
      pathname: "/sales_invoicesearch/",
    });
  }
  /* Get Bttons Based on Invoice Status and Permissions*/
  getButtonsAsPerStatusAndPermission() {
    let status = this.state.status ? this.state.status?.status : "";
    if (Utils.equalsIgnoreCase("CREATED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {(this.state.isexport === true ||
            Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              onClick={(e) => this.showReferenceINRInvoice(e)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ fontSize: "15px" }}
              ></i>{" "}
              &nbsp; View In INR
            </Button>
          )}
          <Button
            type="submit"
            size="small"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.requestVerification(e)}
          >
            Request Verification
          </Button>

          <Button
            type="submit"
            size="small"
            variant="contained"
            style={{
              background: "#9a7031",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<CancelIcon />}
            onClick={(e) => this.markCancel(e)}
          >
            Mark Cancel
          </Button>
          {(Utils.isNull(this.state.isHistory) ||
            this.state.isHistory === false) && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelView(e)}
            >
              Back
            </Button>
          )}
          {this.state.isHistory && this.state.isHistory === true && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.backToHistory(e)}
            >
              Back to History
            </Button>
          )}
        </div>
      );
    } else if (Utils.equalsIgnoreCase("CANCELLED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {(this.state.isexport === true ||
            Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              onClick={(e) => this.showReferenceINRInvoice(e)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ fontSize: "15px" }}
              ></i>{" "}
              &nbsp; View In INR
            </Button>
          )}
          <Button
            type="submit"
            size="small"
            variant="contained"
            style={{
              background: "#9a7031",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<SettingsBackupRestoreIcon />}
            onClick={(e) => this.revertCancel(e)}
          >
            Revert Cancel
          </Button>
          {(Utils.isNull(this.state.isHistory) ||
            this.state.isHistory === false) && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelView(e)}
            >
              Back
            </Button>
          )}
          {this.state.isHistory && this.state.isHistory === true && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.backToHistory(e)}
            >
              Back to History
            </Button>
          )}
        </div>
      );
    } else if (Utils.equalsIgnoreCase("VERIFICATIONREJECTED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {(this.state.isexport === true ||
            Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              onClick={(e) => this.showReferenceINRInvoice(e)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ fontSize: "15px" }}
              ></i>{" "}
              &nbsp; View In INR
            </Button>
          )}
          <Button
            type="submit"
            size="small"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<UpdateIcon />}
            onClick={(e) => this.modify(e)}
          >
            Modify
          </Button>
          {(Utils.isNull(this.state.isHistory) ||
            this.state.isHistory === false) && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelView(e)}
            >
              Back
            </Button>
          )}
          {this.state.isHistory && this.state.isHistory === true && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.backToHistory(e)}
            >
              Back to History
            </Button>
          )}
        </div>
      );
    } else if (Utils.equalsIgnoreCase("VERIFICATIONREQUESTED", status)) {
      if (Utils.hasPrivilege("APPROVE", this.state.privileges)) {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {(this.state.isexport === true ||
              Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#1b7189",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                onClick={(e) => this.showReferenceINRInvoice(e)}
              >
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  style={{ fontSize: "15px" }}
                ></i>{" "}
                &nbsp; View In INR
              </Button>
            )}
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ThumbDownIcon />}
              onClick={(e) => this.rejectVerification(e)}
            >
              Reject Verification
            </Button>

            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<VerifiedUserIcon />}
              onClick={(e) => this.verify(e)}
            >
              Verify
            </Button>
            {(Utils.isNull(this.state.isHistory) ||
              this.state.isHistory === false) && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            )}
            {this.state.isHistory && this.state.isHistory === true && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backToHistory(e)}
              >
                Back to History
              </Button>
            )}
          </div>
        );
      } else {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {(Utils.isNull(this.state.isHistory) ||
              this.state.isHistory === false) && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            )}
            {this.state.isHistory && this.state.isHistory === true && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backToHistory(e)}
              >
                Back to History
              </Button>
            )}
          </div>
        );
      }
    } else if (Utils.equalsIgnoreCase("VERIFIED", status)) {
      if (Utils.hasPrivilege("APPROVE", this.state.privileges)) {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {(this.state.isexport === true ||
              Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#1b7189",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                onClick={(e) => this.showReferenceINRInvoice(e)}
              >
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  style={{ fontSize: "15px" }}
                ></i>{" "}
                &nbsp; View In INR
              </Button>
            )}
            <Button
              type="submit"
              size="small"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<SettingsBackupRestoreIcon />}
              onClick={(e) => this.revertVerification(e)}
            >
              Revert Verification
            </Button>
            {(Utils.isNull(this.state.isHistory) ||
              this.state.isHistory === false) && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            )}
            {this.state.isHistory && this.state.isHistory === true && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backToHistory(e)}
              >
                Back to History
              </Button>
            )}
          </div>
        );
      } else {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {(Utils.isNull(this.state.isHistory) ||
              this.state.isHistory === false) && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            )}
            {this.state.isHistory && this.state.isHistory === true && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backToHistory(e)}
              >
                Back to History
              </Button>
            )}
          </div>
        );
      }
    }
  }

  /* Get Menu Buttons based on Invoice Status and Permissions */
  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];

    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }
    if (Utils.equalsIgnoreCase(this.state.status?.status, "CREATED")) {
      if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
        buttonList = [...buttonList, "EDIT"];
      }
      if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
        buttonList = [...buttonList, "DELETE"];
      }
    } else if (
      Utils.equalsIgnoreCase(this.state.status?.status, "VERIFICATIONREQUESTED")
    ) {
      buttonList = ["PRINT"];
    } else if (
      Utils.equalsIgnoreCase(this.state.status?.status, "VERIFICATIONREJECTED")
    ) {
      buttonList = ["PRINT"];
    } else {
      buttonList = ["PRINT"];
    }

    // if (
    //   Utils.hasPrivilege("UPDATE", this.state.privileges) &&
    //   Utils.hasPrivilege("DELETE", this.state.privileges)
    // ) {
    // } else if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
    //   for (let i = 0; i < buttonList.length; i++) {
    //     if (buttonList[i] === "DELETE") {
    //       buttonList.splice(i, 1);
    //     }
    //   }
    // } else if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
    //   for (let i = 0; i < buttonList.length; i++) {
    //     if (buttonList[i] === "UPDATE") {
    //       buttonList.splice(i, 1);
    //     }
    //   }
    // }
    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      let printCallback = null;
      let createSimilarCallback = null;
      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      if (buttonList.includes("PRINT")) {
        printCallback = this.printButtonClicked.bind(this);
        createSimilarCallback = this.createSimilarButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
        printCallback: printCallback,
        createSimilarCallback: createSimilarCallback,
      });
    }
  }

  getSalesInvoiceDetails() {
    if (
      true === this.state.isAdvanceReceived &&
      Utils.isNotNullAndEmpty(this.state.refinvoice)
    ) {
      return (
        <>
          <Row md={12} style={{ padding: "5px" }}>
            <Col
              md={12}
              style={{
                background: "#c5dde4",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
              }}
            >
              Sales Invoice Details
            </Col>
          </Row>

          <Row md={12} style={{ padding: "5px" }}>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Advance Amt.
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              CESS %
            </Col>

            {Utils.isIgstApplicable(this.state.customerVo) !== true && (
              <>
                <Col
                  md={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  CGST
                </Col>
                <Col
                  md={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  SGST
                </Col>
              </>
            )}

            {Utils.isIgstApplicable(this.state.customerVo) !== false && (
              <Col
                md={2}
                style={{
                  background: "#ececec",
                  color: "#000",
                  fontWeight: "bold",
                  border: "0.5px solid #ece6e6",
                  padding: "0.5em",
                  textAlign: "right",
                }}
              >
                IGST
              </Col>
            )}

            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Total
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Utilized GST
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Utilized Taxable
            </Col>
          </Row>
          <Row md={12} style={{ padding: "5px" }}>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.totaltaxable}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.cess}
            </Col>

            {Utils.isIgstApplicable(this.state.customerVo) !== true && (
              <>
                <Col
                  md={1}
                  style={{
                    border: "0.5px solid #ece6e6",
                    textAlign: "right",
                    color: "#000",
                  }}
                >
                  {this.state.refinvoice.cgst}
                </Col>
                <Col
                  md={1}
                  style={{
                    border: "0.5px solid #ece6e6",
                    textAlign: "right",
                    color: "#000",
                  }}
                >
                  {this.state.refinvoice.sgst}
                </Col>
              </>
            )}

            {Utils.isIgstApplicable(this.state.customerVo) !== false && (
              <Col
                md={2}
                style={{
                  border: "0.5px solid #ece6e6",
                  textAlign: "right",
                  color: "#000",
                }}
              >
                {this.state.refinvoice.igst}
              </Col>
            )}

            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.totalamount}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.utilizedGst}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.utilizedAmount}
            </Col>
          </Row>
        </>
      );
    } else {
      return "";
    }
  }

  showReferenceINRInvoice() {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current &&
      this.state.invnumber
    ) {
      this.referenceInvoiceDialogueRef.current.showViewInINRInvoice(
        this.state.invnumber
      );
    }
  }

  showReferenceInvoice() {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current &&
      this.state.referenceinvnumber
    ) {
      this.referenceInvoiceDialogueRef.current.showAdvanceInvoice(
        this.state.referenceinvnumber,
        true
      );
    }
  }

  // handleRoundUpCheckBox = (e) => {
  //   let totalRoundValue = 0.0;
  //   if (e.target.checked === true) {
  //     totalRoundValue = Utils.getRoundValue(
  //       this.state.totalamount === "NaN"
  //         ? this.state.totaltaxable
  //         : this.state.totalamount
  //     );
  //   } else {
  //     totalRoundValue =
  //       this.state.totalamount === "NaN"
  //         ? this.state.totaltaxable
  //         : this.state.totalamount;
  //   }
  //   console.log(this.state.totalamount);
  //   console.log(e.target.checked);
  //   this.setState({
  //     isroundup: e.target.checked,
  //     totalamount: totalRoundValue,
  //   });
  // };
  handleRoundUpCheckBox = (e) => {
    // let totalRoundValue = this.state.totalamount;

    if (e.target.checked === true) {
      this.state.totalamount = Utils.getRoundValue(
        this.state.totalamount === "NaN"
          ? this.state.totaltaxable
          : this.state.totalamount
      );
    } else {
      this.doCalculations();
      // totalRoundValue = this.state.totalamount;
    }
    this.setState({
      isroundup: e.target.checked,
      // totalamount: totalRoundValue,
    });
  };

  onCopy() {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);

    this.setState({
      copied: true,
    });
  }

  /*Main Render Function */
  render() {
    if (this.state.render === false) {
      return "";
    }
    /* Create References for Calculations */
    this.billingAddressRef = React.createRef();
    this.shippingAddressRef = React.createRef();
    this.menuWithBreadScrumRef = React.createRef();
    this.auditNoteListRef = React.createRef();
    this.referenceInvoiceDialogueRef = React.createRef();
    this.state.invoiceItems &&
      this.state.invoiceItems.forEach((element, index) => {
        this.itemReferenceList[index] = React.createRef();
      });
    if (this.state.action === "create") {
      return this.createRefundVoucher();
    } else if (this.state.action === "edit") {
      return this.editRefundVoucher();
    } else {
      return this.viewRefundVoucher();
    }
  }

  editRefundVoucher() {
    let invoiceItemsList = [];
    /* Create List of RefundVoucher Item */
    let isIgstApplicable = Utils.isIgstApplicable(this.state.customerVo);
    let itemLoc = 1;
    let newCustHsnCodeList = [];

    for (let i = 0; i < this.custHsnCodeList.length; i++) {
      const element = this.custHsnCodeList[i];
      if (element.isnilrated === this.state.isnilrated) {
        newCustHsnCodeList.push(element);
      }
    }

    this.state.invoiceItems &&
      this.state.invoiceItems.forEach((element, index) => {
        if (Utils.isNull(this.itemReferenceList[index])) {
          this.itemReferenceList[index] = React.createRef();
        }
        if ("delete" !== element.operation) {
          element.itemsrno = itemLoc++;
          let itemkey = index + "-" + this.state.refreshKey;
          invoiceItemsList.push(
            <RefundVoucherItem
              isIgstApplicable={isIgstApplicable}
              ref={this.itemReferenceList[index]}
              backgroundColor={
                index % 2
                  ? { background: "#EFF4F7" }
                  : { background: "#F7F7F7" }
              }
              key={itemkey}
              data={element}
              srno={index}
              action="edit"
              productList={this.productList}
              custHsnCodeList={newCustHsnCodeList}
              doCalculations={this.doCalculations.bind(this)}
              onDelete={this.invoiceItemDeleted.bind(this)}
              onChange={this.invoiceItemChanged.bind(this)}
            />
          );
        }
      });

    let billingAddress = (
      <Address
        ref={this.billingAddressRef}
        title="Billing Address"
        data={this.state.billtoaddress}
        action="view"
      />
    );

    let shippingAddress = "";
    if (
      this.state.shiptoaddress &&
      (null === this.state.isbillingshippingsame ||
        Utils.equalsIgnoreCase(this.state.isbillingshippingsame, "No"))
    ) {
      shippingAddress = (
        <Address
          ref={this.shippingAddressRef}
          title="Shipping Address"
          data={this.state.shiptoaddress}
          action="edit"
        />
      );
    }

    /* Check if Currency Different than Default Currency */
    let isExchangeRateMandator = false;
    if (
      undefined !== this.state.customerVo &&
      this.state.customerVo &&
      this.state.customerVo.addressvo
    ) {
      let address = this.state.customerVo.addressvo;
      if (
        (isExchangeRateMandator = Utils.isExchangeRateApplicableForCountry(
          address.statevo.countryvo,
          this.defaultCountry
        ))
      ) {
        this.state.exchangeRate = 1;
        isExchangeRateMandator = true;
      }
    }

    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Edit Refund Voucher"
          history={this.props.history}
          name="Edit Refund Voucher"
        />
        <ValidatorForm
          style={{ width: "100%", color: "#000", padding: "0.5em" }}
          ref="form"
          onSubmit={this.updateRefundVoucherInBackend.bind(this)}
        >
          <Card md={12}>
            <CardHeader style={{ margin: "0px", padding: "0.5em" }}>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Customer<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    disabled
                    disableClearable
                    id="customer"
                    ListboxProps={{ className: "myCustomList" }}
                    options={
                      undefined === this.customerList ||
                      null === this.customerList
                        ? []
                        : this.customerList
                    }
                    value={this.state.customerVo}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateCustomer(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        className="regCountry"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
                {Utils.isNotNull(this.state.customerVo) && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Sales Invoice<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disabled
                        disableClearable
                        id="salesInvoiceReference"
                        ListboxProps={{ className: "myCustomList" }}
                        options={
                          this.customerSalesInvoiceList
                            ? this.customerSalesInvoiceList
                            : []
                        }
                        value={
                          this.state.salesInvoiceNumber
                            ? this.state.salesInvoiceNumber
                            : null
                        }
                        getOptionLabel={(option) => option.invoiceNumber}
                        onChange={(event, value) => {
                          this.updateSalesInvoice(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth={true}
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            className="regCountry"
                            variant="outlined"
                          />
                        )}
                      />
                    </Col>
                    {/* <Col md={2} style={{ padding: '5px', textAlign: 'left' }}>
                      <Button
                        variant="contained"

                        style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                        startIcon={<SaveIcon />}
                        onClick={(e) => this.populateSalesInvoice(e)}
                      >
                        Verify
                      </Button>
                    </Col> */}
                  </>
                )}

                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Invoice Date<span className="asterisk"> *</span>
                  </label>
                  {/* <TextField
                    required
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    id="invoicedate"
                    onChange={this.handleRefundVoucherDateChange.bind(this)}
                    onBlur={this.handleRefundVoucherDateBlurChange.bind(this)}
                    value={this.state.invoicedate}
                    InputLabelProps={{ shrink: true }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        value={this.state.invoicedate}
                        // variant="inline"
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        fullWidth
                        variant="outlined"
                        id="invoicedate"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        onChange={this.handleRefundVoucherDateChange.bind(this)}
                        onBlur={this.handleRefundVoucherDateBlurChange.bind(
                          this
                        )}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Invoice Number<span className="asterisk"> *</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      fullWidth={true}
                      variant="outlined"
                      inputProps={{ style: {} }}
                      id="invnumber"
                      type="text"
                      validators={["required"]}
                      onChange={this.handleRefundVoucherNumberChange.bind(this)}
                      value={this.state.invnumber ? this.state.invnumber : ""}
                      name="invnumber"
                      // InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <RefreshIcon
                              className="gstnBtn"
                              onClick={(e) => this.generateInvoiceNumber()}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <span
                      style={{
                        background: "#2471A3",
                        padding: "0.8em",
                        cursor: "pointer",
                      }}
                    >
                      <RefreshIcon
                        style={{ color: "#fff", fontWeight: "bold" }}
                        onClick={(e) => this.generateInvoiceNumber()}
                      ></RefreshIcon>

                    </span> */}
                    {/* <GenerateIconButton onClick={(e) => this.generateInvoiceNumber()} /> */}
                  </div>
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Remark
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    inputProps={{ style: {} }}
                    id="invoicenote"
                    type="text"
                    validators={["required"]}
                    onChange={this.handleRemarkChange.bind(this)}
                    // onBlur={this.handleNoteOnBlur.bind(this)}
                    value={this.state.description ? this.state.description : ""}
                    name="invoicenote"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Business Unit<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    // className="invoiceAutoComplete"
                    // style={{ padding: '0.5em' }}
                    disableClearable
                    disabled
                    id="businessUnit"
                    ListboxProps={{ className: "myCustomList" }}
                    options={this.businessUnitList ? this.businessUnitList : []}
                    value={this.state.bu ? this.state.bu : " "}
                    defaultValue={this.state.bu}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        placeholder="Business Unit"
                        InputLabelProps={{ shrink: true }}
                        className="regCountry"
                        variant="outlined"
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    style={{ color: "#000" }}
                    control={
                      <Checkbox
                        disabled
                        variant="outlined"
                        id="isnilrated"
                        checked={this.state.isnilrated}
                        value={this.state.isnilrated}
                        onChange={this.handleIsNillRatedChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Nil Rated?</span>}
                  />
                </Col>

                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    GST Date<span className="asterisk"> *</span>
                  </label>
                  {/* <TextField
                    required
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    onChange={this.handleGstDateChange.bind(this)}
                    value={this.state.gstdate ? this.state.gstdate : ""}
                    InputLabelProps={{ shrink: true }}
                  /> */}

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        value={this.state.gstdate}
                        // variant="inline"
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        fullWidth
                        variant="outlined"
                        id="ptsDateTimePicker"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        onChange={this.handleGstDateChange.bind(this)}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Col>
                {isExchangeRateMandator === true && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Currency
                      </label>
                      <Autocomplete
                        disableClearable
                        disabled
                        ListboxProps={{ className: "myCustomList" }}
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="currency"
                        options={this.currencyList ? this.currencyList : []}
                        value={this.state.currency}
                        defaultValue={this.state.currency}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.updateCurrency(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            style={{}}
                            InputLabelProps={{ shrink: true }}
                            className="regCountry"
                            variant="outlined"
                          />
                        )}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Exchange Rate
                      </label>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="exchangeRate"
                        type="text"
                        label="Exchange Rate"
                        onChange={this.handleExchangeRateChange.bind(this)}
                        // onBlur={this.handleGSTNOnBlur.bind(this)}
                        value={
                          this.state.exchangerate ? this.state.exchangerate : ""
                        }
                        name="exchangeRate"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Export Type
                      </label>
                      <Autocomplete
                        disableClearable
                        disabled
                        ListboxProps={{ className: "myCustomList" }}
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="expinvoicetype"
                        options={
                          this.exportInvoiceTypeList
                            ? this.exportInvoiceTypeList
                            : []
                        }
                        value={this.state.expinvoicetype}
                        defaultValue={this.state.expinvoicetype}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => {
                          this.updateExportType(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            style={{}}
                            InputLabelProps={{ shrink: true }}
                            className="regCountry"
                            variant="outlined"
                          />
                        )}
                      />
                    </Col>
                  </>
                )}
              </Row>

              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    disabled
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isSEZDeemedExport"
                        checked={
                          this.state.issez &&
                          Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        value={
                          this.state.issez &&
                          Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        onChange={this.handleIsSEZChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>SEZ/Deemed Export?</span>}
                  />
                </Col>

                {this.issalesthrecom && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <FormControlLabel
                        disabled
                        style={{
                          color: "#000",
                          width: "100%",
                          margin: "0",
                          padding: "0",
                        }}
                        control={
                          <Checkbox
                            variant="outlined"
                            id="isSalesThroughECOM"
                            checked={
                              this.state.salesthrecom &&
                              this.state.salesthrecom === true
                                ? true
                                : false
                            }
                            value={
                              this.state.salesthrecom &&
                              this.state.salesthrecom === true
                                ? true
                                : false
                            }
                            onChange={this.handleSalesThroughECOMChange.bind(
                              this
                            )}
                            style={{ background: "none" }}
                          />
                        }
                        label={<span style={{}}>Sales Through ECOM?</span>}
                      />
                    </Col>
                  </>
                )}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        disabled
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isAsset}
                        value={this.state.isAsset}
                        onChange={this.handleAssetChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Asset Sale?</span>}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="shareWithCustomer"
                        checked={this.state.sharewithcust}
                        value={this.state.sharewithcust}
                        onChange={this.handleShareWithCustomerChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Share With Customer?</span>}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                {Utils.equalsIgnoreCase(this.state.issez, "Yes") ? (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      SEZ Category<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      disabled
                      disableClearable
                      id="sezCategory"
                      ListboxProps={{ className: "myCustomList" }}
                      options={this.sezInvoiceTypeList}
                      value={this.state.sezinvoicetype}
                      defaultValue={this.state.sezinvoicetype}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, value) => {
                        this.updateSezInvoiceType(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth={true}
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          className="regCountry"
                          variant="outlined"
                        />
                      )}
                    />
                  </Col>
                ) : (
                  <Col md={2}></Col>
                )}
                {this.state.salesthrecom &&
                  this.state.salesthrecom === true && (
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        ETIN<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disabled
                        id="etin"
                        size="small"
                        ListboxProps={{ className: "myCustomList" }}
                        options={this.etinInfoList}
                        value={this.state.etin ? this.state.etin : null}
                        defaultValue={this.state.etin}
                        getOptionLabel={(option) => option.etin}
                        onChange={(event, value) => {
                          this.handleEtinChange(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth={true}
                            {...params}
                            variant="outlined"
                            className="regCountry"
                            placeholder="ETIN"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                  )}
              </Row>
              {this.state.customerVo && (
                <>
                  <Row
                    md={12}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      margin: "5px",
                      background: "#f8fcff",
                    }}
                  >
                    <Col md={4}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Bank Account<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        id="bankAccount"
                        ListboxProps={{ className: "myCustomList" }}
                        options={
                          Utils.isNotNull(this.bankAccountList)
                            ? this.bankAccountList
                            : []
                        }
                        value={this.state.bankaccount}
                        getOptionLabel={(option) => option.accountname}
                        onChange={(event, value) => {
                          this.updateBankAccount(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            required
                            {...params}
                            placeholder="Bank Account"
                            className="regCountry"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      {this.state.bankaccount && (
                        <span style={{ wordWrap: "break-word" }}>
                          {this.state.bankaccount.accountname +
                            " - " +
                            this.state.bankaccount.bankname}
                        </span>
                      )}
                    </Col>
                    <Col
                      md={4}
                      style={{
                        padding: "0",
                        margin: "0",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControlLabel
                        style={{
                          color: "#000",
                          width: "100%",
                          margin: "0",
                          padding: "0",
                        }}
                        label={<span style={{}}>Billing Shipping Same</span>}
                        control={
                          <Checkbox
                            // disabled
                            variant="outlined"
                            id="shareWithCustomer"
                            checked={
                              Utils.equalsIgnoreCase(
                                this.state.isbillingshippingsame,
                                "Yes"
                              )
                                ? true
                                : false
                            }
                            value={
                              Utils.equalsIgnoreCase(
                                this.state.isbillingshippingsame,
                                "Yes"
                              )
                                ? true
                                : false
                            }
                            onChange={this.handleBillingShippingSame.bind(this)}
                            style={{ background: "none" }}
                          />
                        }
                      />
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    style={{
                      margin: "0",
                      padding: "0",
                      borderRadius: "1em",
                    }}
                  >
                    <Col
                      md={4}
                      style={{
                        border: "1px solid white",
                      }}
                    >
                      <Row md={12} style={{ marginLeft: "-0.3em" }}>
                        <label
                          style={{
                            fontWeight: "600",
                            color: "#1b7189",
                            fontSize: "16px",
                          }}
                        >
                          <b>Customer Details</b>
                        </label>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Customer Name</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.name}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Customer Type</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.customertype.description}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>GSTN</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.gstn}
                          {Utils.isNotNullAndEmpty(
                            this.state.customerVo.gstn
                          ) && (
                            <>
                              {!this.state.copied ? (
                                <Tooltip title="Copy" aria-label="add">
                                  <IconButton className="ms-4">
                                    <CopyToClipboard
                                      text={this.state.customerVo.gstn}
                                      onCopy={this.onCopy.bind(this)}
                                    >
                                      <FileCopyIcon
                                        style={{
                                          color: "gray",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </CopyToClipboard>
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Copied" aria-label="add">
                                  <IconButton className="ms-4">
                                    <DoneIcon
                                      style={{
                                        color: "green",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>PAN</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.pan}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Currency</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.currency ? this.state.currency.name : ""}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={4}
                      style={{
                        border: "1px solid white",
                      }}
                    >
                      {billingAddress}
                    </Col>

                    <Col
                      md={4}
                      style={{
                        border: "1px solid white",
                      }}
                    >
                      {shippingAddress}
                    </Col>
                  </Row>
                </>
              )}
            </CardHeader>
            <CardBody>
              <Row md={12} style={{ margin: "0" }}>
                <Col
                  md={12}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                  }}
                >
                  Refund Voucher Items
                </Col>
              </Row>
              {invoiceItemsList}

              <Row
                md={12}
                style={{ padding: "0px", margin: "0", marginTop: "1em" }}
              >
                <Col md={8} style={{ margin: "0", padding: "1em" }}>
                  <Row md={12} style={{ flexDirection: "column" }}>
                    <AuditNoteList
                      ref={this.auditNoteListRef}
                      data={this.state.auditnotes}
                      aid={this.state.id}
                      title="Audit Notes"
                      history={this.props.history}
                      addPaymentTransactionServiceUrl={
                        this.addPaymentTransactionServiceUrl
                      }
                      updatePaymentTransactionServiceUrl={
                        this.updatePaymentTransactionServiceUrl
                      }
                      deletePaymentTransactionServiceUrl={
                        this.deletePaymentTransactionServiceUrl
                      }
                    />
                  </Row>
                </Col>
                <Col md={4} style={{ margin: "0", padding: "0" }}>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Value Of Supply</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {this.state.totaltaxable}
                    </Col>
                  </Row>
                  {Utils.isIgstApplicable(this.state.customerVo) === false && (
                    <>
                      <Row md={12} style={{ padding: "5px", margin: "0" }}>
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>CGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.cgst)}
                        </Col>
                      </Row>
                      <Row md={12} style={{ padding: "5px", margin: "0" }}>
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>SGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.sgst)}
                        </Col>
                      </Row>
                    </>
                  )}
                  {Utils.isIgstApplicable(this.state.customerVo) === true && (
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>IGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.igst)}
                      </Col>
                    </Row>
                  )}

                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>CESS</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {parseFloat(this.state.cess)}
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Misc.(If Any)</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <TextField
                        className="ms-4"
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        required
                        // variant="outlined"
                        type="text"
                        id="miscellaneous"
                        onChange={this.handleMiscellaneousChange.bind(this)}
                        onBlur={this.handleMiscellaneousBlurChange.bind(this)}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        value={this.state.miscellaneous}
                      />
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {/* <strong>Total Amount</strong> */}
                      <Row>
                        <Col
                          md={5}
                          style={{ paddingRight: "0em", textAlign: "right" }}
                        >
                          <strong>Total Amount</strong>
                        </Col>

                        <Col md={7}>
                          <FormControlLabel
                            style={{
                              color: "#000",
                              width: "100%",
                              margin: "0",
                              padding: "0",
                            }}
                            label={<span style={{}}>Round Up</span>}
                            control={
                              <Checkbox
                                variant="outlined"
                                id="shareWithCustomer"
                                checked={this.state.isroundup}
                                value={this.state.isroundup}
                                onChange={this.handleRoundUpCheckBox.bind(this)}
                                style={{ background: "none" }}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {this.state.isroundup === true
                        ? Utils.getRoundValue(
                            this.state.totalamount === "NaN"
                              ? this.state.totaltaxable
                              : this.state.totalamount
                          )
                        : Utils.getFloatValue(
                            this.state.totalamount === "NaN"
                              ? this.state.totaltaxable
                              : this.state.totalamount
                          )}
                      {/* {Utils.getFloatValue(
                        this.state.totalamount === "NaN"
                          ? this.state.totaltaxable
                          : this.state.totalamount
                      )} */}
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={12}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.totalinwords}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ padding: "0px", margin: "0", marginTop: "1em" }}>
                <PaymentTransactionList
                  style={{ width: "100%" }}
                  data={this.state.paymentTransactions}
                  currency={this.state.currency}
                  aid={this.state.id}
                  title="Payment Details"
                  type="Invoice"
                  history={this.props.history}
                  onPaymentChange={this.onPaymentChange.bind(this)}
                  addPaymentTransactionServiceUrl={
                    this.addPaymentTransactionServiceUrl
                  }
                  updatePaymentTransactionServiceUrl={
                    this.updatePaymentTransactionServiceUrl
                  }
                  deletePaymentTransactionServiceUrl={
                    this.deletePaymentTransactionServiceUrl
                  }
                />
              </Row>
            </CardBody>
            <CardFooter>
              <Row md={12}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <SaveButton type="submit" />
                  <CancelButton onClick={(e) => this.cancelEdit()} />
                </div>
              </Row>
            </CardFooter>
          </Card>
        </ValidatorForm>
        {/* </Row> */}
      </div>
    );
  }
  createRefundVoucher() {
    let invoiceItemsList = [];
    /* Create List of RefundVoucher Item */
    let isIgstApplicable = Utils.isIgstApplicable(this.state.customerVo);
    let itemLoc = 1;
    let newCustHsnCodeList = [];

    for (let i = 0; i < this.custHsnCodeList.length; i++) {
      const element = this.custHsnCodeList[i];
      if (element.isnilrated === this.state.isnilrated) {
        newCustHsnCodeList.push(element);
      }
    }

    this.state.invoiceItems &&
      this.state.invoiceItems.forEach((element, index) => {
        if ("delete" !== element.operation) {
          element.itemsrno = itemLoc++;
          let itemkey = index + "-" + this.state.refreshKey;
          invoiceItemsList.push(
            <RefundVoucherItem
              isIgstApplicable={isIgstApplicable}
              ref={this.itemReferenceList[index]}
              backgroundColor={
                index % 2
                  ? { background: "#EFF4F7" }
                  : { background: "#F7F7F7" }
              }
              key={itemkey}
              data={element}
              srno={index}
              action="edit"
              productList={this.productList}
              custHsnCodeList={newCustHsnCodeList}
              doCalculations={this.doCalculations.bind(this)}
              onDelete={this.invoiceItemDeleted.bind(this)}
              onChange={this.invoiceItemChanged.bind(this)}
            />
          );
        }
      });

    let billingAddress = (
      <Address
        ref={this.billingAddressRef}
        title="Billing Address"
        data={this.state.billtoaddress}
        action="view"
      />
    );

    let shippingAddress = "";
    if (
      null === this.state.isbillingshippingsame ||
      Utils.equalsIgnoreCase(this.state.isbillingshippingsame, "No")
    ) {
      shippingAddress = (
        <Address
          ref={this.shippingAddressRef}
          title="Shipping Address"
          data={this.state.shiptoaddress}
          action="edit"
        />
      );
    }

    /* Check if Currency Different than Default Currency */
    let isExchangeRateMandator = false;
    if (
      undefined !== this.state.customerVo &&
      this.state.customerVo &&
      this.state.customerVo.addressvo
    ) {
      let address = this.state.customerVo.addressvo;
      if (
        (isExchangeRateMandator = Utils.isExchangeRateApplicableForCountry(
          address.statevo.countryvo,
          this.defaultCountry
        ))
      ) {
        this.state.exchangeRate = 1;
        isExchangeRateMandator = true;
      }
    }

    return (
      <div style={{ display: "grid" }}>
        <SalesInvoiceDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
          pageId="InvoiceVoucherItem"
          invoiceType="RF"
        />

        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Create Refund Voucher"
          history={this.props.history}
          name="Create Refund Voucher"
        />
        <ValidatorForm
          style={{
            width: "100%",
            color: "#000",
            padding: "0.5em",
            marginTop: "1em",
          }}
          ref="form"
          onSubmit={this.createRefundVoucherInBackend.bind(this)}
        >
          <Card md={12}>
            <CardHeader style={{ margin: "0px", padding: "0.5em" }}>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Customer<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    disableClearable
                    id="customer"
                    ListboxProps={{ className: "myCustomList" }}
                    options={
                      undefined === this.customerList ||
                      null === this.customerList
                        ? []
                        : this.customerList
                    }
                    value={this.state.customerVo}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateCustomer(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        variant="outlined"
                        {...params}
                        placeholder="Customer"
                        className="regCountry"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
                {Utils.isNotNull(this.state.customerVo) && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Sales Invoice<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        id="salesInvoiceReference"
                        ListboxProps={{ className: "myCustomList" }}
                        options={
                          this.customerSalesInvoiceList
                            ? this.customerSalesInvoiceList
                            : []
                        }
                        value={
                          this.state.salesInvoiceNumber
                            ? this.state.salesInvoiceNumber
                            : null
                        }
                        getOptionLabel={(option) => option.invoiceNumber}
                        onChange={(event, value) => {
                          this.updateSalesInvoice(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth={true}
                            variant="outlined"
                            {...params}
                            placeholder="Sales Invoice"
                            className="regCountry"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <Button
                        variant="contained"
                        style={{
                          background: "#1b7189",
                          color: "#fff",
                          marginRight: "1rem",
                          padding: "6px 8px 6px 8px",
                        }}
                        startIcon={<SaveIcon />}
                        onClick={(e) => this.populateSalesInvoice(e)}
                      >
                        Verify
                      </Button>
                      {this.state.referenceinvnumber && (
                        <Button
                          variant="contained"
                          style={{
                            background: "#1b7189",
                            color: "#fff",
                            marginRight: "1rem",
                            padding: "6px 8px 6px 8px",
                          }}
                          onClick={(e) => this.showReferenceInvoice(e)}
                        >
                          <i
                            class="fa fa-eye"
                            aria-hidden="true"
                            style={{ fontSize: "15px" }}
                          ></i>
                          &nbsp; View
                        </Button>
                      )}
                    </Col>
                  </>
                )}

                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Invoice Date<span className="asterisk"> *</span>
                  </label>
                  {/* <TextField
                    required
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    id="invoicedate"
                    onChange={this.handleRefundVoucherDateChange.bind(this)}
                    onBlur={this.handleRefundVoucherDateBlurChange.bind(this)}
                    value={this.state.invoicedate}
                    placeholder="Invoice Date"
                    InputLabelProps={{ shrink: true }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        value={this.state.invoicedate}
                        // variant="inline"
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        fullWidth
                        variant="outlined"
                        id="invoicedate"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        onChange={this.handleRefundVoucherDateChange.bind(this)}
                        onBlur={this.handleRefundVoucherDateBlurChange.bind(
                          this
                        )}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  {/* <div style={{ display: "flex" }}> */}
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Invoice Number<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    required
                    fullWidth={true}
                    variant="outlined"
                    inputProps={{ style: {} }}
                    id="invnumber"
                    type="text"
                    placeholder="Invoice Number"
                    validators={["required"]}
                    onChange={this.handleRefundVoucherNumberChange.bind(this)}
                    value={this.state.invnumber ? this.state.invnumber : ""}
                    name="invnumber"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RefreshIcon
                            className="gstnBtn"
                            onClick={(e) => this.generateInvoiceNumber()}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <span
                    style={{
                      background: "#2471A3",
                      padding: "0.8em",
                      cursor: "pointer",
                    }}
                  >
                    <RefreshIcon
                      style={{ color: "#fff", fontWeight: "bold" }}
                      onClick={(e) => this.generateInvoiceNumber()}
                    ></RefreshIcon>
                  </span> */}
                  {/* <GenerateIconButton onClick={(e) => this.generateInvoiceNumber()} /> */}
                  {/* </div> */}
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Remark{" "}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    inputProps={{ style: {} }}
                    id="invoicenote"
                    type="text"
                    placeholder="Remark"
                    validators={["required"]}
                    onChange={this.handleRemarkChange.bind(this)}
                    // onBlur={this.handleNoteOnBlur.bind(this)}
                    value={this.state.description ? this.state.description : ""}
                    name="invoicenote"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Business Unit<span className="asterisk"> *</span>{" "}
                  </label>
                  <Autocomplete
                    // className="invoiceAutoComplete"
                    // style={{ padding: '0.5em' }}
                    ListboxProps={{ className: "myCustomList" }}
                    disableClearable
                    disabled
                    id="businessUnit"
                    options={this.businessUnitList ? this.businessUnitList : []}
                    value={this.state.bu ? this.state.bu : null}
                    defaultValue={this.state.bu}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        variant="outlined"
                        {...params}
                        placeholder="Business Unit"
                        className="regCountry"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    style={{ color: "#000" }}
                    control={
                      <Checkbox
                        disabled
                        variant="outlined"
                        id="isnilrated"
                        checked={this.state.isnilrated}
                        value={this.state.isnilrated}
                        onChange={this.handleIsNillRatedChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Nil Rated?</span>}
                  />
                </Col>

                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    GST Date<span className="asterisk"> *</span>{" "}
                  </label>
                  {/* <TextField
                    required
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    onChange={this.handleGstDateChange.bind(this)}
                    value={this.state.gstdate ? this.state.gstdate : ""}
                    placeholder="GST Date"
                    InputLabelProps={{ shrink: true }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        value={this.state.gstdate}
                        // variant="inline"
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        fullWidth
                        variant="outlined"
                        id="ptsDateTimePicker"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        onChange={this.handleGstDateChange.bind(this)}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Col>
                {isExchangeRateMandator === true && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Currency<span className="asterisk"> *</span>{" "}
                      </label>
                      <Autocomplete
                        disableClearable
                        disabled
                        ListboxProps={{ className: "myCustomList" }}
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="currency"
                        options={this.currencyList ? this.currencyList : []}
                        value={this.state.currency}
                        defaultValue={this.state.currency}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.updateCurrency(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            style={{}}
                            placeholder="Currency"
                            variant="outlined"
                            className="regCountry"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Exchange Rate<span className="asterisk"> *</span>{" "}
                      </label>
                      <TextField
                        variant="outlined"
                        required
                        inputProps={{ style: {} }}
                        fullWidth
                        id="exchangeRate"
                        type="text"
                        placeholder="Exchange Rate"
                        onChange={this.handleExchangeRateChange.bind(this)}
                        // onBlur={this.handleGSTNOnBlur.bind(this)}
                        value={
                          this.state.exchangerate ? this.state.exchangerate : ""
                        }
                        name="exchangeRate"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Export Type<span className="asterisk"> *</span>{" "}
                      </label>
                      <Autocomplete
                        disableClearable
                        disabled
                        ListboxProps={{ className: "myCustomList" }}
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="expinvoicetype"
                        options={
                          this.exportInvoiceTypeList
                            ? this.exportInvoiceTypeList
                            : []
                        }
                        value={this.state.expinvoicetype}
                        defaultValue={this.state.expinvoicetype}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => {
                          this.updateExportType(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            variant="outlined"
                            style={{}}
                            className="regCountry"
                            placeholder="Export Type"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                  </>
                )}
              </Row>

              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    disabled
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isSEZDeemedExport"
                        checked={
                          this.state.issez &&
                          Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        value={
                          this.state.issez &&
                          Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        onChange={this.handleIsSEZChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>SEZ/Deemed Export?</span>}
                  />
                </Col>

                {this.issalesthrecom && (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <FormControlLabel
                      disabled
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          id="isSalesThroughECOM"
                          checked={
                            this.state.salesthrecom &&
                            this.state.salesthrecom === true
                              ? true
                              : false
                          }
                          value={
                            this.state.salesthrecom &&
                            this.state.salesthrecom === true
                              ? true
                              : false
                          }
                          onChange={this.handleSalesThroughECOMChange.bind(
                            this
                          )}
                          style={{ background: "none" }}
                        />
                      }
                      label={<span style={{}}>Sales Through ECOM?</span>}
                    />
                  </Col>
                )}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        disabled
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isAsset}
                        value={this.state.isAsset}
                        onChange={this.handleAssetChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Asset Sale?</span>}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="shareWithCustomer"
                        checked={this.state.sharewithcust}
                        value={this.state.sharewithcust}
                        onChange={this.handleShareWithCustomerChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Share With Customer?</span>}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                {Utils.equalsIgnoreCase(this.state.issez, "Yes") ? (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      SEZ Category<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      disabled
                      disableClearable
                      ListboxProps={{ className: "myCustomList" }}
                      id="sezCategory"
                      options={this.sezInvoiceTypeList}
                      value={this.state.sezinvoicetype}
                      defaultValue={this.state.sezinvoicetype}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, value) => {
                        this.updateSezInvoiceType(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth={true}
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          className="regCountry"
                          variant="outlined"
                        />
                      )}
                    />
                  </Col>
                ) : (
                  <Col md={2}></Col>
                )}
                {this.state.salesthrecom &&
                  this.state.salesthrecom === true && (
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        ETIN<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disabled
                        id="etin"
                        size="small"
                        ListboxProps={{ className: "myCustomList" }}
                        options={this.etinInfoList}
                        value={this.state.etin ? this.state.etin : null}
                        defaultValue={this.state.etin}
                        getOptionLabel={(option) => option.etin}
                        onChange={(event, value) => {
                          this.handleEtinChange(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth={true}
                            {...params}
                            variant="outlined"
                            className="regCountry"
                            placeholder="ETIN"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                  )}
              </Row>
              {this.state.customerVo && (
                <>
                  <Row
                    md={12}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      margin: "5px",
                      background: "#f8fcff",
                    }}
                  >
                    <Col md={4}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Bank Account<span className="asterisk"> *</span>{" "}
                      </label>
                      <Autocomplete
                        disableClearable
                        id="bankAccount"
                        ListboxProps={{ className: "myCustomList" }}
                        options={
                          Utils.isNotNull(this.bankAccountList)
                            ? this.bankAccountList
                            : []
                        }
                        value={this.state.bankaccount}
                        getOptionLabel={(option) => option.accountname}
                        onChange={(event, value) => {
                          this.updateBankAccount(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            {...params}
                            className="regCountry"
                            placeholder="Bank Account"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      {this.state.bankaccount && (
                        <span style={{ wordWrap: "break-word" }}>
                          {this.state.bankaccount.accountname +
                            " - " +
                            this.state.bankaccount.bankname}
                        </span>
                      )}
                    </Col>
                    <Col
                      md={4}
                      style={{
                        padding: "0",
                        margin: "0",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControlLabel
                        style={{
                          color: "#000",
                          width: "100%",
                          margin: "0",
                          padding: "0",
                        }}
                        label={<span style={{}}>Billing Shipping Same</span>}
                        control={
                          <Checkbox
                            // disabled
                            variant="outlined"
                            id="shareWithCustomer"
                            checked={
                              Utils.equalsIgnoreCase(
                                this.state.isbillingshippingsame,
                                "Yes"
                              )
                                ? true
                                : false
                            }
                            value={
                              Utils.equalsIgnoreCase(
                                this.state.isbillingshippingsame,
                                "Yes"
                              )
                                ? true
                                : false
                            }
                            onChange={this.handleBillingShippingSame.bind(this)}
                            style={{ background: "none" }}
                          />
                        }
                      />
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    style={{
                      margin: "0",
                      padding: "0",
                      borderRadius: "1em",
                    }}
                  >
                    <Col
                      md={4}
                      style={{
                        border: "1px solid white",
                      }}
                    >
                      <Row md={12} style={{ marginLeft: "-0.3em" }}>
                        <label
                          style={{
                            fontWeight: "600",
                            color: "#1b7189",
                            fontSize: "16px",
                          }}
                        >
                          <b>Customer Details</b>
                        </label>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Customer Name</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.name}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Customer Type</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.customertype.description}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>GSTN</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.gstn}
                          {Utils.isNotNullAndEmpty(
                            this.state.customerVo.gstn
                          ) && (
                            <>
                              {!this.state.copied ? (
                                <Tooltip title="Copy" aria-label="add">
                                  <IconButton className="ms-4">
                                    <CopyToClipboard
                                      text={this.state.customerVo.gstn}
                                      onCopy={this.onCopy.bind(this)}
                                    >
                                      <FileCopyIcon
                                        style={{
                                          color: "gray",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </CopyToClipboard>
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Copied" aria-label="add">
                                  <IconButton className="ms-4">
                                    <DoneIcon
                                      style={{
                                        color: "green",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>PAN</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo.pan}
                        </Col>
                      </Row>
                      <Row md={12} style={{ marginLeft: "0.5em" }}>
                        <Col
                          md={5}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Currency</strong>
                        </Col>
                        <Col
                          md={7}
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.currency ? this.state.currency.name : ""}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={4}
                      style={{
                        border: "1px solid white",
                      }}
                    >
                      {billingAddress}
                    </Col>

                    <Col
                      md={4}
                      style={{
                        border: "1px solid white",
                      }}
                    >
                      {shippingAddress}
                    </Col>
                  </Row>
                </>
              )}
            </CardHeader>
            <CardBody>
              <Row md={12} style={{ margin: "0" }}>
                <Col
                  md={12}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                  }}
                >
                  Refund Voucher Items
                </Col>
              </Row>
              {invoiceItemsList}

              <Row
                md={12}
                style={{ padding: "0px", margin: "0", marginTop: "1em" }}
              >
                <Col md={8} style={{ margin: "0", padding: "1em" }}></Col>
                <Col md={4} style={{ margin: "0", padding: "0" }}>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Value Of Supply</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {this.state.totaltaxable}
                    </Col>
                  </Row>
                  {Utils.isIgstApplicable(this.state.customerVo) === false && (
                    <>
                      <Row md={12} style={{ padding: "5px", margin: "0" }}>
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>CGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(
                            this.state.cgst === "NaN" ? 0 : this.state.cgst
                          )}
                        </Col>
                      </Row>
                      <Row md={12} style={{ padding: "5px", margin: "0" }}>
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>SGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(
                            this.state.sgst === "NaN" ? 0 : this.state.sgst
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  {Utils.isIgstApplicable(this.state.customerVo) === true && (
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>IGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(
                          this.state.igst === "NaN" ? 0 : this.state.igst
                        )}
                      </Col>
                    </Row>
                  )}

                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>CESS</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {parseFloat(this.state.cess)}
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Misc.(If Any)</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <TextField
                        className="ms-4"
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        required
                        // variant="outlined"
                        type="text"
                        id="miscellaneous"
                        onChange={this.handleMiscellaneousChange.bind(this)}
                        onBlur={this.handleMiscellaneousBlurChange.bind(this)}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        value={this.state.miscellaneous}
                      />
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {/* <strong>Total Amount</strong> */}
                      <Row>
                        <Col
                          md={5}
                          style={{ paddingRight: "0em", textAlign: "right" }}
                        >
                          <strong>Total Amount</strong>
                        </Col>

                        <Col md={7}>
                          <FormControlLabel
                            style={{
                              color: "#000",
                              width: "100%",
                              margin: "0",
                              padding: "0",
                            }}
                            label={<span style={{}}>Round Up</span>}
                            control={
                              <Checkbox
                                variant="outlined"
                                id="shareWithCustomer"
                                checked={this.state.isroundup}
                                value={this.state.isroundup}
                                onChange={this.handleRoundUpCheckBox.bind(this)}
                                style={{ background: "none" }}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {/* {Utils.getFloatValue(
                        this.state.totalamount === "NaN"
                          ? this.state.totaltaxable
                          : this.state.totalamount
                      )} */}
                      {this.state.isroundup === true
                        ? Utils.getRoundValue(
                            this.state.totalamount === "NaN"
                              ? this.state.totaltaxable
                              : this.state.totalamount
                          )
                        : Utils.getFloatValue(
                            this.state.totalamount === "NaN"
                              ? this.state.totaltaxable
                              : this.state.totalamount
                          )}
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={12}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.totalinwords}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <CardFooter>
            <Row md={12}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <CreateButton type="submit" />
                <CancelButton onClick={(e) => this.cancelView()} />
              </div>
            </Row>
          </CardFooter>
        </ValidatorForm>
      </div>
    );
  }
  viewRefundVoucher() {
    let invoiceItemsList = [];
    let isIgstApplicable = Utils.isIgstApplicable(this.state.customerVo);

    this.state.invoiceItems &&
      this.state.invoiceItems.forEach((element, index) => {
        invoiceItemsList.push(
          <RefundVoucherItem
            ref={this.itemReferenceList[index]}
            isIgstApplicable={isIgstApplicable}
            backgroundColor={
              index % 2 ? { background: "#EFF4F7" } : { background: "#F7F7F7" }
            }
            key={index}
            data={element}
            srno={index + 1}
            action="view"
            productList={this.productList}
            custHsnCodeList={this.custHsnCodeList}
            doCalculations={this.doCalculations.bind(this)}
            onDelete={this.invoiceItemDeleted.bind(this)}
            onChange={this.invoiceItemChanged.bind(this)}
          />
        );
      });
    let menuWithBreadScrum = (
      <MenuWithBreadScrum
        ref={this.menuWithBreadScrumRef}
        breadCrumb={this.breadCrumb}
        history={this.props.history}
        addToBreadCrumb="View Refund Voucher"
        name="View Refund Voucher"
        backCallback={this.cancelView.bind(this)}
        // docTitle={this.state.invnumber}
      />
    );

    return (
      <div style={{ display: "grid" }}>
        <SalesInvoiceDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
          pageId="InvoiceVoucherItem"
          invoiceType="RF"
        />
        {menuWithBreadScrum}

        <Card md={12} style={{ margin: "1em" }}>
          <CardHeader>
            <Row md={12} style={{ padding: "5px", margin: "0" }}>
              <Col
                md={12}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div className="row pb-1">
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: "600",
                      color: "#1b7189",
                    }}
                  >
                    <lable
                      className="ms-1"
                      style={{
                        color: "rgb(0, 0, 0)",
                        textAlign: "left",
                        fontWeight: "650",
                      }}
                    >
                      Refund Voucher
                    </lable>{" "}
                    &nbsp;
                    {this.state.invnumber}
                  </span>
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%" }}>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Customer Name</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.customerVo.name}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Customer Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.customerVo.customertype.description}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>GSTN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.customerVo.gstn}
                      {Utils.isNotNullAndEmpty(this.state.customerVo.gstn) && (
                        <>
                          {!this.state.copied ? (
                            <Tooltip title="Copy" aria-label="add">
                              <IconButton className="ms-4">
                                <CopyToClipboard
                                  text={this.state.customerVo.gstn}
                                  onCopy={this.onCopy.bind(this)}
                                >
                                  <FileCopyIcon
                                    style={{
                                      color: "gray",
                                      fontSize: "20px",
                                    }}
                                  />
                                </CopyToClipboard>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Copied" aria-label="add">
                              <IconButton className="ms-4">
                                <DoneIcon
                                  style={{
                                    color: "green",
                                    fontSize: "20px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>PAN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.customerVo.pan}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Share With Customer</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.sharewithcust &&
                      this.state.sharewithcust === true
                        ? "Yes"
                        : "No"}
                    </label>
                  </Row>
                  {/* <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Sales Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.satype ? this.state.satype.type : ""}
                    </label>
                  </Row> */}

                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Business Unit</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.bu.name}
                    </label>
                  </Row>
                  {this.state.exchangerate && this.state.exchangerate > 1 && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Currency Rate</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.exchangerate}
                      </label>
                    </Row>
                  )}
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Row style={{ flex: 1 }}>
                    <Address
                      ref={this.billingAddressRef}
                      title="Billing Address"
                      data={this.state.billtoaddress}
                      action="view"
                    />
                  </Row>

                  <Row style={{ flex: 1 }}>
                    <Address
                      ref={this.shippingAddressRef}
                      title="Shipping Address"
                      data={this.state.shiptoaddress}
                      action="view"
                    />
                  </Row>
                </div>
              </Col>

              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%" }}>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Created On</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* { this.state.createdon ? format(new Date(), 'yyyy-MM-dd') : ''} */}
                      {Utils.ptsDateFormat(this.state.createdon)}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Invoice Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.invoicedate} */}
                      {Utils.ptsDateFormat(this.state.invoicedate)}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>GST Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.gstdate} */}
                      {Utils.ptsDateFormat(this.state.gstdate)}
                    </label>
                  </Row>

                  {(this.state.isexport === true ||
                    Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Export</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </label>
                    </Row>
                  )}
                  {this.state.isnilrated === true && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Nil Rated</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </label>
                    </Row>
                  )}
                  {this.state.isAsset === true && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Asset Sale</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </label>
                    </Row>
                  )}
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Status</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.status ? this.state.status.description : ""}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>PO </strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.purchaseorder ? this.state.purchaseorder : ""}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>PO Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.purchasedate ? this.state.purchasedate : ''} */}
                      {Utils.ptsDateFormat(this.state.purchasedate)}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      <strong>Remark</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {undefined !== this.state.description
                        ? this.state.description
                        : ""}
                    </label>
                  </Row>

                  {Utils.isNotNullAndEmpty(this.state.refinvoice) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Sales Invoice</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.referenceinvnumber && (
                          <Link
                            style={{ cursor: "pointer", color: "#0d6efd" }}
                            onClick={this.showReferenceInvoice.bind(this)}
                            className="text-underline-hover"
                          >
                            <strong>
                              {this.state.referenceinvnumber
                                ? this.state.referenceinvnumber
                                : ""}
                            </strong>
                          </Link>
                        )}
                      </label>
                    </Row>
                  )}
                  {Utils.isNotNullAndEmpty(this.state.etin?.etin) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>ETIN</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.etin ? this.state.etin?.etin : ""}
                      </label>
                    </Row>
                  )}
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: 0,
                        padding: 0,
                        textAlign: "left",
                      }}
                    >
                      <strong>Bank Account</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.bankaccount && (
                        <span>
                          {this.state.bankaccount.accountname +
                            " - " +
                            this.state.bankaccount.bankname}
                        </span>
                      )}
                    </label>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12} style={{ padding: "5px", minHeight: "1em" }}></Row>
            <table>
              <thead>
                <tr style={{ margin: "0" }}>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    <span style={{ float: "left" }}>#</span>
                    <span style={{ float: "right" }}>SAC/HSN</span>
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "20%",
                    }}
                  >
                    Item Details
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    UOM
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "3%",
                    }}
                  >
                    Qty
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "8%",
                    }}
                  >
                    Unit Price
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Gross Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Discount %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Net Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    CESS %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Tax Rate %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    GST
                  </td>

                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Total
                  </td>
                </tr>
              </thead>
              {invoiceItemsList}
            </table>
            <Row
              md={12}
              style={{ padding: "0px", margin: "0", marginTop: "1em" }}
            >
              <Col md={8} style={{ margin: "0", padding: "1em" }}>
                {Utils.equalsIgnoreCase(
                  "VERIFICATIONREQUESTED",
                  this.state.status ? this.state.status?.status : ""
                ) && (
                  <Row
                    md={12}
                    style={{ flexDirection: "column", marginBottom: "1em" }}
                  >
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0em 0em 0em -0.5em",
                        paddingBottom: "6px",
                        textAlign: "left",
                      }}
                    >
                      <strong>Audit Note</strong>
                    </label>
                    <TextareaAutosize
                      variant="outlined"
                      fullWidth
                      id="auditnote"
                      type="text"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "0.5px solid #D5D8DC",
                        backgroundColor: "#FFFEE3",
                      }}
                      minRows={2}
                      value={this.state.auditNote ? this.state.auditNote : " "}
                      onChange={this.addAuditNote.bind(this)}
                      autoComplete="auditnote"
                    />
                  </Row>
                )}
                <Row md={12} style={{ flexDirection: "column" }}>
                  <AuditNoteList
                    ref={this.auditNoteListRef}
                    data={this.state.auditnotes}
                    aid={this.state.id}
                    title="Audit Notes"
                    history={this.props.history}
                    addPaymentTransactionServiceUrl={
                      this.addPaymentTransactionServiceUrl
                    }
                    updatePaymentTransactionServiceUrl={
                      this.updatePaymentTransactionServiceUrl
                    }
                    deletePaymentTransactionServiceUrl={
                      this.deletePaymentTransactionServiceUrl
                    }
                  />
                </Row>
              </Col>
              <Col md={4} style={{ margin: "0", padding: "0" }}>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Value Of Supply</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.currency.symbol !== null
                      ? this.state.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.totaltaxable)}
                  </Col>
                </Row>
                {Utils.isIgstApplicable(this.state.customerVo) === false && (
                  <>
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>CGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(
                          this.state.cgst === "NaN" ? 0 : this.state.cgst
                        )}
                      </Col>
                    </Row>
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>SGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.sgst)}
                      </Col>
                    </Row>
                  </>
                )}

                {Utils.isIgstApplicable(this.state.customerVo) === true && (
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>IGST</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.igst)}
                    </Col>
                  </Row>
                )}

                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>CESS</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {parseFloat(this.state.cess)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Misc.(If Any)</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.miscellaneous)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Total Amount</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.currency.symbol !== null
                      ? this.state.currency.symbol
                      : ""}
                    {this.state.isroundup === true
                      ? Utils.getRoundValue(
                          this.state.totalamount === "NaN"
                            ? this.state.totaltaxable
                            : this.state.totalamount
                        )
                      : Utils.getFloatValue(
                          this.state.totalamount === "NaN"
                            ? this.state.totaltaxable
                            : this.state.totalamount
                        )}
                    {/* {Utils.getFloatValue(
                      this.state.totalamount === "NaN"
                        ? this.state.totaltaxable
                        : this.state.totalamount
                    )} */}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={12}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.totalinwords}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ padding: "0px", margin: "0", marginTop: "1em" }}>
              <PaymentTransactionList
                style={{ width: "100%" }}
                data={this.state.paymentTransactions}
                currency={this.state.currency}
                aid={this.state.id}
                title="Payment Details"
                type="Invoice"
                history={this.props.history}
                onPaymentChange={this.onPaymentChange.bind(this)}
                addPaymentTransactionServiceUrl={
                  this.addPaymentTransactionServiceUrl
                }
                updatePaymentTransactionServiceUrl={
                  this.updatePaymentTransactionServiceUrl
                }
                deletePaymentTransactionServiceUrl={
                  this.deletePaymentTransactionServiceUrl
                }
              />
            </Row>
          </CardBody>
        </Card>
        <CardFooter>
          <Row md={12}>{this.getButtonsAsPerStatusAndPermission()}</Row>
        </CardFooter>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Col } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import AddIcon from "@material-ui/icons/Add";
import PtsAlert from "../../components/ptsAlert/PtsAlert";
import store from "../../../redux/store";
import Button from "@material-ui/core/Button";
import Utils from "../../../provider/Utils";
import AddIconButton from "../buttons/AddIconButton";
import ETINManagement from "./ETINManagement";

class ETINManagementList extends Component {
  header = store.getState().header.header;
  etinRefList = [];
  constructor() {
    super();
    this.state = {
      etinId: "",
      initialPage: 0,
      pageSize: 10,
      etinInfoList: [],
      render: false,
      getCreatePermission: false,
      getUpdatePermission: false,
      getDeletePermission: false,
      addRow: false,
      etinListRow: true,
      action: "view",
    };
    this.getETINManagementist();
  }
  getETINManagementist() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/etinmgmt/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let getPermission = Utils.getRolePermission(["*"]);
          let getCreatePermission = getPermission[1];
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let etinInfoList = output.data.etinInfoList;
          let isPaging = false;
          if (etinInfoList.length > this.state.pageSize) {
            isPaging = true;
          }
          this.setState({
            render: true,
            action: "view",
            addRow: false,
            etinListRow: true,
            etinInfoList: etinInfoList,
            isPaging: isPaging,
            initialPage: this.state.initialPage,
            getCreatePermission: getCreatePermission,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      }
    );
  }
  createETINToBackend(newData) {
    for (let i = 0; i < this.state.etinInfoList.length; i++) {
      if (
        this.state.etinInfoList[i] &&
        newData.srno === i &&
        this.etinRefList[i] &&
        this.etinRefList[i].current
      ) {
        const postObject = {
          header: this.header,
          etinInfo: {
            etin: this.etinRefList[i].current.state.etin,
            description: this.etinRefList[i].current.state.description,
          },
        };
        FetchServerData.callPostService("/etinmgmt/create", postObject).then(
          (output) => {
            if (output.status === "SUCCESS") {
              PtsAlert.success(output.message);
              let etinInfo = output.data.etinInfo;
              this.etinRefList[i].current.state.action = "view";
              this.setState({
                render: true,
                action: "view",
                etinInfoList: [etinInfo, ...this.state.etinInfoList],
              });
            } else {
            }
          }
        );
      }
    }
  }
  updateETINToBackend(newData) {
    const postObject = {
      header: this.header,
      etinInfo: {
        etin: newData.etin,
        description: newData.description,
        id: newData.id,
      },
    };
    FetchServerData.callPostService("/etinmgmt/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let etinCodeData = output.data.etinInfo;
          var currentIndex = this.state.etinInfoList.findIndex(
            (d) => d.id === etinCodeData.id
          );
          let currentList = [...this.state.etinInfoList];
          currentList.splice(currentIndex, 1, etinCodeData);
          this.setState({
            render: true,
            etinInfoList: currentList,
            etinId: newData.id,
            isOpen: false,
          });
        } else {
        }
      }
    );
  }
  deleteETINToBackend = (data) => {
    const postObject = {
      header: this.header,
      etinInfo: {
        id: data.id,
      },
    };
    FetchServerData.callPostService("/etinmgmt/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // var newList = output.data.etinInfo;
          for (let i = 0; i < this.state.etinInfoList.length; i++) {
            // console.log(this.state.etinInfoList[i].id, " === ", newList.id)
            if (this.state.etinInfoList[i].id === data.id) {
              this.state.etinInfoList.splice(i, 1);
            }
          }
          this.setState({
            etinInfoList: this.state.etinInfoList,
            render: true,
          });
          PtsAlert.success(output.message);
        } else {
        }
      }
    );
  };

  addEtin() {
    let etinInfos = this.state.etinInfoList;
    let etinInfo = {
      action: "create",
      operation: "add",
      description: "",
      etin: "",
    };
    etinInfos.push(etinInfo);
    this.setState({
      etinInfoList: etinInfos,
      addRow: true,
      action: "create",
    });
  }

  backToNoteList() {
    this.setState({ etinListRow: true });
  }

  onEtinRemove(item) {
    let items = [];
    if (item.operation && "add" === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.srno;
      for (let i = 0; i < this.state.etinInfoList.length; i++) {
        if (this.state.etinInfoList[i].operation === "add" && index === i) {
          this.etinRefList.splice(i, 1);
        } else {
          items.push(this.state.etinInfoList[i]);
        }
      }
      this.setState({ etinInfoList: items, addRow: false });
    }
  }

  createCancel() {
    this.setState({
      addRow: !this.state.addRow,
    });
  }

  editRow(rowData) {
    this.setState({
      etinId: rowData.id,
      action: "edit",
    });
  }

  onEtinEditClick(e) {
    console.log(e);
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }

    this.etinRefList = [];
    for (
      let i = 0;
      this.state.etinInfoList && i < this.state.etinInfoList.length;
      i++
    ) {
      this.etinRefList[i] = React.createRef();
    }

    let etinInfoItemList = [];
    this.state.etinInfoList &&
      this.state.etinInfoList.forEach((element, index) => {
        etinInfoItemList.push(
          <ETINManagement
            ref={this.etinRefList[index]}
            key={index}
            data={element}
            backgroundColor={
              index % 2 ? { background: "#EFF4F7" } : { background: "#F7F7F7" }
            }
            srno={index}
            action={element.action ? element.action : "view"}
            etinListRow={this.state.etinListRow}
            iteamIndex={index + 1}
            onAdd={this.createETINToBackend.bind(this)}
            onEtinEditClick={this.onEtinEditClick.bind(this)}
            onEtinDelete={this.deleteETINToBackend.bind(this)}
            onEtinUpdateSave={this.updateETINToBackend.bind(this)}
            onEtinRemove={this.onEtinRemove.bind(this)}
          />
        );
      });
    return (
      <>
        <div style={{ maxWidth: "100%" }}>
          <div className="row">
            <Col md={12} style={{ flexDirection: "column", display: "flex" }}>
              <div className="card">
                <div className="row justify-content-between p-3">
                  <div className="d-flex col-12 col-lg-4 col-xl-4">
                    <div className="col-12 text-start totalResults">
                      {/* <span>Total Results:</span> */}
                    </div>
                  </div>
                  <div className="d-flex col-12 col-lg-8 col-xl-8">
                    <div className="col-12 text-end">
                      {/* <input
                        type="search"
                        id="gsearch"
                        placeholder="Search"
                        name="gsearch"
                        style={{ marginTop: "2px" }}
                      // value={this.state.searchText}
                      // onChange={this.onSearchChange.bind(this)}
                      />
                      <button
                        className="gsearchButton"
                      // onClick={this.onSearchClick.bind(this)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button> */}{" "}
                      &nbsp;&nbsp;
                      {!this.state.addRow ? (
                        <AddIconButton onClick={this.addEtin.bind(this)} />
                      ) : (
                        <Button
                          className="add"
                          style={{
                            background: "rgb(27, 113, 137)",
                            color: "white",
                            fontSize: "x-larger",
                            textTransform: "capitalize",
                            cursor: "pointer",
                            float: "right",
                            margin: "0.2em 0.5em 0.3em 0em",
                          }}
                          startIcon={<AddIcon />}
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table text-start table-hover">
                    <thead className="tableThead">
                      <tr>
                        <th style={{ width: "5%" }} scope="col fw-bold ">
                          #
                        </th>
                        <th style={{ width: "20%" }} scope="col fw-bold">
                          ETIN
                        </th>
                        <th scope="col fw-bold">Description</th>
                        <th style={{ width: "20%" }} scope="col fw-bold">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>{etinInfoItemList}</tbody>
                  </table>
                </div>
                {this.state.totalRecords === 0 && (
                  <div className="row text-center p-2">
                    <h5>Data not Available</h5>
                  </div>
                )}
              </div>
            </Col>
          </div>
        </div>
      </>
    );
  }
}
export default ETINManagementList;

import { Card } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { Component } from "react";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import store from "../../../redux/store";
import AddIconButton from "../buttons/AddIconButton";
import ExpenseCategory from "./ExpenseCategory";
import Utils from "../../../provider/Utils";

export class ExpenseCategoryList extends Component {
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();

    this.state = {
      initialPage: 0,
      pageSize: 10,
      expenseCategorys: [],
      onChangeSettings: this.props.onChange,
      isPaging: false,
      render: false,
      action: this.props.action,
    };
    this.getExpenseCategoryList();
  }

  getExpenseCategoryList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/expensecatms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let expenseCatList = output.data;
          let isPaging = false;
          if (expenseCatList.length > this.state.pageSize) {
            isPaging = true;
          }
          this.setState({
            isPaging: isPaging,
            expenseCategorys: expenseCatList,
            render: true,
          });
        } else {
        }
      }
    );
  }

  addExpenseCategory = (e) => {
    this.setState({ action: "add_account" });
  };

  expenseCategoryChanged(changedAccount) {
    //this.props.onChange(changedAccount);
  }

  expenseCategoryAdded(changedAccount) {
    //  this.props.onChange(changedAccount);
  }
  viewExpenseCategory = (e, data) => {
    this.setState({ selectedAccount: data, action: "show_account" });
  };
  render() {
    if (this.state.action === "add_account") {
      return (
        <ExpenseCategory
          action="create"
          title="New Expense Category"
          data={null}
          expenseCategorys={this.state.expenseCategorys}
          onChange={this.expenseCategoryChanged.bind(this)}
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    } else if (this.state.action === "show_account") {
      return (
        <ExpenseCategory
          action="view"
          title="Expense Category Details"
          data={this.state.selectedAccount}
          expenseCategorys={this.state.expenseCategorys}
          onChange={this.expenseCategoryAdded.bind(this)}
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    }
    if (this.state.action === "view") {
      return this.showList();
    }
  }

  showList() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <MaterialTable
          style={{ overflowX: "auto" }}
          icons={this.tableIcons}
          localization={{
            body: {
              emptyDataSourceMessage: Utils.communizeTableText(),
            },
          }}
          title="Expense Category List"
          columns={[
            {
              title: "#",
              render: (rowData) => rowData.tableData.id + 1,
              align: "left",
              cellStyle: {
                width: "5%",
              },
            },
            {
              title: "Type",
              field: "type",
              align: "left",
              width: "20%",
            },
            {
              title: "Description",
              field: "description",
              align: "left",
              width: "20%",
            },
          ]}
          data={this.state.expenseCategorys}
          column={{
            headerStyle: {
              color: "#fff",
            },
          }}
          options={{
            sorting: true,
            pageSize: this.state.pageSize,
            paging: this.state.isPaging,
            pageSizeOptions: Utils.materialTablePageSize(),
            actionsColumnIndex: -1,
            headerStyle: {
              background: "#1b7189",
              fontWeight: "bold",
              color: "#fff",
              align: "center",
            },
            rowStyle: (rowData) => ({
              backgroundColor: rowData.isinbuilt ? "#e6eeff" : "",
            }),
            header: {
              actions: "Action",
            },
            searchFieldStyle: {},
          }}
          actions={[
            {
              icon: () => <AddIconButton />,
              tooltip: "Add Category",
              isFreeAction: true,
              onClick: (event) => this.addExpenseCategory(),
            },
          ]}
          onRowClick={(e, rowData) => this.viewExpenseCategory(e, rowData)}
        />
      </Card>
    );
  }
}
export default ExpenseCategoryList;

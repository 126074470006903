import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
// import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import EditIcon from "@material-ui/icons/Edit";
// import RefreshIcon from "@material-ui/icons/Refresh";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import store from "../../../../redux/store";
import EditIconButton from "../../buttons/EditIconButton";
import RefreshButton from "../../buttons/RefreshButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
// import Button from "@material-ui/core/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ApexPieChart from "../../chart/ApexPieChart";
import { Menu } from "@mui/material";
import Utils from "../../../../provider/Utils";
// import './AnnualFinancial.scss';
import Tooltip from "@material-ui/core/Tooltip";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import SaveButton from "../../buttons/SaveButton";
import CancelIconButton from "../../buttons/CancelButton";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "black",
        backgroundColor: "#F3E3A3",
        border: "1px solid black",
        cursor: "pointer",
      },
    },
  },
});
export default class AnnualFinancial extends Component {
  businessUnitList = store.getState().appData.businessUnitList;
  financialYearList = store.getState().appData.fyList;
  //monthlyFinancials = store.getState().appData.monthlyFinancials;

  breadCrumb = [];
  title = "Annual Financial";
  constructor(props) {
    super(props);
    let action = props.action;
    /* Set Data From history */
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }
    // let dyear = new Date();
    // let yr = dyear.getFullYear();
    // let n = dyear.getMonth();
    // if (n < "3") {
    //   this.finyr = --yr;
    // } else {
    //   this.finyr = dyear.getFullYear();
    // }
    // let year1 = this.finyr;
    // let endyr = ++year1;
    // endyr = endyr.toString().substring(2);
    // let year = this.finyr + "-" + endyr;
    // this.getBusinessUnitList();

    this.state = {
      action: action,
      isItcEditEnabled: false,
      financialsYear: null,
      businessUnit:
        this.businessUnitList && this.businessUnitList.length > 0
          ? this.businessUnitList[0]
          : null,
      salesTaxable: "",
      salesIgst: "",
      salesCgst: "",
      salesSgst: "",
      salesCess: "",
      salesTotal: "",
      advanceTaxable: "",
      advanceIgst: "",
      advanceCgst: "",
      advanceSgst: "",
      advanceCess: "",
      advanceTotal: "",
      debitTaxable: "",
      debitIgst: "",
      debitCgst: "",
      debitSgst: "",
      debitCess: "",
      debitTotal: "",
      creditTaxable: "",
      creditIgst: "",
      creditCgst: "",
      creditSgst: "",
      creditCess: "",
      creditTotal: "",
      refundTaxable: "",
      refundIgst: "",
      refundCgst: "",
      refundSgst: "",
      refundCess: "",
      refundTotal: "",
      netTaxable: "",
      netIgst: "",
      netCgst: "",
      netSgst: "",
      netCess: "",
      netTotal: "",
      salesFinancialMonth: "",
      salesFinancialYear: "",
      salesRcmTaxable: "",
      salesRcmigst: "",
      salesRcmCgst: "",
      salesRcmSgst: "",
      salesRcmCess: "",
      salesRcmTotal: "",
      purchaseTaxable: "",
      purchaseIgst: "",
      purchaseCgst: "",
      purchaseSgst: "",
      purchaseCess: "",
      purchaseTotal: "",
      purchaseAdvanceTaxable: "",
      purchaseAdvanceIgst: "",
      purchaseAdvanceCgst: "",
      purchaseAdvanceSgst: "",
      purchaseAdvanceCess: "",
      purchaseAdvanceTotal: "",
      purchaseDebitTaxable: "",
      purchaseDebitIgst: "",
      purchaseDebitCgst: "",
      purchaseDebitSgst: "",
      purchaseDebitCess: "",
      purchaseDebitTotal: "",
      purchaseCreditTaxable: "",
      purchaseCreditIgst: "",
      purchaseCreditCgst: "",
      purchaseCreditSgst: "",
      purchaseCreditCess: "",
      purchaseCreditTotal: "",
      purchaseRefundTaxable: "",
      purchaseRefundIgst: "",
      purchaseRefundCgst: "",
      purchaseRefundSgst: "",
      purchaseRefundCess: "",
      purchaseRefundTotal: "",
      purchaseNetTaxable: "",
      purchaseNetIgst: "",
      purchaseNetCgst: "",
      purchaseNetSgst: "",
      purchaseNetCess: "",
      purchaseNetTotal: "",
      purchaseItcTaxable: "",
      purchaseItcIgst: "",
      purchaseItcCgst: "",
      purchaseItcSgst: "",
      purchaseItcCess: "",
      purchaseItcTotal: "",
      purchaseRcmTaxable: "",
      purchaseRcmigst: "",
      purchaseRcmCgst: "",
      purchaseRcmSgst: "",
      purchaseRcmCess: "",
      purchaseRcmTotal: "",
      expenseAmount: "",
      expenseFinancialMonth: "",
      expenseFinancialYear: "",
      gstSummary: {},
      gstCollected: "",
      gstCredit: "",
      purchaseFinancialMonth: "",
      purchaseFinancialYear: "",
      payableReceiveable: "",
      purchaseItcId: "",
      defaultFinancialsYear: null,
      defaultBusinessUnit: null,
      showing: true,
      open: false,
      render: false,
      advanceAmountUsed: "",
      advanceGstUsed: "",
    };

    this.getYearFinancialsDeatils(true);
  }

  handleFinancialsYearChange(e) {
    this.setState({
      financialsYear: e,
    });
  }

  handleBusinessUnitChange(e) {
    this.setState({ businessUnit: e });
  }

  handleItcigstChange(e) {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ purchaseItcIgst: e.target.value });
    }
  }

  handleItcCgstChange(e) {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ purchaseItcCgst: e.target.value });
    }
  }

  handleItcSgstChange(e) {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ purchaseItcSgst: e.target.value });
    }
  }

  handleItcCessChange(e) {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ purchaseItcCess: e.target.value });
    }
  }

  editCreditBal = () => {
    const header = store.getState().header.header;
    const postObject = {
      itcBalance: {
        id: this.state.purchaseItcId,
        cess: this.state.purchaseItcCess,
        igst: this.state.purchaseItcIgst,
        cgst: this.state.purchaseItcCgst,
        sgst: this.state.purchaseItcSgst,
      },

      header: header,
    };
    FetchServerData.callPostService("/itc/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let editCreditBal = output.data;
          this.setState({
            isItcEditEnabled: false,
          });
        } else {
        }
      }
    );
  };

  getBusinessUnitList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/bums/getlistbyheader", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.businessUnitListArray = output.data;
          this.setState({
            businessUnit: this.businessUnitListArray[0],
          });
        } else {
        }
      }
    );
  }

  getCreditBalfromGST() {
    const header = store.getState().header.header;
    const postObject = {
      otp: null,
      fyear: this.state.financialsYear,
      itcBalance: {
        month: "APR",
      },
      header: header,
    };
    FetchServerData.callPostService("/itc/calculateItc", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let CreditBalfromGST = output.data;
        } else {
        }
      }
    );
  }

  getYearFinancialsDeatils = () => {
    const header = store.getState().header.header;
    const postObject = {
      bucode: this.state.businessUnit?.code,
      fyear: this.state.financialsYear,
      header: header,
    };
    FetchServerData.callPostService("/orgfinance/getForYear", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let yearData = output.data;
          this.gstSummary = yearData.gstSummary;
          this.setState({
            financialsYear: yearData.year,
            businessUnit: this.state.businessUnit,
          });
          this.setResponseToState(yearData);
        } else {
        }
      }
    );
  };

  resetYearFinancialsDeatils = (year, businessUnit) => {
    const header = store.getState().header.header;
    const postObject = {
      bucode: businessUnit?.code,
      fyear: year,
      header: header,
    };
    FetchServerData.callPostService("/orgfinance/getForYear", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let yearData = output.data;
          this.gstSummary = yearData.gstSummary;

          this.setState({
            financialsYear: yearData.year,
            businessUnit: businessUnit,
          });

          this.setResponseToState(yearData);
        } else {
        }
      }
    );
  };

  setResponseToState(yearData) {
    this.setState({
      //id: YearData.id,
      open: false,
      salesTaxable: yearData.sales.sales.taxable,
      salesIgst: yearData.sales.sales.igst,
      salesCgst: yearData.sales.sales.cgst,
      salesSgst: yearData.sales.sales.sgst,
      salesCess: yearData.sales.sales.cess,
      salesTotal: yearData.sales.sales.total,
      advanceAmountUsed: yearData.sales.sales.advanceAmountUsed,
      advanceGstUsed: yearData.sales.sales.advanceGstUsed,
      advanceTaxable: yearData.sales.receipt.taxable,
      advanceIgst: yearData.sales.receipt.igst,
      advanceCgst: yearData.sales.receipt.cgst,
      advanceSgst: yearData.sales.receipt.sgst,
      advanceCess: yearData.sales.receipt.cess,
      advanceTotal: yearData.sales.receipt.total,
      debitTaxable: yearData.sales.debit.taxable,
      debitIgst: yearData.sales.debit.igst,
      debitCgst: yearData.sales.debit.cgst,
      debitSgst: yearData.sales.debit.sgst,
      debitCess: yearData.sales.debit.cess,
      debitTotal: yearData.sales.debit.total,
      creditTaxable: yearData.sales.credit.taxable,
      creditIgst: yearData.sales.credit.igst,
      creditCgst: yearData.sales.credit.cgst,
      creditSgst: yearData.sales.credit.sgst,
      creditCess: yearData.sales.credit.cess,
      creditTotal: yearData.sales.credit.total,
      refundTaxable: yearData.sales.refund.taxable,
      refundIgst: yearData.sales.refund.igst,
      refundCgst: yearData.sales.refund.cgst,
      refundSgst: yearData.sales.refund.sgst,
      refundCess: yearData.sales.refund.cess,
      refundTotal: yearData.sales.refund.total,
      netTaxable: yearData.sales.nettaxable,
      netIgst: yearData.sales.netigst,
      netCgst: yearData.sales.netcgst,
      netSgst: yearData.sales.netsgst,
      netCess: yearData.sales.netcess,
      netTotal: yearData.sales.nettotal,
      salesRcmTaxable: yearData.sales.rcm.taxable,
      salesRcmIgst: yearData.sales.rcm.igst,
      salesRcmCgst: yearData.sales.rcm.cgst,
      salesRcmSgst: yearData.sales.rcm.sgst,
      salesRcmCess: yearData.sales.rcm.cess,
      salesRcmTotal: yearData.sales.rcm.total,
      purchaseTaxable: yearData.purchase.purchase.taxable,
      purchaseIgst: yearData.purchase.purchase.igst,
      purchaseCgst: yearData.purchase.purchase.cgst,
      purchaseSgst: yearData.purchase.purchase.sgst,
      purchaseCess: yearData.purchase.purchase.cess,
      purchaseTotal: yearData.purchase.purchase.total,
      purchaseAdvanceTaxable: yearData.purchase.receipt.taxable,
      purchaseAdvanceIgst: yearData.purchase.receipt.igst,
      purchaseAdvanceCgst: yearData.purchase.receipt.cgst,
      purchaseAdvanceSgst: yearData.purchase.receipt.sgst,
      purchaseAdvanceCess: yearData.purchase.receipt.cess,
      purchaseAdvanceTotal: yearData.purchase.receipt.total,
      purchaseDebitTaxable: yearData.purchase.debit.taxable,
      purchaseDebitIgst: yearData.purchase.debit.igst,
      purchaseDebitCgst: yearData.purchase.debit.cgst,
      purchaseDebitSgst: yearData.purchase.debit.sgst,
      purchaseDebitCess: yearData.purchase.debit.cess,
      purchaseDebitTotal: yearData.purchase.debit.total,
      purchaseCreditTaxable: yearData.purchase.credit.taxable,
      purchaseCreditIgst: yearData.purchase.credit.igst,
      purchaseCreditCgst: yearData.purchase.credit.cgst,
      purchaseCreditSgst: yearData.purchase.credit.sgst,
      purchaseCreditCess: yearData.purchase.credit.cess,
      purchaseCreditTotal: yearData.purchase.credit.total,
      purchaseRefundTaxable: yearData.purchase.refund.taxable,
      purchaseRefundIgst: yearData.purchase.refund.igst,
      purchaseRefundCgst: yearData.purchase.refund.cgst,
      purchaseRefundSgst: yearData.purchase.refund.sgst,
      purchaseRefundCess: yearData.purchase.refund.cess,
      purchaseRefundTotal: yearData.purchase.refund.total,
      // purchaseNetTaxable: yearData.purchase.rcm.nettaxable,
      purchaseNetIgst: yearData.purchase.netigst,
      purchaseNetCgst: yearData.purchase.netcgst,
      purchaseNetSgst: yearData.purchase.netsgst,
      purchaseNetCess: yearData.purchase.netcess,
      purchaseNetTaxable: yearData.purchase.nettaxable,
      purchaseNetTotal: yearData.purchase.nettotal,
      purchaseItcIgst: yearData.purchase.openingCredit?.igst,
      purchaseItcCgst: yearData.purchase.openingCredit?.cgst,
      purchaseItcSgst: yearData.purchase.openingCredit?.sgst,
      purchaseItcCess: yearData.purchase.openingCredit?.cess,
      purchaseItcId: yearData.purchase.openingCredit?.id,
      purchaseRcmTaxable: yearData.purchase.rcm.taxable,
      purchaseRcmIgst: yearData.purchase.rcm.igst,
      purchaseRcmCgst: yearData.purchase.rcm.cgst,
      purchaseRcmSgst: yearData.purchase.rcm.sgst,
      purchaseRcmCess: yearData.purchase.rcm.cess,
      purchaseRcmTotal: yearData.purchase.rcm.total,
      expenseAmount: yearData.expense.amount,
      gstSummary: yearData.gstSummary,
      gstCollected: yearData.gstSummary.gstcollected,
      gstCredit: yearData.gstSummary.gstcredit,
      payableReceiveable: yearData.gstSummary.payableReceiveable,
      render: true,
    });
  }
  setItcEditEnabled(e) {
    this.setState({
      isItcEditEnabled: !this.state.isItcEditEnabled,
      purchaseItcIgst: "",
      purchaseItcCgst: "",
      purchaseItcSgst: "",
      purchaseItcCess: 0,
    });
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/financialdetails/",
      state: {},
    });
  }
  resetFilter() {
    let dyear = new Date();
    let yr = dyear.getFullYear();
    let n = dyear.getMonth();
    if (n < "3") {
      this.finyr = --yr;
    } else {
      this.finyr = dyear.getFullYear();
    }
    let year1 = this.finyr;
    let endyr = ++year1;
    endyr = endyr.toString().substring(2);
    let year = this.finyr + "-" + endyr;
    let businessUnit =
      this.businessUnitList && this.businessUnitList.length > 0
        ? this.businessUnitList[0]
        : {};
    // this.setState({
    //   financialsYear: year,
    //   businessUnit:
    //     this.businessUnitList && this.businessUnitList.length > 0
    //       ? this.businessUnitList[0]
    //       : {},
    // });

    // store.dispatch(appDataAction.setHistorySalesSearchData({}));
    this.resetYearFinancialsDeatils(year, businessUnit);
  }

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  shawTooltipData() {
    if (
      this.state.advanceAmountUsed !== 0.0 ||
      this.state.advanceGstUsed !== 0.0
    ) {
      return (
        <p>
          Advance Used :{this.state.advanceAmountUsed}
          <br />
          Advance GST Used :{this.state.advanceGstUsed}
        </p>
      );
    } else {
      return <></>;
    }
  }
  render() {
    if (this.state.render === false) {
      return "";
    }

    let graphData = [];
    graphData.push({
      name: "GST Collected",
      value: this.state.gstCollected,
    });
    graphData.push({
      name: "GST Credit",
      value: this.state.gstCredit,
    });
    graphData.push({
      name: "Payable/Receivable",
      value: this.state.payableReceiveable,
    });

    let graphColors = ["#878bb6", "#4acab4", "#ff8153"];
    let totalAnnualGraphColors = ["#0077b6", "#4acab4", "rgb(247, 220, 111)"];
    let totalAnnualFinancialData = [];
    totalAnnualFinancialData.push({
      name: "Sales",
      value: this.state.netTotal,
    });
    totalAnnualFinancialData.push({
      name: "Purchase",
      value: this.state.purchaseNetTotal,
    });
    totalAnnualFinancialData.push({
      name: "Expense",
      value: this.state.expenseAmount,
    });
    return (
      <div>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={this.title}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        <div className="row ">
          <div className="col-8 align-items-start text-start">
            <span className="ms-2">
              {this.state.financialsYear !== null ||
              this.state.businessUnit !== null ? (
                <span>
                  {this.state.financialsYear &&
                  this.state.financialsYear !== null ? (
                    <span>
                      <b>Financial Year : </b>
                      {this.state.financialsYear + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.businessUnit &&
                  this.state.businessUnit !== null ? (
                    <span>
                      <b>Business Unit : </b>
                      {this.state.businessUnit.name}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="col-4 align-items-end text-end">
            <span
              className="text-end"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.handleAccountDropdownOpen}
            >
              <FilterAltIcon
                style={{ fontSize: "28px", color: "black" }}
                aria-controls={this.state.open ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.open ? "true" : undefined}
              />
              Filter
            </span>
            {"  "} &nbsp;&nbsp;
            <span
              className="me-1"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.resetFilter.bind(this)}
            >
              <i class="fa fa-refresh" aria-hidden="true"></i> Reset
            </span>
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <ValidatorForm
              style={{ width: "100%", color: "#000" }}
              ref="form"
              onSubmit={this.getYearFinancialsDeatils.bind(this, false)}
            >
              <div className="row">
                <h5 className="bold">Filter</h5>

                <div className="row mt-2">
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      // className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      <b>Select Financial Year</b>
                    </label>
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="year"
                      options={
                        null === this.financialYearList
                          ? []
                          : this.financialYearList
                      }
                      value={this.state.financialsYear}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        this.handleFinancialsYearChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="Select Financial Year"
                          InputLabelProps={{
                            shrink: true,

                            style: {},
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      // className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      <b>Business Unit</b>
                    </label>
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="businessUnit"
                      options={
                        null === this.businessUnitList
                          ? []
                          : this.businessUnitList
                      }
                      value={this.state.businessUnit}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleBusinessUnitChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="Business Unit"
                          InputLabelProps={{
                            shrink: true,

                            style: {},
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                {this.state.error ? (
                  <lable className="danger" style={{ color: "red" }}>
                    To Date should be after From Date
                  </lable>
                ) : (
                  ""
                )}
                <div className="col-md-12 mt-4">
                  <button
                    className="fileterButton"
                    // onClick={this.onFilterClick.bind(this)}
                    // onClick={(e) => this.filterRecords(e)}
                    type="submit"
                    // onClick={this.getMonthlyFinancialsDetails.bind(this)}
                  >
                    <i className="fa-solid fa-filter"></i>
                    &nbsp; Filter
                  </button>
                  &nbsp; &nbsp;
                  <button
                    className="resetButton"
                    onClick={this.resetFilter.bind(this)}
                  >
                    <i className="fa fa-refresh"></i>
                    &nbsp; Reset
                  </button>
                </div>
              </div>{" "}
            </ValidatorForm>
          </div>
        </Menu>
        <Card style={{ margin: "1em" }}>
          <CardHeader style={{ color: "black" }}>
            <strong>Outward Supply</strong>
          </CardHeader>
          <CardBody style={{ color: "black" }}>
            <TableContainer component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
                style={{ fontSize: "smalle" }}
              >
                <TableHead>
                  <TableRow style={{ background: "#c5c5c5" }}>
                    <TableCell style={{ border: "0.5px solid #9a9898" }}>
                      Particulars
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      Taxable Value(Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      IGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      SGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CESS (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      Total (Rs.)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="outSales">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title={this.shawTooltipData()}>
                          <div style={{ cursor: "pointer" }}>Sales</div>
                        </Tooltip>
                      </MuiThemeProvider>
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title={this.shawTooltipData()}>
                          <div style={{ cursor: "pointer" }}>
                            {this.state.salesTaxable}
                          </div>
                        </Tooltip>
                      </MuiThemeProvider>
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title={this.shawTooltipData()}>
                          <div style={{ cursor: "pointer" }}>
                            {Utils.getParseFloatValue(this.state.salesIgst)}
                          </div>
                        </Tooltip>
                      </MuiThemeProvider>
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title={this.shawTooltipData()}>
                          <div style={{ cursor: "pointer" }}>
                            {Utils.getParseFloatValue(this.state.salesCgst)}
                          </div>
                        </Tooltip>
                      </MuiThemeProvider>
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title={this.shawTooltipData()}>
                          <div style={{ cursor: "pointer" }}>
                            {Utils.getParseFloatValue(this.state.salesSgst)}
                          </div>
                        </Tooltip>
                      </MuiThemeProvider>
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title={this.shawTooltipData()}>
                          <div style={{ cursor: "pointer" }}>
                            {Utils.getParseFloatValue(this.state.salesCess)}
                          </div>
                        </Tooltip>
                      </MuiThemeProvider>
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title={this.shawTooltipData()}>
                          <div style={{ cursor: "pointer" }}>
                            {Utils.getParseFloatValue(this.state.salesTotal)}
                          </div>
                        </Tooltip>
                      </MuiThemeProvider>
                    </TableCell>
                  </TableRow>
                  <TableRow key="outAdvance">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Advance
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.advanceTaxable)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.advanceIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.advanceCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.advanceSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.advanceCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.advanceTotal)}
                    </TableCell>
                  </TableRow>

                  <TableRow key="outDebit">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Debit
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.debitTaxable)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.debitIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.debitCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.debitSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.debitCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.debitTotal)}
                    </TableCell>
                  </TableRow>

                  <TableRow key="outCredit">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Credit
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.creditTaxable)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.creditIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.creditCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.creditSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.creditCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.creditTotal)}
                    </TableCell>
                  </TableRow>

                  <TableRow key="outRefund">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Refund
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.refundTaxable)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.refundIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.refundCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.refundSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.refundCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.refundTotal)}
                    </TableCell>
                  </TableRow>

                  <TableRow key="outNet">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Net Amount
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.netTaxable)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.netIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.netCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.netSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.netCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.netTotal)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>

        <Card style={{ margin: "1em" }}>
          <CardHeader style={{ color: "black" }}>
            <strong>Inward Supply Liable to RCM</strong>
          </CardHeader>
          <CardBody style={{ color: "black" }}>
            <TableContainer component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
                style={{ fontSize: "smalle" }}
              >
                <TableHead>
                  <TableRow style={{ background: "#c5c5c5" }}>
                    <TableCell style={{ border: "0.5px solid #9a9898" }}>
                      Particulars
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      Taxable Value(Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      IGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      SGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CESS (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      Total (Rs.)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="rcmSales">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      RCM
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {this.state.salesRcmTaxable}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {this.state.salesRcmIgst}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {this.state.salesRcmCgst}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {this.state.salesRcmSgst}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {this.state.salesRcmCess}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {this.state.salesRcmTotal}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
        <Card style={{ margin: "1em" }}>
          <CardHeader style={{ color: "black" }}>
            <Row md={12}>
              <Col md={6}>
                <strong>ITC Balance</strong>
              </Col>
              <Col md={6}>
                <EditIconButton onClick={() => this.setItcEditEnabled()} />
                <RefreshButton
                  style={{
                    color: "#fff",
                    borderRadius: "25%",
                    cursor: "pointer",
                    fontSize: "x-large",
                    float: "right",
                    marginRight: "5px",
                    background: "#585353",
                  }}
                  onClick={(e) => this.getCreditBalfromGST()}
                />
                {/* <EditIcon
                  style={{
                    color: "#fff",
                    borderRadius: "25%",
                    cursor: "pointer",
                    fontSize: "x-large",
                    float: "right",
                    marginRight: "5px",
                    background: "#585353",
                  }}
                  onClick={() => this.setItcEditEnabled()}
                />
                <AutorenewIcon
                  style={{
                    color: "#fff",
                    borderRadius: "25%",
                    cursor: "pointer",
                    fontSize: "x-large",
                    float: "right",
                    marginRight: "5px",
                    background: "#585353",
                  }}
                  onClick={(e) => this.getCreditBalfromGST()}
                /> */}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <TableContainer component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
                style={{ fontSize: "smalle" }}
              >
                <TableHead>
                  <TableRow style={{ background: "#c5c5c5" }}>
                    <TableCell style={{ border: "0.5px solid #9a9898" }}>
                      Particulars
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      IGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      SGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CESS (Rs.)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ border: "0.5px solid #9a9898" }}>
                      Opening ITC As on Date
                    </TableCell>
                    <TableCell style={{ border: "0.5px solid #9a9898" }}>
                      <TextField
                        id="purchaseItcIgst"
                        value={this.state.purchaseItcIgst}
                        disabled={!this.state.isItcEditEnabled}
                        name="purchaseItcIgst"
                        //autoComplete="purchaseItcIgst"
                        type="text"
                        onChange={this.handleItcigstChange.bind(this)}
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                      />
                    </TableCell>

                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <TextField
                        id="purchaseItcCgst"
                        value={this.state.purchaseItcCgst}
                        disabled={!this.state.isItcEditEnabled}
                        name="purchaseItcCgst"
                        type="text"
                        onChange={this.handleItcCgstChange.bind(this)}
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <TextField
                        id="purchaseItcSgst"
                        value={this.state.purchaseItcSgst}
                        disabled={!this.state.isItcEditEnabled}
                        name="purchaseItcSgst"
                        type="text"
                        onChange={this.handleItcSgstChange.bind(this)}
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      <TextField
                        id="purchaseItcCess"
                        value={this.state.purchaseItcCess}
                        disabled={!this.state.isItcEditEnabled}
                        name="purchaseItcCess"
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        type="text"
                        onChange={this.handleItcCessChange.bind(this)}
                      />
                    </TableCell>
                  </TableRow>
                  {this.state.isItcEditEnabled === true && (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <span style={{ float: "right", marginRight: "-12px" }}>
                          <SaveButton onClick={(e) => this.editCreditBal()} />
                          <CancelIconButton
                            onClick={(e) => this.setItcEditEnabled()}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Row md={12} className="itcRow">
              <Col md={12}>
                <div class="tile">
                  {showing ? (
                    <table class="table table-bordered" id="tableStyle">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>IGST (Rs.)</th>
                          <th>CGST (Rs.)</th>
                          <th>SGST (Rs.)</th>
                          <th>Cess</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td id="monthly_itc">Opening ITC As on Date</td>

                          <td>
                            <TextField
                              id="purchaseItcIgst"
                              value={this.state.purchaseItcIgst}
                              disabled
                              name="purchaseItcIgst"
                              //autoComplete="purchaseItcIgst"
                              type="purchaseItcIgst"
                              onChange={this.handleItcigstChange.bind(this)}
                              className="textField"
                            />
                          </td>
                          <td>
                            <TextField
                              id="purchaseItcCgst"
                              value={this.state.purchaseItcCgst}
                              disabled
                              name="purchaseItcCgst"
                              //autoComplete="purchaseItcCgst"
                              type="text"
                              onChange={this.handleItcCgstChange.bind(this)}
                              className="textField"
                            />
                          </td>
                          <td>
                            <TextField
                              id="purchaseItcSgst"
                              value={this.state.purchaseItcSgst}
                              disabled
                              name="purchaseItcSgst"
                              //autoComplete="purchaseItcSgst"
                              type="purchaseItcSgst"
                              onChange={this.handleItcSgstChange.bind(this)}
                              className="textField"
                            />
                          </td>
                          <td>
                            <TextField
                              id="purchaseItcCess"
                              value={this.state.purchaseItcCess}
                              disabled
                              name="purchaseItcCess"
                              //autoComplete="purchaseItcCess"
                              type="purchaseItcCess"
                              onChange={this.handleItcCessChange.bind(this)}
                              className="textField"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <table class="table table-bordered" id="tableStyle">
                        <thead>
                          <tr>
                            <th>Particulars</th>
                            <th>IGST (Rs.)</th>
                            <th>CGST (Rs.)</th>
                            <th>SGST (Rs.)</th>
                            <th>Cess</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td id="monthly_rcm">Opening ITC As on Date</td>

                            <td>
                              <TextField
                                id="purchaseItcIgst"
                                value={this.state.purchaseItcIgst}
                                name="purchaseItcIgst"
                                //autoComplete="purchaseItcIgst"
                                type="purchaseItcIgst"
                                onChange={this.handleItcigstChange.bind(this)}
                                className="textField"
                              />
                            </td>
                            <td>
                              <TextField
                                id="purchaseItcCgst"
                                value={this.state.purchaseItcCgst}
                                name="purchaseItcCgst"
                                //autoComplete="purchaseItcCgst"
                                type="purchaseItcCgst"
                                onChange={this.handleItcCgstChange.bind(this)}
                                className="textField"
                              />
                            </td>
                            <td>
                              <TextField
                                id="purchaseItcSgst"
                                value={this.state.purchaseItcSgst}
                                name="purchaseItcSgst"
                                //autoComplete="purchaseItcSgst"
                                type="purchaseItcSgst"
                                onChange={this.handleItcSgstChange.bind(this)}
                                className="textField"
                              />
                            </td>
                            <td>
                              <TextField
                                id="purchaseItcCess"
                                value={this.state.purchaseItcCess}
                                name="purchaseItcCess"
                                //autoComplete="purchaseItcCess"
                                type="purchaseItcCess"
                                onChange={this.handleItcCessChange.bind(this)}
                                className="textField"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Button
                        className="dashboardButton"
                        style={{ float: 'right' }}
                        size="sm"
                        onClick={(e) => this.editCreditBal()}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row> */}
          </CardBody>
        </Card>

        <Card style={{ margin: "1em" }}>
          <CardHeader style={{ color: "black" }}>
            <strong>Inward Supply</strong>
          </CardHeader>
          <CardBody>
            <TableContainer component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
                style={{ fontSize: "smalle" }}
              >
                <TableHead>
                  <TableRow style={{ background: "#c5c5c5" }}>
                    <TableCell style={{ border: "0.5px solid #9a9898" }}>
                      Particulars
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      Taxable Value(Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      IGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      SGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CESS (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      Total (Rs.)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="inSales">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Purchase
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseTaxable)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseTotal)}
                    </TableCell>
                  </TableRow>
                  <TableRow key="inAdvance">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Advance
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(
                        this.state.purchaseAdvanceTaxable
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseAdvanceIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseAdvanceCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseAdvanceSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseAdvanceCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(
                        this.state.purchaseAdvanceTotal
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow key="inDebit">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Debit
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(
                        this.state.purchaseDebitTaxable
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseDebitIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseDebitCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseDebitSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseDebitCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseDebitTotal)}
                    </TableCell>
                  </TableRow>

                  <TableRow key="inCredit">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Credit
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(
                        this.state.purchaseCreditTaxable
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseCreditIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseCreditCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseCreditSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseCreditCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseCreditTotal)}
                    </TableCell>
                  </TableRow>

                  <TableRow key="inRefund">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Refund
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(
                        this.state.purchaseRefundTaxable
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRefundIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRefundCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRefundSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRefundCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRefundTotal)}
                    </TableCell>
                  </TableRow>

                  <TableRow key="inNet">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      Net Amount
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseNetTaxable)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseNetIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseNetCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseNetSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseNetCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseNetTotal)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
        <Card style={{ margin: "1em" }}>
          <CardHeader style={{ color: "black" }}>
            <strong>ITC On Inward Supply Liable to RCM</strong>
          </CardHeader>
          <CardBody style={{ color: "black" }}>
            <TableContainer component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
                style={{ fontSize: "smalle" }}
              >
                <TableHead>
                  <TableRow style={{ background: "#c5c5c5" }}>
                    <TableCell style={{ border: "0.5px solid #9a9898" }}>
                      Particulars
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      Taxable Value(Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      IGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      SGST (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      CESS (Rs.)
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      Total (Rs.)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="rcmSales">
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="left"
                    >
                      RCM
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRcmTaxable)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRcmIgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRcmCgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRcmSgst)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRcmCess)}
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.purchaseRcmTotal)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
        <Card style={{ margin: "1em" }}>
          <CardHeader style={{ color: "black" }}>
            <strong>Non-GST Expense</strong>
          </CardHeader>
          <CardBody>
            <TableContainer component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
                style={{ fontSize: "smalle" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: "0.5px solid #9a9898" }}>
                      <strong>Expense Amount</strong>
                    </TableCell>
                    <TableCell
                      style={{ border: "0.5px solid #9a9898" }}
                      align="right"
                    >
                      {Utils.getParseFloatValue(this.state.expenseAmount)}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>

        <Card style={{ margin: "1em" }}>
          <CardHeader style={{ color: "black" }}>
            <Col md={6}>
              <strong>Financial Summary</strong>
            </Col>
            <Col md={6}>
              <strong>GST Summary</strong>
            </Col>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <Table
                  size="small"
                  aria-label="a dense table"
                  style={{ border: "0", fontSize: "smalle" }}
                >
                  <TableHead style={{ border: "none" }}>
                    <TableRow>
                      <Row>
                        <Col md={6}>
                          <TableCell style={{ border: "none" }}>
                            <ApexPieChart
                              style={{ margin: "0", padding: "0" }}
                              series={[
                                this.state.netTotal,
                                this.state.purchaseNetTotal,
                                this.state.expenseAmount,
                              ]}
                              title={"GST Summary"}
                              dataKeys={["Sales", "Purchase", "Expense"]}
                              dataKeyColors={totalAnnualGraphColors}
                            />
                          </TableCell>
                        </Col>
                        <Col md={6}>
                          <TableCell align="left" style={{ border: "none" }}>
                            <Row
                              style={{ padding: "5px", display: "block" }}
                              className="mt-4"
                            >
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: totalAnnualGraphColors[0],
                                  background: totalAnnualGraphColors[0],
                                }}
                              />{" "}
                              <strong>
                                {`Sales : ${Utils.getParseFloatValue(
                                  totalAnnualFinancialData[0].value
                                )}`}
                              </strong>
                            </Row>
                            <Row style={{ padding: "5px", display: "block" }}>
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: totalAnnualGraphColors[1],
                                  background: totalAnnualGraphColors[1],
                                }}
                              />{" "}
                              <strong>
                                {`Purchase : ${Utils.getParseFloatValue(
                                  totalAnnualFinancialData[1].value
                                )}`}
                              </strong>
                            </Row>
                            <Row style={{ padding: "5px", display: "block" }}>
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: totalAnnualGraphColors[2],
                                  background: totalAnnualGraphColors[2],
                                }}
                              />{" "}
                              <strong>
                                {`Expense : ${Utils.getParseFloatValue(
                                  totalAnnualFinancialData[2].value
                                )}`}
                              </strong>
                            </Row>
                          </TableCell>
                        </Col>
                      </Row>
                    </TableRow>
                  </TableHead>
                </Table>
              </Col>
              <Col md={6}>
                <Table
                  size="small"
                  aria-label="a dense table"
                  style={{ border: "0", fontSize: "smalle" }}
                >
                  <TableHead style={{ border: "none" }}>
                    <TableRow>
                      <Row>
                        <Col md={6}>
                          <TableCell style={{ border: "none" }}>
                            <ApexPieChart
                              style={{ margin: "0", padding: "0" }}
                              series={[
                                this.state.gstCollected,
                                this.state.gstCredit,
                                this.state.payableReceiveable,
                              ]}
                              title={"GST Summary"}
                              dataKeys={[
                                "GST Collected",
                                "GST Credit",
                                "Payable Receiveable",
                              ]}
                              dataKeyColors={graphColors}
                            />
                          </TableCell>
                        </Col>
                        <Col md={6}>
                          <TableCell align="left" style={{ border: "none" }}>
                            <Row
                              style={{ padding: "5px", display: "block" }}
                              className="mt-4"
                            >
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: graphColors[0],
                                  background: graphColors[0],
                                }}
                              />{" "}
                              <strong>
                                {`GST Collected : ${Utils.getParseFloatValue(
                                  graphData[0].value
                                )}`}
                              </strong>
                            </Row>
                            <Row style={{ padding: "5px", display: "block" }}>
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: graphColors[1],
                                  background: graphColors[1],
                                }}
                              />{" "}
                              <strong>
                                {`GST Credit : ${Utils.getParseFloatValue(
                                  graphData[1].value
                                )}`}
                              </strong>
                            </Row>
                            <Row style={{ padding: "5px", display: "block" }}>
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  color: graphColors[2],
                                  background: graphColors[2],
                                }}
                              />{" "}
                              <strong>
                                {`Payable/Receivable : ${Utils.getParseFloatValue(
                                  graphData[2].value
                                )}`}
                              </strong>
                            </Row>
                          </TableCell>
                        </Col>
                      </Row>
                    </TableRow>
                  </TableHead>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

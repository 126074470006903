import Dashboard from "../pts/components/dashboard/Dashboard";
import { Filing } from "../pts/components/filing/Filing";
import { FilingHistory } from "../pts/components/filing/general/FilingHistory";
import { GstUser } from "../pts/components/filing/general/GstUser";
import GstUserList from "../pts/components/filing/general/GstUserList";
import GstR1List from "../pts/components/filing/gstr1/GstR1List";
import GSTRBDetails from "../pts/components/filing/gstr3/GSTRBDetails";
import GSTRB from "../pts/components/filing/gstr3/GstRB";
import GSTRBList from "../pts/components/filing/gstr3/GstRBList";
import FinancialAnaylsis from "../pts/components/financialAnalysis/FinancialAnalysis";
import AnnualFinancial from "../pts/components/financialAnalysis/annualFinancial/AnnualFinacial";
import MonthlyFinancial from "../pts/components/financialAnalysis/monthlyFinancial/MonthlyFinancial";
import QuarterlyFinancial from "../pts/components/financialAnalysis/quarterlyFinancial/QuarterlyFinancial";
import History from "../pts/components/history/History";
import { SalesHistory } from "../pts/components/history/SalesHistory/SalesHistory";
import { ExpenseHistory } from "../pts/components/history/expenseHistory/ExpenseHistory";
import { PurchaseHistory } from "../pts/components/history/purchaseHistory/PurchaseHistory";
import { Home } from "../pts/components/home/Home";
import Import from "../pts/components/import/Import";

import ImportProductServices from "../pts/components/import/productServices/ImportProductServices";
import ImportProductServicesList from "../pts/components/import/productServices/ImportProductServicesList";
// import ImportPurchaseInvoices from "../pts/components/import/purchaseImport/ImportPurchaseInvoices";
import ImportPurchaseInvoicesList from "../pts/components/import/purchaseImport/ImportPurchaseInvoicesList";
// import ImportPurchaseInvoicesList from "../pts/components/import/purchase/ImportPurchaseInvoicesList";
// import ImportPurchaseVoucher from "../pts/components/import/purchase/ImportPurchaseVoucher";
import ImportSalesInvoicesList from "../pts/components/import/sales/ImportSalesInvoicesList";
import ImportSalesVoucher from "../pts/components/import/sales/ImportSalesVoucher";
import ImportSupplier from "../pts/components/import/supplier/ImportSupplier";
import ImportSupplierList from "../pts/components/import/supplier/ImportSupplierList";
import { Login } from "../pts/components/login/Login";
import Master from "../pts/components/master/Master";
import Customer from "../pts/components/master/customer/Customer";
import CustomerList from "../pts/components/master/customer/CustomerList";
import { GoodsHsnCode } from "../pts/components/master/goodsHsnCode/GoodsHsnCode";
import ProductServiceList from "../pts/components/master/productServices/ProductServiceList";
import ProductServices from "../pts/components/master/productServices/ProductServices";
import { ServiceAccountingCode } from "../pts/components/master/serviceAccountingCodes/ServiceAccountingCode";
import Supplier from "../pts/components/master/supplier/Supplier";
import SupplierList from "../pts/components/master/supplier/SupplierList";
import Purchase from "../pts/components/purchase/Purchase";
import PurchaseAdvanceVoucher from "../pts/components/purchase/advanceVoucher/PurchaseAdvanceVoucher";
import PurchaseAdvanceVoucherList from "../pts/components/purchase/advanceVoucher/PurchaseAdvanceVoucherList";
import PurchaseAdvanceVoucherPrintPreview from "../pts/components/purchase/advanceVoucher/PurchaseAdvanceVoucherPrintPreview";
import PurchaseCreditNote from "../pts/components/purchase/creditNote/PurchaseCreditNote";
import PurchaseCreditNoteList from "../pts/components/purchase/creditNote/PurchaseCreditNoteList";
import PurchaseCreditNotePrintPreview from "../pts/components/purchase/creditNote/PurchaseCreditNotePrintPreview";
import PurchaseDebitNote from "../pts/components/purchase/debitNote/PurchaseDebitNote";
import PurchaseDebitNoteList from "../pts/components/purchase/debitNote/PurchaseDebitNoteList";
import PurchaseDebitNotePrintPreview from "../pts/components/purchase/debitNote/PurchaseDebitNotePrintPreview";
import GoodsServiceImportVoucher from "../pts/components/purchase/goodsServiceImportVoucher/GoodsServiceImportVoucher";
import GoodsServiceImportVoucherList from "../pts/components/purchase/goodsServiceImportVoucher/GoodsServiceImportVoucherList";
import PurchaseOrder from "../pts/components/purchase/purchaseOrder/PurchaseOrder";
import PurchaseOrderList from "../pts/components/purchase/purchaseOrder/PurchaseOrderList";
import PurchaseVoucher from "../pts/components/purchase/purchaseVoucher/PurchaseVoucher";
import PurchaseVoucherList from "../pts/components/purchase/purchaseVoucher/PurchaseVoucherList";
import PurchaseVoucherPrintPreview from "../pts/components/purchase/purchaseVoucher/PurchaseVoucherPrintPreview";
import RcmPaymentVoucher from "../pts/components/purchase/rcmPaymentVoucher/RcmPaymentVoucher";
import RcmPaymentVoucherList from "../pts/components/purchase/rcmPaymentVoucher/RcmPaymentVoucherList";
import RcmPaymentVoucherPrintPreview from "../pts/components/purchase/rcmPaymentVoucher/RcmPaymentVoucherPrintPreview";
import RcmVoucher from "../pts/components/purchase/rcmVoucher/RcmVoucher";
import RcmVoucherList from "../pts/components/purchase/rcmVoucher/RcmVoucherList";
import RcmVoucherPrintPreview from "../pts/components/purchase/rcmVoucher/RcmVoucherPrintPreview";
import PurchaseRefund from "../pts/components/purchase/refund/PurchaseRefund";
import PurchaseRefundList from "../pts/components/purchase/refund/PurchaseRefundList";
import PurchaseRefundPrintPreview from "../pts/components/purchase/refund/PurchaseRefundPrintPreview";
import PersonalRegistrationDetails from "../pts/components/registration/PersonalRegistrationDetails";
import RegistrationTandC from "../pts/components/registration/RegistrationTandC";
import { Reports } from "../pts/components/reports/Reports";
import NonGstReport from "../pts/components/reports/expense/NonGstReport";
import MonthlyReport from "../pts/components/reports/finance/MonthlyReport";
import QuaterlyReport from "../pts/components/reports/finance/QuaterlyReport";
import YearlyReport from "../pts/components/reports/finance/YearlyReport";
import AllSuppliers from "../pts/components/reports/purchase/AllSuppliers";
import SupplierWiseInvoice from "../pts/components/reports/purchase/SupplierWiseInvoice";
import SupplierWiseReport from "../pts/components/reports/purchase/SupplierWiseReport";
import AllCustomers from "../pts/components/reports/sales/AllCustomers";
import CustomerWiseInvoice from "../pts/components/reports/sales/CustomerWiseInvoice";
import CustomerWiseReport from "../pts/components/reports/sales/CustomerWiseReport";
import GeneralSettings from "../pts/components/settings/GeneralSettings";
import Settings from "../pts/components/settings/Settings";
import User from "../pts/components/userManagement/User";
import { UserManagement } from "../pts/components/userManagement/UserManagement";
import Expense from "../pts/components/expense/Expense";
import ExpenseList from "../pts/components/expense/ExpenseList";
import Sales from "../pts/components/sales/Sales";
import AdvanceVoucher from "../pts/components/sales/advanceVoucher/AdvanceVoucher";
import AdvanceVoucherList from "../pts/components/sales/advanceVoucher/AdvanceVoucherList";
import CreditNote from "../pts/components/sales/creditNote/CreditNote";
import CreditNoteList from "../pts/components/sales/creditNote/CreditNoteList";
import DebitNote from "../pts/components/sales/debitNote/DebitNote";
import DebitNoteList from "../pts/components/sales/debitNote/DebitNoteList";
import RefundVoucher from "../pts/components/sales/refundVoucher/RefundVoucher";
import RefundVoucherList from "../pts/components/sales/refundVoucher/RefundVoucherList";
import SalesVoucher from "../pts/components/sales/salesVoucher/SalesVoucher";
import SalesVoucherList from "../pts/components/sales/salesVoucher/SalesVoucherList";
import SalesVoucherPrintPreview from "../pts/components/sales/salesVoucher/SalesVoucherPrintPreview";
import AdvanceVoucherPrintPreview from "../pts/components/sales/advanceVoucher/AdvanceVoucherPrintPreview";
import DebitNotePrintPreview from "../pts/components/sales/debitNote/DebitNotePrintPreview";
import CreditNotePrintPreview from "../pts/components/sales/creditNote/CreditNotePrintPreview";
import RefundVoucherPrintPreview from "../pts/components/sales/refundVoucher/RefundVoucherPrintPreview";
import ExpensePrintPreview from "../pts/components/expense/ExpensePrintPreview";
import ImportCustomerList from "../pts/components/import/customerImport/ImportCustomerList";
import ImportCustomer from "../pts/components/import/customerImport/ImportCustomer";
import TermsAndCondition from "../pts/components/termsAndCondition/TermsAndCondition";
import PtsAboutUs from "../pts/components/ptsAboutUs/PtsAboutUs";
// import LoginWithEmail from "../pts/components/login/LoginWithEmail";
import GstR1 from "../pts/components/filing/gstr1/GstR1";
import { Profile } from "../pts/components/profile/Profile";
import ImportPurchaseVoucher from "../pts/components/import/purchaseImport/ImportPurchaseVoucher";
import { ResetPasswordViaEmail } from "../pts/components/forgotPassword/resetPassword/ResetPasswordViaEmail";
import { ResetPassword } from "../pts/components/forgotPassword/resetPassword/ResetPassword";
import ForgotPassword from "../pts/components/forgotPassword/ForgotPassword";
import ForgotPasswordEmail from "../pts/components/forgotPassword/ForgotPasswordEmail";
import TermsConditions from "../pts/components/home/termsConditions/TermsConditions";
import PrivacyPolicy from "../pts/components/home/privacyPolicy/PrivacyPolicy";
import AdminDashboard from "../pts/adminPanel/adminDashboard/AdminDashboard";
import AdminRegistrationList from "../pts/adminPanel/adminRegistration/AdminRegistrationList";
import AdminAppUserList from "../pts/adminPanel/adminAppUser/AdminAppUserList";
import AdminAppUser from "../pts/adminPanel/adminAppUser/AdminAppUser";
import AdminForgotPassword from "../pts/adminPanel/adminForgotPassword/AdminForgotPassword";
import AdminResetPassword from "../pts/adminPanel/adminForgotPassword/adminResetPassword/AdminResetPassword";
import AdminResetPasswordViaEmail from "../pts/adminPanel/adminForgotPassword/adminResetPassword/AdminResetPasswordViaEmail";
import AdminProfileManagementList from "../pts/adminPanel/adminProfileManagement/AdminProfileManagementList";
import AdminProfileManagement from "../pts/adminPanel/adminProfileManagement/AdminProfileManagement";
import EmailTemplateList from "../pts/adminPanel/emailTemplate/EmailTemplateList";
import EmailTemplate from "../pts/adminPanel/emailTemplate/EmailTemplate";
import AdminProfile from "../pts/adminPanel/adminProfile/AdminProfile";
import AdminRegistration from "../pts/adminPanel/adminRegistration/AdminRegistration";
import SupplierInvoice from "../pts/components/master/supplier/SupplierInvoice";
import PurchaseOrderPrintPreview from "../pts/components/purchase/purchaseOrder/PurchaseOrderPrintPreview";
import PrivacyPolicyTemplate from "../pts/adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplate";
import PrivacyPolicyTemplateList from "../pts/adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplateList";
import TermsConditionTemplate from "../pts/adminPanel/terms&ConditionTemplate/TermsConditionTemplate";
import TermsConditionTemplateList from "../pts/adminPanel/terms&ConditionTemplate/TermsConditionTemplateList";
import InwardLedger from "../pts/components/ledgerManagement/inwardLedger/InwardLedger";
import LedgerManagement from "../pts/components/ledgerManagement/LedgerManagement";
import OutwardLedger from "../pts/components/ledgerManagement/outwardLedger/OutwardLedger";
import DocumentReceipt from "../pts/components/documentReceipt/DocumentReceipt";
import InwardLedgerPrintPreview from "../pts/components/ledgerManagement/inwardLedger/InwardLedgerPrintPreview";
import PurchaseLedgerPrintPreview from "../pts/components/ledgerManagement/outwardLedger/PurchaseLedgerPrintPreview";
import ExpenseLedgerPrintPriview from "../pts/components/ledgerManagement/outwardLedger/ExpenseLedgerPrintPriview";
import { AdminServiceAccountingCodeList } from "../pts/adminPanel/adminServiceAccountingCode/AdminServiceAccountingCodeList";
import { AdminGoodHsnCodeList } from "../pts/adminPanel/adminGoodHsnCodes/AdminGoodHsnCodeList";
import AdminGoodHsnCode from "../pts/adminPanel/adminGoodHsnCodes/AdminGoodHsnCode";
import AdminServiceAccountingCode from "../pts/adminPanel/adminServiceAccountingCode/AdminServiceAccountingCode";
import ProductList from "../pts/components/reports/product/ProductList";
import ProductWiseSalesReport from "../pts/components/reports/product/ProductWiseSalesReport";
import ProductWisePurchseReport from "../pts/components/reports/product/ProductWisePurchseReport";
import ExpenseSupplierList from "../pts/components/master/expenseSupplier/ExpenseSupplierList";
import ExpenseSupplier from "../pts/components/master/expenseSupplier/ExpenseSupplier";

const pageList = [
  {
    name: "Home",
    path: "/home",
    component: Home,
  },
  {
    name: "Terms Conditions",
    path: "/termsConditions",
    component: TermsConditions,
  },
  {
    name: "Terms Conditions",
    path: "/termsCondition",
    component: TermsAndCondition,
  },
  {
    name: "Privacy Policy",
    path: "/privacypolicy",
    component: PrivacyPolicy,
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
  },
  {
    name: "registration",
    path: "/personalDetailsReg",
    component: PersonalRegistrationDetails,
  },
  // {
  //   name: "Confirmation Email",
  //   path: "/confirmationEmail",
  //   component: ConfirmationEmail,
  // },
  {
    name: "RegistrationT&C",
    path: "/regtandc",
    component: RegistrationTandC,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
  },
  {
    name: "Master",
    path: "/master",
    component: Master,
  },
  {
    name: "Product/Service Master",
    path: "/prodserv",
    component: ProductServiceList,
  },
  {
    name: "ProductServices",
    path: "/product",
    component: ProductServices,
  },
  {
    name: "ProductServices",
    path: "/productview",
    component: ProductServices,
  },
  {
    name: "ProductServices",
    path: "/productedit",
    component: ProductServices,
  },
  {
    name: "ServiceAccountingCode",
    path: "/services",
    component: ServiceAccountingCode,
  },
  {
    name: "GoodsHsnCode,",
    path: "/goods",
    component: GoodsHsnCode,
  },
  {
    name: "Customer",
    path: "/customer",
    component: Customer,
  },
  {
    name: "CustomerEdit",
    path: "/customeredit",
    component: Customer,
  },
  {
    name: "CustomerView",
    path: "/customerview",
    component: Customer,
  },

  {
    name: "Customer Master",
    path: "/cust",
    component: CustomerList,
  },
  {
    name: "Supplier Master",
    path: "/supp",
    component: SupplierList,
  },
  {
    name: "Supplier",
    path: "/supplier",
    component: Supplier,
  },
  {
    name: "Supplier",
    path: "/supplierview",
    component: Supplier,
  },
  {
    name: "Supplier",
    path: "/supplieredit",
    component: Supplier,
  },
  {
    name: "Supplier",
    path: "/supplierinvoiceview",
    component: SupplierInvoice,
  },
  {
    name: "Expense Supplier List",
    path: "/expensesupplier",
    component: ExpenseSupplierList,
  },
  {
    name: "Create Expense Supplier",
    path: "/exsupplier",
    component: ExpenseSupplier,
  },
  {
    name: "View Expense Supplier",
    path: "/exsupplierview",
    component: ExpenseSupplier,
  },
  {
    name: "Edit Expense Supplier",
    path: "/exsupplieredit",
    component: ExpenseSupplier,
  },
  {
    name: Settings,
    path: "/settings",
    component: Settings,
  },
  {
    name: GeneralSettings,
    path: "/generalSettings",
    component: GeneralSettings,
  },
  {
    name: "Purchase",
    path: "/purchase",
    component: Purchase,
  },
  {
    name: "Purchase Order",
    path: "/po",
    component: PurchaseOrderList,
  },
  ,
  {
    name: "Purchase Order Create",
    path: "/purchaseOrderCreate",
    component: PurchaseOrder,
  },
  {
    name: "Purchase Order",
    path: "/purchaseOrder",
    component: PurchaseOrder,
  },
  {
    name: "Purchase Order Edit",
    path: "/purchaseOrderEdit",
    component: PurchaseOrder,
  },
  {
    name: "PurchaseVoucherList",
    path: "/purchasesa",
    component: PurchaseVoucherList,
  },
  {
    name: "purchaseOrderPrint",
    path: "/purchaseOrderPrint",
    component: PurchaseOrderPrintPreview,
  },
  {
    name: "PurchaseVoucher",
    path: "/purchaseVoucher",
    component: PurchaseVoucher,
  },
  {
    name: "PurchaseVoucher",
    path: "/purchaseVoucherCreate",
    component: PurchaseVoucher,
  },
  {
    name: "PurchaseVoucher",
    path: "/purchaseVoucherEdit",
    component: PurchaseVoucher,
  },
  {
    name: "PurchaseVoucherPrintPreview",
    path: "/purchaseVoucherPrint",
    component: PurchaseVoucherPrintPreview,
  },
  {
    name: "PurchaseAdvanceVoucher",
    path: "/purchaseReceipt",
    component: PurchaseAdvanceVoucher,
  },
  {
    name: "PurchaseAdvanceVoucher",
    path: "/purchaseReceiptCreate",
    component: PurchaseAdvanceVoucher,
  },
  {
    name: "PurchaseAdvanceVoucher",
    path: "/purchaseReceiptEdit",
    component: PurchaseAdvanceVoucher,
  },
  {
    name: "PurchaseAdvanceVoucherPrintPreview",
    path: "/purchaseReceiptPrint",
    component: PurchaseAdvanceVoucherPrintPreview,
  },
  {
    name: "PurchaseAdvanceVoucherList",
    path: "/purchasere",
    component: PurchaseAdvanceVoucherList,
  },
  {
    name: "PurchaseCreditNoteList",
    path: "/purchasecn",
    component: PurchaseCreditNoteList,
  },
  {
    name: "PurchaseCreditNote",
    path: "/purchaseCreditNote",
    component: PurchaseCreditNote,
  },
  {
    name: "PurchaseCreditNote",
    path: "/purchaseCreditNoteCreate",
    component: PurchaseCreditNote,
  },
  {
    name: "PurchaseCreditNote",
    path: "/purchaseCreditNoteEdit",
    component: PurchaseCreditNote,
  },
  {
    name: "PurchaseCreditNotePrintPreview",
    path: "/purchaseCreditNotePrint",
    component: PurchaseCreditNotePrintPreview,
  },
  {
    name: "PurchaseDebitNote",
    path: "/purchaseDebitNote",
    component: PurchaseDebitNote,
  },
  {
    name: "PurchaseDebitNote",
    path: "/purchaseDebitNoteCreate",
    component: PurchaseDebitNote,
  },
  {
    name: "PurchaseDebitNote",
    path: "/purchaseDebitNoteEdit",
    component: PurchaseDebitNote,
  },
  {
    name: "PurchaseDebitNotePrintPreview",
    path: "/purchaseDebitNotePrint",
    component: PurchaseDebitNotePrintPreview,
  },
  {
    name: "PurchaseDebitNoteList",
    path: "/purchasedn",
    component: PurchaseDebitNoteList,
  },
  {
    name: "PurchaseRefund",
    path: "/purchaseRefund",
    component: PurchaseRefund,
  },
  {
    name: "PurchaseRefund",
    path: "/purchaseRefundCreate",
    component: PurchaseRefund,
  },
  {
    name: "PurchaseRefund",
    path: "/purchaseRefundEdit",
    component: PurchaseRefund,
  },
  {
    name: "PurchaseRefundPrintPreview",
    path: "/purchaseRefundPrint",
    component: PurchaseRefundPrintPreview,
  },
  {
    name: "PurchaseRefundList",
    path: "/purchaserf",
    component: PurchaseRefundList,
  },
  {
    name: "RcmVoucher",
    path: "/rcmVoucher",
    component: RcmVoucher,
  },
  {
    name: "RcmVoucher",
    path: "/rcmVoucherCreate",
    component: RcmVoucher,
  },
  {
    name: "RcmVoucher",
    path: "/rcmVoucherEdit",
    component: RcmVoucher,
  },
  {
    name: "RcmVoucherPrintPreview",
    path: "/rcmVoucherPrint",
    component: RcmVoucherPrintPreview,
  },
  {
    name: "RcmVoucherList",
    path: "/rcm",
    component: RcmVoucherList,
  },
  {
    name: "Goods/Service Import Voucher List",
    path: "/gsimprcm",
    component: GoodsServiceImportVoucherList,
  },
  {
    name: "Goods/Service Import Voucher Create",
    path: "/gsimprcmCreate",
    component: GoodsServiceImportVoucher,
  },
  {
    name: "Goods/Service Import Voucher Create",
    path: "/gsimprcmView",
    component: GoodsServiceImportVoucher,
  },
  {
    name: "Goods/Service Import Voucher Create",
    path: "/gsimprcmEdit",
    component: GoodsServiceImportVoucher,
  },
  {
    name: "RcmPaymentVoucher",
    path: "/rcmPaymentVoucher",
    component: RcmPaymentVoucher,
  },
  {
    name: "RcmPaymentVoucher",
    path: "/rcmPaymentVoucherCreate",
    component: RcmPaymentVoucher,
  },
  {
    name: "RcmPaymentVoucher",
    path: "/rcmPaymentVoucherEdit",
    component: RcmPaymentVoucher,
  },
  {
    name: "RcmPaymentVoucherPrintPreview",
    path: "/rcmPaymentVoucherPrint",
    component: RcmPaymentVoucherPrintPreview,
  },
  {
    name: "RcmPaymentVoucherList",
    path: "/pv",
    component: RcmPaymentVoucherList,
  },

  {
    name: "Import",
    path: "/import",
    component: Import,
  },
  {
    name: "ImportSupplierList",
    path: "/suppImport",
    component: ImportSupplierList,
  },
  {
    name: "ImportSupplier",
    path: "/importSupplier",
    component: ImportSupplier,
  },
  {
    name: "ImportSupplierEdit",
    path: "/importSupplierEdit",
    component: ImportSupplier,
  },
  {
    name: "ImportSupplierView",
    path: "/importSupplierView",
    component: ImportSupplier,
  },

  {
    name: "ImportProductServicesList",
    path: "/prod/imp",
    component: ImportProductServicesList,
  },
  {
    name: "ImportProductServices",
    path: "/importProductService",
    component: ImportProductServices,
  },
  {
    name: "ImportProductServicesEdit",
    path: "/importProductServiceEdit",
    component: ImportProductServices,
  },
  {
    name: "ImportProductServicesView",
    path: "/importProductServiceView",
    component: ImportProductServices,
  },
  {
    name: "ImportSalesVoucher",
    path: "/importSalesVoucher",
    component: ImportSalesVoucher,
  },
  {
    name: "ImportSalesVoucher",
    path: "/importSalesVoucherEdit",
    component: ImportSalesVoucher,
  },

  {
    name: "ImportSalesInvoicesList",
    path: "/sa/imp/",
    component: ImportSalesInvoicesList,
  },
  {
    name: "View ImportPurchaseVoucher",
    path: "/importPurchaseVoucher",
    component: ImportPurchaseVoucher,
  },
  {
    name: "Edit ImportPurchaseVoucher",
    path: "/importPurchaseVoucherEdit",
    component: ImportPurchaseVoucher,
  },

  {
    name: "ImportPurchaseInvoicesList",
    path: "/purchaseImport/",
    component: ImportPurchaseInvoicesList,
  },
  {
    name: "ImportCustomerList",
    path: "/custImport",
    component: ImportCustomerList,
  },
  {
    name: "ImportCustomer",
    path: "/importCustomer",
    component: ImportCustomer,
  },
  {
    name: "ImportCustomerEdit",
    path: "/importCustomerEdit",
    component: ImportCustomer,
  },
  {
    name: "ImportCustomerView",
    path: "/importCustomerView",
    component: ImportCustomer,
  },
  {
    name: "Reports",
    path: "/reports/",
    component: Reports,
  },
  {
    name: "AllCustomers",
    path: "/custlist_rpt/",
    component: AllCustomers,
  },
  {
    name: "CustomerWiseReport",
    path: "/custwise_rpt/",
    component: CustomerWiseReport,
  },
  {
    name: "CustomerWiseInvoice",
    path: "/custwise_inv_rpt/",
    component: CustomerWiseInvoice,
  },
  {
    name: "AllSuppliers",
    path: "/supplist_rpt/",
    component: AllSuppliers,
  },
  {
    name: "SupplierWiseReport",
    path: "/suppwise_rpt/",
    component: SupplierWiseReport,
  },
  {
    name: "SupplierWiseInvoice",
    path: "/suppwise_inv_rpt/",
    component: SupplierWiseInvoice,
  },
  {
    name: "NonGstReport",
    path: "/expense_rpt/",
    component: NonGstReport,
  },
  {
    name: "QuaterlyReport",
    path: "/qtrfinancial_rpt/",
    component: QuaterlyReport,
  },
  {
    name: "MonthlyReport",
    path: "/mnthfinancial_rpt/",
    component: MonthlyReport,
  },
  {
    name: "YearlyReport",
    path: "/yearfinancial_rpt/",
    component: YearlyReport,
  },
  {
    name: "FinancialAnaylsis",
    path: "/financialdetails/",
    component: FinancialAnaylsis,
  },
  {
    name: "MonthlyFinancial",
    path: "/monthlyfin_details/",
    component: MonthlyFinancial,
  },
  {
    name: "AnnualFinancial",
    path: "/yearlyfin_details/",
    component: AnnualFinancial,
  },
  {
    name: "QuarterlyFinancial",
    path: "/quaterlyfin_details/",
    component: QuarterlyFinancial,
  },
  {
    name: "Product List",
    path: "/product_rpt",
    component: ProductList,
  },
  {
    name: "Product Wise Sales Report",
    path: "/productwise_sales_rpt",
    component: ProductWiseSalesReport,
  },
  {
    name: "Product Wise Purchase Report",
    path: "/productwise_inv_rpt",
    component: ProductWisePurchseReport,
  },
  {
    name: "Filing",
    path: "/filing/",
    component: Filing,
  },
  {
    name: "FilingHistory",
    path: "/filinghisory/",
    component: FilingHistory,
  },
  {
    name: "GstUserList",
    path: "/gstr1usermgmt",
    component: GstUserList,
  },
  {
    name: "GstUser",
    path: "/gstr1usercreate",
    component: GstUser,
  },
  {
    name: "GstUser",
    path: "/gstr1userview",
    component: GstUser,
  },
  {
    name: "GstUser",
    path: "/gstr1useredit",
    component: GstUser,
  },
  {
    name: "GstR1List",
    path: "/gstr1list/",
    component: GstR1List,
  },
  // {
  //   name: "GstR1List",
  //   path: "/gstr1list/tbgstr1/",
  //   component: GstR1List,
  // },
  // {
  //   name: "GstR1List",
  //   path: "/tbgstr1/",
  //   component: GstR1List,
  // },

  {
    name: "GstR1",
    path: "/gstr1create",
    component: GstR1,
  },
  {
    name: "GstR1",
    path: "/gstr1view",
    component: GstR1,
  },
  {
    name: "GSTRB",
    path: "/gstr3list/",
    component: GSTRBList,
  },
  {
    name: "GSTRB",
    path: "/gstr3bcreate",
    component: GSTRB,
  },
  {
    name: "GSTRB",
    path: "/gstr3bview",
    component: GSTRB,
  },
  {
    name: "GSTRBDetails",
    path: "/gstr3bDetails",
    component: GSTRBDetails,
  },
  {
    name: "UserManagement",
    path: "/user/",
    component: UserManagement,
  },
  {
    name: "UserManagement",
    path: "/usercreate/",
    component: User,
  },
  {
    name: "UserManagement",
    path: "/userview/",
    component: User,
  },
  {
    name: "UserManagement",
    path: "/useredit/",
    component: User,
  },
  {
    name: "History",
    path: "/history",
    component: History,
  },
  {
    name: "Sales",
    path: "/sales_invoicesearch/",
    component: SalesHistory,
  },
  {
    name: "Purchase History",
    path: "/purchase_invoicesearch/",
    component: PurchaseHistory,
  },
  {
    name: "Expense",
    path: "/expense_invoicesearch/",
    component: ExpenseHistory,
  },
  {
    name: "Expense List",
    path: "/expense",
    component: ExpenseList,
  },
  {
    name: "Expense Add",
    path: "/expenseCreate",
    component: Expense,
  },
  {
    name: "Expense",
    path: "/expenseView",
    component: Expense,
  },
  {
    name: "Expense Edit",
    path: "/expenseEdit",
    component: Expense,
  },
  {
    name: "Expense Print Preview",
    path: "/expensePrint",
    component: ExpensePrintPreview,
  },
  {
    name: "Sales",
    path: "/sales",
    component: Sales,
  },
  {
    name: "Sales Voucher List",
    path: "/sa",
    component: SalesVoucherList,
  },
  {
    name: "Sales Voucher",
    path: "/salesVoucherCreate",
    component: SalesVoucher,
  },
  {
    name: "Sales Voucher",
    path: "/salesVoucher",
    component: SalesVoucher,
  },
  {
    name: "SalesVoucher",
    path: "/salesVoucherEdit",
    component: SalesVoucher,
  },
  {
    name: "Sales Voucher Print Preview",
    path: "/salesVoucherPrint",
    component: SalesVoucherPrintPreview,
  },
  {
    name: "Advance Voucher List",
    path: "/re",
    component: AdvanceVoucherList,
  },
  {
    name: "Advance Voucher",
    path: "/receiptCreate",
    component: AdvanceVoucher,
  },
  {
    name: "Advance Voucher",
    path: "/receipt",
    component: AdvanceVoucher,
  },
  {
    name: "Advance Voucher",
    path: "/receiptEdit",
    component: AdvanceVoucher,
  },
  {
    name: "Advance Voucher Print Preview",
    path: "/receiptPrint",
    component: AdvanceVoucherPrintPreview,
  },
  {
    name: "Debit NoteList",
    path: "/dn",
    component: DebitNoteList,
  },
  {
    name: "Debit Note",
    path: "/debitNoteCreate",
    component: DebitNote,
  },
  {
    name: "Debit Note",
    path: "/debitNote",
    component: DebitNote,
  },
  {
    name: "Debit Note",
    path: "/debitNoteEdit",
    component: DebitNote,
  },
  {
    name: "Debit Note Print Preview",
    path: "/debitNotePrint",
    component: DebitNotePrintPreview,
  },
  {
    name: "Credit Note List",
    path: "/cn",
    component: CreditNoteList,
  },
  {
    name: "Credit Note",
    path: "/creditNoteCreate",
    component: CreditNote,
  },
  {
    name: "Credit Note",
    path: "/creditNote",
    component: CreditNote,
  },
  {
    name: "Credit Note",
    path: "/creditNoteEdit",
    component: CreditNote,
  },
  {
    name: "Credit Note Print Preview",
    path: "/creditNotePrint",
    component: CreditNotePrintPreview,
  },
  {
    name: "Refund Voucher List",
    path: "/rf",
    component: RefundVoucherList,
  },
  {
    name: "RefundVoucher Create",
    path: "/refundVoucherCreate",
    component: RefundVoucher,
  },
  {
    name: "Refund Voucher",
    path: "/refundVoucher",
    component: RefundVoucher,
  },
  {
    name: "Refund Voucher Edit",
    path: "/refundVoucherEdit",
    component: RefundVoucher,
  },
  {
    name: "Refund Voucher Print Preview",
    path: "/refundVoucherPrint",
    component: RefundVoucherPrintPreview,
  },
  {
    name: "RegistrationT&C",
    path: "/regtandc",
    component: RegistrationTandC,
  },
  {
    name: "TermsAndConditions",
    path: "/tandc",
    component: TermsAndCondition,
  },
  {
    name: "PtsAboutUs",
    path: "/aboutUs",
    component: PtsAboutUs,
  },
  {
    name: "Document Receipt",
    path: "/viewReceipt",
    component: DocumentReceipt,
  },
  // {
  //   name: "LoginWithEmail",
  //   path: "/login",
  //   component: LoginWithEmail,
  // },
  {
    name: "Profile",
    path: "/admindetails",
    component: Profile,
  },
  {
    name: "ForgotPasswordEmail",
    path: "/forgotPasswordEmail",
    component: ForgotPasswordEmail,
  },
  {
    name: "ForgotPassword",
    path: "/forgotPassword",
    component: ForgotPassword,
  },
  {
    name: "ResetPassword",
    path: "/resetPassword",
    component: ResetPassword,
  },
  {
    name: "ResetPasswordViaEmail",
    path: "/resetPasswordViaEmail",
    component: ResetPasswordViaEmail,
  },
  {
    name: "Admin Forgot Password",
    path: "/ApForgotPassword",
    component: AdminForgotPassword,
  },
  {
    name: "Admin Reset Password",
    path: "/ApResetPassword",
    component: AdminResetPassword,
  },
  {
    name: "Admin Reset Password ViaEmail",
    path: "/ApResetPasswordViaEmail",
    component: AdminResetPasswordViaEmail,
  },
  {
    name: "Admin Dashboard",
    path: "/APDashboard",
    component: AdminDashboard,
  },
  {
    name: "Admin Registration List",
    path: "/registrationList",
    component: AdminRegistrationList,
  },
  {
    name: "View Admin Registration",
    path: "/viewRegistration",
    component: AdminRegistration,
  },
  {
    name: "Admin App User List",
    path: "/appUserList",
    component: AdminAppUserList,
  },
  {
    name: "Create Admin App User",
    path: "/createAppUser",
    component: AdminAppUser,
  },
  {
    name: "View Admin App User",
    path: "/viewAppUser",
    component: AdminAppUser,
  },
  {
    name: "Edit Admin App User",
    path: "/editAppUser",
    component: AdminAppUser,
  },
  {
    name: "Admin Profile Managemnet List",
    path: "/apSettings",
    component: AdminProfileManagementList,
  },
  {
    name: "Create Admin Profile Managemnet",
    path: "/apCreateProfileMgmnt",
    component: AdminProfileManagement,
  },
  {
    name: "View Admin Profile Managemnet",
    path: "/apViewProfileMgmnt",
    component: AdminProfileManagement,
  },
  {
    name: "Edit Admin Profile Managemnet",
    path: "/apEditProfileMgmnt",
    component: AdminProfileManagement,
  },
  {
    name: "Admin Email Template",
    path: "/emailTemplateList",
    component: EmailTemplateList,
  },
  {
    name: "View Email Template",
    path: "/viewEmailTemplate",
    component: EmailTemplate,
  },
  {
    name: "Edit Email Template",
    path: "/editEmailTemplate",
    component: EmailTemplate,
  },
  {
    name: "Admin profile",
    path: "/ApProfile",
    component: AdminProfile,
  },
  {
    name: "Admin profile",
    path: "/ApProfile",
    component: AdminProfile,
  },
  {
    name: "Admin Service Accounting CodeList",
    path: "/sacHsnList",
    component: AdminServiceAccountingCodeList,
  },
  {
    name: " Create Admin Service Accounting CodeList",
    path: "/createSacHsn",
    component: AdminServiceAccountingCode,
  },
  {
    name: " View Admin Service Accounting CodeList",
    path: "/viewSacHsn",
    component: AdminServiceAccountingCode,
  },
  {
    name: " Edit Admin Service Accounting CodeList",
    path: "/editSacHsn",
    component: AdminServiceAccountingCode,
  },
  {
    name: "Admin Good HSN CodeList",
    path: "/goodsHsnList",
    component: AdminGoodHsnCodeList,
  },
  {
    name: "Create Admin Good HSN Code",
    path: "/createGoodsHsn",
    component: AdminGoodHsnCode,
  },
  {
    name: "View Admin Good HSN Code",
    path: "/viewGoodsHsn",
    component: AdminGoodHsnCode,
  },
  {
    name: "Edit Admin Good HSN Code",
    path: "/editGoodsHsn",
    component: AdminGoodHsnCode,
  },
  {
    name: "terms Condition Template List",
    path: "/aptermsConditionsList",
    component: TermsConditionTemplateList,
  },
  {
    name: "termsConditionTemplate",
    path: "/addTermsCondition",
    component: TermsConditionTemplate,
  },
  {
    name: "termsConditionTemplate",
    path: "/viewTermsCondition",
    component: TermsConditionTemplate,
  },
  {
    name: "termsConditionTemplate",
    path: "/editTermsCondition",
    component: TermsConditionTemplate,
  },
  {
    name: "PrivacyPolicyTemplateList",
    path: "/privacyPolicyList",
    component: PrivacyPolicyTemplateList,
  },
  {
    name: "PrivacyPolicyTemplate",
    path: "/addPrivacyPolicy",
    component: PrivacyPolicyTemplate,
  },
  {
    name: "termsConditionTemplate",
    path: "/viewPrivacyPolicy",
    component: PrivacyPolicyTemplate,
  },
  {
    name: "termsConditionTemplate",
    path: "/editPrivacyPolicy",
    component: PrivacyPolicyTemplate,
  },
  {
    name: "Ledger Management",
    path: "/ledgerMgmt",
    component: LedgerManagement,
  },
  {
    name: "Inward Ledger",
    path: "/ledgerInward",
    component: InwardLedger,
  },
  {
    name: "Inward Ledger Print",
    path: "/ledgerInwardPrint",
    component: InwardLedgerPrintPreview,
  },
  {
    name: "Outward Ledger",
    path: "/ledgerOutward",
    component: OutwardLedger,
  },
  {
    name: "Purchase Outward Ledger Print",
    path: "/purchaseledgerPrint",
    component: PurchaseLedgerPrintPreview,
  },
  {
    name: "Expense Outward Ledger Print",
    path: "/expenseledgerPrint",
    component: ExpenseLedgerPrintPriview,
  },
];

if (module.hot) {
  module.hot.accept();
}
export default pageList;

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import AddIconButton from "../buttons/AddIconButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import PaymentTransaction from "./PaymentTransaction";
import "./PaymentTransaction.scss";

export class PaymentTransactionList extends Component {
  header = store.getState().header.header;

  constructor(props) {
    super(props);
    let paymentTransactions = this.props.data;
    if (paymentTransactions) {
      this.state = {
        history: props.history,
        title: props.title,
        aid: props.aid,
        type: props.type,
        expamount: props.expamount,
        expdate: props.expdate,
        documentTypeList: props.documentTypeList,
        paymentList: props.data ? props.data : [],
        onPaymentChange: props.onPaymentChange,
        addPaymentTransactionServiceUrl: props.addPaymentTransactionServiceUrl,
        updatePaymentTransactionServiceUrl:
          props.updatePaymentTransactionServiceUrl,
        deletePaymentTransactionServiceUrl:
          props.deletePaymentTransactionServiceUrl,
        isPayments: paymentTransactions.length > 0 ? true : false,
      };
    } else {
      this.state = {
        title: "Payment Details",
        type: props.type,
        aid: props.aid,
        expamount: props.expamount,
        expdate: props.expdate,
        addPaymentTransactionServiceUrl: props.addPaymentTransactionServiceUrl
          ? props.addPaymentTransactionServiceUrl
          : "",
        updatePaymentTransactionServiceUrl:
          props.updatePaymentTransactionServiceUrl
            ? props.updatePaymentTransactionServiceUrl
            : "",
        deletePaymentTransactionServiceUrl:
          props.deletePaymentTransactionServiceUrl
            ? props.deletePaymentTransactionServiceUrl
            : "",
        documentTypeList: props.documentTypeList,
        paymentList: [],
        isPayments: false,
      };
    }
  }

  addPayment(e) {
    this.state.paymentList.push({
      id: "",
      note: "",
      amount: this.props.expamount !== null ? this.props.expamount : "",
      currency: this.props.currency !== null ? this.props.currency : "",
      paymentdate: this.props.expdate,
      // paymentdate: null,
      ismoneyreceived: false,
      paymentstatus: null,
      paymentmethod: null,
      bankAccount: null,
      operation: "add",
      action: "create",
    });
    this.setState({ paymentList: this.state.paymentList, isPayments: true });
  }
  onPaymentAdd(transationDetail, index) {
    let payment = {};
    // if(this.state.type === 'Purchase Invoice'){
    //   console.log("if")
    //   payment = {
    //     note: transationDetail.note,
    //     amount: transationDetail.amount,
    //     paymentdate: transationDetail.paymentdate,

    //     paymentStatus: transationDetail.paymentstatus,
    //     paymentmethod: transationDetail.paymentmethod,
    //     ismoneyreceived: false,
    //     bankAccount: null,
    //   };
    //   payment.invoice = {
    //     id: transationDetail.aid,
    //   };
    // }else{
    payment = {
      note: transationDetail.note,
      amount: transationDetail.amount,
      paymentdate: transationDetail.paymentdate,
      paymentStatus: transationDetail.paymentstatus,
      paymentmethod: transationDetail.paymentmethod,
      bankAccount: { id: transationDetail.bankAccount?.id },
      currency: {
        id: transationDetail.currency?.id,
        isocode: transationDetail?.currency?.isocode,
      },
      ismoneyreceived: false,
    };
    if (this.state.type === "Invoice") {
      payment.invoice = {
        id: transationDetail.aid,
      };
    } else {
      payment.expense = {
        id: transationDetail.aid,
      };
    }
    // }

    let ptsObject = {
      header: this.header,
      payment: payment,
    };
    // console.log(
    //   "url :" + JSON.stringify(this.state.addPaymentTransactionServiceUrl)
    // );
    FetchServerData.callPostService(
      this.state.addPaymentTransactionServiceUrl,
      ptsObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let payment = output.data;
        payment.action = "view";
        payment.operation = "";
        let docList = [];
        for (let i = 0; i < this.state.paymentList.length; i++) {
          if (i === index) {
            docList.push(payment);
          } else {
            docList.push(this.state.paymentList[i]);
          }
        }
        if (this.state.onPaymentChange) {
          this.state.onPaymentChange(docList);
        }
        this.setState({ paymentList: docList, action: "view" });
      }
    });
  }

  onPaymentUpdate(transationDetail, index) {
    let payment = {
      id: transationDetail.id,
      note: transationDetail.note,
      amount: transationDetail.amount,
      paymentdate: transationDetail.paymentdate,
      paymentStatus: transationDetail.paymentstatus,
      paymentmethod: transationDetail.paymentmethod,
      bankAccount: { id: transationDetail.bankAccount?.id },
      currency: {
        id: transationDetail.currency?.id,
        isocode: transationDetail?.currency?.isocode,
      },
      ismoneyreceived: false,
    };
    if (this.state.type === "Invoice") {
      payment.invoice = {
        // id: transationDetail.aid,
        id: this.state.aid,
      };
    } else {
      payment.expense = {
        // id: transationDetail.aid,
        id: this.state.aid,
      };
    }
    let ptsObject = {
      header: this.header,
      payment: payment,
    };
    FetchServerData.callPostService(
      this.state.updatePaymentTransactionServiceUrl,
      ptsObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let payment = output.data;
        payment.action = "view";
        payment.operation = "";
        let docList = [];
        for (let i = 0; i < this.state.paymentList.length; i++) {
          if (i === index) {
            docList.push(payment);
          } else {
            docList.push(this.state.paymentList[i]);
          }
        }
        if (this.state.onPaymentChange) {
          this.state.onPaymentChange(docList);
        }
        this.setState({ paymentList: docList, action: "view" });
      }
    });
  }

  onPaymentDelete(postObject, index) {
    let objectToDelete = null;
    let docList = [];
    for (let i = 0; i < this.state.paymentList.length; i++) {
      if (i !== index) {
        docList.push(this.state.paymentList[i]);
      } else {
        if ("add" === this.state.paymentList[i].operation) {
          /* Do Nothing for Added Element */
        } else {
          /* Call Delete Service */
          objectToDelete = this.state.paymentList[i];
        }
      }
    }

    if (objectToDelete) {
      let payment = {
        id: objectToDelete.id,
      };
      let ptsObject = {
        header: this.header,
        payment: payment,
      };
      FetchServerData.callPostService(
        this.state.deletePaymentTransactionServiceUrl,
        ptsObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          if (this.state.onPaymentChange) {
            this.state.onPaymentChange(docList);
          }
          this.setState({ paymentList: docList, action: "view" });
        }
      });
    } else {
      this.setState({
        paymentList: docList,
        action: "view",
        isPayments: docList.length > 0 ? true : false,
      });
    }
  }
  getTableHeader() {
    return (
      <TableHead
        className="paymentMethodCell"
        style={{ backgroundColor: "#ececec" }}
      >
        <TableRow>
          <TableCell
            style={{
              width: "4%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>#</strong>
          </TableCell>
          <TableCell
            style={{
              width: "10%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>
              Date<span className="asterisk"> *</span>
            </strong>
          </TableCell>
          <TableCell
            style={{
              width: "12%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Reference</strong>
          </TableCell>
          <TableCell
            style={{
              width: "12%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>
              Method<span className="asterisk"> *</span>
            </strong>
          </TableCell>
          <TableCell
            style={{
              width: "12%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Bank Account</strong>
          </TableCell>
          <TableCell
            style={{
              width: "10%",
              textAlign: "right",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>
              Amount<span className="asterisk"> *</span>
            </strong>
          </TableCell>
          <TableCell
            style={{
              width: "10%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Currency</strong>
          </TableCell>
          <TableCell
            style={{
              width: "12%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>
              Status<span className="asterisk"> *</span>
            </strong>
          </TableCell>
          <TableCell
            style={{
              width: "12%",
              textAlign: "left",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Action</strong>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
  render() {
    let paymentList = [];
    if (this.state.paymentList) {
      this.state.paymentList.forEach((element, index) => {
        paymentList.push(
          <PaymentTransaction
            key={index}
            data={element}
            // action={element.action ? 'view' : element.action} sir code
            action={element.action ? element.action : "view"}
            aid={this.state.aid}
            index={index}
            history={this.props.history}
            onPaymentAdd={this.onPaymentAdd.bind(this)}
            onPaymentUpdate={this.onPaymentUpdate.bind(this)}
            onPaymentDelete={this.onPaymentDelete.bind(this)}
            documentTypeList={this.state.documentTypeList}
          />
        );
      });
    }
    return (
      <Col
        md={this.state.isPayments ? 12 : 4}
        style={{ margin: "0", padding: "1em" }}
      >
        <Row md={12} style={{ flexDirection: "column" }}>
          <div
            style={{
              display: "block",
              overflow: "scroll",
              width: "100%",
              padding: "0px",
            }}
          >
            <Row
              md={12}
              style={{
                fontSize: "small",
                margin: 0,
                padding: 0,
                background: "#ececec",
                border: "0.5px solid #cacaca",
              }}
            >
              <Col md={6}>
                <span style={{ fontSize: "15px" }}>
                  <strong>{this.state.title}</strong>
                </span>
              </Col>
              <Col md={6}>
                <AddIconButton onClick={this.addPayment.bind(this)} />
              </Col>
            </Row>
            {paymentList.length > 0 && (
              <Row
                md={12}
                style={{
                  fontSize: "small",
                  margin: 0,
                  padding: 0,
                }}
              >
                <Table
                  size="small"
                  style={{ color: "black", fontSize: "small" }}
                >
                  {this.getTableHeader()}
                  <TableBody>{paymentList}</TableBody>
                </Table>
              </Row>
            )}
          </div>
        </Row>
      </Col>
    );
  }
}

export default PaymentTransactionList;

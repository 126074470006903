import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import Address from "../address/Address";
import CancelButton from "../buttons/CancelButton";
import CreateButton from "../buttons/CreateButton";
import DeleteIconButton from "../buttons/DeleteIconButton";
import EditIconButton from "../buttons/EditIconButton";
import SaveButton from "../buttons/SaveButton";
import "./BusinessUnit.scss";
import BusinessUnitList from "./BusinessUnitList";
import Utils from "../../../provider/Utils";

export class BusinessUnit extends Component {
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;

  constructor(props) {
    super(props);

    this.addressRef = React.createRef();
    let businessUnit = props.data;
    if (businessUnit !== null) {
      this.state = {
        id: null === businessUnit.id ? "" : businessUnit.id,
        name: null === businessUnit.name ? "" : businessUnit.name,
        code: null === businessUnit.code ? "" : businessUnit.code,
        startdate:
          null === businessUnit.startdate ? null : businessUnit.startdate,
        address: null === businessUnit.address ? "" : businessUnit.address,
        businessUnits: null === props.businessUnits ? [] : props.businessUnits,
        onChangeSettings: props.onChangeSettings,
        action: this.props.action,
      };
    } else {
      this.state = {
        id: "",
        name: "",
        code: "",
        startdate: null,
        address: "",
        businessUnits: null === props.businessUnits ? [] : props.businessUnits,
        onChangeSettings: props.onChangeSettings,
        action: this.props.action,
      };
    }
  }

  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleStartdateChange(date) {
    this.setState({ startdate: date });
  }
  handleAddressChange(address) {
    address.pin = address.pincode;
    this.setState({ address: address });
  }

  createBusinessUnitInBackend(e) {
    e.preventDefault();
    if (this.addressRef && this.addressRef.current) {
      if (false === this.addressRef.current.validateAddress()) {
        return;
      }
    }
    this.state.address = this.addressRef.current.state;
    const header = store.getState().header.header;
    this.setState({ render: false });
    let newaddress = {};
    if (this.state.address) {
      newaddress = {
        id: this.state.address.id,
        line1: this.state.address.line1,
        line2: this.state.address.line2,
        city: this.state.address.city,
        pin: this.state.address.pincode,
      };
    }
    if (this.state.address.state) {
      let statevo = {
        id: this.state.address.state.id,
        name: this.state.address.state.name,
        code: this.state.address.state.code,
        countryvo: {
          id: this.state.address.country.id,
          name: this.state.address.country.name,
          code: this.state.address.country.code,
        },
      };
      newaddress.statevo = statevo;
    }

    const postObject = {
      bu: {
        id: this.state.id,
        name: this.state.name,
        code: this.state.code,
        address: newaddress,
        startdate: this.state.startdate,
      },
      header: header,
    };
    FetchServerData.callPostService("/bums/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let businessUnitData = output.data;
          let newData = {
            id: businessUnitData.id,
            name: businessUnitData.name,
            code: businessUnitData.code,
            startdate: businessUnitData.startdate,
            address: businessUnitData.address,
            action: "show_list",
            render: true,
            isAdd: true,
          };
          this.props.onChangeSettings(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }
  updateBusinessUnitInBackend(e) {
    e.preventDefault();
    if (this.addressRef && this.addressRef.current) {
      if (false === this.addressRef.current.validateAddress()) {
        return;
      }
    }
    this.state.address = this.addressRef.current.state;
    const header = store.getState().header.header;
    this.setState({ render: false });

    let newaddress = {
      id: this.state.address.id,
      line1: this.state.address.line1,
      line2: this.state.address.line2,
      city: this.state.address.city,
      pin: this.state.address.pincode,
      statevo: {
        id: this.state.address.state.id,
        name: this.state.address.state.name,
        code: this.state.address.state.code,
        countryvo: {
          id: this.state.address.country.id,
          name: this.state.address.country.name,
          code: this.state.address.country.code,
        },
      },
    };
    const postObject = {
      bu: {
        id: this.state.id,
        name: this.state.name,
        code: this.state.code,
        address: newaddress,
        startdate: this.state.startdate,
      },
      header: header,
    };
    FetchServerData.callPostService("/bums/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let businessUnitData = output.data;
          let newData = {
            id: businessUnitData.id,
            name: businessUnitData.name,
            code: businessUnitData.code,
            address: businessUnitData?.address,
            startdate: businessUnitData.startdate,
            action: "show_list",
            render: true,
            isUpdate: true,
          };
          this.props.onChangeSettings(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }
  deleteBusinessUnit() {
    /* Go Back to List Page */
    const header = store.getState().header.header;
    this.setState({ render: false });

    // let newaddress = {
    //   id: this.state.address.id,
    //   line1: this.state.address.line1,
    //   line2: this.state.address.line2,
    //   city: this.state.address.city,
    //   pin: this.state.address.pincode,
    //   statevo: {
    //     id: this.state.address.state.id,
    //     name: this.state.address.state.name,
    //     code: this.state.address.state.code,
    //     countryvo: {
    //       id: this.state.address.country.id,
    //       name: this.state.address.country.name,
    //       code: this.state.address.country.code,
    //     },
    //   },
    // };
    const postObject = {
      bu: {
        id: this.state.id,
        name: this.state.name,
        code: this.state.code,
        // address: newaddress,
      },
      header: header,
    };
    FetchServerData.callPostService("/bums/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let businessUnitData = output.data;
          let newData = {
            id: this.state.id,
            name: this.state.name,
            code: this.state.code,
            address: this.state.address,
            action: "show_list",
            render: true,
            isDelete: true,
          };
          this.props.onChangeSettings(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }

  modifyBusinessUnit() {
    /* Go Back to List Page */
    this.setState({ action: "edit" });
  }
  cancel() {
    /* Go Back to List Page */
    this.setState({ action: "show_list" });
  }
  viewAccount() {
    /* Go Back to List Page */
    this.setState({ action: "view" });
  }
  render() {
    this.addressRef = React.createRef();
    if (this.state.action === "show_list") {
      return (
        <BusinessUnitList
          data={this.state.businessUnits}
          onChange={this.state.onChangeSettings}
          action="view"
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    } else if (this.state.action === "create") {
      return this.createBusinessUnit();
    } else if (this.state.action === "edit") {
      return this.editBusinessUnit();
    } else {
      return this.viewBusinessUnit();
    }
  }

  viewBusinessUnit() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          <Row
            style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}
          >
            <Col>
              <span>
                <strong>Business Unit Details</strong>
              </span>
            </Col>
            <Col>
              <div style={{ float: "right" }}>
                <DeleteIconButton
                  domainObject="Business Unit"
                  onClick={this.deleteBusinessUnit.bind(this)}
                  caption="Delete Business Unit"
                />
                <EditIconButton
                  onClick={this.modifyBusinessUnit.bind(this)}
                  caption="Edit Business Unit"
                ></EditIconButton>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row md={12} className="mt-2" style={{ margin: 0, padding: 0 }}>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Name
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>{this.state.name}</label>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Code
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>{this.state.code}</label>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label style={{ color: "#000", fontWeight: "600" }}>
                    Start Date
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ color: "#000" }}>
                    {Utils.ptsDateFormat(this.state.startdate)}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Address data={this.state.address} action="view" />
            </Col>
          </Row>
        </CardBody>
        <CardFooter style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<CloseIcon />}
              onClick={(e) => this.cancel()}
            >
              Close
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }

  createBusinessUnit() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <ValidatorForm
          ref="form"
          onSubmit={this.createBusinessUnitInBackend.bind(this)}
        >
          <CardHeader
            style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "5px",
              }}
            >
              <Col>
                <span>
                  <strong>Create Business Unit</strong>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="mt-2" md={12}>
              <Col md={6}>
                <Row>
                  <Col md={12} style={{ padding: "0.5em" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Branch Name<span className="asterisk"> *</span>
                    </label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id="name"
                      placeholder="Branch Name"
                      value={this.state.name}
                      onChange={this.handleNameChange.bind(this)}
                      //onBlur={() => {this.props.onChange(this.state)}}
                      name="name"
                      font="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ padding: "0.5em" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Branch Code<span className="asterisk"> *</span>
                    </label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id="code"
                      placeholder="Branch Code"
                      value={this.state.code}
                      onChange={this.handleCodeChange.bind(this)}
                      //onBlur={() => {this.props.onChange(this.state)}}
                      name="name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ padding: "0.5em" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Start Date
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          // clearable
                          value={this.state.startdate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleStartdateChange.bind(this)}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>{" "}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Address
                  ref={this.addressRef}
                  data={this.state.address}
                  onChange={this.handleAddressChange.bind(this)}
                  action="create"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div style={{ textAlign: "center" }}>
              <CreateButton type="submit" />
              <CancelButton onClick={(e) => this.cancel()} />
            </div>
          </CardFooter>
        </ValidatorForm>
      </Card>
    );
  }
  editBusinessUnit() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <ValidatorForm
          ref="form"
          onSubmit={this.updateBusinessUnitInBackend.bind(this)}
        >
          <CardHeader
            style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "5px",
              }}
            >
              <Col>
                <span>
                  <strong>Edit Business Unit</strong>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12} className="mt-2">
              <Col md={6}>
                <Row>
                  <Col md={12} style={{ padding: "0.5em" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Branch Name<span className="asterisk"> *</span>
                    </label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id="name"
                      placeholder="Branch Name"
                      value={this.state.name}
                      onChange={this.handleNameChange.bind(this)}
                      //onBlur={() => {this.props.onChange(this.state)}}
                      name="name"
                      font="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ padding: "0.5em" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Branch Code<span className="asterisk"> *</span>
                    </label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id="code"
                      placeholder="Branch Code"
                      value={this.state.code}
                      onChange={this.handleCodeChange.bind(this)}
                      //onBlur={() => {this.props.onChange(this.state)}}
                      name="name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ padding: "0.5em" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Start Date
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          // clearable
                          value={this.state.startdate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleStartdateChange.bind(this)}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>{" "}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Address
                  ref={this.addressRef}
                  data={this.state.address}
                  onChange={this.handleAddressChange.bind(this)}
                  action="edit"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div style={{ textAlign: "center" }}>
              <SaveButton type="submit" />
              <CancelButton onClick={(e) => this.viewAccount()} />
            </div>
          </CardFooter>
        </ValidatorForm>
      </Card>
    );
  }
}

export default BusinessUnit;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#1B7189",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});

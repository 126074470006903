// import Button from '@material-ui/core/Button';
import FileViewer from "react-file-viewer";
import logo from "../../../assets/images/logo3.png";
import { Buffer } from "buffer";
import React, { Component } from "react";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import PtsAlert from "../ptsAlert/PtsAlert";

export default class DocumentReceipt extends Component {
  header = store.getState().header.header;
  tableIcons = UiUtils.getMaterialTableIcons();
  title = "Receipt Details";
  constructor(props) {
    super(props);
    let str = window.location.href;
    let splitted = str.split("?");
    let splitForTid = str.split("&");
    let receiptUrl = splitted[1].substring(
      4,
      splitted[1].indexOf("&documentId")
    );
    let documentId = splitForTid[1].substring(11);
    let tenantId = splitForTid[2].substring(4);
    this.state = {
      srcType: null,
      receiptUrl: receiptUrl,
      newUrl: "",
      expenseReceiptUrl: "",
      fileType: "",
      documentId: documentId,
      tenantId: tenantId,
      open: false,
      render: false,
    };
    this.viewDocument();
  }

  viewDocument() {
    if (!this.state.receiptUrl) {
      PtsAlert.error("Receipt URL Not Available");
      return;
    }
    const postObject = {
      url: this.state.receiptUrl,
      documentid: this.state.documentId,
      header: {
        tid: this.state.tenantId,
        appclient: "W",
      },
    };

    FetchServerData.callPostFileDownload("/filems/get", postObject).then(
      (output) => {
        if ("" !== output.data) {
          var expenseReceiptUrl =
            "data:application/*;base64," + output.data?.entity;
          const byteArray = new Buffer(
            output.data.entity.replace(/^[\w\d;:\/]+base64\,/g, ""),
            "base64"
          );
          const url = URL.createObjectURL(new Blob([byteArray]));
          let fileDetails = [];
          fileDetails = output.data?.metadata?.FileName;
          let fileName = "";
          if (fileDetails.length > 0) {
            for (let i = 0; i < fileDetails.length; i++) {
              fileName = fileDetails[i];
            }
          }
          let urlArr = fileName?.substring(fileName.lastIndexOf(".") + 1);
          let fileType = urlArr.toLowerCase();
          this.setState({
            newUrl: url,
            fileType: fileType,
            expenseReceiptUrl: expenseReceiptUrl,
            render: true,
            open: true,
          });
        }
      }
    );
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleServiceGoodsSelectChange(e) {
    this.setState({ serviceGoods: e.target.value });
  }

  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  hsnCodeSelected(e, selectedCode) {
    if (Utils.isNotNull(this.props.onCodeChange)) {
      this.props.onCodeChange(selectedCode);
      this.setState({ open: false });
    }
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <div>
            <nav
              id="ptsHomeHeader"
              style={{ padding: 0, backgroundColor: "#054e63" }}
              className="navbar navbar-default bootsnav navbar-fixed no-background white"
            >
              <div className="navbar-header">
                <button
                  type="button"
                  className="mobile-nav-toggle d-lg-none text-end"
                  style={{ marginRight: "-5em", marginTop: "2em" }}
                  onClick={(e) => this.openMenu()}
                >
                  <i className="fa fa-bars" style={{ fontSize: "1.5em" }}></i>
                </button>{" "}
                <span style={{ display: "inline-flex", margin: "1.5em 8.5em" }}>
                  <img
                    src={logo}
                    className="logo"
                    alt=""
                    style={{ width: "44px" }}
                    width="auto"
                    height="auto"
                  />
                  &nbsp;&nbsp;
                  <h3
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      margin: "auto",
                    }}
                  >
                    Taxonomy Hub
                  </h3>
                </span>
                {/* </a> */}
              </div>
            </nav>
          </div>
          <div
            className="termsBanner"
            id="top"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div
              className="container"
              style={{ border: "1px solid lightgray", padding: "2em" }}
            >
              <div
                md={12}
                style={{
                  width: "100%",
                  color: "#000",
                  fontSize: "small",
                  padding: "0.5em",
                }}
              >
                {this.state.fileType === "txt" ? (
                  <iframe
                    src={this.state.newUrl}
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <div style={{ height: "100%" }}>
                    <FileViewer
                      fileType={this.state.fileType}
                      filePath={this.state.newUrl}
                      onError={this.onError}
                      height={800}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </section> */}
          {/* <footer style={{ background: "#000" }}>
                        <div className="container termsConditionsUI">
                            <div className="row">
                                <div className="col-lg-6" style={{ margin: "auto" }}>
                                    <div
                                        className="copyright"
                                        style={{ fontSize: "12px", color: "#fff" }}
                                    >
                                        © Copyright
                                        <strong>
                                            <span> AssetTrackifier</span>
                                        </strong>
                                        . All Rights Reserved
                                    </div>
                                </div>
                                <div className="col-lg-3"></div>
                                <div className="col-lg-3">
                                    <ul
                                        style={{
                                            float: "right",
                                            listStyle: "none",
                                            lineHeight: "3em",
                                            margin: "0 100px 0px 0",
                                        }}
                                    >
                                        <li style={{ textAlign: "left" }}>
                                            <i
                                                className="fa fa-angle-right"
                                                style={{ color: "#fff" }}
                                            />

                                            <a
                                                onClick={() => this.navigateToLink("/termsConditions")}
                                                style={{ color: "#fff", cursor: "pointer" }}
                                            >
                                                {" "}
                                                Terms &amp; Conditions
                                            </a>
                                        </li>
                                        <li style={{ textAlign: "left" }}>
                                            <i
                                                className="fa fa-angle-right"
                                                style={{ color: "#fff" }}
                                            />

                                            <a
                                                onClick={() => this.navigateToLink("/privacyPolicy")}
                                                style={{ color: "#fff", cursor: "pointer" }}
                                            >
                                                {" "}
                                                Privacy Policy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer> */}
        </>
      );
    }
  }
}

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./assets/css/animate.css";
import "./assets/css/bootsnav.css";
import "./assets/css/bootstrap.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/font1.css";
import "./assets/css/font2.css";
import "./assets/css/iconfont.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/plugins.css";
import "./assets/css/responsive.css";
import "./assets/css/style.css";
import Device1 from "./assets/images/device1.png";
import logo3 from "./assets/images/logo3.png";
import Overview from "./assets/images/overview.png";
import Service from "./assets/images/service.png";
// import testImg1 from "./assets/images/test-img1.jpg";
import aboutUs from "./assets/images/aboutUs.png";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Container } from "react-floating-action-button";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Fab from "@material-ui/core/Fab";

import Button from "@material-ui/core/Button";
import FetchServerData from "../../../provider/FetchServerData";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Home.scss";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      yourCompany: "",
      message: "",
      subject: "",
      activeElement: "",
      visible: false,
      menuList: false,
    };
  }
  // componentDidMount() {
  //   // window.addEventListener('scroll', this.toggleVisible);

  // }
  handleScroll() {
    if (window.scrollY > 50) {
      let element = document.getElementById("navbar-menu"); //.className.add = 'ptsInActiveHeader';
      element.classList.add("ptsInActiveHeader");
    } else {
      let element = document.getElementById("navbar-menu"); //.className = 'ptsActiveHeader';
      element.classList.remove("ptsInActiveHeader");
    }
  }
  // if (window.scrollY > 50) {
  //   document.getElementsByClassName('.header')
  //     ? document.getElementsByClassName('.header')[0].addClass('active')
  //     : '';
  // } else {
  //   //remove the background property so it comes transparent again (defined in your css)
  //   document.getElementsByClassName('.header')
  //     ? document.getElementsByClassName('.header').removeClass('active')
  //     : '';
  // }

  // componentDidMount() {
  //   window.addEventListener("scroll", this.handleScroll);
  // }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleVisible);
    window.addEventListener("scroll", this.handleScroll);
    let scrollTo = "";
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state
    ) {
      scrollTo = this.props.history.location.state.scrollTo;
    }
    if (typeof scrollTo !== "undefined" || scrollTo !== null) {
      this.scrollToSection(scrollTo);
    }
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }

  handleCompanyChange(e) {
    this.setState({ yourCompany: e.target.value });
  }

  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  handleSubjectChange(e) {
    this.setState({ subject: e.target.value });
  }
  addContactToBackend(e) {
    const postObject = {
      name: this.state.name,
      email: this.state.email,
      companyName: this.state.yourCompany,
      message: this.state.message,
      phone: this.state.phone,
      subject: this.state.subject,
    };
    FetchServerData.callPostService("/enquiryms/sendEnquiry", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);

          this.setState({
            email: "",
            name: "",
            phone: "",
            yourCompany: "",
            message: "",
            subject: "",
          });
        } else {
        }
      }
    );
  }

  scrollToSection(elementID) {
    this.setState({
      activeElement: elementID,
    });
    if (document.getElementById(elementID)) {
      document.getElementById(elementID).scrollIntoView();
    }
  }

  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      this.setState({
        visible: true,
      });
    } else if (scrolled <= 300) {
      this.setState({
        visible: false,
      });
    }
  };
  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }
  openMenu() {
    this.setState({
      menuList: true,
    });
  }
  menuListToggle = () => {
    this.setState({
      menuList: !this.state.menuList,
    });
  };
  render() {
    return (
      <>
        <div>
          {/*[if lt IE 7]>      <html class="no-js lt-ie9 lt-ie8 lt-ie7" lang=""> <![endif]*/}
          {/*[if IE 7]>         <html class="no-js lt-ie9 lt-ie8" lang=""> <![endif]*/}
          {/*[if IE 8]>         <html class="no-js lt-ie9" lang=""> <![endif]*/}
          {/*[if gt IE 8]><!*/}
          {/*<![endif]*/}
          <title>Taxonomy Hub</title>
          {/* <link rel="icon" href="images/fevicon.ico" type="image/x-icon" />
        <meta charSet="utf-8" />
        <meta name="description" content />
        <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
          {/* <link rel="stylesheet" href="assets/css/font1.css" />
        <link rel="stylesheet" href="assets/css/font2.css" />
        <link rel="stylesheet" href="assets/css/animate.css" />
        <link rel="stylesheet" href="assets/css/iconfont.css" />
        <link rel="stylesheet" href="assets/css/font-awesome.min.css" />
        <link rel="stylesheet" href="assets/css/bootstrap.css" />
        <link rel="stylesheet" href="assets/css/magnific-popup.css" />
        <link rel="stylesheet" href="assets/css/bootsnav.css" />
        <link rel="stylesheet" href="assets/css/plugins.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <link rel="stylesheet" href="assets/css/responsive.css" /> */}
          {/* <script src="assets/js/bootstrap.min.js"></script> */}
          {/* <div id="loading">
          <div id="loading-center">
            <div id="loading-center-absolute">
              <div className="object" id="object_one" />
              <div className="object" id="object_two" />
              <div className="object" id="object_three" />
              <div className="object" id="object_four" />
            </div>
          </div>
        </div> */}
          {/* <div className="colum" md={12}> */}
          {/* <Row> */}
          <nav
            id="ptsHomeHeader"
            style={{ padding: 0 }}
            className="navbar navbar-default bootsnav navbar-fixed no-background white"
          >
            {/* <div className="top-search">
                <div className="container-fluid">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fa fa-search" />
                    </span>
                    <input className="form-control" placeholder="Search" />{' '}
                    <span className="input-group-addon close-search">
                      <i className="fa fa-times" />
                    </span>
                  </div>
                </div>
              </div> */}
            <div>
              <div className="navbar-header d-flex">
                {/* <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                    <i className="fa fa-bars" />
                  </button> */}
                {/* <a className="navbar-brand" href="#brand"> */}
                <button
                  type="button"
                  className="mobile-nav-toggle d-lg-none text-end"
                  style={{ marginTop: "2em", marginBottom: "2em", alignItems: "left", padding: "5px" }}
                  onClick={(e) => this.openMenu()}
                >
                  <i className="fa fa-bars" style={{ fontSize: "1.5em" }}></i>
                </button>{" "}
                <span className="taxonomyHubHeding" style={{ display: "inline-flex" }}>
                  <img
                    src={logo3}
                    className="logo"
                    alt=""
                    style={{ width: "50px" }}
                  // width="auto"
                  // height="auto"
                  />
                  &nbsp;&nbsp;
                  <h2
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      margin: "auto",
                    }}
                  >
                    Taxonomy Hub
                  </h2>
                </span>
                {/* </a> */}
              </div>
              <div className="collapse navbar-collapse" id="navbar-menu">
                <ul
                  className="nav navbar-nav navbar-right"
                  style={{ flexDirection: "inherit", marginRight: "6em" }}
                >
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="home#features">About Us</a>
                  </li>
                  <li>
                    <a href="home#ourFeatures">Services</a>
                  </li>
                  <li>
                    <a href="home#overview">Overview</a>
                  </li>
                  <li>
                    <a href="home#contact">Contact Us</a>
                  </li>
                  <li>
                    <a href="/login" target="_blank">
                      Login
                    </a>
                  </li>
                  <li>
                    <a href="/personalDetailsReg">Sign Up</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* </Row> */}
          {/* </div> */}
          <section id="home" className="home">
            <div className="overlay" />
            <div className="container">
              <div className="row">
                <div className="main_home">
                  <div className="col-md-12">
                    <div className="hello_slid">
                      <div className="slid_item xs-text-center">
                        <div className="col-sm-6">
                          <img
                            src={Device1}
                            alt=""
                            width="auto"
                            height="auto"
                          />
                        </div>
                        <div className="col-sm-5">
                          <div className="home_text xs-m-top-30">
                            <h3 className="text-white">
                              With{" "}
                              <strong style={{ color: "#FFF" }}>
                                Taxonomy Hub,
                              </strong>
                            </h3>
                            <h3 className="text-white">
                              I have full control of my Business Financials
                              along with GST Compliance
                            </h3>
                            <h4 className="text-white">
                              It is Simple, Secure and Superfast
                            </h4>
                          </div>
                          <div className="home_btns m-top-40">
                            <a
                              href="/personalDetailsReg"
                              target="_blank"
                              className="btn btn-primary m-top-20"
                            >
                              Buy Now
                            </a>{" "}
                            {/* <a href="" class="btn btn-default m-top-20">Take a
                                                Tour</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="features bg-grey">
            <div className="container">
              <div className="row" style={{ padding: "1em" }}>
                {/* <div className="main_features fix roomy-70"> */}
                <div className="col-md-4">
                  <div className="features_item  m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-tachometer" />
                    </div>
                    <div className="f_item_text">
                      <h3>Dashboard</h3>
                      <p>Business Overview and Summary of GST compliance</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features_item  m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-star" />
                    </div>
                    <div className="f_item_text">
                      <h3>Multiple GST Registration</h3>
                      <p>GST filing of Multiple branches at one Go</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features_item  m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-cubes" />
                    </div>
                    <div className="f_item_text">
                      <h3>Reconciliation</h3>
                      <p>
                        Reconcile GSTR-
                        <span style={{ fontFamily: "arial", color: "#7e7b79" }}>
                          3
                        </span>
                        B vs GSTR-
                        <span style={{ fontFamily: "arial", color: "#7e7b79" }}>
                          1
                        </span>
                        &amp; all inward supplies with GSTR-
                        <span style={{ fontFamily: "arial", color: "#7e7b79" }}>
                          2
                        </span>
                        A
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features_item m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-tasks" />
                    </div>
                    <div className="f_item_text">
                      <h3>Manage data</h3>
                      <p>Import/Export of data in Excel, PDF, and JSON</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features_item m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-file" />
                    </div>
                    <div className="f_item_text">
                      <h3>GSTR Filing</h3>
                      <p>Integration with GSTN for GST filling</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features_item m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-universal-access" />
                    </div>
                    <div className="f_item_text">
                      <h3>Access Control</h3>
                      <p>
                        Define access for Invoice Creator, Auditor &amp; Clients
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features_item m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-pencil" />
                    </div>
                    <div className="f_item_text">
                      <h3>HSN Code</h3>
                      <p>
                        Mapping of Your Product/Service with HSN/ SAC code made
                        Easy
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features_item m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-check-circle" />
                    </div>
                    <div className="f_item_text">
                      <h3>Auto Error Check</h3>
                      <p>
                        Check all the errors while creating and filing invoice
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features_item m-top-70">
                    <div className="f_item_icon">
                      <i className="fa fa-mobile" />
                    </div>
                    <div className="f_item_text">
                      <h3>Mobile App</h3>
                      <p>
                        Allowing any time anywhere monitoring of your invoicing
                        and GSTN filing activities ( Supported Platform -
                        Android &amp; iOS )
                      </p>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </section>
          <section id="features" className=" bg-grey">
            <div className="row" style={{ margin: "5em" }}>
              <div
                className="col-md-6 aboutUs"
              >
                <h2 className="text-uppercase " style={{ color: "#000" }}>
                  <u>
                    <strong>About Us</strong>
                  </u>
                </h2>
                <p>
                  Our mission is to simplify complex Indian taxation, save money
                  and time for millions of Indian businesses and people. We are
                  a Technology company that builds trusted, useful and
                  insightful platforms for our clients to run their finances and
                  improve their relationship with money.
                </p>
                <p>
                  Today Most enterprises use an ERP systems and customized soft
                  wares designed to fit their business, yet, compliance work is
                  still done manually, with Taxonomy Hub, integration of GST,
                  e-WayBill and e-Invoice help in demanding requirements of
                  implementation, compliance, reduce efforts, increase work
                  accuracy and improve ease of doing.
                </p>
              </div>
              <div className="col-md-6">
                <div className="business_item" style={{ marginTop: "-32px" }}>
                  <div
                    className="business_img"
                    style={{ textAlign: "center", marginTop: "30px" }}
                  >
                    <img src={aboutUs} alt="" width="400px" height="auto" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="dialogue" className="dialogue bg-white roomy-40">
            <div className="container">
              <div className="row" style={{ padding: "1em" }}>
                <div className="main_dialogue text-center">
                  <div className="col-md-12">
                    <h3>
                      Developed and Maintained by Technology Leaders and
                      Taxation Experts in the Industry.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ourFeatures" className=" ourFeatures business bg-blue">
            <div className="business_overlay" />
            {/* <div className="container"> */}
            <div className="row" style={{ padding: "1em" }}>
              <div className="main_business">
                <div className="col-md-6" style={{ textAlign: "center" }}>
                  <div className="business_item">
                    <div className="business_img">
                      <img src={Service} alt="" width="400px" height="auto" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ourServices" >
                  <div className="business_item sm-m-top-50">
                    <h2 className="text-uppercase " style={{ color: "#000" }}>
                      <u>
                        <strong>Our Services</strong>
                      </u>
                    </h2>
                    {/* <p className="m-top-20 " /> */}
                    <ul
                      // className="text-white"
                      style={{ textAlign: "justify", color: "#000" }}
                    >
                      <li>
                        {/* <i className="fa fa-arrow-circle-right bulletpoint" /> */}
                        Manage Multiple GST Registrations across the country
                      </li>
                      <li>
                        {/* <i className="fa  fa-arrow-circle-right bulletpoint" /> */}
                        Handle GST filing for Intrastate, Interstate, Union
                        Territory, including Export of Goods &amp; Services
                      </li>
                      <li>
                        {/* <i className="fa  fa-arrow-circle-right bulletpoint" /> */}
                        Import all Invoices from Purchases and Sales Ledgers
                        maintained in any popular ERP Software
                      </li>
                      <li>
                        {/* <i className="fa  fa-arrow-circle-right bulletpoint" /> */}
                        Export all Invoices to Excel and PDF format.
                      </li>
                      <li>
                        {/* <i className="fa  fa-arrow-circle-right bulletpoint" /> */}
                        Verify all information of customer and supplier
                        registered with GSTN
                      </li>
                      <li>
                        {/* <i className="fa  fa-arrow-circle-right bulletpoint" /> */}
                        Track and notify of upcoming due dates, progress and
                        refund status of returns
                      </li>
                      <li>
                        {/* <i className="fa  fa-arrow-circle-right bulletpoint" /> */}
                        Import data to GST Software with excel, FTP or pre-built
                        connectors for main stream ERP and Accounting Software
                      </li>
                      <li>
                        {/* <i className="fa  fa-arrow-circle-right bulletpoint" /> */}
                        Assurance on correctness of the data uploaded on GSTN
                      </li>
                      <li>
                        {/* <i className="fa  fa-arrow-circle-right bulletpoint" /> */}
                        Workflow enables applying checkpoints and corrective
                        action prior to the submission of returns
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>
          <section id="overview" className="dialogue bg-white">
            {/* <div className="container"> */}
            <div className="row" style={{ padding: "3em" }}>
              <div className="main_dialogue">
                <div
                  className="col-md-6 overView"
                >
                  <div className="business_item sm-m-top-50">
                    <h2
                      className="text-uppercase"
                      style={{ marginLeft: "2em" }}
                    >
                      {" "}
                      <u>
                        <strong>Overview</strong>
                      </u>
                    </h2>
                    <ul>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            <i className="fa fa-edit fa-lg" />
                          </Col>
                          <Col md={11}>
                            Developed by technology leaders and taxation
                            specialists
                          </Col>
                        </Row>
                      </li>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            <i className="fa fa-sliders" />
                          </Col>
                          <Col md={11}>
                            {" "}
                            Manage all Business GST under one single account
                            from anywhere
                          </Col>
                        </Row>
                      </li>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            <i className="fa fa-files-o" />
                          </Col>
                          <Col md={11}>
                            Unlimited Invoices &amp; Return Filing
                          </Col>
                        </Row>
                      </li>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            {" "}
                            <i className="fa fa-compress" />
                          </Col>
                          <Col md={11}>
                            Integration with existing Accounting Systems
                          </Col>
                        </Row>
                      </li>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            <i className="fa fa-level-up" />
                          </Col>
                          <Col md={11}>
                            {" "}
                            Stay on top with Managed Services for GST Filing
                          </Col>
                        </Row>
                      </li>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            {" "}
                            <i className="fa fa-retweet " />
                          </Col>
                          <Col md={11}> Workflow Integration</Col>
                        </Row>
                      </li>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            {" "}
                            <i className="fa fa-shield" />
                          </Col>
                          <Col md={11}>100% Secure</Col>
                        </Row>
                      </li>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            {" "}
                            <i className="fa fa-hand-o-up" />
                          </Col>
                          <Col md={11}>
                            {" "}
                            Connect with your Vendors/Clients in one click
                          </Col>
                        </Row>
                      </li>
                      <li style={{ fontSize: "17px" }}>
                        <Row>
                          <Col md={1} className="iconColumn">
                            {" "}
                            <i className="fa fa-money" />
                          </Col>
                          <Col md={11}>
                            {" "}
                            User-Friendly, Easy to use and Cost effective
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="business_item" style={{ marginTop: "-32px" }}>
                    <div
                      className="business_img"
                      style={{ textAlign: "center" }}
                    >
                      <img src={Overview} alt="" width="320px" height="auto" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>
          {/* <section id="test" className="test bg-black roomy-60 fix">
          <div className="overlay" />
          <div className="container">
            <div className="row" style={{ padding: "1em" }}>
              <div className="main_test fix text-center">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="head_title text-center fix">
                    <h2 className="text-uppercase text-white">
                      Our Clients Response
                    </h2>
                    <h5 className="text-white">
                      We are already getting good feedbacks
                    </h5>
                  </div>
                </div>
                <div
                  id="testslid"
                  className="carousel slide carousel-fade"
                  data-ride="carousel"
                >
                  <div className="carousel-inner" role="listbox">
                    <div className="item active">
                      <div className="col-md-8 col-md-offset-2">
                        <div className="test_item fix">
                          <div className="test_img fix">
                            <img
                              className="img-circle"
                              src={testImg1}
                              alt=""
                              width="auto"
                              height="auto"
                            />
                          </div>
                          <div className="test_text text-white">
                            <em>
                              It's easy to operate and fast. It is best software
                              in the Market
                            </em>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-md-8 col-md-offset-2">
                        <div className="test_item fix">
                          <div className="test_img fix">
                            <img
                              className="img-circle"
                              srcasset={testImg1}
                              alt=""
                              width="auto"
                              height="auto"
                            />
                          </div>
                          <div className="test_text text-white">
                            <em>
                              Invoicing is Superfast, It just take a minute to
                              create and print Invoice
                            </em>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-md-8 col-md-offset-2">
                        <div className="test_item fix">
                          <div className="test_img fix">
                            <img
                              className="img-circle"
                              src={testImg1}
                              alt=""
                              width="auto"
                              height="auto"
                            />
                          </div>
                          <div className="test_text text-white">
                            <em>We can see Financials in a single click</em>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-md-8 col-md-offset-2">
                        <div className="test_item fix">
                          <div className="test_img fix">
                            <img
                              className="img-circle"
                              src={testImg1}
                              alt=""
                              width="auto"
                              height="auto"
                            />
                          </div>
                          <div className="test_text text-white">
                            <em>Best service Provided</em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className="left carousel-control"
                    href="#testslid"
                    role="button"
                    data-slide="prev"
                  >
                    {" "}
                    <i className="fa fa-angle-left" aria-hidden="true" />{" "}
                    <span className="sr-only">Previous</span>
                  </a>{" "}
                  <a
                    className="right carousel-control"
                    href="#testslid"
                    role="button"
                    data-slide="next"
                  >
                    {" "}
                    <i className="fa fa-angle-right" aria-hidden="true" />{" "}
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
          <div id="contact" className="contact-us section">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 align-self-center wow fadeInLeft"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.25s"
                >
                  <div className="section-heading">
                    <h2>Feel Free To Send Us a Message</h2>

                    {/* <div className="phone-info">
                    <h4>
                      For any enquiry, Call Us:{" "}
                      <span>
                        <i className="fa fa-phone" />{" "}
                        <a href="#">010-020-0340</a>
                      </span>
                    </h4>
                  </div> */}
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInRight"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.25s"
                  id="contactForm"
                >
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.addContactToBackend.bind(this)}
                  >
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <TextField
                          margin="normal"
                          variant="outlined"
                          type="text"
                          fullWidth
                          required
                          id="name"
                          onChange={this.handleNameChange.bind(this)}
                          placeholder="Your Name*"
                          name="name"
                          autoComplete="off"
                          value={this.state.name}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <TextValidator
                          margin="normal"
                          variant="outlined"
                          type="email"
                          fullWidth
                          required
                          id="email"
                          onChange={this.handleEmailChange.bind(this)}
                          placeholder="Email*"
                          name="email"
                          autoComplete="off"
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "this field is required",
                            "email is not valid",
                          ]}
                          value={this.state.email}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <TextValidator
                          margin="normal"
                          variant="outlined"
                          type="text"
                          fullWidth
                          id="phone"
                          onChange={this.handlePhoneChange.bind(this)}
                          // label="Phone
                          placeholder="Phone"
                          name="phone"
                          validators={["isNumber"]}
                          errorMessages={["phone is not valid"]}
                          autoComplete="off"
                          value={this.state.phone}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <TextField
                          margin="normal"
                          variant="outlined"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          id="yourCompany"
                          onChange={this.handleCompanyChange.bind(this)}
                          placeholder="Your Company"
                          name="yourCompany"
                          value={this.state.yourCompany}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <TextField
                          margin="normal"
                          variant="outlined"
                          type="text"
                          fullWidth
                          id="subject"
                          autoComplete="off"
                          required
                          onChange={this.handleSubjectChange.bind(this)}
                          placeholder="Subject*"
                          name="subject"
                          value={this.state.subject}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        required
                        margin="normal"
                        variant="outlined"
                        style={{
                          width: "100%",
                          padding: "1em",
                          border: "1px solid lightgray",
                          borderRadius: " 5px",
                        }}
                        value={this.state.message}
                        name="message"
                        autoComplete="off"
                        fullWidth
                        onChange={this.handleMessageChange.bind(this)}
                        placeholder="Message*"
                      />
                    </div>

                    <div className="text-center" style={{ marginTop: "1em" }}>
                      <Button
                        type="submit"
                        style={{
                          background: "#0b6f9d",
                          borderRadius: "5px",
                          border: "none",
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Send Message
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
          {/* <footer
          id="contact"
          className="footer action-lage p-top-80"
          style={{ background: "black" }}
        >
          <div className="container">
            <div className="row" style={{ padding: "1em" }}>
              <div className="widget_area">
                <div className="col-md-3">
                  <div className="widget_item widget_about">
                    <h5 className="text-white" style={{ fontSize: "1em" }}>
                      About Us
                    </h5>
                    <p className="m-top-20" style={{ color: "#797979" }}>
                      Proto Technology Solutions
                    </p>
                    <div className="widget_ab_item m-top-30">
                      <div className="item_icon">
                        <i
                          className="fa fa-location-arrow"
                          style={{ color: "#797979" }}
                        />
                      </div>
                      <div className="widget_ab_item_text">
                        <h6
                          className="text-white"
                          style={{ fontSize: "0.9em" }}
                        >
                          Location
                        </h6>
                        <p style={{ color: "#797979" }}>
                          Sliver Chember Hadapsar, Pune,Maharashtra,India -
                          411028
                        </p>
                      </div>
                    </div>
                    <div className="widget_ab_item m-top-30">
                      <div className="item_icon">
                        <i
                          className="fa fa-phone"
                          style={{ color: "#797979" }}
                        />
                      </div>
                      <div className="widget_ab_item_text">
                        <h6
                          className="text-white"
                          style={{ fontSize: "0.9em" }}
                        >
                          Phone :
                        </h6>
                        <p style={{ color: "#797979" }}>+91 920 902 1155</p>
                      </div>
                    </div>
                    <div className="widget_ab_item m-top-30">
                      <div className="item_icon">
                        <i
                          className="fa fa-envelope-o"
                          style={{ color: "#797979" }}
                        />
                      </div>
                      <div className="widget_ab_item_text">
                        <h6
                          className="text-white"
                          style={{ fontSize: "0.9em" }}
                        >
                          Email Address :
                        </h6>
                        <p style={{ color: "#797979" }}>
                          admin@taxonomyhub.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3" />
                <div className="col-md-3">
                  <div className="widget_item widget_service sm-m-top-50">
                    <h5 className="text-white" style={{ fontSize: "1em" }}>
                      Our Services
                    </h5>
                    <ul className="m-top-10">
                      <li className="m-top-20">
                        <a style={{ color: "#797979" }}>
                          <i className="fa fa-angle-right" />
                          Mobile Application Development
                        </a>
                      </li>
                      <li className="m-top-20">
                        <a style={{ color: "#797979" }}>
                          <i className="fa fa-angle-right" />
                          Internet of Things (IoT) platform development
                        </a>
                      </li>
                      <li className="m-top-20">
                        <a style={{ color: "#797979" }}>
                          <i className="fa fa-angle-right" /> Data Analytics
                        </a>
                      </li>
                      <li className="m-top-20">
                        <a style={{ color: "#797979" }}>
                          <i className="fa fa-angle-right" /> Cloud based
                          service development
                        </a>
                      </li>
                      <li className="m-top-20">
                        <a style={{ color: "#797979" }}>
                          <i className="fa fa-angle-right" /> Software
                          Consulting
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="widget_item widget_newsletter sm-m-top-50">
                    <div className="widget_brand m-top-40">
                      <a className="text-uppercase">TaxonomyHub</a>
                      <p style={{ color: "#797979" }}>
                        With Taxonomy Hub, I have full control of my Business
                        Financials including Sales, Purchase &amp; Expense
                        Management. GST Filing (GSTR1, GSTR3B) is done in few
                        clicks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="main_footer fix bg-mega text-center p-top-40 p-bottom-30 m-top-80"
            style={{ paddingTop: "1em", paddingBottom: "1em" }}
          >
            <div className="col-md-6">

              <h5 style={{ color: "#FFF" }}>
                <b>Powered By</b>
              </h5>
              <p style={{ color: "#FFF" }}>Proto technology Solutions</p>
              <img src={ptsLogo} alt="" style={{ height: "50px" }} />
              <br />
              <a
                href="https://prototsolutions.com/"
                target="_blank"
                style={{ cursor: "pointer", color: "#FFF" }}
              >
                www.prototsolutions.com
              </a>
            </div>
            <div className="col-md-6" style={{ textAlign: "center" }}>
              <p
                className="wow fadeInRight"
                data-wow-duration="1s"
                style={{ color: "#FFF", textAlign: "center" }}
              >
                Copyright © 2017. All rights reserved.
              </p>
            </div>
          </div>
        </footer> */}

          <Container id="scroll">
            <a
              onClick={() => this.scrollToSection("home")}
              style={{
                display: this.state.visible ? "inline" : "none",
                textDecoration: "none",
              }}
            >
              <Fab
                aria-label="add"
                className="foatingActionBtn"
                style={{
                  float: "right",
                  fontSize: "small",
                  height: "42px",
                  width: "42px",
                  background: "#054e63",
                  cursor: "pointer",
                  color: "white",
                  backgroundImage:
                    "-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);",
                  border: "2px solid #edf0f1",
                  // margin: "0em 3em",
                  position: "absolute",
                }}
              >
                <ArrowUpwardIcon />
              </Fab>{" "}
            </a>
          </Container>

          <Modal
            isOpen={this.state.menuList}
            toggle={this.menuListToggle}
            fade={false}
            style={{
              alignContent: "center",
              width: "100%",
              marginLeft: "0px",
              marginTop: "80px",
              opacity: 1,
            }}
          >
            <ModalBody style={{ backgroundColor: "#0B6F9D", color: "#fff" }}>
              <nav className="mobileViewNav">
                <ul>
                  <li
                    className={
                      this.state.activeElement === "/" ||
                        this.state.activeElement === "home" ||
                        this.state.activeElement === ""
                        ? this.state.homeActiveClass
                        : ""
                    }
                  >
                    <a
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={() => this.scrollToSection("home")}
                    >
                      Home
                    </a>
                  </li>
                  <li className="scroll-to-section pb-2">
                    <a
                      className={
                        this.state.activeElement === "features"
                          ? this.state.homeActiveClass
                          : ""
                      }
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={() => this.scrollToSection("features")}
                    >
                      About Us
                    </a>
                  </li>
                  <li className="scroll-to-section pb-2">
                    <a
                      className={
                        this.state.activeElement === "ourFeatures"
                          ? this.state.homeActiveClass
                          : ""
                      }
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={() => this.scrollToSection("ourFeatures")}
                    >
                      Services
                    </a>
                  </li>
                  <li className="scroll-to-section pb-2">
                    <a
                      className={
                        this.state.activeElement === "overview"
                          ? this.state.homeActiveClass
                          : ""
                      }
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={() => this.scrollToSection("overview")}
                    >
                      Overview
                    </a>
                  </li>
                  <li className="scroll-to-section pb-2">
                    <a
                      className={
                        this.state.activeElement === "contact"
                          ? this.state.homeActiveClass
                          : ""
                      }
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={() => this.scrollToSection("contact")}
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className="scroll-to-section pb-2">
                    <a
                      href="/login"
                      style={{ color: "#fff", cursor: "pointer" }}
                    // onClick={() => this.navigateToLink("/login")}
                    // style={{ cursor: "pointer" }}
                    >
                      Login
                    </a>
                  </li>
                  <li className="scroll-to-section pb-2">
                    <a
                      href="/personalDetailsReg"
                      style={{ color: "#fff", cursor: "pointer" }}
                    // onClick={() => this.navigateToLink("/personalDetailsReg")}
                    // style={{ cursor: "pointer" }}
                    >
                      Register
                    </a>
                  </li>
                  {/* <li className="scroll-to-section">
                  <a
                    className={
                      this.state.activeElement === "contact"
                        ? this.state.homeActiveClass
                        : ""
                    }
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => this.scrollToSection("contact")}
                  >
                    Contact Us
                  </a>
                </li> */}
                </ul>
              </nav>
            </ModalBody>
          </Modal>
          <footer style={{ background: "#000" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8" style={{ margin: "auto" }}>
                  <div
                    className="copyright"
                    style={{ fontSize: "12px", color: "#fff" }}
                  >
                    <span>
                      ©Copyright <b>TaxonomyHub</b> 2023. All rights reserved.
                    </span>{" "}
                    &nbsp;
                    {/* <span
                    style={{ fontSize: "14px", color: "rgb(11, 111, 157)" }}
                  >
                    <b>Product of Proto technology Solutions</b>
                  </span> */}
                    {/* <br></br>
                  <b>
                    <a
                      href="http://www.assettrackifier.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.prototsolutions.com
                    </a>
                  </b> */}
                  </div>
                </div>
                {/* <div className="col-lg-3"></div> */}
                <div className="col-lg-4">
                  <ul
                    style={{
                      float: "right",
                      listStyle: "none",
                      lineHeight: "3em",
                      margin: "0 100px 0px 0",
                    }}
                  >
                    <li style={{ textAlign: "left" }}>
                      <i
                        className="fa fa-angle-right"
                        style={{ color: "#fff" }}
                      />

                      <a
                        onClick={() => this.navigateToLink("/termsConditions")}
                        style={{ color: "#fff", cursor: "pointer" }}
                      >
                        {" "}
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li style={{ textAlign: "left" }}>
                      <i
                        className="fa fa-angle-right"
                        style={{ color: "#fff" }}
                      />

                      <a
                        onClick={() => this.navigateToLink("/privacypolicy")}
                        style={{ color: "#fff", cursor: "pointer" }}
                      >
                        {" "}
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }
}
export default withRouter(Home);

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row, Card, Col } from "reactstrap";
import Utils from "../../../provider/Utils";
import appDataAction from "../../../redux/appData/appDataAction";
import store from "../../../redux/store";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import FetchServerData from "../../../provider/FetchServerData";
import masterDataAction from "../../../redux/masterData/masterDataAction";

const styles = (theme) => ({
  bigIndicator: {
    height: 10,
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

export class Reports extends Component {
  selectedIndex = store.getState().appData.reportSelectedTab;
  header = store.getState().header.header;
  breadCrumb = [];
  title = "Reports";
  tabMenuList = [];
  constructor(props) {
    super(props);
    store.dispatch(appDataAction.setReportTab());
    if (this.props.location && this.props.location.pathname) {
      let menu = Utils.getMenu(this.props.location.pathname);
      this.tabMenuList = Utils.getSubMenus(menu.id);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }
    let activeMenuList = [];
    for (let i = 0; i < this.tabMenuList.length; i++) {
      if (this.tabMenuList[i].isactive === true) {
        activeMenuList.push(this.tabMenuList[i]);
      }
    }
    if (this.selectedIndex === undefined) {
      this.state = {
        value: 0,
        selectedTabIndex: 0,
        selectedTabName: activeMenuList[0].menuid,
      };
    } else {
      this.state = {
        value: 0,
        selectedTabIndex: this.selectedIndex,
        selectedTabName: activeMenuList[this.selectedIndex].menuid,
      };
    }
  }

  componentDidMount() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/masterms/getMasterData", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let masterData = output.data;
          store.dispatch(masterDataAction.setMasterData(masterData));
          this.setState({ render: true });
        }
      }
    );
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setReportTab(index));
    this.setState({
      selectedTabIndex: index,
      selectedTabName: tabName.menuid,
    });
  }

  getTabForMenu(tabmenu, selectedTabName) {
    /*GET SubMenus for menu */
    // let reportList = Utils.getSubMenus(tabmenu.id, this.state.selectedTabIndex);
    let salesList = [
      {
        pageid: "/custlist_rpt",
        uiname: "Customer List",
        menuid: "Sales",
      },
      {
        pageid: "/custwise_rpt",
        uiname: "Customer wise Sales Reports",
        menuid: "Sales",
      },
      {
        pageid: "/custwise_inv_rpt",
        uiname: "Customer wise Sales Invoices",
        menuid: "Sales",
      },
    ];
    let purchaseList = [
      {
        pageid: "/supplist_rpt",
        uiname: "Supplier List",
        menuid: "Purchase",
      },
      {
        pageid: "/suppwise_rpt",
        uiname: "Supplier wise Purchase Reports",
        menuid: "Purchase",
      },
      {
        pageid: "/suppwise_inv_rpt",
        uiname: "Supplier wise Purchase Invoices",
        menuid: "Purchase",
      },
    ];
    let expenseList = [
      {
        pageid: "/expense_rpt",
        uiname: "Expense Report",
        menuid: "Expense",
      },
    ];
    let financeList = [
      {
        pageid: "/mnthfinancial_rpt",
        uiname: "Monthly Financial Analysis Report",
        menuid: "Finance",
      },
      {
        pageid: "/qtrfinancial_rpt",
        uiname: "Quarterly Financial Analysis Report",
        menuid: "Finance",
      },
      {
        pageid: "/yearfinancial_rpt",
        uiname: "Yearly Financial Analysis Report",
        menuid: "Finance",
      },
    ];
    let productList = [
      {
        pageid: "/product_rpt",
        uiname: "Product List",
        menuid: "Product",
      },
      {
        pageid: "/productwise_sales_rpt",
        uiname: "Product wise Sales Reports",
        menuid: "Product",
      },
      {
        pageid: "/productwise_inv_rpt",
        uiname: "Product wise Purchase Reports",
        menuid: "Product",
      },
    ];

    const menuItemMap = {
      Sales: salesList,
      Purchase: purchaseList,
      Expense: expenseList,
      Finance: financeList,
      Product: productList,
    };
    let reportListMap = [].concat(
      salesList,
      purchaseList,
      expenseList,
      financeList,
      productList
    );
    let activeMenuList = [];
    for (let i = 0; i < this.tabMenuList.length; i++) {
      if (this.tabMenuList[i].isactive === true) {
        activeMenuList[this.tabMenuList[i].menuid] =
          menuItemMap[this.tabMenuList[i].menuid];
      }
    }
    // return (
    //   <>
    //     {Object.entries(activeMenuList).map(function ([k, v]) {
    //       return v.map((item, i) => {
    //         // For each object we grab the key/value from the first
    //         // element of its `Object.entries` (an array)
    //         // const [key, value] = Object.entries(item)[0];
    //         console.log(item);
    //         // And return some JSX
    //         // Note that we're using the `map` index to add
    //         // both a key, and a data attribute to both the list
    //         // item, and the button. We'll use that id when we
    //         // remove the item.
    //         return (
    //           <li key={i} data-id={i}>
    //             {item.pageid}
    //             {/* <button data-id={i} onClick={deleteItem}>
    //     Delete
    //   </button> */}
    //           </li>
    //         );
    //       });
    //     })}
    //   </>
    // );
    let reportSelectedTabList = (element, tabmenu) => {
      this.props.history.push({
        pathname: element.pageid,
        state: {
          breadCrumb: this.breadCrumb,
          selectedTabIndex: this.state.selectedTabIndex,
        },
      });
    };
    return (
      <>
        {Object.entries(activeMenuList).map(function ([k, v]) {
          return v.map((item, i) => {
            return (
              <>
                {item.menuid === selectedTabName ? (
                  <ListItem
                    key={i}
                    style={{ borderBottom: "1px solid lightgrey" }}
                    // onClick={this.reportSelectedTabList.bind(this, item)}
                    // onClick={() => reportSelectedTabList(item)}
                  >
                    <a
                      style={{ color: "#000", cursor: "pointer" }}
                      onClick={reportSelectedTabList.bind(this, item)}
                    >
                      <u> {item.uiname} </u>
                    </a>
                  </ListItem>
                ) : (
                  <></>
                )}
              </>
            );
          });
          // console.log(reportItems1);
          // {
          // }
        })}
      </>
    );
  }

  render() {
    let activeMenuList = [];
    for (let i = 0; i < this.tabMenuList.length; i++) {
      if (this.tabMenuList[i].isactive === true) {
        activeMenuList.push(this.tabMenuList[i]);
      }
    }
    // this.state.selectedTabName = activeMenuList[0].menuid;
    const tabList = activeMenuList.map((menu, keyIndex) => {
      return (
        <>
          {menu.isactive === true ? (
            <Tab
              key={keyIndex}
              onClick={(e) => this.selectedTab(menu, keyIndex)}
            >
              <span style={{ fontWeight: "600" }}>{menu.uiname}</span>
            </Tab>
          ) : (
            <></>
          )}
        </>
      );
    });
    const tabPanelList = this.tabMenuList.map((tab, keyIndex) => {
      return (
        <>
          {/* {tab.menuid === this.state.selectedTabName ? ( */}
          <TabPanel>
            <Row md={12} style={{ minHeight: "2em" }}>
              <Col md={12}>
                <Card style={{ padding: "1.5em" }}>
                  <List
                    md={6}
                    style={{
                      border: "1px solid lightgrey ",
                      borderBottom: "none",
                      borderRadius: "5px",
                      padding: "0em",
                      width: " 50%",
                    }}
                  >
                    {this.getTabForMenu(tab, this.state.selectedTabName)}
                  </List>
                </Card>
              </Col>
            </Row>
          </TabPanel>
          {/* ) : (
            <></>
          )} */}
        </>
      );
    });

    // let selectedIndex = store.getState().appData.reportSelectedTab;
    let selectedIndex = this.state.selectedTabIndex;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <Row md={12} style={{ margin: "0.3em" }}>
          <Tabs
            selectedIndex={selectedIndex}
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            <TabList
              style={{
                minWidth: "5em",
                // color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              {tabList}
            </TabList>
            {tabPanelList}

            {/* {this.getTabForMenu()}{" "} */}
          </Tabs>
        </Row>
      </div>
    );
  }
}
Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Reports);

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@mui/material";
import { format } from "date-fns";
import MaterialTable from "material-table";
import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Switch, createMuiTheme } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row } from "reactstrap";
import { Buffer } from "buffer";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import PtsAlert from "../../../components/ptsAlert/PtsAlert";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Badge from "@material-ui/core/Badge";
import { Menu } from "@mui/material";
import moment from "moment";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
import PaymentDetailsDialogue from "../../payments/PaymentDetailsDialogue";
import ViewReceipt from "../../attachments/ViewReceipt";
import paymentIcon from "../../../../assets/images/paymentIcon.jpeg";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});
export class ExpenseHistory extends Component {
  header = store.getState().header.header;
  expenseHistoryState = store.getState().applicationState.expenseHistoryState;
  businessUnitList = store.getState().master.masterData.businessUnits;
  financialsYearArray = store.getState().appData.fyList;
  expenseHistoryStateDetails = {};
  breadCrumb = [
    { pageid: "/history", uiname: "History", cssclass: "fa fa-history" },
    { pageid: "/expense_invoicesearch", uiname: "Expense History List" },
  ];
  title = "Expense History";
  expenseHeaderTabList = ["Search By Date", "Search By Number"];
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();

    let pageId = props.pageId;
    if (
      Utils.isNull(pageId) &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      pageId = props.history.location.state.pageId;
    }

    let expenseHistoryData = store.getState().appData.historyExpenseSearchData;
    if (this.expenseHistoryState) {
      this.expenseHistoryStateDetails = Object.getOwnPropertyNames(
        this.expenseHistoryState
      );
    }
    if (this.expenseHistoryStateDetails.length > 0) {
      this.state = {
        selectedTabIndex:
          expenseHistoryData && expenseHistoryData.selectedTabIndex
            ? expenseHistoryData.selectedTabIndex
            : 0,
        selectedHeaderTabIndex:
          expenseHistoryData && expenseHistoryData.selectedHeaderTabIndex
            ? expenseHistoryData.selectedHeaderTabIndex
            : 0,
        value: 0,
        startDate: this.expenseHistoryState.startDate,
        endDate: this.expenseHistoryState.endDate,
        financialsYear: this.expenseHistoryState.financialsYear,
        isAsset: this.expenseHistoryState.isAsset,
        expenseCategoryList: [],
        invoiceStatusArray: [],
        selectedCategory: this.expenseHistoryState.selectedCategory,
        selectedBusinessUnit: this.expenseHistoryState.selectedBusinessUnit,
        expenseList: [],
        expNumber: "",
        expenseId: this.expenseHistoryState.expenseId,
        pageSize: this.expenseHistoryState.pageSize,
        initialPage: this.expenseHistoryState.initialPage,
        searchExpenseHistoryDetails: expenseHistoryData,
        excelStartDate: null,
        excelEndDate: null,
        excelFinancialsYear: null,
        excelSelectedCategory: [],
        isFirstTimeLoading: true,
        isExcelAsset: false,
        errorMassage: false,
        error: false,
        excelErrorMassage: false,
        excelError: false,
        swichCheck: false,
        excelSwichCheck: false,
        isPaging: false,
        open: false,
      };
    } else {
      this.state = {
        selectedTabIndex:
          expenseHistoryData && expenseHistoryData.selectedTabIndex
            ? expenseHistoryData.selectedTabIndex
            : 0,
        selectedHeaderTabIndex:
          expenseHistoryData && expenseHistoryData.selectedHeaderTabIndex
            ? expenseHistoryData.selectedHeaderTabIndex
            : 0,
        value: 0,
        startDate: null,
        endDate: null,
        financialsYear: null,
        expenseCategoryList: [],
        invoiceStatusArray: [],
        selectedCategory: [],
        expenseList: [],
        selectedBusinessUnit: this.businessUnitList[0],
        expNumber: "",
        initialStep: 0,
        pageSize: 10,
        searchExpenseHistoryDetails: expenseHistoryData,
        excelStartDate: null,
        excelEndDate: null,
        excelFinancialsYear: null,
        excelSelectedCategory: [],
        isAsset: false,
        errorMassage: false,
        error: false,
        excelErrorMassage: false,
        excelError: false,
        isExcelAsset: false,
        isFirstTimeLoading: false,
        swichCheck: false,
        excelSwichCheck: false,
        isPaging: false,
        open: false,
      };
    }
    this.onTagsChange = this.onTagsChange.bind(this);
    if (expenseHistoryData) {
      this.getExpenseHistoryData(this.state.isFirstTimeLoading);
    }
  }

  componentDidMount() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService(
      "/expconsolidatems/getExpCategories",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let history = output.data;
        let expenseCategoryListArray = history.categoryList;
        let invoiceStatusList = history.invoiceStatusList;
        let allexpenseCategory = {
          type: "All",
        };
        const expenseCategoryList = [
          allexpenseCategory,
          ...expenseCategoryListArray,
        ];
        this.setState({
          expenseCategoryList: expenseCategoryList,
          invoiceStatusArray: invoiceStatusList,
          open: false,
        });
      } else {
      }
    });
  }

  getExpenseHistoryData = (reset) => {
    if (Utils.isNull(this.state.selectedHeaderTabIndex)) {
      this.setState({ selectedHeaderTabIndex: 0 });
    }

    if (this.state.errorMassage || this.state.error) {
      return false;
    }
    let financialYear = this.state.financialsYear;

    let historyData = {
      financialsYear: financialYear,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      isAsset: this.state.isAsset,
      expNumber: this.state.expNumber,
      selectedTabIndex: this.state.selectedTabIndex,
      selectedHeaderTabIndex: this.state.selectedHeaderTabIndex,
    };
    store.dispatch(appDataAction.setHistoryExpenseSearchData(historyData));
    if (this.state.selectedHeaderTabIndex === 0) {
      const postObject = {
        header: this.header,
        financialYear: financialYear,
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        bu: {
          id: this.state.selectedBusinessUnit?.id,
          code: this.state.selectedBusinessUnit?.code,
        },
        isAsset: this.state.isAsset,
        expnumber: "",
      };
      FetchServerData.callPostService(
        "/expensesearchms/bydate",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          let expenseData = output.data;
          let expenseList = output.data.expenseList;
          for (let i = 0; i < expenseList.length; i++) {
            expenseList[i].expdate = new Date(expenseList[i].expdate);
            expenseList[i].amount = `${
              expenseList[i].currencysymbol
            } ${expenseList[i].amount.toFixed(2)}`;
          }
          let isPaging = false;
          if (expenseList.length > 10) {
            isPaging = true;
          }
          this.setState({
            expenseList: expenseList,
            financialsYear: expenseData.financialYear,
            excelFinancialsYear: expenseData.financialYear,
            excelStartDate:
              expenseData.financialYear === null ? expenseData.fromDate : null,
            excelEndDate:
              expenseData.financialYear === null ? expenseData.toDate : null,
            startDate: expenseData.fromDate,
            endDate: expenseData.toDate,
            defaultFinancialYear: expenseData.financialYear,
            defaultStartDate: expenseData.fromDateStr,
            defaultEndDate: expenseData.toDateStr,
            isPaging: isPaging,
            swichCheck: financialYear === null ? true : false,
            excelSwichCheck: financialYear === null ? true : false,
            isFirstTimeLoading: reset ? true : false,
            errorMassage: false,
            open: false,
          });
        } else {
        }
      });
    } else if (this.state.selectedHeaderTabIndex === 1) {
      const postObject = {
        header: this.header,
        expnumber: this.state.expNumber,
      };
      FetchServerData.callPostService(
        "/expensesearchms/bynumber",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          let expenseList = output.data;
          for (let i = 0; i < expenseList.length; i++) {
            expenseList[i].expdate = new Date(expenseList[i].expdate);
            expenseList[i].amount = `${
              expenseList[i].currencysymbol
            } ${expenseList[i].amount.toFixed(2)}`;
          }
          this.setState({
            expenseList: expenseList,
            isFirstTimeLoading: reset ? true : false,
            open: false,
          });
        } else {
        }
      });
    }
  };

  resetExpenseHistoryData = () => {
    let startDate = null;
    let endDate = null;
    let isAsset = false;
    let expNumber = "";
    // let selectedCategory = [];

    let historyData = {
      financialsYear: null,
      startDate: startDate,
      endDate: endDate,
      isAsset: isAsset,
      expNumber: expNumber,
      selectedTabIndex: this.state.selectedTabIndex,
      selectedHeaderTabIndex: this.state.selectedHeaderTabIndex,
    };
    store.dispatch(appDataAction.setHistoryExpenseSearchData(historyData));
    if (this.state.selectedHeaderTabIndex === 0) {
      const postObject = {
        header: this.header,
        fromDate: startDate,
        toDate: endDate,
        bucode: null,
        expnumber: "",
        isAsset: isAsset,
      };
      FetchServerData.callPostService(
        "/expensesearchms/bydate",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          let expenseData = output.data;
          let expenseList = output.data.expenseList;
          for (let i = 0; i < expenseList.length; i++) {
            expenseList[i].expdate = new Date(expenseList[i].expdate);
            expenseList[i].amount = `${
              expenseList[i].currencysymbol
            } ${expenseList[i].amount.toFixed(2)}`;
          }
          let isPaging = false;
          if (expenseList.length > 10) {
            isPaging = true;
          }
          this.setState({
            expenseList: expenseList,
            financialsYear: expenseData.financialYear,
            excelFinancialsYear: expenseData.financialYear,
            excelStartDate:
              expenseData.financialYear === null ? expenseData.fromDate : null,
            excelEndDate:
              expenseData.financialYear === null ? expenseData.toDate : null,
            startDate: expenseData.fromDate,
            endDate: expenseData.toDate,
            defaultFinancialYear: expenseData.financialYear,
            defaultStartDate: expenseData.fromDateStr,
            defaultEndDate: expenseData.toDateStr,
            selectedBusinessUnit: this.businessUnitList[0],
            isPaging: isPaging,
            swichCheck: this.state.financialsYear === null ? true : false,
            excelSwichCheck: this.state.financialsYear === null ? true : false,
            isAsset: false,
            errorMassage: false,
            isFirstTimeLoading: false,
            open: false,
          });
        } else {
        }
      });
    } else if (this.state.selectedHeaderTabIndex === 1) {
      const postObject = {
        header: this.header,
        expnumber: expNumber,
      };
      FetchServerData.callPostService(
        "/expensesearchms/bynumber",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          let expenseList = output.data;
          for (let i = 0; i < expenseList.length; i++) {
            expenseList[i].expdate = new Date(expenseList[i].expdate);
            expenseList[i].amount = `${
              expenseList[i].currencysymbol
            } ${expenseList[i].amount.toFixed(2)}`;
          }
          this.setState({
            expenseList: expenseList,
            isFirstTimeLoading: false,
            open: false,
          });
        } else {
        }
      });
    }
  };

  downloadDayExcelFromBackend() {
    if (this.state.excelErrorMassage || this.state.excelError) {
      return false;
    }

    // let startDate = moment(this.state.excelStartDate).format("YYYY-MM-DD");
    // let endDate = moment(this.state.excelEndDate).format("YYYY-MM-DD");
    //  let invoiceTypeArray = [this.state.selectedInvoice.type];

    let fromDate = Date.parse(this.state.excelStartDate);
    let toDate = Date.parse(this.state.excelEndDate);

    if (fromDate !== null && toDate !== null && fromDate > toDate) {
      return PtsAlert.error("From Date Must Be Before To Date");
    }

    const header = store.getState().header.header;
    const postObject = {
      header: header,
      financialYear: this.state.excelFinancialsYear,
      fromDate: this.state.excelStartDate,
      toDate: this.state.excelEndDate,
      expNumber: this.state.expNumber,
      isAsset: this.state.isExcelAsset,
    };

    FetchServerData.callPostService(
      "/expenseimpexp/exportAllData",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let expenseExcelData = output.data;
        const byteArray = new Buffer(
          expenseExcelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );

        const outputFilename = expenseExcelData.filename;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        this.setState({
          isExcelAsset: false,
          open: false,
        });
      } else {
      }
    });
  }

  paymentDetails(invoiceId) {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current
    ) {
      this.referenceInvoiceDialogueRef.current.showPurchasePaymentDetails(
        invoiceId
      );
    }
  }

  ReceiptDetails(data) {
    let receiptUrl = data.receiptUrl;
    let aid = data.id;
    if (this.receiptDialogueRef && this.receiptDialogueRef.current) {
      this.receiptDialogueRef.current.viewDocument(receiptUrl, aid);
    }
  }

  handleAssetChange(e) {
    this.setState({ isAsset: e.target.checked });
  }
  handleExcelAssetChange(e) {
    this.setState({ isExcelAsset: e.target.checked });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleStartDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.endDate);

    if (this.state.endDate === null && date === null) {
      this.setState({
        startDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    } else if (date === null) {
      this.setState({
        startDate: date,
        errorMassage: true,
        error: false,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        startDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        startDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    }
  }

  handleEndDateChange(date) {
    let fromDate = Date.parse(this.state.startDate);
    let toDate = Date.parse(date);

    if (this.state.startDate === null && date === null) {
      this.setState({
        endDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    } else if (this.state.startDate === null) {
      this.setState({
        endDate: date,
        errorMassage: true,
        error: false,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        endDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        endDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    }
  }

  handleSwichCheckChange(e) {
    this.setState({ swichCheck: e.target.checked });
  }
  handleExcelSwichCheckChange(e) {
    this.setState({ excelSwichCheck: e.target.checked });
  }

  updateFinancialYear(e, year) {
    this.setState({ financialsYear: year, startDate: null, endDate: null });
  }

  updateExcelFinancialYear(e, year) {
    this.setState({
      excelFinancialsYear: year,
      excelStartDate: null,
      excelEndDate: null,
    });
  }

  handleExcelStartDateChange(exdate) {
    let startDate = Date.parse(exdate);
    let endDate = Date.parse(this.state.excelEndDate);

    if (this.state.excelEndDate === null && exdate === null) {
      this.setState({
        excelStartDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (exdate === null) {
      this.setState({
        excelStartDate: exdate,
        excelErrorMassage: true,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (startDate > endDate) {
      this.setState({
        excelStartDate: exdate,
        excelError: true,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else {
      this.setState({
        excelStartDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    }
  }

  handleExcelEndDateChange(exdate) {
    let startDate = Date.parse(this.state.excelStartDate);
    let endDate = Date.parse(exdate);
    if (this.state.excelStartDate === null && exdate === null) {
      this.setState({
        excelEndDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (this.state.excelStartDate === null) {
      this.setState({
        excelEndDate: exdate,
        excelErrorMassage: true,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (startDate > endDate) {
      this.setState({
        excelStartDate: exdate,
        excelError: true,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else {
      this.setState({
        excelEndDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    }
  }

  updateBusinessUnit(e, name) {
    this.setState({ selectedBusinessUnit: name });
  }

  handleExpenseTypeChange(e, values) {
    this.setState({ excelSelectedCategory: values });
  }
  handleExpenseNumberChange(e) {
    this.setState({ expNumber: e.target.value });
  }
  onTagsChange = (event, values) => {
    this.setState(
      {
        selectedCategory: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
      }
    );
  };

  selectedExpenseHeaderTab(tabName, index) {
    store.dispatch(appDataAction.setHistoryExpenseHeaderTab(index));
    this.setState({ selectedHeaderTabIndex: index });
  }
  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setHistoryExpenseTab(index));
    this.setState({ selectedTabIndex: index });
  }

  getExpenseHeaderTab(keyindex) {
    if (keyindex === 0) {
      return (
        <ValidatorForm
          style={{ width: "100%", color: "#000" }}
          ref="dateForm"
          onSubmit={this.getExpenseHistoryData.bind(this)}
        >
          <>
            <h5 className="bold">Filter</h5>
            <div className="row mt-2">
              <div className="col-md-12" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Business Unit
                </label>
                <Autocomplete
                  fullWidth
                  ListboxProps={{ className: "myCustomList" }}
                  variant="outlined"
                  id="businessUnit"
                  options={this.businessUnitList}
                  value={this.state.selectedBusinessUnit}
                  defaultValue={this.state.selectedBusinessUnit}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Select Business Unit"
                  style={{ background: "#fff", borderRadius: "5px" }}
                  onChange={(event, value) => {
                    this.updateBusinessUnit(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="regCountry"
                      placeholder="Business Unit"
                      variant="outlined"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12" id="assetFormLabel">
                <FormControlLabel
                  style={{
                    color: "#000",
                    width: "100%",
                    margin: "0px",
                    padding: "0",
                  }}
                  control={
                    <Switch
                      variant="outlined"
                      // id="isAsset"
                      checked={this.state.swichCheck}
                      value={this.state.swichCheck}
                      onChange={this.handleSwichCheckChange.bind(this)}
                      style={{ background: "none", margin: "8px" }}
                    />
                  }
                  label={<span>Custom search </span>}
                />
              </div>
              {!this.state.swichCheck && (
                <div className="col-md-12" id="assetFormLabel">
                  <label
                    className="form-label "
                    // htmlFor="collapsible-companyName"
                  >
                    Financial Year
                  </label>
                  <br />
                  <Autocomplete
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    variant="outlined"
                    id="supplierList"
                    options={this.financialsYearArray}
                    value={this.state.financialsYear}
                    defaultValue={this.state.financialsYear}
                    getOptionLabel={(option) => option}
                    style={{ background: "#fff", borderRadius: "5px" }}
                    onChange={(event, value) => {
                      this.updateFinancialYear(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Financial Year"
                        className="regCountry"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {this.state.swichCheck && (
                <>
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label "
                      // htmlFor="collapsible-companyName"
                    >
                      Start Date
                    </label>
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          required
                          value={this.state.startDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleStartDateChange.bind(this)}
                          helperText={
                            this.state.errorMassage
                              ? "Start Date is Required"
                              : ""
                          }
                          error={this.state.errorMassage}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      End Date
                    </label>{" "}
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          required
                          value={this.state.endDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleEndDateChange.bind(this)}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  {this.state.error ? (
                    <lable className="errorMassageShow">
                      End Date should be after Start Date
                    </lable>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <div className="row mt-2">
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <FormControlLabel
                  style={{
                    color: "#000",
                    width: "100%",
                    margin: "0",
                    padding: "0",
                  }}
                  control={
                    <Checkbox
                      variant="outlined"
                      id="isAsset"
                      checked={this.state.isAsset}
                      value={this.state.isAsset}
                      onChange={this.handleAssetChange.bind(this)}
                      style={{ background: "none" }}
                    />
                  }
                  label={<span style={{}}>Is Capital Asset?</span>}
                />
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <button
                className="fileterButton"
                // onClick={this.onFilterClick.bind(this)}
                // onClick={(e) => this.filterRecords(e)}
                onClick={this.getExpenseHistoryData.bind(this)}
              >
                <i className="fa-solid fa-filter"></i>
                &nbsp; Filter
              </button>
              &nbsp; &nbsp;
              <button
                className="resetButton"
                onClick={this.resetFilter.bind(this)}
              >
                <i className="fa fa-refresh"></i>
                &nbsp; Reset
              </button>
            </div>
          </>
        </ValidatorForm>
      );
    } else if (keyindex === 1) {
      return (
        <ValidatorForm
          style={{ width: "100%", color: "#000" }}
          ref="numberForm"
          onSubmit={this.getExpenseHistoryData.bind(this)}
        >
          <>
            <h5 className="bold">Filter</h5>
            <div className="row mt-2">
              <div className="col-md-12" id="assetFormLabel">
                {" "}
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Expense Number
                </label>
                <br />
                <TextField
                  style={{ background: "#fff", borderRadius: "5px" }}
                  variant="outlined"
                  required
                  fullWidth
                  id="expenseNumber"
                  onChange={this.handleExpenseNumberChange.bind(this)}
                  placeholder="Expense Number"
                  name="expenseNumber"
                  type="text"
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.expNumber ? this.state.expNumber : ""}
                />
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <button
                className="fileterButton"
                // onClick={this.onFilterClick.bind(this)}
                // onClick={(e) => this.filterRecords(e)}
                onClick={this.getExpenseHistoryData.bind(this)}
              >
                <i className="fa-solid fa-filter"></i>
                &nbsp; Filter
              </button>
              &nbsp; &nbsp;
              <button
                className="resetButton"
                onClick={this.resetInvNumFilter.bind(this)}
              >
                <i className="fa fa-refresh"></i>
                &nbsp; Reset
              </button>
            </div>
          </>
        </ValidatorForm>
      );
    }
  }

  getTablesWithStatus(status) {
    let filteredData = [];
    if (status === "All") {
      filteredData = this.state.expenseList;
    } else {
      filteredData = this.state.expenseList.filter(function (e) {
        return e.status === status;
      });
    }
    return (
      <Row md={12} style={{ padding: "0em 0.3em" }}>
        <div style={{ width: "100%" }}>
          <MaterialTable
            icons={this.tableIcons}
            title=""
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "5%",
                },
              },

              {
                title: "Expense Date",
                render: (rowData) => format(rowData.expdate, "dd-MMM-yyyy"),
                customSort: (a, b) => {
                  return (
                    new Date(a.expdate).getTime() -
                    new Date(b.expdate).getTime()
                  );
                },
                width: "10%",
              },
              // { title: 'Remark', field: 'remark', width: '50%' },
              {
                title: "Expense Number",
                field: "expnumber",
                width: "20%",
              },
              {
                title: "Amount",
                field: "amount",
                align: "right",
                width: "20%",
              },
              {
                title: "Status",
                render: (rowData) => Utils.toCamelCaseUL(rowData.status),
                width: "15%",
              },
            ]}
            data={filteredData}
            options={{
              sorting: true,
              paging: filteredData.length > this.state.pageSize,
              pageSizeOptions: Utils.materialTablePageSize(),
              pageSize: this.state.pageSize,
              initialPage: this.state.initialPage,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#1b7189",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.id === this.state.expenseId ? "#FEF5E7" : "",
                height: "3em",
                textTransform: "capitalize",
              }),
            }}
            style={{
              boder: "1px solid black",
            }}
            actions={[
              (rowData) => ({
                icon: () =>
                  rowData.hasReceipt === true ? (
                    <>
                      <Badge color="primary" overlap="rectangular">
                        <ReceiptLongIcon
                          className="message"
                          style={{
                            fontSize: "25px",
                            alignItems: "center",
                            color: "#1b7189",
                          }}
                        />
                      </Badge>
                      &nbsp; &nbsp;
                    </>
                  ) : (
                    <div style={{ marginLeft: "1.4em", fontSize: "1.5rem" }}>
                      &nbsp;&nbsp;
                    </div>
                  ),
                onClick: () => this.ReceiptDetails(rowData),
                tooltip: "Receipt",
              }),
              (rowData) => ({
                icon: () =>
                  rowData.hasPayment === true ? (
                    <>
                      <Badge color="primary" overlap="rectangular">
                        <img
                          alt=""
                          src={paymentIcon}
                          style={{
                            height: "28px",
                            maxWidth: "none",
                            cursor: "pointer",
                          }}
                        />
                      </Badge>
                    </>
                  ) : (
                    <div
                      style={{ marginLeft: "1.8em", fontSize: "1.5rem" }}
                    ></div>
                  ),
                onClick: () => this.paymentDetails(rowData.id),
                tooltip: "Payment Details",
              }),
            ]}
            onRowClick={(e, rowData) => this.toExpenseDetails(e, rowData)}
            onChangePage={(page, pageSize) => {
              this.handleMaterialTablePageChange(page, pageSize);
            }}
          />
        </div>
      </Row>
    );
  }
  validateForm(e) {
    e.preventDefault();
    if (this.state.selectedHeaderTabIndex === 0) {
      let sdate = this.state.startDate;
      let edate = this.state.endDate;

      let sYear = new Date(sdate).getFullYear();
      let eYear = new Date(edate).getFullYear();
      let sMnt = new Date(sdate).getMonth();
      sMnt = parseInt(sMnt) + 1;

      let eMnt = new Date(edate).getMonth();
      eMnt = parseInt(eMnt) + 1;
      // let expenseCategory = this.state.selectedCategory;

      if (new Date(sdate).getTime() > new Date(edate).getTime()) {
        PtsAlert.error("Start date should be Less than or Equals to End date");
        this.setState({
          startDate: format(new Date(), "yyyy-MM-dd"),
        });
        return true;
      }
      if (new Date(sdate).getTime() > new Date().getTime()) {
        PtsAlert.error("Future Date Not Allowed");
        this.setState({
          startDate: format(new Date(), "yyyy-MM-dd"),
        });
        return true;
      }
      if (new Date(edate).getTime() > new Date().getTime()) {
        PtsAlert.error("Future Date Not Allowed");
        this.setState({
          endDate: format(new Date(), "yyyy-MM-dd"),
        });
        return true;
      }
      if (sYear < eYear) {
        let calYr = parseInt(eYear) - parseInt(sYear);
        if (calYr > 1) {
          PtsAlert.error(
            "Difference between Start Date and End Date should not Exceed One Year"
          );
          return true;
        } else if (calYr === 1) {
          if (sMnt < eMnt) {
            PtsAlert.error(
              "Difference between Start Date and End Date should not Exceed One Year"
            );
            return true;
          }
        }
      }
    }
    if (this.state.selectedHeaderTabIndex === 1) {
      let expenseNum = this.state.expNumber;
      if (Utils.isNotNullAndEmpty(expenseNum)) {
        return false;
      } else {
        PtsAlert.error("Please Enter Expense Number");
        return true;
      }
    }
    return false;
  }

  resetFilter() {
    this.setState({
      startDate: null,
      endDate: null,
      financialsYear: null,
      error: false,
      errorMassage: false,
      selectedCategory: [],
      isAsset: false,
    });
    store.dispatch(appDataAction.setHistoryPurchaseSearchData({}));
    this.resetExpenseHistoryData();
  }
  resetInvNumFilter() {
    this.setState({
      expNumber: "",
    });
    store.dispatch(appDataAction.setHistoryPurchaseSearchData({}));
    this.resetExpenseHistoryData();
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  handleExcelDownloadDropdownOpen = (e) => {
    this.setState({ downloadOpen: true, anchorEl1: e.currentTarget });
  };
  handleExcelDownloadDropdownClose = () => {
    this.setState({ downloadOpen: false, anchorEl1: null });
  };
  toExpenseDetails = (e, data) => {
    let expenseHistoryState = {
      expenseId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      isAsset: this.state.isAsset,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ expenseHistoryState })
    );
    this.props.history.push({
      pathname: "/expenseView",
      state: {
        expenseId: data.id,
        action: "view",
        pageId: "/expense_invoicesearch",
        breadCrumb: this.breadCrumb,
        isHistory: true,
      },
    });
  };

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  render() {
    this.referenceInvoiceDialogueRef = React.createRef();
    this.receiptDialogueRef = React.createRef();
    /* Header Tabs */
    const headerTabList = this.expenseHeaderTabList.map((expTab, keyIndex) => {
      return (
        <Tab
          style={{ minHeight: "1em" }}
          key={keyIndex}
          onClick={(e) => this.selectedExpenseHeaderTab(expTab, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{expTab}</span>
        </Tab>
      );
    });
    const headerTabPanelList = this.expenseHeaderTabList.map(
      (tab, keyIndex) => {
        return (
          <TabPanel
            style={{
              minHeight: "1em",
              padding: "1em",
              border: "1px solid lightgray",
            }}
            key={keyIndex}
          >
            {this.getExpenseHeaderTab(keyIndex)}
          </TabPanel>
        );
      }
    );

    /* Expense Tabs */
    const tabList = this.state.invoiceStatusArray.map((expStatus, keyIndex) => {
      return (
        <Tab
          key={keyIndex}
          onClick={(e) => this.selectedTab(expStatus, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{expStatus.uiname}</span>
        </Tab>
      );
    });

    const tabPanelList = this.state.invoiceStatusArray.map((tab, keyIndex) => {
      return (
        <>
          <PaymentDetailsDialogue
            ref={this.referenceInvoiceDialogueRef}
            style={{ float: "right" }}
          />
          <ViewReceipt
            ref={this.receiptDialogueRef}
            style={{ float: "right" }}
            action="view"
          />
          <TabPanel key={keyIndex}>
            {this.getTablesWithStatus(tab.status)}
          </TabPanel>
        </>
      );
    });
    let selectedHeaderIndex =
      store.getState().appData.historyExpenseHeaderSelectedTab;
    if (Utils.isNull(selectedHeaderIndex)) {
      // this.state.selectedHeaderTabIndex = 0;
      selectedHeaderIndex = 0;
    }

    let selectedIndex = store.getState().appData.historyExpenseSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      // this.state.selectedTabIndex = 0;
      selectedIndex = 0;
    }
    // let searchHistoryDetails = {};
    // if (this.state.searchExpenseHistoryDetails) {
    //   searchHistoryDetails = Object.getOwnPropertyNames(
    //     this.state.searchExpenseHistoryDetails
    //   );
    // }
    return (
      <div>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        {this.state.selectedHeaderTabIndex === 0 ? (
          <div className="row">
            <div className="col-8 align-items-start text-start">
              <span className="ms-2">
                <span>
                  {this.state.selectedBusinessUnit &&
                    this.state.selectedBusinessUnit !== null && (
                      <span>
                        <b>Business Unit : </b>
                        {this.state.selectedBusinessUnit.name + ", "}
                      </span>
                    )}
                  {this.state.defaultFinancialYear !== "" &&
                    this.state.defaultFinancialYear !== null && (
                      <span>
                        <b>Financial Year : </b>
                        {this.state.defaultFinancialYear + ", "}
                      </span>
                    )}
                  {this.state.defaultStartDate &&
                    this.state.defaultStartDate !== null && (
                      <span>
                        <b>Start Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultStartDate) +
                          ", "}
                      </span>
                    )}
                  {this.state.defaultEndDate &&
                    this.state.defaultEndDate !== null && (
                      <span>
                        <b>End Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultEndDate) + ", "}
                      </span>
                    )}
                  {this.state.isAsset && this.state.isAsset !== false ? (
                    <span>
                      <b>Is Capital Asset : </b>
                      {this.state.isAsset ? "Yes" : "No"}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </span>
            </div>
            <div className="col-4 align-items-end text-end">
              <span
                className="text-end"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.handleAccountDropdownOpen}
              >
                <FilterAltIcon
                  style={{ fontSize: "28px", color: "black" }}
                  aria-controls={this.state.open ? "dropdown-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? "true" : undefined}
                />
                Filter
              </span>
              {"  "} &nbsp;&nbsp;
              {this.state.isFirstTimeLoading ? (
                <span
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={this.resetFilter.bind(this)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                </span>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              <span
                className="text-end"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.handleExcelDownloadDropdownOpen}
              >
                <Button
                  variant="contained"
                  size="small"
                  id="excelBtn"
                  aria-controls={
                    this.state.downloadOpen ? "dropdown-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={this.state.downloadOpen ? "true" : undefined}
                  style={{
                    background: "#5ac489",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                  startIcon={<i class="fa fa-download"></i>}
                  // onClick={(e) => this.downloadDayExcelFromBackend()}
                >
                  Download Excel
                </Button>
              </span>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-8 align-items-start text-start">
              <span className="ms-4">
                {this.state.expNumber !== null ? (
                  <span>
                    {this.state.expNumber && this.state.expNumber !== null ? (
                      <span>
                        <b>Expense Number : </b>
                        {this.state.expNumber}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="col-4 align-items-end text-end">
              <span
                className="text-end"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.handleAccountDropdownOpen}
              >
                <FilterAltIcon
                  style={{ fontSize: "28px", color: "black" }}
                  aria-controls={this.state.open ? "dropdown-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? "true" : undefined}
                />
                Filter
              </span>
              {"  "} &nbsp;&nbsp;
              {this.state.isFirstTimeLoading ? (
                <span
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={this.resetInvNumFilter.bind(this)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                </span>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              <span
                className="text-end"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.handleExcelDownloadDropdownOpen}
              >
                {/* <FilterAltIcon
                  style={{ fontSize: "28px", color: "black" }}
                  aria-controls={this.state.open ? "dropdown-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? "true" : undefined}
                /> */}
                <Button
                  variant="contained"
                  size="small"
                  id="excelBtn"
                  aria-controls={
                    this.state.downloadOpen ? "dropdown-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={this.state.downloadOpen ? "true" : undefined}
                  style={{
                    background: "#5ac489",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                  startIcon={<i class="fa fa-download"></i>}
                  // onClick={(e) => this.downloadDayExcelFromBackend()}
                >
                  Download Excel
                </Button>
              </span>
            </div>
          </div>
        )}
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <Row md={12}>
              <Tabs
                selectedIndex={selectedHeaderIndex}
                md={12}
                style={{
                  width: "100%",
                  color: "#1b7189",
                  outline: "none",
                  // margin: "0",
                }}
              >
                <TabList
                  style={{
                    minWidth: "5em",
                    color: "#1b7189",
                    outline: "none",
                    margin: "0",
                  }}
                >
                  {headerTabList}
                </TabList>
                {headerTabPanelList}
              </Tabs>
            </Row>
          </div>
        </Menu>
        <Menu
          anchorEl={this.state.anchorEl1}
          id="dropdown-menu1"
          className="filterDropdownMenu"
          open={this.state.downloadOpen}
          onClose={this.handleExcelDownloadDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <ValidatorForm
              style={{ width: "100%", color: "#000" }}
              ref="dateForm"
              onSubmit={this.downloadDayExcelFromBackend.bind(this)}
            >
              <div className="row">
                <h5 className="bold">Download Excel</h5>
                <div className="row mt-2 d-flex">
                  <div className="col-md-12" id="assetFormLabel">
                    <FormControlLabel
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0px",
                        padding: "0",
                      }}
                      control={
                        <Switch
                          variant="outlined"
                          // id="isAsset"
                          checked={this.state.excelSwichCheck}
                          value={this.state.excelSwichCheck}
                          onChange={this.handleExcelSwichCheckChange.bind(this)}
                          style={{ background: "none", margin: "8px" }}
                        />
                      }
                      label={<span>Custom search </span>}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  {!this.state.excelSwichCheck && (
                    <div className="col-md-12" id="assetFormLabel">
                      <label
                        className="form-label "
                        // htmlFor="collapsible-companyName"
                      >
                        Financial Year
                      </label>
                      <br />
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        variant="outlined"
                        id="supplierList"
                        options={this.financialsYearArray}
                        value={this.state.excelFinancialsYear}
                        defaultValue={this.state.excelFinancialsYear}
                        getOptionLabel={(option) => option}
                        style={{ background: "#fff", borderRadius: "5px" }}
                        onChange={(event, value) => {
                          this.updateExcelFinancialYear(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Financial Year"
                            className="regCountry"
                            InputLabelProps={{
                              fontSize: "small !important",
                              style: { fontSize: "small !important" },
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  {this.state.excelSwichCheck && (
                    <>
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label "
                          // htmlFor="collapsible-companyName"
                        >
                          Start Date
                        </label>
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              // required
                              value={this.state.excelStartDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleExcelStartDateChange.bind(
                                this
                              )}
                              helperText={
                                this.state.excelErrorMassage
                                  ? "Start Date is Required"
                                  : ""
                              }
                              error={this.state.excelErrorMassage}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          End Date
                        </label>{" "}
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              // required
                              value={this.state.excelEndDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleExcelEndDateChange.bind(
                                this
                              )}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      {this.state.excelError ? (
                        <lable className="errorMassageShow">
                          End Date should be after Start Date
                        </lable>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 mt-2" id="assetFormLabel">
                    <FormControlLabel
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          id="isAsset"
                          checked={this.state.isExcelAsset}
                          value={this.state.isExcelAsset}
                          onChange={this.handleExcelAssetChange.bind(this)}
                          style={{ background: "none" }}
                        />
                      }
                      label={<span style={{}}>Is Capital Asset?</span>}
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    className="fileterButton"
                    // onClick={this.onFilterClick.bind(this)}
                    // onClick={(e) => this.filterRecords(e)}
                    // onClick={this.getExpenseHistoryData.bind(this)}
                  >
                    <i className="fa fa-download"></i>
                    &nbsp; Download
                  </button>
                </div>
              </div>
            </ValidatorForm>
          </div>
        </Menu>
        <Row md={12}>
          <Tabs
            selectedIndex={selectedHeaderIndex}
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            {/* <TabList
              style={{
                minWidth: "5em",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              {headerTabList}
            </TabList> */}
            {/* {headerTabPanelList} */}
          </Tabs>
        </Row>

        <Row md={12} style={{ marginTop: "1em" }}>
          <Tabs
            selectedIndex={selectedIndex}
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            <TabList
              style={{
                minWidth: "5em",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              {tabList}
            </TabList>
            {tabPanelList}
          </Tabs>
        </Row>
      </div>
    );
  }
}

export default withStyles()(ExpenseHistory);

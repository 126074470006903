import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DoneIcon from "@mui/icons-material/Done";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, InputAdornment } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { NavLink } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import MaterialTable from "material-table";
import FetchServerData from "../../../../provider/FetchServerData";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import Address from "../../address/Address";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import "./Supplier.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import BackIconButton from "../../buttons/BackIconButton";
import UiUtils from "../../../../provider/UiUtils";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
export class Supplier extends Component {
  countryList = store.getState().staticData.countryList;
  supplierTypeList = store.getState().appData.supplierTypeList;
  businessTypeList = store.getState().appData.businessTypeList;
  supplierCategoryList = store.getState().appData.supplierCategoryList;
  rcmSupplierCategories = store.getState().appData.rcmServiceCategories;
  menuWithBreadScrumRef = null;
  originalRcmServiceCodes = [];
  breadCrumb = [];

  title = "Supplier";
  constructor(props) {
    super(props);
    let supplierId = props.supplierId;
    let action = props.action;
    if (
      !supplierId &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      supplierId = props.history.location.state.supplierId;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    let canRender = false;
    if (supplierId && supplierId > 0) {
      this.getSupplierDetails(supplierId);
    } else {
      canRender = true;
    }
    this.state = {
      id: supplierId,
      name: "",
      description: "",
      gstnverified: false,
      pan: "",
      gstn: "",
      phone: "",
      email: "",
      tradeName: "",
      usetnforinvoicing: false,
      isTradeNameApplicable: false,
      invoicetandc: "",
      isrcm: "No",
      rcmServiceCode: false,
      supplierType:
        this.supplierTypeList && this.supplierTypeList.length > 0
          ? this.supplierTypeList[0]
          : {},
      businesstype: null,
      address: {},
      rcmSuppCategory: {},
      suppStatus: "InActive",
      supplierStatus: {},
      gstregstatus: "",
      suppCategoryId: "",
      privileges: [],
      selectedSupplierCategory: this.supplierCategoryList[0],
      supRcmCodes: [],
      selectedSupplierRcmServiceList:
        this.rcmSupplierCategories && this.rcmSupplierCategories.length > 0
          ? this.rcmSupplierCategories[0].supplierservices
          : [],
      selectedSupplierServiceCodeList:
        this.rcmSupplierCategories && this.rcmSupplierCategories.length > 0
          ? this.rcmSupplierCategories[0].supplierservices.length > 0
            ? this.rcmSupplierCategories[0].supplierservices[0].rcmServices
            : []
          : [],
      rcmSuppServicetype: {},
      rcmSupplierServiceCode: [],
      supplieInvoices: [],
      action: action,
      selected: false,
      setSelected: false,
      showSuccessAlert: false,
      showFailAlert: false,
      showGSTN: true,
      isaccessSupplierInvoices: false,
      selectedRcmSupplierCategory:
        this.rcmSupplierCategories && this.rcmSupplierCategories.length > 0
          ? this.rcmSupplierCategories[0]
          : null,
      selectedRcmSupplierService:
        this.rcmSupplierCategories && this.rcmSupplierCategories.length > 0
          ? this.rcmSupplierCategories[0].supplierservices.length > 0
            ? this.rcmSupplierCategories[0].supplierservices[0]
            : null
          : null,
      selectedRcmServiceCodes: [],
      render: canRender,
      pageSize: 10,
      initialStep: 0,
      copied: false,
    };
    // if (action !== 'create' &&  supplierId && supplierId > 0) {
    //   this.getSupplierDetails(supplierId);
    // } else {
    //   this.setState({ render: true });
    // }
  }

  handleSupplierCategoryChange(supplierCategory) {
    if (null === supplierCategory || supplierCategory === "") {
      return;
    }

    if (
      this.state.selectedSupplierCategory &&
      supplierCategory.id === this.state.selectedSupplierCategory.id
    ) {
      /* Same Category */
    } else {
    }

    this.setState({
      selectedSupplierCategory: supplierCategory,
    });
  }

  handleSupplierTypeChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }

    if (
      Utils.equalsIgnoreCase(selectedType.type.toUpperCase(), "UNREGISTERED")
    ) {
      // document.getElementById("gstn").disabled = true;
      this.setState({
        supplierType: selectedType,
        selectedSupplierCategory: this.supplierCategoryList[1],
        country: {},
        // gstn: this.state.gstn,
        isTradeNameApplicable: false,
        showGSTN: false,
      });
    } else {
      // document.getElementById("gstn").disabled = false;
      this.setState({
        supplierType: selectedType,
        selectedSupplierCategory: this.supplierCategoryList[0],
        gstn: this.state.gstn,
        showGSTN: true,
      });
    }
  }

  handleGstnOnBlur(e) {
    let gstn = this.state.gstn;
    if (gstn.length > 0) {
      if (gstn && gstn.substr(5, 1) === "P") {
        this.setState({ isTradeNameApplicable: true });
      } else {
        this.setState({ isTradeNameApplicable: false });
      }
      if (
        Utils.equalsIgnoreCase(
          this.state.supplierType && this.state.supplierType.type.toUpperCase(),
          "REGISTERED"
        )
      ) {
        if (null === gstn || gstn.length <= 0) {
          document.getElementById("gstn").focus();
          PtsAlert.error("Enter GSTN");
          return false;
        } else if (gstn.length < 15) {
          document.getElementById("gstn").focus();
          PtsAlert.error("GSTN Must be 15 Characters long");
          return false;
        } else {
          let gstnPattern =
            "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
          let patternArray = gstn.match(gstnPattern);
          if (patternArray === null) {
            PtsAlert.error(
              "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
            );
            return false;
          } else {
            let panNo = gstn.substr(2, 10);
            this.setState({ pan: panNo });
          }
        }
      }
    }
  }
  handleTradeNameChange(e) {
    this.setState({ tradeName: e.target.value });
  }

  handleBusinessTypeChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      businesstype: selectedType,
    });
  }

  handleRcmSuppCategChange(rcmSupplier) {
    if (null === rcmSupplier || rcmSupplier === "") {
      return;
    }
    this.setState({
      selectedRcmSupplierCategory: rcmSupplier,
      selectedSupplierRcmServiceList: rcmSupplier.supplierservices,
      selectedRcmSupplierService:
        rcmSupplier.supplierservices && rcmSupplier.supplierservices.length > 0
          ? rcmSupplier.supplierservices[0]
          : null,
      selectedSupplierServiceCodeList:
        rcmSupplier.supplierservices[0].rcmServices,
    });
  }

  handleRcmSuppServiceChange(rcmSuppService) {
    if (null === rcmSuppService || rcmSuppService === "") {
      return;
    }
    if (
      this.state.selectedRcmSupplierService &&
      rcmSuppService.id === this.state.selectedRcmSupplierService.id
    ) {
      /* Same Category */
      this.setState({
        selectedRcmSupplierService: rcmSuppService,
        selectedSupplierServiceCodeList: rcmSuppService.rcmServices,
      });
    } else {
      // let rcmServiceCodeArray = this.state.selectedRcmServiceCodes === null ? [] : this.state.selectedRcmServiceCodes;
      // for (let i = 0; i < rcmServiceCodeArray.length; i++) {}
      this.setState({
        // selectedRcmServiceCodes: rcmServiceCodeArray,
        selectedRcmSupplierService: rcmSuppService,
        selectedSupplierServiceCodeList: rcmSuppService.rcmServices,
      });
    }
  }

  handleRcmServiceCodeChange(e, codeId) {
    const checked = e.target.checked;
    let selectedRcmCodeNew =
      null === this.state.supRcmCodes ? [] : this.state.supRcmCodes;
    if (checked === true) {
      // let selectedRcmCodeObject = {
      //   operation: "add",
      //   id: codeId,
      // };

      /* If Exists in SupRcmCodes */
      for (
        let i = 0;
        selectedRcmCodeNew && i < selectedRcmCodeNew.length;
        i++
      ) {
        if (
          selectedRcmCodeNew[i].rcmservice &&
          codeId === selectedRcmCodeNew[i].rcmservice.id
        ) {
          selectedRcmCodeNew[i].rcmservice.operation = "update";
          this.setState({ supRcmCodes: selectedRcmCodeNew });
          return;
        }
      }

      let newServiceCode = {
        rcmservice: {
          id: codeId,
          operation: "add",
        },
      };
      selectedRcmCodeNew.push(newServiceCode);
      this.setState({ supRcmCodes: selectedRcmCodeNew });
      return;
      //   /* Dont Add if item was part of original List */
      //   for (let i = 0; i < this.originalRcmServiceCodes.length; i++) {
      //     if (codeId === this.originalRcmServiceCodes[i].id) {
      //       /* If Previous deleted then remove from List */
      //       for (let j = 0; j < selectedRcmCodeArray.length; j++) {
      //         if (codeId === selectedRcmCodeArray[j].id && selectedRcmCodeArray[j].operation === 'delete') {
      //           selectedRcmCodeArray[j] = { id: codeId };
      //           this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //           ////console.log('selectedRcmCodeArray 1' + JSON.stringify(selectedRcmCodeArray));
      //           return;
      //         }
      //       }

      //       /* Add without operation */
      //       selectedRcmCodeArray.push({ id: codeId });
      //       this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //       ////console.log('selectedRcmCodeArray 2' + JSON.stringify(selectedRcmCodeArray));
      //       return;
      //     }
      //   }

      //   /* Deleted Object again Added */
      //   for (
      //     let i = 0;
      //     undefined !== this.state.selectedRcmCodeArray && i < this.state.selectedRcmCodeArray.length;
      //     i++
      //   ) {
      //     if (codeId === selectedRcmCodeArray[i].id) {
      //       selectedRcmCodeArray[i] = selectedRcmCodeObject;
      //       this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //       ////console.log('selectedRcmCodeArray 3' + JSON.stringify(selectedRcmCodeArray));
      //       return;
      //     }
      //   }
      //   /* Add Object */
      //   ////console.log('selectedRcmCodeArray 40' + JSON.stringify(selectedRcmCodeArray));
      //   selectedRcmCodeArray.push(selectedRcmCodeObject);
      //   this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //   ////console.log('selectedRcmCodeArray 4' + JSON.stringify(selectedRcmCodeArray));
      //   return;
    } else {
      // let selectedRcmCodeObject = {
      //   operation: "delete",
      //   id: codeId,
      // };
      /* Delete Only if exists in original List */
      for (
        let i = 0;
        this.originalRcmServiceCodes && i < this.originalRcmServiceCodes.length;
        i++
      ) {
        if (codeId === this.originalRcmServiceCodes[i].id) {
          for (let j = 0; j < selectedRcmCodeNew.length; j++) {
            if (
              selectedRcmCodeNew[j].rcmservice &&
              codeId === selectedRcmCodeNew[j].rcmservice.id
            ) {
              selectedRcmCodeNew[i].rcmservice.operation = "delete";
              this.setState({ supRcmCodes: selectedRcmCodeNew, render: true });
              return;
            }
          }
        }
      }

      for (
        let j = 0;
        selectedRcmCodeNew && j < selectedRcmCodeNew.length;
        j++
      ) {
        if (
          selectedRcmCodeNew[j].rcmservice &&
          codeId === selectedRcmCodeNew[j].rcmservice.id
        ) {
          //   selectedRcmCodeNew.splice(j, 1);
          selectedRcmCodeNew.splice(j, 1);
          this.setState({ supRcmCodes: selectedRcmCodeNew });
          return;
        }
      }

      //   /* Delete Only if exists in original List */
      //   for (let i = 0; i < this.originalRcmServiceCodes.length; i++) {
      //     if (codeId === this.originalRcmServiceCodes[i].id) {
      //       for (let j = 0; j < selectedRcmCodeArray.length; j++) {
      //         if (codeId === selectedRcmCodeArray[j].id) {
      //           selectedRcmCodeArray[j] = selectedRcmCodeObject;
      //           this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //           ////console.log('selectedRcmCodeArray 11' + JSON.stringify(selectedRcmCodeArray));

      //           return;
      //         }
      //       }
      //       selectedRcmCodeArray.push(selectedRcmCodeObject);
      //       this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //       ////console.log('selectedRcmCodeArray 12' + JSON.stringify(selectedRcmCodeArray));
      //       return;
      //     }
      //   }

      //   /* Remove From Array */
      //   for (let i = 0; i < selectedRcmCodeArray.length; i++) {
      //     if (codeId === selectedRcmCodeArray[i].id) {
      //       selectedRcmCodeArray.splice(i, 1);
      //       this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //       ////console.log('selectedRcmCodeArray 13' + JSON.stringify(selectedRcmCodeArray));
      //       return;
      //     }
      //   }
    }
    //this.setState({selectedRcmServiceCodes:selectedRcmCodeArray});
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleGstVerifiedChange(e) {
    this.setState({ gstnverified: e.target.value });
  }

  handlePanChange(e) {
    let val = e.target.value?.toUpperCase().trim();
    this.setState({ pan: val });
  }

  handleGstnChange(e) {
    let val = e.target.value?.toUpperCase().trim();
    this.setState({ gstn: val });
  }

  handleUsetnforinvoicingChange(e) {
    this.setState({
      usetnforinvoicing:
        null === this.state.usetnforinvoicing
          ? false
          : !this.state.usetnforinvoicing,
    });
  }

  handleInvoicetandcChange(e) {
    this.setState({ invoicetandc: e.target.value });
  }
  handleRcmServiceChange(e) {
    if (e.target.checked) {
      this.setState({ isrcm: "Yes" });
    } else {
      this.setState({ isrcm: "No" });
    }
  }

  handleSuppStatusChange(selectedState) {
    if (Utils.equalsIgnoreCase(this.state.suppStatus, "InActive")) {
      this.setState({ suppStatus: "Active" });
    } else {
      this.setState({ suppStatus: "InActive" });
    }
  }

  handleAddressChange(inpuAddress) {
    this.setState({ address: inpuAddress });
  }

  regiStatus(e) {
    this.setState({ registatus: e.target.value });
  }

  handleAccessSupplierInvoicesChange(selectedState) {
    this.setState({
      isaccessSupplierInvoices: !this.state.isaccessSupplierInvoices,
    });
  }
  getSupplierInvoices() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
      supplier: {
        // id: this.state.id,
        gstn: this.state.gstn,
        supTid: this.state.supTid,
        supEmail: this.state.email,
      },
      customerGstn: this.state.customerGstn,
      customerTid: this.state.customerTid,
      customerEmail: this.state.customerEmail,
    };
    FetchServerData.callPostService(
      "/supplierms/getSupInvoices",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let supplieInvoices = output.data;
        for (let i = 0; i < supplieInvoices.length; i++) {
          supplieInvoices[i].invdate = Utils.ptsDateFormat(
            supplieInvoices[i].invdate
          );
        }
        this.setState({
          supplieInvoices: supplieInvoices,
          isaccessSupplierInvoices: !this.state.isaccessSupplierInvoices,
        });
      } else {
      }
    });
  }
  formValidation(e) {
    let gstn = this.state.gstn;
    let panNo = this.state.pan;
    e.preventDefault();
    if (this.addressRef && this.addressRef.current) {
      if (!this.addressRef.current.validateAddress()) {
        return;
      }
    }
    let supplierType = this.state.supplierType;
    if (
      Utils.equalsIgnoreCase(
        supplierType && supplierType.type.toUpperCase(),
        "REGISTERED"
      )
    ) {
      /* GSTN Validation */
      document.getElementById("gstn").required = true;
      if (null === gstn || gstn.length <= 0) {
        document.getElementById("gstn").focus();
        PtsAlert.error("Enter GSTN");
        return false;
      } else if (gstn.length < 15) {
        document.getElementById("gstn").focus();
        PtsAlert.error("GSTN Must be 15 Characters long");
        return false;
      } else {
        let gstnPattern =
          "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
        let patternArray = gstn.match(gstnPattern);
        if (patternArray === null) {
          PtsAlert.error(
            "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
          );
          return false;
        }
      }

      /* PAN Number Validation */
      if (null === panNo || panNo.length <= 0) {
        document.getElementById("pan").focus();
        PtsAlert.error("Enter PAN Number");
        return false;
      } else if (panNo.length < 10) {
        document.getElementById("pan").focus();
        PtsAlert.error("PAN Number Must be 10 Characters long");
      } else {
        let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        let panPatternArray = panNo.match(regpan);
        if (panPatternArray === null) {
          PtsAlert.error(
            "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Characte"
          );
          return false;
        }
      }
    } else if (
      Utils.equalsIgnoreCase(
        supplierType && supplierType.type.toUpperCase(),
        "UNREGISTERED"
      )
    ) {
      this.setState({ gstn: "" });
      /* Check if Customer is from India or Out Of India */
      /*Indian Customer required Pan*/
      let address = this.state.address;
      // if ( address &&  address.country) {
      if (address) {
        if (address.country?.name !== "India") {
          /* Foreign Customer */
        } else {
          /* PAN Number Validation */
          if (null === panNo || panNo.length <= 0) {
            document.getElementById("pan").focus();
            PtsAlert.error("Enter PAN Number");
            return false;
          } else if (panNo.length < 10) {
            document.getElementById("pan").focus();
            PtsAlert.error("PAN Number Must be 10 Characters long");
          } else {
            let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
            let panPatternArray = panNo.match(regpan);
            if (panPatternArray === null) {
              PtsAlert.error(
                "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Characte"
              );
              return false;
            }
          }
        }
      }
    }

    if (this.state.action === "create") {
      return this.createSupplierInBackend(e);
    } else if (this.state.action === "edit") {
      return this.updateSupplierInBackend(e);
    }
  }

  formValidation2(e) {
    let gstn = this.state.gstn;
    let panNo = this.state.pan;

    let suppName = this.state.name;
    let gstnPattern =
      "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    let patternArray = gstn.match(gstnPattern);
    let panPatternArray = panNo.match(regpan);
    gstn = gstn.toUpperCase();

    if (gstn.length === 0) {
      this.setState({
        gstn: e.target.value,
        showFailAlert: true,
        errorMessages: "Enter GSTN No",
      });
    } else if (gstn.length < 15) {
      this.setState({
        gstn: e.target.value,
        showFailAlert: true,
        errorMessages: "Enter 15 Digit GSTN No",
      });
      //errorAlert("Enter 15 Digit GSTN No");
      return false;
    } else if (patternArray === null) {
      this.setState({
        gstn: e.target.value,
        showFailAlert: true,
        errorMessages:
          "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character",
      });
    } else if (suppName.length === 0) {
      this.setState({
        name: e.target.value,
        showFailAlert: true,
        errorMessages: "Enter Name",
      });
    } else if (panNo.length < 10) {
      this.setState({
        pan: e.target.value,
        showFailAlert: true,
        errorMessages: "Enter 10 Digit PAN No",
      });
    } else if (panPatternArray === null) {
      this.setState({
        pan: e.target.value,
        showFailAlert: true,
        errorMessages:
          "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Character",
      });
    } else {
      if (this.state.action === "create") {
        return this.createSupplierInBackend(e);
      } else if (this.state.action === "edit") {
        return this.updateSupplierInBackend(e);
      }
    }
  }

  setSelectedCategoryListDetails(
    selectedRcmSupplierCategory,
    in_selectedRcmSupplierService
  ) {
    if (selectedRcmSupplierCategory) {
      for (let i = 0; i < this.rcmSupplierCategories.length; i++) {
        if (
          this.rcmSupplierCategories[i].id === selectedRcmSupplierCategory.id
        ) {
          let selectedSupplierRcmServiceList =
            this.rcmSupplierCategories[i].supplierservices;
          let selectedSupplierServiceCodeList = [];
          for (let j = 0; j < selectedSupplierRcmServiceList.length; j++) {
            if (
              selectedSupplierRcmServiceList[j].id ===
              in_selectedRcmSupplierService.id
            ) {
              selectedSupplierServiceCodeList =
                selectedSupplierRcmServiceList[j].rcmServices;
            }
          }
          this.setState({
            selectedSupplierRcmServiceList: selectedSupplierRcmServiceList,
            selectedSupplierServiceCodeList: selectedSupplierServiceCodeList,
          });
        }
      }
    }
  }

  getSupplierDetails(supplierId) {
    const header = store.getState().header.header;
    const postObject = {
      supplier: {
        id: supplierId,
      },
      header: header,
    };
    FetchServerData.callPostService("/supplierms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplier = output.data;
          let isTradeNameApplicable = false;
          if (supplier.gstn && supplier.gstn.substr(5, 1) === "P") {
            isTradeNameApplicable = true;
          }
          let selectedRcmSupplierCategory = supplier.supplierServiceCategory;
          let selectedRcmSupplierService = null;
          // let selectedRcmServiceCodes = [];

          if (
            selectedRcmSupplierCategory &&
            selectedRcmSupplierCategory.supplierService &&
            selectedRcmSupplierCategory.supplierService.length > 0
          ) {
            selectedRcmSupplierService =
              selectedRcmSupplierCategory.supplierService[0];
            /* Set Selected Supplier Service CodeList */
            //   await this.setSelectedCategoryListDetails(selectedRcmSupplierCategory, selectedRcmSupplierService);
            //   if ( selectedRcmSupplierCategory) {
            for (
              let i = 0;
              this.rcmSupplierCategories &&
              i < this.rcmSupplierCategories.length;
              i++
            ) {
              if (
                this.rcmSupplierCategories[i].id ===
                selectedRcmSupplierCategory.id
              ) {
                let selectedSupplierRcmServiceList =
                  this.rcmSupplierCategories[i].supplierservices;
                let selectedSupplierServiceCodeList = [];
                for (
                  let j = 0;
                  selectedSupplierRcmServiceList &&
                  j < selectedSupplierRcmServiceList.length;
                  j++
                ) {
                  if (
                    selectedSupplierRcmServiceList[j].id ===
                    selectedRcmSupplierService.id
                  ) {
                    selectedSupplierServiceCodeList =
                      selectedSupplierRcmServiceList[j].rcmServices;
                  }
                }
                this.setState({
                  selectedSupplierRcmServiceList:
                    selectedSupplierRcmServiceList,
                  selectedSupplierServiceCodeList:
                    selectedSupplierServiceCodeList,
                });
              }
            }
            //   }
            if (selectedRcmSupplierService) {
              this.originalRcmServiceCodes = JSON.parse(
                JSON.stringify(selectedRcmSupplierService.rcmSupplierServices)
              );
              // selectedRcmServiceCodes = selectedRcmSupplierService.rcmSupplierServices;
              // for (let i = 0; i < selectedRcmServiceCodes.length; i++) {
              //   selectedRcmServiceCodes[i].operation = 'add';
              // }
              //this.originalRcmServiceCodes=selectedRcmServiceCodes;
            }
          }

          for (
            let i = 0;
            supplier.supRcmCodes && i < supplier.supRcmCodes.length;
            i++
          ) {
            if (supplier.supRcmCodes[i].rcmservice) {
              supplier.supRcmCodes[i].rcmservice.operation = "update";
            }
          }
          // let inIsrcm = false;
          // if (supplier.isrcm && Utils.equalsIgnoreCase(supplier.isrcm, 'YES')) {
          //   inIsrcm = true;
          // }
          this.setState({
            selectedRcmSupplierCategory: selectedRcmSupplierCategory,
            selectedRcmSupplierService: selectedRcmSupplierService,
            // selectedRcmServiceCodes: selectedRcmServiceCodes,
            isrcm: supplier.isrcm,
            supRcmCodes: supplier.supRcmCodes,
            //rcmServiceCode:true,
            id: supplier.id,
            name: supplier.name,
            tradeName: supplier.tradeName,
            usetnforinvoicing: supplier.usetnforinvoicing,
            businesstype: supplier.businesstype,
            selectedSupplierCategory: supplier.category,
            supplierType: supplier.suppliertypevo,
            gstn: supplier.gstn,
            pan: supplier.pan,
            description: supplier.description,
            phone: supplier.phone,
            email: supplier.email,
            registatus: supplier.gstregstatus,
            suppStatus: supplier.supstatus ? supplier.supstatus.status : "",
            address: supplier.addressvo,
            gstregstatus: supplier.gstregstatus,
            privileges: supplier.privileges,
            isTradeNameApplicable: isTradeNameApplicable,
            showGSTN: Utils.equalsIgnoreCase(
              supplier.suppliertypevo?.type,
              "Registered"
            )
              ? true
              : false,
            supTid: supplier.supTid,
            customerGstn: supplier.custGstn,
            customerTid: supplier.custTid,
            customerEmail: supplier.custEmail,
            gstnverified: supplier.gstnverified,
            render: true,
          });
          this.getMenuIconsAsPerStatusAndPermission();
        } else {
        }
      }
    );
  }

  createSupplierInBackend = () => {
    const header = store.getState().header.header;
    let address = this.addressRef.current.state;
    const postObject = {
      supplier: {
        name: this.state.name,
        // usetnforinvoicing: false,
        pan: this.state.pan,
        gstn: this.state.gstn,
        isrcm: this.state.isrcm,
        tradeName: this.state.tradeName,
        description: this.state.description,
        phone: this.state.phone,
        email: this.state.email,
        gstregstatus: this.state.registatus,
        suppliertypevo: this.state.supplierType,
        category: this.state.selectedSupplierCategory,
        businesstype: this.state.businesstype,
        supRcmCodes: this.state.supRcmCodes,
        addressvo: {
          id: address.id,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          pin: address.pincode,
          statevo: {
            id: address.state.id,
            name: address.state.name,
            code: address.state.code,
            countryvo: {
              id: address.country.id,
              name: address.country.name,
              code: address.country.code,
            },
          },
        },
        gstnverified: this.state.gstnverified,
        usetnforinvoicing: this.state.usetnforinvoicing,
      },
      header: header,
    };

    FetchServerData.callPostService("/supplierms/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplier = output.data;
          let supplierMasterState = {
            invoiceId: supplier.id,
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
          };
          store.dispatch(
            ApplicationStateAction.setApplicationState({ supplierMasterState })
          );
          this.props.history.push({
            pathname: "/supplierview",
            state: {
              supplierId: supplier.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  isServiceArrayContainsItem(array, item, currentArray) {
    if (array && array.length <= 0) {
      for (let i = 0; i < array.length; i++) {
        let element = array[i];
        if (
          element.id === item.id &&
          item.operation &&
          item.operation.length > 0
        ) {
          return true;
        } else if (
          element.id + "" === item.id + "" &&
          item.operation.length > 0
        ) {
          return true;
        }
      }
    }
    return false;
  }

  updateSupplierInBackend = () => {
    const header = store.getState().header.header;
    for (
      let i = 0;
      this.state.supRcmCodes && i < this.state.supRcmCodes.length;
      i++
    ) {
      if (
        this.state.supRcmCodes[i].rcmservice &&
        (this.state.supRcmCodes[i].rcmservice.operation === "update" ||
          this.state.supRcmCodes[i].rcmservice.operation === "add")
      ) {
        //this.state.supRcmCodes.splice(i, 1);
        this.state.supRcmCodes[i].operation = "add";
        this.state.supRcmCodes[i].id = null;
      }

      if (
        this.state.supRcmCodes[i].rcmservice &&
        this.state.supRcmCodes[i].rcmservice.operation === "delete"
      ) {
        //this.state.supRcmCodes.splice(i, 1);
        this.state.supRcmCodes[i].operation = "delete";
        //  this.state.supRcmCodes[i].id = null;
      }
    }
    let address = this.addressRef.current.state;
    const postObject = {
      supplier: {
        id: this.state.id,
        name: this.state.name,
        tradeName: this.state.tradeName,
        pan: this.state.pan,
        gstn: this.state.gstn,
        isrcm: this.state.isrcm,
        description: this.state.description,
        supstatus: {
          status: this.state.suppStatus,
        },
        phone: this.state.phone,
        email: this.state.email,
        gstregstatus: this.state.registatus,
        suppliertypevo: this.state.supplierType,
        category: this.state.selectedSupplierCategory,
        businesstype: this.state.businesstype,
        supRcmCodes: this.state.supRcmCodes,
        addressvo: {
          id: address.id,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          pin: address.pincode,
          statevo: {
            id: address.state.id,
            name: address.state.name,
            code: address.state.code,
            countryvo: {
              id: address.country.id,
              name: address.country.name,
              code: address.country.code,
            },
          },
        },
        gstnverified: this.state.gstnverified,
        usetnforinvoicing: this.state.usetnforinvoicing,
      },
      header: header,
    };
    FetchServerData.callPostService("/supplierms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let supplier = output.data;
          this.props.history.push({
            pathname: "/supplierview",
            state: {
              supplierId: this.state.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  deleteSupplier = (id) => {
    const header = store.getState().header.header;
    const postObject = {
      supplier: {
        id: id,
      },
      header: header,
    };
    FetchServerData.callPostService("/supplierms/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplier = output.data;
          this.props.history.push({
            pathname: "/supp",
            state: { supplierId: supplier.id, action: "view" },
          });
        } else {
        }
      }
    );
  };

  editButtonClicked() {
    this.props.history.push({
      pathname: "/supplieredit",
      state: {
        supplierId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
      },
    });
  }
  deleteButtonClicked() {
    this.deleteSupplier(this.state.id);
  }
  cancelEdit() {
    this.props.history.push({
      pathname: "/supplierview/",
      state: {
        supplierId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  }

  cancelView() {
    this.props.history.push({ pathname: "/supp/" });
  }

  getGstn() {
    const header = store.getState().header.header;
    const postObject = {
      gstin: this.state.gstn,
      header: header,
    };
    FetchServerData.callPostService(
      "/publicapi/searchTxPayer",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let gstnDetails = output.data;
        this.setState({
          name: gstnDetails.legalname,
          state: gstnDetails.state,
          address: gstnDetails.address,
          registatus: gstnDetails.status,
          gstnverified: true,
        });
      } else {
      }
    });
  }

  isArrayContainsItem(array, item) {
    if (!array) {
      return false;
    }
    array.forEach((element) => {
      if (element.id === item.id) {
        return true;
      } else if (element.id + "" === item.id + "") {
        return true;
      }
    });
    return false;
  }

  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];
    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }

    if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      buttonList = [...buttonList, "EDIT"];
    }
    if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      buttonList = [...buttonList, "DELETE"];
    }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
        text: "Do you want to delete?",
      });
    }
  }

  viewSupplierInvoices(e, data) {
    this.props.history.push({
      pathname: "/supplierinvoiceview",
      state: {
        supplierId: this.state.id,
        supplierInvoiceId: data.id,
        gstn: this.state.gstn,
        invoiceNumber: data.number,
        invoiceDate: data.invdate,
        supTid: this.state.supTid,
        supEmail: this.state.email,
        customerGstn: this.state.customerGstn,
        customerTid: this.state.customerTid,
        customerEmail: this.state.customerEmail,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  }

  onCopy = (e) => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);

    this.setState({
      copied: true,
    });
  };

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.addressRef = React.createRef();
    this.menuWithBreadScrumRef = React.createRef();
    if (this.state.action === "create") {
      return this.createSupplier();
    }
    if (this.state.action === "edit") {
      return this.editSupplier();
    } else {
      return this.viewSupplier();
    }
  }
  editSupplier() {
    let isActiveSupplier = null;

    if (
      Utils.equalsIgnoreCase(
        this.state.suppStatus !== null && this.state.suppStatus,
        "Active"
      )
    ) {
      isActiveSupplier = (
        <ToggleButton
          className="ToggleButtonActive"
          onChange={this.handleSuppStatusChange.bind(this)}
        >
          Active
        </ToggleButton>
      );
    } else {
      isActiveSupplier = (
        <ToggleButton
          className="ToggleButtonInActive"
          onChange={this.handleSuppStatusChange.bind(this)}
        >
          InActive
        </ToggleButton>
      );
    }

    let serviceCodeArray = [];
    // this.state.selectedSupplierServiceCodeList.forEach((item) => {
    for (
      let i = 0;
      this.state.selectedSupplierServiceCodeList &&
      i < this.state.selectedSupplierServiceCodeList.length;
      i++
    ) {
      let item = this.state.selectedSupplierServiceCodeList[i];
      let flag = false;
      if (this.state.supRcmCodes && this.state.supRcmCodes.length > 0) {
        // this.state.supRcmCodes.forEach((element) => {
        for (
          let j = 0;
          this.state.supRcmCodes && j < this.state.supRcmCodes.length;
          j++
        ) {
          let element = this.state.supRcmCodes[j];

          if (
            element.rcmservice &&
            element.rcmservice.id === item.id &&
            element.rcmservice.operation !== "delete"
          ) {
            flag = true;
          } else if (
            element.rcmservice &&
            element.rcmservice.id + "" === item.id + "" &&
            element.rcmservice.operation !== "delete"
          ) {
            flag = true;
          }
        }
      }

      if (flag) {
        serviceCodeArray.push(
          <li>
            <span style={{}}>
              <Checkbox
                checked={true}
                //defaultChecked={ServiceCode.checked}
                value={item.id}
                // onChange={this.handleRcmServicecode}
                onChange={(e) => this.handleRcmServiceCodeChange(e, item.id)}
                name="checkedB"
                color="primary"
              />
              {item.description}
            </span>
          </li>
        );
      } else {
        serviceCodeArray.push(
          <li>
            <span>
              <Checkbox
                checked={false}
                //defaultChecked={ServiceCode.checked}
                value={item.id}
                // onChange={this.handleRcmServicecode}
                onChange={(e) => this.handleRcmServiceCodeChange(e, item.id)}
                name="checkedB"
                color="primary"
              />
              {item.description}
            </span>
          </li>
        );
      }
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Edit Supplier"
          history={this.props.history}
          name="Edit Supplier"
          domainObject="Supplier"
        />
        <div style={{ padding: "1em", width: "100%" }} className="supplier_gst">
          <ValidatorForm ref="form" onSubmit={this.formValidation.bind(this)}>
            <Row>
              <Col
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1em",
                }}
              >
                <Card style={{ flexGrow: "1" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Supplier Details</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Supplier Type<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="supplierType"
                          ListboxProps={{
                            className: "myCustomList",
                          }}
                          options={
                            null === this.supplierTypeList
                              ? []
                              : this.supplierTypeList
                          }
                          value={this.state.supplierType}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleSupplierTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              className="regCountry"
                              InputLabelProps={{ shrink: true }}
                              required
                              {...params}
                              // label="Supplier Type"
                            />
                          )}
                        />
                      </Col>

                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Business Type<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="businesstype"
                          ListboxProps={{
                            className: "myCustomList",
                          }}
                          options={
                            null === this.businessTypeList
                              ? []
                              : this.businessTypeList
                          }
                          value={this.state.businesstype}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleBusinessTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              required
                              {...params}
                              placeholder="Business Type"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    {this.state.showGSTN ? (
                      <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                        <Col md={6} style={{ padding: "5px" }}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            GSTN
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="gstn"
                            type="text"
                            placeholder="GSTN"
                            validators={["required"]}
                            errorMessages={["GSTN is required"]}
                            onChange={this.handleGstnChange.bind(this)}
                            onBlur={this.handleGstnOnBlur.bind(this)}
                            value={this.state.gstn}
                            name="gstn"
                            //InputLabelProps={{ shrink: true,  }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <RefreshIcon
                                    className="gstnBtn"
                                    onClick={(e) => this.getGstn()}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row></Row>
                    )}
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={12} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                          id="name"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                          name="name"
                          validators={["required"]}
                          errorMessages={["Customer Name is Required"]}
                          autoComplete="name"
                          className="textField"
                        />
                      </Col>
                    </Row>
                    {true === this.state.isTradeNameApplicable && (
                      <>
                        <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Trade Name
                            </label>
                            <TextField
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              id="tradeName"
                              placeholder="Trade Name"
                              value={this.state.tradeName}
                              onChange={this.handleTradeNameChange.bind(this)}
                              name="tradename"
                              className="textField"
                            />
                          </Col>
                        </Row>
                        <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                          <Col md={12} style={{ padding: "5px" }}>
                            <label style={{ width: "50%", fontWeight: "500" }}>
                              Use Trade Name For Invoice?
                            </label>
                            {this.state.usetnforinvoicing === true && (
                              <ToggleButton
                                className="ToggleButtonActive"
                                onChange={this.handleUsetnforinvoicingChange.bind(
                                  this
                                )}
                              >
                                Yes
                              </ToggleButton>
                            )}
                            {this.state.usetnforinvoicing === false && (
                              <ToggleButton
                                className="ToggleButtonInActive"
                                onChange={this.handleUsetnforinvoicingChange.bind(
                                  this
                                )}
                              >
                                No
                              </ToggleButton>
                            )}
                          </Col>
                        </Row>{" "}
                      </>
                    )}
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={12} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Description
                        </label>
                        <TextareaAutosize
                          variant="outlined"
                          fullWidth
                          id="description"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "0.5px solid #D5D8DC",
                          }}
                          minRows={2}
                          // label="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                          autoComplete="description"
                          placeholder="Description"
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          PAN<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          required
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          id="pan"
                          placeholder="PAN"
                          value={this.state.pan}
                          onChange={this.handlePanChange.bind(this)}
                          name="pan"
                        />
                      </Col>

                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Supplier Category
                        </label>
                        <Autocomplete
                          id="category"
                          ListboxProps={{
                            className: "myCustomList",
                          }}
                          disabled
                          options={
                            null === this.supplierCategoryList
                              ? []
                              : this.supplierCategoryList
                          }
                          value={this.state.selectedSupplierCategory}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleSupplierCategoryChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...params}
                              // label="Supplier Category"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Phone Number
                        </label>
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          id="phone"
                          placeholder="Phone Number"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                          name="phone"
                          type="phone"
                        />
                      </Col>

                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Email
                        </label>
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          id="email"
                          placeholder="Email"
                          value={this.state.email}
                          name="email"
                          type="email"
                          onChange={this.handleEmailChange.bind(this)}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Supplier Status
                        </label>
                        <span> {isActiveSupplier}</span>
                      </Col>
                    </Row>
                    {!this.state.showGSTN ? (
                      <Row style={{ alignItems: "flex-end", padding: "16px" }}>
                        <Col md={6} style={{ padding: "16px" }}></Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} style={{ padding: "1em" }}>
                {/* <Row style={{ marginBottom: "1em" }}> */}
                <Card style={{ flexGrow: "1", paddingBottom: "1em" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Registered Address</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Address
                      title=""
                      ref={this.addressRef}
                      data={this.state.address}
                      onChange={this.handleAddressChange.bind(this)}
                      action="edit"
                    />
                  </CardBody>
                </Card>
                {/* </Row> */}

                {/* <Row> */}
                <Card
                  style={{
                    width: "-webkit-fill-available",
                    height:
                      true === this.state.isTradeNameApplicable
                        ? "55.5%"
                        : "46%",
                    position: "relative",
                    marginTop: "1em",
                  }}
                >
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Other Information</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Row style={{ padding: "5px", alignItems: "flex-end" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Registration Status
                        </label>
                        <TextField
                          variant="outlined"
                          disabled
                          fullWidth
                          id="registatus"
                          placeholder="Registration Status"
                          value={this.state.registatus}
                          name="registatus"
                          autoComplete="registatus"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Col>
                      {/* </Row>
                        <Row style={{ padding: '5px', alignItems: 'flex-end' }}> */}
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          GST verified
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled
                          id="gstverified"
                          placeholder="GST verified"
                          value={this.state.gstnverified ? "Yes" : "No"}
                          onChange={this.handleGstVerifiedChange.bind(this)}
                          name="gstverified"
                          autoComplete="gstverified"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* </Row> */}
              </Col>
            </Row>
            <Row className="supplierRcm">
              <Col>
                <Card style={{ padding: "1em" }}>
                  <CardBody>
                    <Row>
                      <Col md={6} style={{ padding: "5px" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.isrcm &&
                                Utils.equalsIgnoreCase(this.state.isrcm, "Yes")
                                  ? true
                                  : false
                              }
                              onChange={this.handleRcmServiceChange.bind(this)}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="RCM"
                        />
                      </Col>
                    </Row>

                    {this.state.isrcm &&
                    Utils.equalsIgnoreCase(this.state.isrcm, "Yes") ? (
                      <div>
                        <Row>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Supplier Category
                            </label>
                            <Autocomplete
                              id="supplierCategory"
                              ListboxProps={{
                                className: "myCustomList",
                              }}
                              options={
                                null === this.rcmSupplierCategories
                                  ? []
                                  : this.rcmSupplierCategories
                              }
                              value={this.state.selectedRcmSupplierCategory}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleRcmSuppCategChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  {...params}
                                  className="regCountry"
                                  // label="Supplier Category"
                                />
                              )}
                            />
                          </Col>
                          {/* </Row>
                        <Row> */}
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Supplier Service
                            </label>
                            <Autocomplete
                              id="rcmSuppServicetype"
                              ListboxProps={{
                                className: "myCustomList",
                              }}
                              options={
                                null ===
                                this.state.selectedSupplierRcmServiceList
                                  ? []
                                  : this.state.selectedSupplierRcmServiceList
                              }
                              value={this.state.selectedRcmSupplierService}
                              getOptionLabel={(option) => option.type}
                              onChange={(event, value) => {
                                this.handleRcmSuppServiceChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  {...params}
                                  className="regCountry"
                                  // label="Supplier Service"
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <div style={{}}>
                            <ul>{serviceCodeArray}</ul>
                          </div>
                        </Row>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row md={12} style={{ marginTop: "2em" }}>
              <Col>
                <CardFooter style={{ width: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      style={{ backgroundColor: "#1a7088", color: "#fff" }}
                      variant="contained"
                      // onClick={(e) => this.formValidation(e)}
                    >
                      Save
                    </Button>
                    &nbsp;
                    {/* <Button
                      style={{ backgroundColor: '#777777', color: '#fff' }}
                      onClick={e => this.cancel()}
                    >
                      Cancle
                    </Button> */}
                    <NavLink
                      style={{ backgroundColor: "#777777", color: "#fff" }}
                      to={{
                        pathname: "/supplierview/",
                        state: {
                          supplierId: this.state.id,
                          action: "view",
                          breadCrumb: this.breadCrumb,
                        },
                      }}
                      activeClassName="selectedLink"
                      strict
                    >
                      <Button
                        style={{ backgroundColor: "#777777", color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </NavLink>
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </ValidatorForm>
        </div>
      </>
    );
  }

  createSupplier() {
    let serviceCodeArray = [];
    // this.state.selectedSupplierServiceCodeList.forEach((item) => {
    for (
      let i = 0;
      this.state.selectedSupplierServiceCodeList &&
      i < this.state.selectedSupplierServiceCodeList.length;
      i++
    ) {
      let item = this.state.selectedSupplierServiceCodeList[i];
      let flag = false;
      if (this.state.supRcmCodes && this.state.supRcmCodes.length > 0) {
        // this.state.supRcmCodes.forEach((element) => {
        for (
          let j = 0;
          this.state.supRcmCodes && j < this.state.supRcmCodes.length;
          j++
        ) {
          let element = this.state.supRcmCodes[j];

          if (
            element.rcmservice &&
            element.rcmservice.id === item.id &&
            element.rcmservice.operation !== "delete"
          ) {
            flag = true;
          } else if (
            element.rcmservice &&
            element.rcmservice.id + "" === item.id + "" &&
            element.rcmservice.operation !== "delete"
          ) {
            flag = true;
          }
        }
      }

      if (flag) {
        serviceCodeArray.push(
          <li>
            <span>
              <Checkbox
                checked={true}
                //defaultChecked={ServiceCode.checked}
                value={item.id}
                // onChange={this.handleRcmServicecode}
                onChange={(e) => this.handleRcmServiceCodeChange(e, item.id)}
                name="checkedB"
                color="primary"
              />
              {item.description}
            </span>
          </li>
        );
      } else {
        serviceCodeArray.push(
          <li>
            <span>
              <Checkbox
                checked={false}
                //defaultChecked={ServiceCode.checked}
                value={item.id}
                // onChange={this.handleRcmServicecode}
                onChange={(e) => this.handleRcmServiceCodeChange(e, item.id)}
                name="checkedB"
                color="primary"
              />
              {item.description}
            </span>
          </li>
        );
      }
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="create Supplier"
          history={this.props.history}
          name="Create Supplier"
          domainObject="Supplier"
        />
        <div style={{ padding: "1em", width: "100%" }} className="supplier_gst">
          <ValidatorForm ref="form" onSubmit={this.formValidation.bind(this)}>
            <Row>
              <Col
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1em",
                }}
              >
                <Card style={{ flexGrow: "1" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Supplier Details</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Supplier Type<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="supplierType"
                          ListboxProps={{
                            className: "myCustomList",
                          }}
                          options={
                            null === this.supplierTypeList
                              ? []
                              : this.supplierTypeList
                          }
                          value={this.state.supplierType}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleSupplierTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              className="regCountry"
                              InputLabelProps={{ shrink: true }}
                              required
                              {...params}
                              // label="Supplier Type"
                            />
                          )}
                        />
                      </Col>

                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Business Type<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="businesstype"
                          ListboxProps={{
                            className: "myCustomList",
                          }}
                          options={
                            null === this.businessTypeList
                              ? []
                              : this.businessTypeList
                          }
                          value={this.state.businesstype}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleBusinessTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              required
                              {...params}
                              placeholder="Business Type"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    {this.state.showGSTN ? (
                      <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                        <Col md={6} style={{ padding: "5px" }}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            GSTN
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="gstn"
                            type="text"
                            placeholder="GSTN"
                            validators={["required"]}
                            errorMessages={["GSTN is required"]}
                            onChange={this.handleGstnChange.bind(this)}
                            onBlur={this.handleGstnOnBlur.bind(this)}
                            value={this.state.gstn}
                            name="gstn"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <RefreshIcon
                                    className="gstnBtn"
                                    onClick={(e) => this.getGstn()}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row></Row>
                    )}
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={12} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                          id="name"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                          name="name"
                          validators={["required"]}
                          errorMessages={["Customer Name is Required"]}
                          autoComplete="name"
                          className="textField"
                        />
                      </Col>
                    </Row>
                    {true === this.state.isTradeNameApplicable && (
                      <>
                        <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Trade Name
                            </label>
                            <TextField
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              id="tradeName"
                              placeholder="Trade Name"
                              value={this.state.tradeName}
                              onChange={this.handleTradeNameChange.bind(this)}
                              name="tradename"
                              className="textField"
                            />
                          </Col>
                        </Row>
                        <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                          <Col md={12} style={{ padding: "5px" }}>
                            <label style={{ width: "50%", fontWeight: "500" }}>
                              Use Trade Name For Invoice?
                            </label>
                            {this.state.usetnforinvoicing === true && (
                              <ToggleButton
                                className="ToggleButtonActive"
                                onChange={this.handleUsetnforinvoicingChange.bind(
                                  this
                                )}
                              >
                                Yes
                              </ToggleButton>
                            )}
                            {this.state.usetnforinvoicing === false && (
                              <ToggleButton
                                className="ToggleButtonInActive"
                                onChange={this.handleUsetnforinvoicingChange.bind(
                                  this
                                )}
                              >
                                No
                              </ToggleButton>
                            )}
                          </Col>
                        </Row>{" "}
                      </>
                    )}
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={12} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Description
                        </label>
                        <TextareaAutosize
                          variant="outlined"
                          fullWidth
                          id="description"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "0.5px solid #D5D8DC",
                          }}
                          minRows={2}
                          // label="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                          autoComplete="description"
                          placeholder="Description"
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          PAN<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          required
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          id="pan"
                          placeholder="PAN"
                          value={this.state.pan}
                          onChange={this.handlePanChange.bind(this)}
                          name="pan"
                        />
                      </Col>

                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Supplier Category
                        </label>
                        <Autocomplete
                          id="category"
                          ListboxProps={{
                            className: "myCustomList",
                          }}
                          disabled
                          options={
                            null === this.supplierCategoryList
                              ? []
                              : this.supplierCategoryList
                          }
                          value={this.state.selectedSupplierCategory}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleSupplierCategoryChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...params}
                              // label="Supplier Category"
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Phone Number
                        </label>
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          id="phone"
                          placeholder="Phone Number"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                          name="phone"
                          type="phone"
                        />
                      </Col>

                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Email
                        </label>
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          id="email"
                          placeholder="Email"
                          value={this.state.email}
                          name="email"
                          type="email"
                          onChange={this.handleEmailChange.bind(this)}
                        />
                      </Col>
                    </Row>
                    {!this.state.showGSTN ? (
                      <Row style={{ alignItems: "flex-end", padding: "20px" }}>
                        <Col md={6} style={{ padding: "20px" }}></Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} style={{ padding: "1em" }}>
                {/* <Row style={{ marginBottom: "1em" }}> */}
                <Card style={{ flexGrow: "1", paddingBottom: "1em" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Registered Address</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Address
                      title=""
                      ref={this.addressRef}
                      data={this.state.address}
                      onChange={this.handleAddressChange.bind(this)}
                      action="create"
                    />
                  </CardBody>
                </Card>
                {/* </Row> */}

                {/* <Row> */}
                <Card
                  style={{
                    width: "-webkit-fill-available",
                    height: "42.8%",
                    position: "relative",
                    marginTop: "1em",
                  }}
                >
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Other Information</strong>
                    </label>
                  </CardHeader>
                  {/* <hr></hr> */}
                  <CardBody>
                    <Row style={{ padding: "5px", alignItems: "flex-end" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Registration Status
                        </label>
                        <TextField
                          variant="outlined"
                          disabled
                          fullWidth
                          id="registatus"
                          placeholder="Registration Status"
                          value={this.state.registatus}
                          name="registatus"
                          autoComplete="registatus"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Col>
                      {/* </Row>
                        <Row style={{ padding: '5px', alignItems: 'flex-end' }}> */}
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          GST verified
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled
                          id="gstverified"
                          placeholder="GST verified"
                          value={this.state.gstnverified ? "Yes" : "No"}
                          onChange={this.handleGstVerifiedChange.bind(this)}
                          name="gstverified"
                          autoComplete="gstverified"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* </Row> */}
              </Col>
            </Row>
            <Row className="supplierRcm">
              <Col>
                <Card style={{ padding: "1em" }}>
                  <CardBody>
                    <Row>
                      <Col md={6} style={{ padding: "5px" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.isrcm &&
                                Utils.equalsIgnoreCase(this.state.isrcm, "Yes")
                                  ? true
                                  : false
                              }
                              onChange={this.handleRcmServiceChange.bind(this)}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="RCM"
                        />
                      </Col>
                    </Row>

                    {this.state.isrcm &&
                    Utils.equalsIgnoreCase(this.state.isrcm, "Yes") ? (
                      <div>
                        <Row>
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Supplier Category
                            </label>
                            <Autocomplete
                              id="supplierCategory"
                              ListboxProps={{
                                className: "myCustomList",
                              }}
                              options={
                                null === this.rcmSupplierCategories
                                  ? []
                                  : this.rcmSupplierCategories
                              }
                              value={this.state.selectedRcmSupplierCategory}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleRcmSuppCategChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  {...params}
                                  className="regCountry"
                                  // label="Supplier Category"
                                />
                              )}
                            />
                          </Col>
                          {/* </Row>
                        <Row> */}
                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Supplier Service
                            </label>
                            <Autocomplete
                              id="rcmSuppServicetype"
                              ListboxProps={{
                                className: "myCustomList",
                              }}
                              options={
                                null ===
                                this.state.selectedSupplierRcmServiceList
                                  ? []
                                  : this.state.selectedSupplierRcmServiceList
                              }
                              value={this.state.selectedRcmSupplierService}
                              getOptionLabel={(option) => option.type}
                              onChange={(event, value) => {
                                this.handleRcmSuppServiceChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  {...params}
                                  className="regCountry"
                                  // label="Supplier Service"
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <div style={{}}>
                            <ul>{serviceCodeArray}</ul>
                          </div>
                        </Row>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row md={12} style={{ marginTop: "2em" }}>
              <Col>
                <CardFooter style={{ width: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      style={{ backgroundColor: "#1a7088", color: "#fff" }}
                      variant="contained"
                      // onClick={(e) => this.formValidation(e)}
                    >
                      Create
                    </Button>
                    &nbsp;
                    {/* <Button
                      style={{ backgroundColor: '#777777', color: '#fff' }}
                      onClick={e => this.cancel()}
                    >
                      Cancle
                    </Button> */}
                    <NavLink
                      style={{ backgroundColor: "#777777", color: "#fff" }}
                      to={{
                        pathname: "/supp/",
                        state: {
                          // supplierId: this.state.id,
                          // action: "view",
                          breadCrumb: this.breadCrumb,
                        },
                      }}
                      activeClassName="selectedLink"
                      strict
                    >
                      <Button
                        style={{ backgroundColor: "#777777", color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </NavLink>
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </ValidatorForm>
        </div>
      </>
    );
  }
  viewSupplier() {
    let rcmServiceList = [];
    if (this.state.supRcmCodes && this.state.supRcmCodes.length > 0) {
      this.state.supRcmCodes.forEach((item, index) => {
        if (item.rcmservice) {
          rcmServiceList.push(
            <li style={{ marginLeft: "1em" }}>
              <span>
                <b>{item.rcmservice.code}</b>{" "}
                {" - " + item.rcmservice.description}
              </span>
            </li>
          );
        }
      });
    }

    return (
      <>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="View Supplier"
          history={this.props.history}
          name="View Supplier"
          domainObject="View Supplier"
          docTitle={this.state.name}
          // editCallback={this.editButtonClicked.bind(this)}
          // deleteCallback={this.deleteButtonClicked.bind(this)}
          backCallback={this.cancelView.bind(this)}
        />
        {/* <div
          style={{ padding: '1em', width: '100%', background: '#f9f8f8', border: '1px solid #efefef' }}
          className="supplier_gst"
        > */}
        <Row md={12} style={{ margin: "auto" }}>
          <Col
            md={6}
            style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}
          >
            <Card style={{ padding: "1em" }}>
              <CardHeader className="cardHeaderStyle">
                <label className="cardHeaderTitle">
                  <strong>Supplier Information</strong>
                </label>
              </CardHeader>
              {/* <hr></hr> */}
              <CardBody>
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Supplier Name</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.name}
                  </Col>
                </Row>
                {true === this.state.isTradeNameApplicable && (
                  <Row style={{ padding: "5px" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Trade Name</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.tradeName}
                    </Col>
                  </Row>
                )}
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Business Type</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {null === this.state.businesstype
                      ? ""
                      : this.state.businesstype.type}
                  </Col>
                </Row>
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Supplier Type</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {null === this.state.supplierType
                      ? ""
                      : this.state.supplierType.type}
                  </Col>
                </Row>
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>GSTN</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.gstn}
                    {Utils.isNotNullAndEmpty(this.state.gstn) && (
                      <>
                        {!this.state.copied ? (
                          <Tooltip title="Copy" aria-label="add">
                            <IconButton className="ms-4">
                              <CopyToClipboard
                                text={this.state.gstn}
                                onCopy={this.onCopy.bind(this)}
                                options={{
                                  message: "ABCD",
                                }}
                              >
                                <FileCopyIcon
                                  style={{
                                    color: "gray",
                                    fontSize: "20px",
                                  }}
                                />
                              </CopyToClipboard>
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Copied" aria-label="add">
                            <IconButton className="ms-4">
                              <DoneIcon
                                style={{
                                  color: "green",
                                  fontSize: "20px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>PAN</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.pan}
                  </Col>
                </Row>
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Description</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000", wordWrap: "break-word" }}>
                    {this.state.description}
                  </Col>
                </Row>
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Phone</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.phone}
                  </Col>
                </Row>
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Email</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.email}
                  </Col>
                </Row>
                <Row style={{ padding: "5px" }}>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Supplier Status</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.suppStatus}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col
            md={6}
            style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}
          >
            <div md={12} style={{ width: "100%" }}>
              <Card
                md={12}
                style={{ padding: "1em", flexGrow: "1", marginBottom: "1em" }}
              >
                <CardHeader className="cardHeaderStyle">
                  <label className="cardHeaderTitle">
                    <strong>Registered Address</strong>
                  </label>
                </CardHeader>
                {/* <hr></hr> */}
                <CardBody>
                  <Address
                    ref={this.addressRef}
                    data={this.state.address}
                    action="view"
                  />
                </CardBody>
              </Card>
            </div>
            <div
              md={12}
              style={{ display: "flex", flexGrow: "1", width: "100%" }}
            >
              <Card
                md={12}
                style={{ padding: "1em", flexGrow: "1", paddingBottom: "3em" }}
              >
                <CardHeader className="cardHeaderStyle">
                  <label className="cardHeaderTitle">
                    <strong>Other Information</strong>
                  </label>
                </CardHeader>
                {/* <hr></hr> */}
                <CardBody>
                  <Row style={{ padding: "5px" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>GSTN Verified</strong>
                      </label>
                    </Col>
                    <Col md={8}>
                      {this.state.gstnverified &&
                      true === this.state.gstnverified
                        ? "Yes"
                        : "No"}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        {this.state.selectedRcmSupplierCategory?.name ? (
          <Row md={12} style={{ margin: "auto" }}>
            <div
              md={12}
              style={{
                padding: "1em",
                margin: 0,
                flexGrow: "1",
                flexDirection: "column",
              }}
            >
              <Card md={12} style={{ margin: "auto" }}>
                <CardHeader className="cardHeaderStyle">
                  <label className="cardHeaderTitle">
                    <strong>Supplier RCM Services</strong>
                  </label>
                </CardHeader>
                {/* <hr></hr> */}
                <CardBody>
                  <Row>
                    <Col md={1}>
                      <label style={{ color: "#000" }}>
                        <strong>Category</strong>
                      </label>
                    </Col>
                    <Col className="ms-3" md={10}>
                      {null === this.state.selectedRcmSupplierCategory
                        ? ""
                        : this.state.selectedRcmSupplierCategory.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}>
                      <label style={{ color: "#000" }}>
                        <strong>Service</strong>
                      </label>
                    </Col>
                    <Col md={10} className="ms-3">
                      {null === this.state.selectedRcmSupplierService
                        ? ""
                        : this.state.selectedRcmSupplierService.type}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}>
                      <label style={{ color: "#000" }}>
                        <strong>Code</strong>
                      </label>
                    </Col>
                    <Col md={10}>
                      <ul style={{ padding: "0px", textAlign: "left" }}>
                        {rcmServiceList}
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        ) : (
          ""
        )}
        {this.state.supTid !== null ? (
          <Row md={12} style={{ margin: "auto" }}>
            <div
              md={12}
              style={{
                padding: "1em",
                margin: 0,
                flexGrow: "1",
                flexDirection: "column",
              }}
            >
              <Card md={12} style={{ margin: "auto" }}>
                <CardHeader className="cardHeaderStyle">
                  <label className="cardHeaderTitle">
                    <strong>Access Supplier Invoices</strong>
                  </label>
                  {this.state.isaccessSupplierInvoices === true && (
                    <ToggleButton
                      value="Yes"
                      className="ToggleButtonActive"
                      onChange={this.handleAccessSupplierInvoicesChange.bind(
                        this
                      )}
                    >
                      Yes
                    </ToggleButton>
                  )}
                  {this.state.isaccessSupplierInvoices === false && (
                    <ToggleButton
                      value="No"
                      className="ToggleButtonInActive"
                      onChange={this.getSupplierInvoices.bind(this)}
                    >
                      No
                    </ToggleButton>
                  )}
                </CardHeader>
                {this.state.isaccessSupplierInvoices ? (
                  <CardBody className="p-0">
                    <Row>
                      <MaterialTable
                        icons={UiUtils.getMaterialTableIcons()}
                        title=""
                        onRowClick={(event, rowData) => {
                          this.viewSupplierInvoices(event, rowData);
                          event.stopPropagation();
                        }}
                        columns={[
                          {
                            title: "#",
                            render: (rowData) => rowData.tableData.id + 1,
                            cellStyle: {
                              width: "5%",
                            },
                          },
                          {
                            title: "Date",
                            field: "invdate",
                            type: "date",
                            align: "left",
                            width: "10%",
                          },
                          {
                            title: "Invoice Number",
                            field: "number",
                            align: "left",
                            width: "15%",
                          },
                          {
                            title: "Customer",
                            field: "custname",
                            align: "left",
                            width: "20%",
                          },
                          {
                            title: "Taxable Amount",
                            align: "right",
                            width: "15%",
                            render: (rowData) => {
                              return (
                                rowData.currencySymobol +
                                " " +
                                parseFloat(rowData.taxable).toFixed(2)
                              );
                            },
                          },
                          {
                            title: "Invoice Amount",
                            align: "right",
                            width: "15%",
                            render: (rowData) => {
                              return (
                                rowData.currencySymobol +
                                " " +
                                parseFloat(rowData.total).toFixed(2)
                              );
                            },
                          },
                          {
                            title: "Status",
                            field: "statusdesc",
                            align: "center",
                            width: "15%",
                          },
                        ]}
                        data={this.state.supplieInvoices}
                        // onSelectionChange={(rows) => {
                        //   this.rowItemSelected(rows);
                        // }}
                        options={{
                          // selection: true,
                          // showTextRowsSelected: false,
                          sorting: true,
                          pageSize: this.state.pageSize,
                          paging: this.state.isPaging,
                          actionsColumnIndex: -1,
                          headerStyle: {
                            backgroundColor: "#1b7189",
                            fontWeight: "bold",
                            color: "#fff",
                            align: "center",
                          },
                          rowStyle: (rowData) => {
                            // if (rowData.isexistinmain && rowData.isexistinmain === true) {
                            //   return { backgroundColor: "#ecc0a0" };
                            // }
                          },
                        }}
                        style={{
                          boder: "1px solid black",
                        }}
                      />
                    </Row>
                    {/* <Row md={12} className="m-2">
                      <Col md={12} style={{ textAlign: "center", alignItems: "center" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            background: "#1b7189",
                            color: "#fff",
                            marginRight: "1rem",
                          }}
                          startIcon={<ArrowUpwardIcon />}
                        // onClick={(e) => this.bukImportCustomer(e)}
                        >
                          Pull To Purchase
                        </Button>
                      </Col>
                    </Row> */}
                  </CardBody>
                ) : (
                  ""
                )}
              </Card>
            </div>
          </Row>
        ) : (
          ""
        )}
        <Row md={12}>
          <Col>
            <CardFooter style={{ width: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <BackIconButton
                  variant="contained"
                  size="small"
                  style={{
                    background: "#717373",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  // startIcon={<CloseIcon />}
                  onClick={(e) => this.cancelView()}
                />
                {/* Back */}
                {/* </BackIconButton> */}

                {/* <NavLink style={{ backgroundColor: '#777777', color: '#fff' }}
                        to="/supp/"
                        activeClassName="selectedLink"
                        strict>
                    <Button
                      type="button"
                      variant="contained"
                      style={{ backgroundColor: '#1a7088', color: '#fff' }}
                     >
                      Back
                    </Button>
                    </NavLink> */}
              </div>
            </CardFooter>
          </Col>
        </Row>
        {/* </div> */}
      </>
    );
  }
}
export default Supplier;
